import React from "react"
import useToast from "./useToast"
import Snackbar from "@material-ui/core/Snackbar"
import Alert from "@material-ui/lab/Alert"

const Toast = () => {
  const { toastsList, closeToast } = useToast()

  const closeHandler = (reason, id) => {
    if (reason === "clickaway") {
      return
    }
    closeToast(id)
  }

  return (
    <div>
      {toastsList.map((toast, index) => (
        <Snackbar
          key={toast.id}
          open
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          style={{ top: index * 60, marginTop: "25px" }}
          onClose={(e, reason) => closeHandler(reason, toast.id)}
        >
          <Alert
            variant="filled"
            severity={toast.type}
            onClose={(e, reason) => {
              e.stopPropagation()
              closeHandler(reason, toast.id)
            }}
          >
            {toast.message}
          </Alert>
        </Snackbar>
      ))}
    </div>
  )
}

export default Toast
