import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  MainContainer: {
    width: "100%",
    height: 97,
    background: "#FFFFFF",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
    display:'flex',
    flexDirection:'column',
    justifyContent:'center'
  },
  HeaderInner: {
    padding: "2px 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },

  IconWrapper: {
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#81A4FF",
    margin: "0px 20px",
    width: 47,
    height: 44
  },
  icon: {
    width: 24,
    height: 24
  },
  MainHeading: {
    color: "#363E51",
    fontFamily: "Open Sans",
    fontSize: theme.fonts.sizes.fontXL,
    fontWeight: 600,
    textTransform: "uppercase"
  },
  Wrapper: {
    display: "flex",
    alignItems: "center"
  },
  topClass: {
    height: '300px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems:'center',
    width: '350px'
  },

  innerClass: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '90%'
  }
}))

export const newStyles = makeStyles(theme => ({
 

  button: {
    background: "#3971FF",
    margin: "0px 20px",
    "&.MuiButton-containedSecondary": {
      width: "80%",
      height: 38,
      display: "flex",
      alingItems: "center",
      borderRadius: "5px !important",
      "&:hover": {
        background: "#3971FF"
      },
      "& .MuiButton-label": {
        fontFamily: theme.fonts.family.SemiBold,
        fontSize: 15,
        fontWeight: 400,
        textTransform: "capitalize",
        marginTop: 3
      },
      "& .MuiSvgIcon-root": {
        marginTop: -2
      }
    }
  },

}))

export const finalStyles = makeStyles(theme => ({
  MainCont: {
    width: "100%",
    height: 127,
    background: "#FFFFFF",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)"
  },

  topMain: {
    height: 117,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%"
  },

  upperMain: {
    height: 65,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
    alignItems: "center"
  },

  bottomCont: {
    height: 45,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    alignItems: "center"
  },

  flex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center"
  }
}))
