import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  MainContainer: {
    width: "100%",
    height: 97,
    background: "#FFFFFF",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around"
  },
  HeaderInner: {
    padding: "2px 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },

  IconWrapper: {
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#81A4FF",
    margin: "0px 20px",
    width: 47,
    height: 44
  },
  icon: {
    width: 24,
    height: 24
  },
  MainHeading: {
    color: "#363E51",
    fontFamily: "Open Sans",
    fontSize: theme.fonts.sizes.fontXL,
    fontWeight: 600,
    textTransform: "uppercase"
  },
  Wrapper: {
    display: "flex",
    alignItems: "center"
  },
  LogoWrapper: {
    backgroundColor: "white",
    borderRadius: 50,
    padding: 10,
    border: "1px solid #FFFFFF",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.203851)",
    position: "absolute",
    top: -25
  },
  Logo: {
    width: 34,
    height: 32
  },
  search: {
    position: "relative",
    // borderRadius: theme.shape.borderRadius,
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
    borderRadius: 5,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto"
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch"
    }
  },
  button: {
    background: "#3971FF",
    "&.MuiButton-containedSecondary": {
      width: 160,
      height: 30,
      display: "flex",
      alingItems: "center",
      borderRadius: "5px !important",
      "&:hover": {
        background: "#3971FF"
      },
      "& .MuiButton-label": {
        fontFamily: "Open Sans",
        fontSize: 16,
        fontWeight: 700,
        textTransform: "capitalize",
        marginTop: 3
      },
      "& .MuiSvgIcon-root": {
        marginTop: -2
      },
      "&.MuiButton-root": {
        lineHeight: "1 !important",
        letterSpacing: 0
      }
    }
  }
}))

export const dialogStyles = makeStyles(theme => ({
  paper: {
    overflowY: "initial",

    position: "relative"
  },
  topDiv: {
   // height: "540px",
    width: "400px",
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    background: "none",
    alignItems: "center",
    boxShadow: "none"
  },
  LogoWrapper: {
    backgroundColor: "white",
    borderRadius: 50,
    padding: 10,
    border: "1px solid #FFFFFF",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.203851)",
    position: "absolute",
    top: -25
  },
  Logo: {
    width: 34,
    height: 32
  },
  upperDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "400px",
    alignItems: "center",
    background: "white"
  },
  firstDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "80%",
    marginBottom: "20px"
  },

  selectCont: {
    width: "100%",
    height: "38px",
    background: "rgb(244, 244, 244)",
    color: "gray",
    borderRadius: "5px",
    // paddingLeft: "5px",
    border: "1px solid lightgray",
    fontFamily: theme.fonts.family.Light,
    fontSize: "16px",
    paddingLeft: "10px",
    marginBottom: "5px"
  },
  bottomCont: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "80%",
    marginBottom: "20px"
  },

  bottomContLeft: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "50%"
  },

  bottomContRight: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "45%"
  },

  inputCont: {
    width: "100%",
    height: "38px",
    background: "rgb(244, 244, 244)",
    color: "gray",
    borderRadius: "5px",
    border: "1px solid lightgray",
    fontFamily: theme.fonts.family.Light,
    fontSize: "16px",
    paddingLeft: "15px",
    marginBottom: "5px"
  },
  
  button: {
    background: "#3971FF",
    margin: "0px 20px 30px 20px",
    "&.MuiButton-containedSecondary": {
      width: "80%",
      height: 38,
      display: "flex",
      alingItems: "center",
      borderRadius: "5px !important",
      marginTop: "5px",
      "&:hover": {
        background: "#3971FF"
      },
      "& .MuiButton-label": {
        fontFamily: theme.fonts.family.SemiBold,
        fontSize: 15,
        fontWeight: 400,
        textTransform: "capitalize",
        marginTop: 3
      },
      "& .MuiSvgIcon-root": {
        marginTop: -2
      }
    }
  },

  topDiv1: {
    height: "400px",
    width: "400px",
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    background: "none",
    alignItems: "center",
    boxShadow: "none"
  },
  dialogWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    textAlign: "center"
  },
  dialogContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "90%",
    background: "rgb(244, 244, 244)",
    flexWrap: "wrap",
    padding: "20px 20px 5px 20px",
    marginBottom: "25px"
  },
  containerItemLeft: {
    width: "40%",
    display: "flex",
    flexDirection: "column",
    color: "#979797",
    fontWeight: "550",
    "& div": {
      paddingBottom: "15px"
    }
  },
  containerItemRight: {
    width: "40%",
    flexDirection: "column",
    display: "flex",
    alignItems: "flex-end",
    "& div": {
      paddingBottom: "15px"
    }
  },
  subHeading: {
    color: "#979797",

    padding: "20px 25px",
    fontSize: 12,
    fontFamily: "Roboto"
  },
  // .uploadVideoWrapper {
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: center;
  //   align-items: center;
  //   background-color: #f7fafc;
  //   border: 1px dashed #0965e0;
  //   padding: 8px 12px;
  //   box-sizing: border-box;
  //   border-radius: 6px;
  // }
  inputFileWrapper:{
    width: "100%",
    height: "100px",
    background: "#F8FAFF",
    color: "gray",
    borderRadius: "5px",
    border: "1px dashed #3971FF",
    fontFamily: theme.fonts.family.Light,
    boxSizing: "border-box",
    fontSize: "16px",
   // paddingLeft: "15px",
    marginBottom: "5px",
    display: "flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center", 
    textAlign:"center",
    cursor:"pointer"
  },
  imageText:{
    // textAlign:"center",
    // fontFamily: theme.fonts.family.Light,
    // fontSize: "18px",
    // paddingTop:"20px"
   
  },
  uploadInstruction:{
    color: "#3971FF",
    
  },
  imageContainer:{
    display: "flex",
    flexWrap:" wrap",
    justifyContent: "space-between",
    marginTop: 10

    //background: "red"
  },
  imageWrapper:{
    border:"1px solid #C7C7C7",
    padding: "5px 10px",
    boxSizing: "border-box",
    borderRadius: 80,
    width: "fit-content",
    marginBottom:"5px"
    
  }
}))
