import React from 'react'
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme)=>({
    aboveCont:{
        height: '60px',
        width:'100%',
        background:'white',
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-around',
        alignItems:'center' 
    },
    firstCont:{
        width:'150px',
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-around',
        alignItems:'center'
    },
    secondCont:{
        width:'50px',
        display:'flex',
        flexDirection:'row',
        alignItems:'center'
    },
    finalCont:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'space-around',
        height:'500px'
    },
    only:{
        display:"flex",
        alignItems:'center'
    },
    logBtn: {
        backgroundColor: "#3971FF !important",
        borderRadius: "22px !important",
        color: "white !important",
        fontFamily: theme.fonts.family.Medium,
        width:'80px',
        fontSize:'14px',
        marginLeft:'10px',
      },


}))