import React,{useEffect, useState} from 'react';
import CloseIcon from "../../assets/CloseIcon.png";
import {useStyles} from "./ReportViewsStyle";
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import moment from 'moment'

const OpenBalanceView = ({onClose,today,DataArray}) => {
  const classes = useStyles()
 
  const TextClipper = (bio, length) => {
    let vb = ""

    if (bio?.length >= length) {
      for (var i = 0; i < length; i++) {
        vb += bio.charAt(i)
      }
      bio = `${vb}...`
    }

    return bio
  }
  const convertDateFormat = (date)=>{
    return moment(date).format('MM-DD-YYYY')
  }

  const [exportDataArray,setExportDataArray] = useState([])

  const [tableRows,setTableRows] = useState([]);

  const fetchInvoices = () => {

      let table_rows = [];

      // get teritories in response
      let teritories = Object.keys(DataArray.data.data)
      if (teritories.length === 0){
        return table_rows
      }
      else{
        let _exportData = []
        teritories.map((teritory,idx)=>{
          // count total balance for teritory
          let total_for_teritory = 0.0

          // get courts keys in teritories
          let courts = Object.keys(DataArray.data.data[teritory])
          // loop from each court
          courts.map((court,CourtIdx)=>{
            // get total balance for each court
            let total_for_court = 0.0
            // get all users from courts object
            let users = Object.keys(DataArray.data.data[teritory][court])
            // map all users
            users.map((user,userIdx)=>{
              // get total for each user
              let total_for_user = 0.0
              // map each user court monthly invoices
              DataArray.data.data[teritory][court][user].map((user_invoice)=>{
                total_for_user += parseFloat(user_invoice.balance)
                _exportData.push([court,user,user_invoice['invoice#'],convertDateFormat(user_invoice['invoice-date']),user_invoice.balance])
                table_rows.push(<tr className={classes.styleRowWithBackground} key={user_invoice['invoice#']}>
                  <td className={classes.firstColumn}>{TextClipper(court,15)}</td>
                  <td>{TextClipper(user,15)}</td>
                  <td>{TextClipper(user_invoice['invoice#'],15)}</td>
                  <td>{TextClipper(convertDateFormat(user_invoice['invoice-date']),15)}</td>
                  <td>$ {TextClipper(user_invoice.balance,15)}</td>
                </tr>)
              })
              // add user total to court total
              total_for_court += total_for_user
              // 
              _exportData.push([`Total for Client ${user}`,"","","",total_for_user])
              table_rows.push(
                <tr className={classes.styleRowWithoutBackground} key={user+userIdx}>
                   <td className={classes.firstColumn}>{`Total for Client ${user}`}</td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td>$ {total_for_user}</td>
                 </tr>
              )
            })
            total_for_teritory += total_for_court
            _exportData.push([`Total for Court ${court}`,"","","",total_for_court])
            table_rows.push(
              <tr className={classes.styleRowWithoutBackground} key={court+CourtIdx}>
                 <td className={classes.firstColumn}>{`Total for Court ${court}`}</td>
                 <td></td>
                 <td></td>
                 <td></td>
                 <td>$ {total_for_court}</td>
               </tr>
            )
          })
          _exportData.push([`Total for Teritorry ${teritory}`,"","","",total_for_teritory])
          table_rows.push(
            <tr className={classes.styleRowWithoutBackground} key={teritory}>
               <td className={classes.firstColumn}>{`Total for Teritorry ${teritory}`}</td>
               <td></td>
               <td></td>
               <td></td>
               <td>$ {total_for_teritory}</td>
             </tr>
          )
      })
      setExportDataArray(_exportData)
      }
      setTableRows(table_rows)
  }

    useEffect(()=>{
      fetchInvoices()
    },[])

    const exportPDF = () => {
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 or A4
      const orientation = "portrait"; // portrait or landscape
  
      const doc = new jsPDF(orientation, unit, size);
      
      doc.setFontSize(15);
      
      const title = "OPEN BALANCE REPORT";
      const headers = [["Court Name","Client Name","Invoice Number","Invoiced Date","Balance Amount"]];
      const marginLeft = 40;
      let marginTop = 40;

      doc.text(title, marginLeft, marginTop);
      marginTop+=20
      doc.text(`Run Date : ${convertDateFormat(DataArray.data.run_date)}`,marginLeft,marginTop)
      if(DataArray.data.court){
        marginTop+=20
        doc.text(`Court: ${DataArray.data.court}`,marginLeft,marginTop)
      }
      if(DataArray.data.teritory){
        marginTop+=20
        doc.text(`Territory: ${DataArray.data.teritory}`,marginLeft,marginTop)
      }
      marginTop+=20
      doc.text(`From date: ${convertDateFormat(DataArray.data.from_date)}`,marginLeft,marginTop)
      marginTop+=20
      doc.text(`To date: ${convertDateFormat(DataArray.data.to_date)}`,marginLeft,marginTop)

      let content = {
        startY: marginTop+20,
        head: headers,
        body: exportDataArray,
        theme:"plain"
      };
      doc.autoTable(content);
      doc.save("open_balance_report.pdf")
    }


  return (
    <>
      <button onClick={exportPDF} style={{border:"1px solid black",height:"20px",width:"80px",backgroundColor:"#D8D8D8",color:"black",marginLeft:"20px",color:"blue",cursor:"pointer"}}>Export Pdf</button>
      <div className={classes.aligner}>
        <div className={classes.closeIconDiv}>
          <img src={CloseIcon} onClick={onClose} style={{ cursor: 'pointer',paddingRight:"10px",marginTop:"-10px"}} height="15px" />
        </div>
        <div className={classes.firstContLayer} style={{padding:"0px 25px"}}>
          <p>OPEN BALANCE REPORT</p>
          <p>{`Run Date : ${convertDateFormat(DataArray.data.run_date)}`}</p>
        </div>
        <div className={classes.firstContLayer} style={{padding:"0px 25px"}}>
          {
            DataArray.data.court !==null?
            <p>{`Court: ${DataArray.data.court}`}</p>:""
          }
          {
            DataArray.data.teritory !==null?
            <p>{`Territory: ${DataArray.data.teritory}`}</p>:""
          }
          {
            DataArray.data.from_date !==null?
            <p>{`From Date: ${convertDateFormat(DataArray.data.from_date)}`}</p>:""
          }
          {
            DataArray.data.to_date !==null?
            <p>{`To Date: ${convertDateFormat(DataArray.data.to_date)}`}</p>:""
          }
        </div>
        <div className={classes.tableDiv}>
          {tableRows.length > 0?
          <table className="table table-striped" style={{ width: '100%', height: 'auto', borderCollapse:"collapse"}}>
          <thead className={classes.stickyHeader}>
          <tr>

            <td scope="col" className={classes.firstColumn}>Court Name</td>
            <td scope="col" className={classes.openBalanceColumn}>Client Name</td>
            <td scope="col" className={classes.openBalanceColumn}>Invoice Number</td>
            <td scope="col" className={classes.openBalanceColumn}>Invoiced Date</td>
            <td scope="col" className={classes.openBalanceColumn}>Balance Amount</td>
          </tr>
          </thead>
          <tbody style={{ width: '100%',fontFamily:"Open Sans",fontWeight:600 }}>
          {tableRows}
          </tbody>
        </table>:<p className={classes.error}>No Data Found</p>}
        </div>
      </div>
    </>
  );
};

export default OpenBalanceView;