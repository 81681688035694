import React,{ useEffect, useState } from "react";
import { Dialog,Button,makeStyles } from "@material-ui/core";
import { useStyles } from "./ReportsHeaderStyle";
import Logo from '../../assets/AMSLogo.png';
import useToast from "../Toast/useToast";
import axios from "./../../Store/request";
import CircularSpinner from "../CircularProgressUserReport/CircularSpinner";
import CourtDropDownData from "../CourtDropDownData/CourtDropDownData";
import SearchableDropDown from "../SearchableDropdown/SearchableDropDown";
import { useSelector,useDispatch } from "react-redux";
import { GetOpenBalance } from "../../Store/Actions/ReportsAction";

const OpenBalaceReport = ({addOpenBalanceModal,setAddOpenBalanceModal,currentPage,result,setResult}) => {
    const classes = useStyles()
    const useNewStyles = makeStyles(() => ({
        paper: { width: '25%' },
    }));
    const dispatch = useDispatch() 
    const defClasses = useNewStyles()
    const { showToast } = useToast()
    
    const [territories,setTerritories] = useState([]);

    const [openBalanceReport,setOpenBalanceReport] = useState({
        from_date : "",
        to_date : "",
        court : "", 
        territory : "", 
    })
    const [postsPerPage] = useState(10)
    const [openBalanceLoading,setOpenBalanceLoading] = useState(false)

    const getTeritories = async () =>{
        setOpenBalanceLoading(true)
        const response=await axios.get(`/api/v1/courts/territory/`)
        if(response.status===200){
            response.data.map((object)=>{
                territories.push({value:object.id,label:object.name})
            })
            setTerritories(territories)
        }
        setOpenBalanceLoading(false)
    }

    useEffect(()=>{
        getTeritories();
    },[])

    const addOpenBalanceReport = async (e) => {
        e.preventDefault();
        setOpenBalanceLoading(true)
        try{
        const response=await axios.post(`/api/v1/reports/open_balance_report/`,{...openBalanceReport},{headers: {'Content-Type': 'multipart/form-data'}})
        if(response.status===201){
            setOpenBalanceLoading(false)
            setOpenBalanceReport({
                from_date : "",
                to_date : "",
                court : "", 
                territory : "", 
            })
            setAddOpenBalanceModal(false)
            showToast("Open Balance Report Added Successfully", "Success")
            setResult({...result,loading:true})
            try {
                const { data: { results, count }} = await axios.get(`/api/v1/reports/open_balance_report/?limit=${postsPerPage}&offset=${postsPerPage * currentPage - postsPerPage}`)
                setResult({ data: results, count ,loading:false});
            }catch (e) {
              setResult({...result,loading:false})
            }
        }
        }
        catch (e){
            setOpenBalanceLoading(false)
            showToast("Open Balance Report Posting Fail", "error")
        }   
    }

    // disable pastdate.
    const disableToDates=()=>{
        const today = new Date(openBalanceReport.from_date);
        const dd = String(today.getDate() + 1).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    }
    const disableFromDates=()=>{

        const today = new Date(openBalanceReport.to_date);
        const dd = String(today.getDate()-1).padStart(2, "0");
        const mm = String(today.getMonth()+1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;

    }
    const inputChange=(event)=>{
        const {name,value}=event.target;
        setOpenBalanceReport((prevalue)=>{
            return{
                ...prevalue,
                [name]:value
            }
        })
    }

    return (<Dialog
        scroll="body"
        open={addOpenBalanceModal}
        onClose={() => {setAddOpenBalanceModal(false)
        setOpenBalanceReport({
            from_date : "",
            to_date : "",
            court : "", 
            territory : "", 
        })}}
        PaperProps={{
            style: {
              overflow: "unset",
              margin:40
            }
          }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
    >
        {openBalanceLoading?<CircularSpinner onModal/>:(<div className={classes.topDiv}>
            <div className={classes.LogoWrapper}>
                <img src={Logo} className={classes.Logo} />
            </div>
            <form onSubmit={addOpenBalanceReport} className={classes.upperDiv}>
                <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {setAddOpenBalanceModal(false)
                        setOpenBalanceReport({
                            from_date : "",
                            to_date : "",
                            court : "", 
                            territory : "", 
                        })}}
                >
                    <i
                        className="fal fa-times fa-lg"
                        style={{ position: "absolute", top: "3%", right: "4%" }}
                    />
                </div>

                <div className={classes.firstDiv}>
                    <p className={classes.tagStyle}>REPORT TYPE</p>
                    <input
                        type="text"
                        placeholder="Open Balance Report"
                        className={classes.inputCont}
                        required
                        disabled
                        style={{paddingLeft:'15px'}}
                    />
                </div>

                <div className={classes.firstDivCourt}>
                    <p className={classes.tagStyle}>COURT NAME</p>
                    <CourtDropDownData
                        handleSelect={val => setOpenBalanceReport({...openBalanceReport,court:val})}
                        selectedValue={openBalanceReport.court}
                    />
                </div>
                <div className={classes.firstDivCourt}>
                    <p className={classes.tagStyle}>TERRITORY</p>
                    <SearchableDropDown
                        data={territories}
                        isDisabled={territories.length===0}
                        handleClick={val =>{
                            setOpenBalanceReport({ ...openBalanceReport, territory: val })}
                        }
                        selectedValue={openBalanceReport.territory}

                    />
                </div>
                <div className={classes.bottomCont} >
                    <div className={classes.bottomContLeft}>
                        <p className={classes.tagStyle}>FROM DATE</p>
                        <input
                            type="date"
                            name="from_date"
                            className={classes.inputContDate}
                            required
                            // style={{ paddingLeft: '15px' }}
                            value={openBalanceReport.from_date}
                            onChange={inputChange}
                        />
                    </div>
                    <div className={classes.bottomContRight}>
                        <p className={classes.tagStyle}>TO DATE</p>
                        <input
                            type="date"
                            className={classes.inputContDate}
                            name="to_date"
                            required
                            // style={{ paddingLeft: '15px' }}
                            value={openBalanceReport.to_date}
                            onChange={inputChange}
                        />
                    </div>

                </div>

                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    type="submit"
                    disabled={openBalanceReport.to_date===""||openBalanceReport.from_date===""}

                >
                    {"CREATE REPORT"}
                </Button>
            </form>
        </div>)}
    </Dialog>)
}

export default OpenBalaceReport