import { makeStyles } from "@material-ui/core/styles";
const minus = 200

export const useStyles = makeStyles((theme) => ({

    root: {
      display: 'flex',
      margin:"auto",
      position:"fixed",
      top:0,
      height: "100vh",
      alignItems:"center",
      justifyContent: "center",
      padding:8,
    },
    progress:{
      color: "#3971FF"
    }
  }));