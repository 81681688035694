const DataArray=[
  {
    UsetType:"User",
    CourtSystem:  "Court Name",
    PaymentPlan:"Hybrid",
    FirstName:"First",
    MiddleName:"Middle",
    LastName:"Land",
    DateofBirth:"09.28.1984",
    ParoleOfficer:"Name of the officer",
    FullAddress:"Address name",
    EmergencyContact:"Contact Name",
    EmailAddress:"Jogn.doe@example.com",
    ResidentAddress:"Address name",
    HomePhone:"(000) 000-0000",
    CellPhone:"(000) 000-0000",
    AlternativePhone:"(000) 000-0000",
  }
]
const CourtDataArray=[{

  Gender:"Male",
  BirthDay:"Feburary 24th,1982",
  Phno:"111 000 333 555 ",
  Address:"23,Elm Street,Baker",
  City:"Harverton",
  zipCode:"11123",
  MemberStatus:"Active Member",
  RegDate:"February 28th,2017",
  UserType:"Active",
  OfficerFirstname:"John",
  OfficerLastname:"Doe",
  CourtName:"Harvertion",
  Statement:"Selected Satement",
  Emailaddress:"john.doe@example.com",
}]
export default DataArray;
export {CourtDataArray};