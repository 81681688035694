import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  MainContainer: {
    width: "100%",
    height: 97,
    background: "#FFFFFF",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around"
  },
  modal_container: {
    display: "flex",
    justifyContent:"center",
    // padding:"20px",
    // border:"2px solid black",
    flexDirection:"column",
    alignItems:"center",
    maxHeight: "790px",
    width: "456PX",
    left: "31.32%",
    right: "33.06%",
    top: "10.27%",
    bottom: "7.01%",
    padding: "10px",
    background: "#FFFFFF",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
    borderRadius: "8px",
    // overflow:"scroll"
  },
  modal_icon: {
    // border:"2px solid blue",
    display: "flex",
    justifyContent: "center",
    top: "-35px",
    border: "1px solid #FFFFFF",
    padding: "18px",
    position: "absolute",
    // boxShadow: "0px 1px 5px rgb(0 0 0 / 20%)",
    borderRadius: "50px",
    backgroundColor: "white",
    // marginLeft: "180px"
  },
  modal_title: {
    // border:"2px solid red",
    // position: "absolute",
    width: "430px",
    height: "25px",
    marginTop: "10px",
    // left: "547px",
    // top: "174px",

    fontFamily: "Open San",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "25px",
    /* identical to box height */

    textAlign: "center",
    textTransform: "uppercase",

    color: "#363E51"
  },
  modal_body: {
    // border:"2px solid navy",
    // height: "760px",
    marginTop: "10px",
    overflow: "auto"
  },
  paymentSelection: {
    // border:"2px solid yellow",
    padding: "10px",
    display:"flex",
    justifyContent:"center",
    flexDirection: "column",
    // width:"350px",
  },
  modal_payment_input1: {
    // width: "397px",
    height: "60px",
    background: "#EDEDED",
    borderRadius: "4px",
    padding: "10px",
    display: "flex",
    marginTop: "10px"
  },
  modal_payment_img: {

    // background: "#059BBF",
    marginLeft: "10px",
    marginRight: 10,
    marginTop:"10px",
  },
  model_payment_code: {
    // left: "42.57%",
    right: "55%",
    // top: "35%",
    // bottom: "74.47%",
    marginTop:"10px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    /* identical to box height */
    color: "#363E51"
  },
  model_payment_visa: {
    // left: "47%",
    // right: "48.19%",
    // top: "35%",
    // bottom: "74.47%",
    marginLeft:"30px",
    marginTop:"10px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",

    color: "#363E51"
  },
  model_payment_checkbox: {},
  modal_payment_input2: {
    width: "405px",
    height: "60px",
    marginTop: "80px",
    background: "#EDEDED",
    borderRadius: "4px",
    padding: "10px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    display: "flex",
    justifyContent: "start"
  },
  model_payment_newpayment: {
    marginTop: "10px",
    marginLeft: "20px"
  },
  hrLine: {
    width: "400px",
    height: "0px",
    left: "25px",
    top: "240px",
    border: "1px solid #EDEFF0"
  },
  hrLine2: {
    width: "420px",
    height: "0px",
    left: "20px",
    top: "560px",
    border: "1px solid #EDEFF0"
  },
  card: {
    // border:"2px solid black",
    display: "flex",
    justifyContent: "flex-start",
    padding: "5px",
    marginLeft: "10px"
  },
  card_visa: {
    // border:"2px solid red",
    // width:"70px",

    width: "70px",
    height: "45px",
    // left: "486px",

    top: "410px",
    padding: "10px",
    background: "#FFFFFF",
    border: "1.5px solid #3971FF",
    boxSizing: "borderBox",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px"
  },
  visa_img: {
    marginLeft: "5px"
  },
  card_master: {
    // border:"2px solid red",
    // width:"70px",

    width: "70px",
    height: "45px",
    marginLeft: "10px",

    top: "410px",
    padding: "10px",
    background: "#FFFFFF",
    border: " 1.5px solid #979797",
    boxSizing: "border-box",
    borderRadius: "4px"
  },
  master_img: {
    marginLeft: "8px",
    marginTop: "-5px"
  },
  payment_Container: {
    width: "100%",
    height: 44,
    background: "#FFFFFF",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.151907)",
    borderRadius: 2,
    margin: "-20px 0px 35px 0px"
  },
  ContainerInner: {
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    padding: "0 15px",
    position: "relative"
  },
  card_Detail: {
    // border:"2px solid black",
    marginTop: "10px",
    marginLeft:"10px",
    padding: "5px",
    display: "flex",
    flexWrap: "wrap"
    // height:"300px",
  },
  input_name: {
    // left: "33.75%",
    // right: "48.4%",
    top: "49.42%",
    bottom: "46.35%",
    height: "40px",
    background: "#F9F9F9",
    border: "1px solid #EDEDED",
    boxSizing: "borderBox",
    borderRadius: "5px",
    width: "260px",
    padding: "10px"
  },
  input_code: {
    top: "49.42%",
    bottom: "46.35%",
    height: "40px",
    background: "#F9F9F9",
    border: "1px solid #EDEDED",
    boxSizing: "borderBox",
    borderRadius: "5px",
    width: "120px",
    padding: "10px"
  },
  input_expiray: {
    top: "49.42%",
    bottom: "46.35%",
    height: "40px",
    background: "#F9F9F9",
    border: "1px solid #EDEDED",
    boxSizing: "borderBox",
    borderRadius: "5px",
    width: "195px",
    padding: "10px"
  },
  input_name_onCard: {
    // left: "33.75%",
    // right: "48.4%",
    top: "49.42%",
    bottom: "46.35%",
    height: "40px",
    background: "#F9F9F9",
    border: "1px solid #EDEDED",
    boxSizing: "borderBox",
    borderRadius: "5px",
    width: "180px",
    padding: "10px"
  },
  save: {
    marginRight: "-10px"
  },
  input_amount: {
    // top: "100.42%",
    // bottom: "46.35%",
    height: "40px",
    background: "#F9F9F9",
    border: "none",
    fontFamily:"Open Sans",
    fontSize: "16px",
    boxSizing: "borderBox",
    borderRadius: "5px",
    width: "200px",
    paddingLeft: "5px",
    outline:"none"
  },
  amount: {
    // border:"2px solid black",
    marginTop: "15px",
    marginRight: "91px"
  },
  payBtn: {
    // border:"2px solid black",
    marginTop: "45px",
    marginLeft: "55px"
  },
  btnpay: {
    color: " #FFFFFF",
    opacity: "1",
    fontSize: "16px",
    background: "#3971FF",
    boxShadow: "0 1px 5px rgb(0 0 0 / 25%)",
    fontWeight: "bold",
    lineHeight: "22px",

    borderRadius: "5px",
    textTransform: "uppercase",
    width: "300px",
    "&.MuiButton-containedSecondary": {
      "& :hover": {
          background: "#3971FF",
      },
  }
  },
  ListItmes: {
    color: "#31394D",
    fontFamily: theme.fonts.family.Medium,
    fontSize: theme.fonts.sizes.fontM,
    fontWeight: 500
  },
  selection: {
    width: "150px",
    height: "30px",
    borderRadius: "5px",
    paddingLeft: "7px",
    color: "rgb(70,70,250)",
    border: "1px solid lightgray",
    marginLeft: "10px"
  },
  HeaderInner: {
    padding: "2px 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },

  IconWrapper: {
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#81A4FF",
    margin: "0px 20px",
    width: 47,
    height: 44
  },
  icon: {
    width: 24,
    height: 24
  },
  MainHeading: {
    color: "#363E51",
    fontFamily: "Open Sans",
    fontSize: theme.fonts.sizes.fontXL,
    fontWeight: 600,
    textTransform: "uppercase"
  },
  Wrapper: {
    display: "flex",
    alignItems: "center"
  },
  Title: {
    marginRight: "30px"
  },
  button1: {
    background: "#3971FF",
    margin: "0px 20px",
    "&.MuiButton-containedSecondary": {
      // width: '90%',
      height: 38,
      display: "flex",
      alingItems: "center",
      borderRadius: "5px !important",
      "&:hover": {
        background: "#3971FF",
      },
      "& .MuiButton-label": {
        fontFamily: "Open Sans",
        fontSize: 16,
        fontWeight: 700,
        textTransform: "capitalize",
        color:"#FFFFFF",
        lineHeight: "22px",
      },
      "& .MuiSvgIcon-root": {
        marginTop: -2
      }
    }
  },
  btnRecharge: {
    color: " #FFFFFF",
    fontSize: "16px",
    background: "#3971FF",
     fontWeight: "bold",
    lineHeight: "22px",
    textTransform: "uppercase",
   margin: "0px 20px",
    "&.MuiButton-containedSecondary": {
      width: '80%',
      height: 38,
      display: "flex",
      alingItems: "center",
      borderRadius: "5px !important",
      "&:hover": {
        background: "#3971FF",
      },
      "& .MuiButton-label": {
        fontFamily: theme.fonts.family.SemiBold,
        fontSize: 15,
        fontWeight: 400,
        textTransform: "capitalize",
        marginTop: 3,
      },
      "& .MuiSvgIcon-root": {
        marginTop: -2
      }
    }
  },

  button: {
    background: "#B5B5B5",
    margin: "0px 20px",
    "&.MuiButton-containedSecondary": {
      width: 150,
      height: 30,
      display: "flex",
      alingItems: "center",
      borderRadius: "5px !important",
      "&:hover": {
        background: "#B5B5B5"
      },
      "& .MuiButton-label": {
        fontFamily: theme.fonts.family.SemiBold,
        fontSize: 12,
        fontWeight: "bold",
        textTransform: "capitalize",
        marginTop: 3
      },
      "& .MuiSvgIcon-root": {
        marginTop: -2
      },
      "&.MuiButton-root": {
        lineHeight: "0 !important",
        letterSpacing: 0
      }
    }
  },
  newbutton: {
    backgroundColor: "#3971FF !important",
    margin: "0px 20px",
    "&.MuiButton-containedSecondary": {
      width: 150,
      height: 30,
      display: "flex",
      alingItems: "center",
      borderRadius: "5px !important",
      "&:hover": {
        background: "#B5B5B5"
      },
      "& .MuiButton-label": {
        fontFamily: theme.fonts.family.SemiBold,
        fontSize: 12,
        fontWeight: "bold",
        textTransform: "capitalize",
        marginTop: 3
      },
      "& .MuiSvgIcon-root": {
        marginTop: -2
      },
      "&.MuiButton-root": {
        lineHeight: "0 !important",
        letterSpacing: 0
      }
    }
  },


  optionText:{
    color: "#3971FF",
    width:"186px",
  }

}))

export const finalStyles = makeStyles(theme => ({
  MainCont: {
    width: "100%",
    height: 127,
    background: "#FFFFFF",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)"
  },

  topMain: {
    height: 117,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%"
  },

  upperMain: {
    height: 65,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
    alignItems: "center"
  },

  bottomCont: {
    height: 45,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    alignItems: "center"
  },

  flex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center"
  }
}))
