import React, { useState, useEffect } from "react"
import Select, { components } from "react-select"
import { useStyles } from "./SearchableDropDownStyle"


const SearchableDropDown = ({
  data,
  handleClick,
  openModalHandler,
  isMulti = false,
  selectedValue,
  addNew = true,
  inputValue,
  isDisabled=false,
  onKeyDown
}) => {
  const classes = useStyles()
  const [inputData, setInputData] = useState("")
  const OnChangeHandler = e => {
    if (isMulti) {
      const selectedData = e?.map(item => item.value)
      handleClick(selectedData)
    } else {
      handleClick(e.value)
    }
  }
  const sortArray=[]
  data?.map((element)=>{
    sortArray.push(element)
  })

  const sortedusers = sortArray.sort(function(a, b) {
    const nameA = a.label?.toUpperCase(); 
    const nameB = b.label?.toUpperCase(); 
    if (nameA < nameB) {
      return -1; 
    }
    if (nameA > nameB) {
      return 1; 
    }
    return 0;  
  });
  const handleInputChange = e => {
    setInputData(e)
  }

  const onClickHandler = e => {
    e.preventDefault()
    if (openModalHandler) {
      openModalHandler()
    }
    inputValue(inputData)
  }

  const NoOptionsMessage = props => {
      return (
        <components.NoOptionsMessage {...props}>
          <div className={classes.addNewOption}>No Options</div>
        </components.NoOptionsMessage>
      )
    // }
  }

  const selectStyle = base => ({
    ...base,
    "&:hover": { borderColor: "gray" },
    border: "1px solid lightgray",
    boxShadow: "none",
    background: "#F9F9F9"
  })
  return (
    <div>
      <Select
        isMulti={isMulti}
        options={sortedusers}
        onChange={OnChangeHandler}
        maxMenuHeight={130}
        className={classes.selectDropDown}
        components={{ NoOptionsMessage }}
        placeholder="Select"
        isDisabled={isDisabled}
        styles={{ control: base => selectStyle(base) }}
        onInputChange={handleInputChange}
        value={selectedValue!=="" && data?.find(option => option?.value === selectedValue)}
        onKeyDown={onKeyDown}
      />
    </div>
  )
}

export default SearchableDropDown