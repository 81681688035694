import React from 'react';
import Button from '@material-ui/core/Button';
import { useStyles } from './PaginationStyle';

const Pagination = () => {
  const classes = useStyles();

  return (
    <div className={classes.Container}>
      <Button disabled className={classes.Previous}>Previous entries</Button>
      <Button color="secondary" className={classes.Next}>Next entries</Button>
    </div>

  );
}

export default Pagination
