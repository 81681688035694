import React from 'react';
import Button from "@material-ui/core/Button"
import {useStyles} from "./PaymentsStyle";
const Credit_Btn = ({state}) => {
  const classes = useStyles()
  return (
    <>
      {state===1 &&<Button variant="contained" style={{color:"red"}}>CREDIT:$100</Button>}
      {state===3?<Button variant="contained" style={{color:"green"}}>CREDIT:-$100</Button>:""}
    </>
  );
};

export default Credit_Btn;