import React, { useState, useEffect, useContext } from "react";
import Container from '@material-ui/core/Container';
import StorageManagementHeader from "../../Components/StorageManagementHeader/StorageManagementHeader";
import StorageDataTable from "../../Components/StorageDataTable/StorageDataTable";
import Pagination from "../../Components/Pagination/Pagination"
import { useStyles, BtnStyles } from "./FileStorageManagementStyle"
import { DataArray } from "../../Components/PaymentDataTable/PaymentDataArray";
import { useHistory } from "react-router";
import { connect, useDispatch } from "react-redux";
import { auth } from "../../Store/Actions/Auth";
import { filter } from "lodash";
import { ScreenContext } from './../../context/context'
import { Button } from "@material-ui/core";
import axios from '../../Store/request';


const StorageManagement = (props) => {

    const classes = useStyles()
    const btnclasses = BtnStyles()
    const { screenSize, setScreenSize } = useContext(ScreenContext)

    const history = useHistory()

    const [Data, setData] = useState([])
    const [filterTxt, setFilterTxt] = useState('');
    const [tabs, setTabs] = useState([
        {
            index: 1,
            name: 'PDF & CSV',
            selected: true
        },
        {
            index: 2,
            name: 'IMAGES',
            selected: false
        }])
    var filteredData = []

    const [filesArray, setFilesArray] = useState([])

    
    var filteredData = []

    useEffect(() => {
        axios.get('/api/v1/documents/')
            .then(function (response) {
                setFilesArray(response.data.results)
            })
            .catch(function (error) {
                setFilesArray([])
            });
    }, [])

    useEffect(() => {
        if (props.accessToken === null || props.accessToken === "" || props.accessToken === undefined) {
            history.push("/")
        }
    }, [])


    const filterData = (e) => {
        let val = e.target.value
        setFilterTxt(val)
    }

    filteredData = filesArray?.filter(data => {
        return (data.user.toLowerCase().includes(filterTxt?.toLowerCase()))
    })


    useEffect(() => {
        setData(DataArray)
        if (props.accessToken === null || props.accessToken === "" || props.accessToken === undefined) {
            history.push("/")
        }
    }, [])


    const selectChange = (index) => {
        //filter the selected object to update its selected attribute

        const elementsIndex = tabs.findIndex(element => element.index == index)
        let newArray = [...tabs]

        newArray[elementsIndex] = { ...newArray[elementsIndex], selected: true }

        let updatedArray = newArray;

        let latestArray = [...updatedArray]

        for (let i = 0; i < latestArray.length; i++) {
            if (i !== elementsIndex) {
                latestArray[i] = { ...latestArray[i], selected: false }
            }
        }

        let finalArray = latestArray;

        setTabs(finalArray)

    }




    return (
        <div className={classes.MainContainer}>
            <StorageManagementHeader searchText={filterData} />

            <Container maxWidth="lg" className={screenSize > 1400 ? classes.container : classes.containerMob}>
                <div className={btnclasses.BtnContainer}>
                    {
                        tabs.map((tab, i) => {
                            return (
                                <Button onClick={selectChange.bind(this, tab.index)} variant="contained" color={tab.selected ? "" : "secondary"} className={tab.selected ? btnclasses.InvoiceButton : btnclasses.PrepaidButton}>
                                    {tab.name}
                                </Button>
                            )
                        })
                    }

                </div>
                <StorageDataTable DataArray={filteredData} passedIndex={tabs} />
                <Pagination />
            </Container>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        accessToken: state.auth.accessToken
    }
}

export default connect(mapStateToProps, { auth })(StorageManagement);