import { makeStyles } from "@material-ui/core/styles";
const minus = 200

export const useStyles = makeStyles((theme) => ({

    root: {
      display: 'flex',
      margin:"auto",
    //   position:"fixed",
    //   top:0,
        width:"100%",
      height: "100%",
      alignItems:"center",
      justifyContent: "center",
      padding:8,
    },
    progress:{
      color: "#3971FF"
    }
  }));