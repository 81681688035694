import React, { useState, useEffect, useContext, useCallback } from "react"
import StorageIcon from "../../assets/Storage icon.png"
import AddButton from "../Buttons/AddButton"
import { useStyles, newStyles } from "./StorageManagementHeaderStyle"
import SearchBar from "../SearchBar/SearchBar"
import SearchIcon from "@material-ui/icons/Search"
import InputBase from "@material-ui/core/InputBase"
import { AddPaymentModal } from "../AddPaymentModal/AddPaymentModal"
import Logo from "../../assets/AMSLogo.png"
import { ScreenContext } from "./../../context/context"
import { finalStyles } from "./../CourtsHeader/CourtsHeaderStyle"
import { barStyles } from "../SearchBar/SearchBarStyle"
import { Button, Dialog } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import MultiplyBtn from "../../../src/assets/Multiply.png"
import { useDropzone } from "react-dropzone"
import { DropzoneArea } from "material-ui-dropzone"
import "./StorageManagementHeader.css"
import axios from "../../Store/request"
import { connect, useDispatch } from "react-redux"
import { auth } from "../../Store/Actions/Auth"
import useToast from "../Toast/useToast"
import useAuth from "../../utils/useAuth"

const StorageManagementHeader = ({ searchText, accessToken }) => {
  const classes = useStyles()
  const newclasses = newStyles()
  const { showToast } = useToast()

  const [openSignal, setOpenSignal] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const finalclasses = finalStyles()
  const barclasses = barStyles()
  const { screenSize, setScreenSize } = useContext(ScreenContext)
  const [currentFiles, setCurrentFiles] = useState([])
  const [username, setUsername] = useState("")
  const { isIndividualUser } = useAuth()

  useEffect(() => {
    setScreenSize(window.innerWidth)
  }, [screenSize])

  const openModal = () => {
    setOpenSignal(true)
  }

  const useNewStyles = makeStyles(() => ({
    paper: { maxWidth: "800px", width: "70%" }
  }))

  const defClasses = useNewStyles()

  const TextClipper = (bio, length) => {
    let vb = ""

    if (bio?.length >= length) {
      for (var i = 0; i < length; i++) {
        vb += bio.charAt(i)
      }
      bio = `${vb}...`
    }

    return bio
  }

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    acceptedFiles.forEach(file => {
      //setCurrentFiles(currentFiles.push(file))
    })
  }, [])

  const handleChange = files => {
    setCurrentFiles(files)
  }

  const uploadStorageDocument = e => {
    e.preventDefault()

    if (currentFiles.length === 0) {
      showToast("Please Upload atleast one document", "error")
      return
    }

    let transferObj = {
      user: 1, //ise baad mein username krna hoga
      files: currentFiles
    }
    let finalObj = JSON.stringify(transferObj)
    axios
      .post("/api/v1/documents/", finalObj)
      .then(function (response) {
        showToast("Document Added Successfully", "success")
      })
      .catch(function (error) {
        showToast("An error occured please try again later", "error")
      })
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div
      className={
        screenSize > 800 ? classes.MainContainer : finalclasses.MainCont
      }
    >
      {screenSize > 800 ? (
        <div className={classes.HeaderInner}>
          <div className={classes.Wrapper}>
            <div className={classes.IconWrapper}>
              <img src={StorageIcon} className={classes.icon} />
            </div>
            <p className={classes.MainHeading}>File Storage Area</p>
            {!isIndividualUser && (
              <div className={classes.buttonContainer}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  disabled={disabled}
                  onClick={() => setOpenSignal(true)}

                  // startIcon={<AddIcon />}
                >
                  {`Upload Document`}
                </Button>
              </div>
            )}
          </div>
          <div className={classes.SearchContainer}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput
                }}
                inputProps={{ "aria-label": "search" }}
                onChange={searchText}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className={finalclasses.topMain}>
          <div className={finalclasses.upperMain}>
            <div className={finalclasses.flex}>
              <div className={classes.IconWrapper} style={{ width: "57px" }}>
                <img src={StorageIcon} className={classes.icon} alt="" />
              </div>
              <p className={classes.MainHeading} style={{ fontSize: "12px" }}>
                File Storage Area
              </p>
            </div>
            <div className={barclasses.SearchContainer}>
              <div className={barclasses.search}>
                <div className={barclasses.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search…"
                  classes={{
                    root: barclasses.inputRoot,
                    input: barclasses.inputInput
                  }}
                  inputProps={{ "aria-label": "search" }}
                  onChange={searchText}
                />
              </div>
            </div>
          </div>
          <p className={classes.MainHeading}>File Storage Area</p>
          {!isIndividualUser && (
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                disabled={disabled}
                onClick={() => setOpenSignal(true)}

                // startIcon={<AddIcon />}
              >
                {`Upload Document`}
              </Button>
            </div>
          )}
        </div>
      )}

      <Dialog
        open={openSignal}
        scroll="body"
        // keepMounted
        onClose={() => setOpenSignal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        overlayStyle={{ backgroundColor: "transparent" }}
        classes={{ paper: defClasses.paper }}
      >
        <div className={newclasses.topDiv} style={{ width: "100% !important" }}>
          <div className={newclasses.LogoWrapper}>
            <img src={Logo} className={newclasses.Logo} />
          </div>
          <form
            className={newclasses.upperDiv}
            onSubmit={uploadStorageDocument}
          >
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setOpenSignal(false)}
            >
              <i
                class="fal fa-times fa-lg"
                style={{ position: "absolute", top: "3%", right: "4%" }}
              ></i>
            </div>

            <DropzoneArea
              onChange={handleChange}
              dropzoneText="Drop Document to Upload. Or Press here to browse"
              showPreviews={true}
              showPreviewsInDropzone={false}
              useChipsForPreview
              previewGridProps={{ container: { spacing: 1, direction: "row" } }}
              previewChipProps={{ classes: { root: classes.previewChip } }}
              previewText="Selected files"
              filesLimit={10}
            />

            <div className={newclasses.bottomCont}>
              <div className={newclasses.bottomleft}>
                <label>PINNED TO (USER NAME)</label>
                <select className={newclasses.selectCont}>
                  <option value={"no"}>User1</option>
                  <option value={"yes"}>User2</option>
                </select>
              </div>
              <div className={newclasses.bottomRight}>
                <label>VISIBLITY</label>
                <select className={newclasses.selectCont}>
                  <option value={"no"}>No</option>
                  <option value={"yes"}>Yes</option>
                </select>
              </div>
            </div>

            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              style={{ width: "80%", height: "40px" }}
              type="submit"

              // startIcon={<AddIcon />}
            >
              {"UPLOAD DOCUMENT"}
            </Button>
          </form>
        </div>
      </Dialog>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    accessToken: state.auth.accessToken
  }
}

export default connect(mapStateToProps, { auth })(StorageManagementHeader)
