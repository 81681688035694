import axios from '../../Store/request';
import Actions from "../Type";

export function auth(userObject) {
  return async (dispatch) => {
    const data = JSON.stringify(userObject);
    try {
      const response = await axios.post('/rest-auth/login/', data)
      dispatch({
        type: Actions.LOGIN_SUCCESS,
        payload: response.data
      })
    } catch (e) {
      dispatch({
        type: Actions.LOGIN_FAIL
      })
    }
  };
}

export function getUserDetails() {
  return async (dispatch) => {
    try {
      const response = await axios.get('/manage-users/user_profile/')
      dispatch({
        type: Actions.GET_USER_DETAIL_SUCCESS,
        payload: response.data
      })
    } catch (e) {
      dispatch({
        type: Actions.GET_USER_DETAIL_FAIL
      })
    }
  };
}