import React from "react"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import CloseIcon from "@material-ui/icons/Close"
import { useStyles } from "./MoreDropDownStyle"
import EditIcon from "@material-ui/icons/Edit"
import VisibilityIcon from "@material-ui/icons/Visibility"
import DeleteIcon from "@material-ui/icons/Delete"
import CreditCardIcon from '@material-ui/icons/CreditCard';
// import AddIcon from "@material-ui/icons/AddIcon"
import useAuth from "../../utils/useAuth"

const   MoreDropDown = ({userApplyCredit,clientApplyCredit,courtApplyCredit, operatorApplyCredit,servicesEdit,pricingEdit,courtEdit,officerEdit,editPaymentFire,usersEdit,courtAdminEdit,operatorEdit,courtView,servicesDescriptionView,pricingView,officerView,usersDescriptionView,courtAdminDescriptionView,operatorDescriptionView,reportsDescriptionView,payPlanDescriptionView,courtDescriptionView,courtDelete,officerDelete,serviceDelete,courtPricingDelete,pricingDelete,usersDelete,userDelete,courtAdminDelete,userEdit,operatorDelete,serviceView,viewReportType,ReportDelete}) => {
  const classes = useStyles()
  const { isAdmin,isCourtAdmin } = useAuth()

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <div className={classes.MainContainer}>
      <MoreVertIcon onClick={handleClick} className={classes.VerticalIcon} />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        // keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div className={classes.Inner}>
          <div className={classes.Wrapper}>
            <CloseIcon onClick={handleClose} className={classes.CloseIcon} />
          </div>
          {userApplyCredit ? (
              <MenuItem onClick={()=> {
                userApplyCredit()
                handleClose()
              }} className={classes.MenuList} >
                <CreditCardIcon className={classes.CreditCardIcon} />
                Apply Credit
              </MenuItem>
          ):null}

          {servicesEdit ? (
            <MenuItem onClick={()=> {
              servicesEdit()
              handleClose()
            }} className={classes.MenuList}>
              <EditIcon className={classes.EditIcon} />
              Edit
            </MenuItem>
          ) : pricingEdit ? (
            <MenuItem onClick={()=> {
              pricingEdit()
              handleClose()
            }} className={classes.MenuList}>
              <EditIcon className={classes.EditIcon} />
              Edit
            </MenuItem>
          ) : courtEdit && isAdmin ? (
            <MenuItem onClick={()=> {
              courtEdit()
              handleClose()

            }} className={classes.MenuList}>
              <EditIcon className={classes.EditIcon} />
              Edit
            </MenuItem>
          ) : officerEdit ? (
            <MenuItem onClick={()=> {
              officerEdit()
              handleClose()
            }} className={classes.MenuList}>
              <EditIcon className={classes.EditIcon} />
              Edit
            </MenuItem>
          ) : editPaymentFire && isAdmin ? (
            <MenuItem
              onClick={()=> {
                editPaymentFire()
                handleClose()
              }}
              className={classes.MenuList}
            >
              <EditIcon className={classes.EditIcon} />
              EdithandleClose
            </MenuItem>
          ) : usersEdit ? (
            <MenuItem onClick={()=> {
              usersEdit()
              handleClose()
            }} className={classes.MenuList}>
              <EditIcon className={classes.EditIcon} />
              Edit
            </MenuItem>
          ):null}

          {courtView ? (
            <MenuItem className={classes.MenuList} onClick={()=> {
              courtView()
              handleClose()
            }}>
              <VisibilityIcon className={classes.SeeIcon} />
              See details
            </MenuItem>
          ) : servicesDescriptionView ? (
            <MenuItem
              className={classes.MenuList}
              onClick={
                servicesDescriptionView && servicesDescriptionView

              }
            >
              <VisibilityIcon className={classes.SeeIcon} />
              See details
            </MenuItem>
          ) : pricingView ? (
            <MenuItem className={classes.MenuList} onClick={()=> {
              pricingView()
              handleClose()
            }}>
              <VisibilityIcon className={classes.SeeIcon} />
              See details
            </MenuItem>
          ) : serviceView ? (
            <MenuItem className={classes.MenuList} onClick={()=> {
              serviceView()
              handleClose()
            }}>
              <VisibilityIcon className={classes.SeeIcon} />
              See details
            </MenuItem>


          ) : officerView ? (
            <MenuItem className={classes.MenuList} onClick={()=> {
              officerView()
              handleClose()
            }}>
              <VisibilityIcon className={classes.SeeIcon} />
              See details
            </MenuItem>

          ) : usersDescriptionView ? (
            <MenuItem
              className={classes.MenuList}
              onClick={()=> {
                usersDescriptionView()
                handleClose()
              }}
            >
              <VisibilityIcon className={classes.SeeIcon} />
              See details
            </MenuItem>

          ) : courtAdminDescriptionView ? (
              <MenuItem className={classes.MenuList} onClick={()=> {
                courtAdminDescriptionView()
                handleClose()
              }}>
                <VisibilityIcon className={classes.SeeIcon} />
                See details
              </MenuItem>
          ) :operatorDescriptionView ? (
              <MenuItem className={classes.MenuList} onClick={()=> {
                operatorDescriptionView()
                handleClose()
              }}>
                <VisibilityIcon className={classes.SeeIcon} />
                See details
              </MenuItem>
            ) :

            reportsDescriptionView ? (
            <>
            <MenuItem
              className={classes.MenuList}
              onClick={()=> {
                reportsDescriptionView()
                handleClose()
              }}
            >
              <VisibilityIcon className={classes.SeeIcon} />
              See details
            </MenuItem>
            {(viewReportType==="clientpaycall") && <><MenuItem
              className={classes.MenuList}
              onClick={()=> {
                payPlanDescriptionView("client")
                handleClose()
              }}
            >
              <VisibilityIcon className={classes.SeeIcon} />
              See Client pay plan
            </MenuItem>
            <MenuItem
            className={classes.MenuList}
            onClick={()=> {
              payPlanDescriptionView("court")
              handleClose()
            }}
          >
            <VisibilityIcon className={classes.SeeIcon} />
            See Court pay plan
          </MenuItem>
          <MenuItem
              className={classes.MenuList}
              onClick={()=> {
                payPlanDescriptionView("hybrid")
                handleClose()
              }}
            >
              <VisibilityIcon className={classes.SeeIcon} />
              See Hybrid pay plan
            </MenuItem></>}
            </>
          
          ):
          
           null}
          {isCourtAdmin && courtDescriptionView?(
            <MenuItem
              className={classes.MenuList}
              onClick={()=> {
                courtDescriptionView()
                handleClose()
              }}
            >
              <VisibilityIcon className={classes.SeeIcon}  />
              See details
            </MenuItem>
          ):""}


          {courtDelete && isAdmin ? (
            <MenuItem
              className={classes.MenuListDel}
              onClick={()=> {
                courtDelete()
                handleClose()
              }}
            >
              <DeleteIcon className={classes.DeleteIcon} />
              Delete
            </MenuItem>
          ) : officerDelete ? (
            <MenuItem
              className={classes.MenuListDel}
              onClick={()=> {
                officerDelete()
                handleClose()
              }}
            >
              <DeleteIcon className={classes.DeleteIcon} />
              Delete
            </MenuItem>
          ) : courtPricingDelete ? (
            <MenuItem
              className={classes.MenuListDel}
              onClick={()=> {
                courtPricingDelete()
                handleClose()
              }}
            >
              <DeleteIcon className={classes.DeleteIcon} />
              Delete
            </MenuItem>
          ) : courtPricingDelete ? (
            <MenuItem
              className={classes.MenuListDel}
              onClick={()=> {
                courtPricingDelete()
                handleClose()
              }}
            >
              <DeleteIcon className={classes.DeleteIcon} />
              Delete
            </MenuItem>
          ) :serviceDelete ? (
            <MenuItem
              className={classes.MenuListDel}
              onClick={()=> {
                serviceDelete()
                handleClose()
              }}
            >
              <DeleteIcon className={classes.DeleteIcon} />
              Delete
            </MenuItem>
          ) : pricingDelete ? (
            <MenuItem
              className={classes.MenuListDel}
              onClick={()=> {
                pricingDelete()
                handleClose()
              }}
            >
              <DeleteIcon className={classes.DeleteIcon} />
              Delete
            </MenuItem>
          ) : usersDelete ? (
            <MenuItem
              className={classes.MenuListDel}
              onClick={()=> {
                usersDelete()
                handleClose()
              }}
            >
              <DeleteIcon className={classes.DeleteIcon} />
              Delete
            </MenuItem>
          ) : userDelete ? (
            <MenuItem
              className={classes.MenuListDel}
              onClick={()=> {
                userDelete()
                handleClose()
              }}
            >
              <DeleteIcon className={classes.DeleteIcon} />
              Delete
            </MenuItem>
          ) :
            courtAdminDelete ? (
            <MenuItem
            className={classes.MenuListDel}
            onClick={()=> {
              courtAdminDelete()
              handleClose()
            }}
            >
            <DeleteIcon className={classes.DeleteIcon} />
            Delete
            </MenuItem>
            ) :
            operatorDelete ? (
                <MenuItem
                  className={classes.MenuListDel}
                  onClick={()=> {
                    operatorDelete()
                    handleClose()
                  }}
                >
                  <DeleteIcon className={classes.DeleteIcon} />
                  Delete
                </MenuItem>
              ) :

              ReportDelete ? (
                  <MenuItem
                    className={classes.MenuListDel}
                    onClick={()=> {
                      ReportDelete()
                      handleClose()
                    }}
                  >
                    <DeleteIcon className={classes.DeleteIcon} />
                    Delete
                  </MenuItem>
                ) :
                null}
        </div>
      </Menu>
    </div>
  )
}

export default MoreDropDown
