export const adminDataArray = [
  { text: "Courts", link: "/courts" },

  {
    text: "Officers",
    link: "/officers"
  },
  // {
  //   text: "Client Pricing",
  //   link: "/client-pricing"
  // },
  // {
  //   text: "Court Pricing",
  //   link: "/court-pricing"
  // },
  // {
  //   text: "Services",
  //   link: "/services"
  // },
  // {
  //   text: "Documentation",
  //   link: "/documentation"
  // },
  {
    text: "Manage Users",
    link: "/manage-users"
  },
  {
    text: "Payments Plans",
    link: "/payment-plans"
  },
  {
    text: "Reports",
    link: "/reports"
  },
  // {
  //   text: "Storage",
  //   link: "/file-storage"
  // }
]

export const courtDataArray = [
  {
    text: "Documentation",
    link: "/documentation"
  },
  {
    text: "Reports",
    link: "/reports"
  },
  // {
  //   text: "Storage",
  //   link: "/file-storage"
  // }
]

export const operatorDataArray = [
  { text: "Courts", link: "/courts" },
  // {
  //   text: "Documentation",
  //   link: "/documentation"
  // },
  {
    text: "Manage Users",
    link: "/manage-users"
  },
  
  {
    text: "Reports",
    link: "/reports"
  },
  // {
  //   text: "Storage",
  //   link: "/file-storage"
  // }
]

export const userDataArray = [
  {
    text: "Payments Plans",
    link: "/payment-plans"
  },
  // {
  //   text: "Storage",
  //   link: "/file-storage"
  // }
]
