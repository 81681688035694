import React, {useState} from 'react'
import {useStyles} from './UserDataFilterStyle'
import CloseIcon from './../../assets/CloseIcon.png'
import SliderIcon from './../../assets/Slider.png'
// import CourtDropDownData from '../CourtDropDownData/CourtDropDownData'
import SearchableDropDown from '../../Components/SearchableDropdown/SearchableDropDown'
import axios from "../../Store/request";
import {useDispatch, useSelector} from "react-redux";
import {GetFilterData} from "../../Store/Actions/ManageUserAction";
// import useToast from "../Toast/useToast";

const UserDataFilter = ({close, setIsFilterCont, setFilterPage, initialFilter, setInitialFilter}) => {
	
	const classes = useStyles()
	const [boolenOption] = useState([
		{value: "True", label: "Yes"},
		{value: "False", label: "No"}
	])
	const [boolenBalance] = useState([
		{value: 1, label: "Positive"},
		{value: -1, label: "Negative"}
	])
	const disablePastDueDate = () => {
		
		const today = new Date();
		const dd = String(today.getDate() - 1).padStart(2, "0");
		const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
		const yyyy = today.getFullYear();
		return yyyy + "-" + mm + "-" + dd;
	}
	const disableComingDueDates = () => {
		const today = new Date();
		const dd = String(today.getDate() + 1).padStart(2, "0");
		const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
		const yyyy = today.getFullYear();
		return yyyy + "-" + mm + "-" + dd;
	}
	const inputEvent = (event) => {
		const {name, value} = event.target
		setInitialFilter((prevalue) => {
			return {
				...prevalue,
				[name]: value
			}
		})
	}
	const dispatch = useDispatch()
	const filterData = async () => {
		const {date_added, active, date_removed, balance_owed} = initialFilter
		const params = {
			date_added: date_added ? date_added : null,
			active: active ? active : null,
			date_removed: date_removed ? date_removed : null,
			balance_owed: balance_owed ? balance_owed : null,
		}
		dispatch(GetFilterData({initialFilter: params}))
		setIsFilterCont(false)
		setFilterPage(true)
	}
	const onReset = () => {
		setInitialFilter({
			date_added: "",
			active: "",
			date_removed: "",
			balance_owed: "",
		})
	}
	
	return (
		<form>
			<div className={classes.mainFilter}>
				<div style={{width: "100%", textAlign: "right", marginRight: "24px"}}>
					<img src={CloseIcon} style={{height: '18px', width: '18px', cursor: 'pointer'}} onClick={close}/>
				</div>
				<div className={classes.filterHead}>
					<div className={classes.filterdiv} style={{border: 'none', marginLeft: '0px', boxShadow: 'none'}}>
						<p style={{fontSize: '16px'}}>Filters</p>
						<img src={SliderIcon} style={{height: '16px', width: '16px'}}/>
					</div>
				</div>
				<div className={classes.filterMiddle}>
					
					<div className={classes.filterMiddleRight} style={{marginTop: '-9px'}}>
						<div className={classes.filterMiddleTopRight}>
							<div className={classes.RegularInputDiv}>
								<p className={classes.innerPara}>Date Added</p>
								<input
									type="date"
									name="date_added"
									value={initialFilter.date_added}
									onChange={inputEvent}
									className={classes.selectContInput}
									min="0001-01-01"
									max={disablePastDueDate()}
								/>
							</div>
							
							<div className={classes.RegularInputDiv}>
								<p className={classes.innerPara}>Date Remove</p>
								<input
									type="date"
									name="date_removed"
									value={initialFilter?.date_removed}
									onChange={inputEvent}
									className={classes.selectContInput}
									min={disableComingDueDates()}
									max="9999-12-31"
								/>
							</div>
						</div>
						<div className={classes.filterMiddleTopRight} style={{marginTop: '-21px'}}>
							
							<div className={classes.RegularInputDivCourt}>
								<p className={classes.innerPara}>Active Status</p>
								<SearchableDropDown
									data={boolenOption}
									handleClick={val => {
										setInitialFilter({...initialFilter, active: val})
									}
									}
									selectedValue={initialFilter?.active}
								
								/>
							</div>
							<div className={classes.RegularInputDivCourt}>
								<p className={classes.innerPara}>Balance Owed</p>
								<SearchableDropDown
									data={boolenBalance}
									handleClick={val => {
										setInitialFilter({...initialFilter, balance_owed: val})
									}
									}
									selectedValue={initialFilter?.balance_owed}
								
								/>
							</div>
						</div>
					</div>
				</div>
				
				<div className={classes.filterFooter}>
					<div className={classes.btnHolder}>
						<button type="reset" className={classes.resetBtn} onClick={onReset}>Reset Filters</button>
						<button type="submit" className={classes.applyBtn} onClick={(e) => {
							e.preventDefault()
							filterData()
						}}>Apply Filters
						</button>
					</div>
				</div>
			</div>
		</form>
	)
}

export default UserDataFilter