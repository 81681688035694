import { makeStyles } from "@material-ui/core/styles";


export const useStyles = makeStyles((theme) => ({

  MainContainer: {
    width: "100%",
    height: 44,
    background: "#FFFFFF",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.151907)",
    borderRadius: 2,
    margin: "-20px 0px 35px 0px",

  },
  ContainerInner: {
    display: "grid",
    gridTemplateColumns: "1fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
    padding: "0 15px",
    position: "relative"

  },
  HeadingInner: {
    display: "grid",
    gridTemplateColumns: "1fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
    padding: "0 15px",
    position: "relative",
    paddingBottom: 20,
  },
  ListItmes: {
    color: "#31394D",
    fontFamily: "Open Sans",
    fontSize: theme.fonts.sizes.fontM,
    fontWeight: 600,
  },
  Heading: {
    color: "#31394D",
    fontFamily: "Open Sans",
    fontSize: theme.fonts.sizes.fontM,
    fontWeight: 400,
  },
  Heading1: {
    color: "#31394D",
    fontFamily: "Open Sans",
    fontSize: theme.fonts.sizes.fontM,
    fontWeight: 400,
    position: "absolute",
    left: 0,
  },
  ServiceHeading: {
    marginLeft: 10,

  },
  Service: {
    marginLeft: 28,
  }

}));

