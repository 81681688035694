import React, { useEffect, useState, useContext, useRef } from "react"
import { ScreenContext } from "./../../context/context"
import { useStyles,finalStyles} from "./UserHeaderStyle"
import UsersIcon from "../../assets/UsersIcon.png"
import { barStyles } from "../SearchBar/SearchBarStyle"
import SearchIcon from "@material-ui/icons/Search"
import InputBase from "@material-ui/core/InputBase"
import useAuth from "../../utils/useAuth";

const UserHeader = props => {

  const classes = useStyles()
  const { screenSize, setScreenSize } = useContext(ScreenContext)
  const finalclasses = finalStyles()
  const barclasses = barStyles()
  const [search,setSearch]=useState(false);
  const searchBtn =(e)=>{
    setSearch(!search);
  }
  return (
    <div
    className={
      screenSize > 800 ? classes.MainContainer : finalclasses.MainCont
    }
  >
    {screenSize > 800 ? (
      <div className={classes.HeaderInner}>
        <div className={classes.Wrapper}>
          <div className={classes.IconWrapper}>
            <img src={UsersIcon} className={classes.icon} />
          </div>
          <p className={classes.MainHeading}>Court Paid Users</p>

        
        </div>
        <div className={barclasses.SearchContainer}>
          {search===false?
            <div className={barclasses.search1}>
              <SearchIcon  onClick={searchBtn}  style={{ backgroundColor:"none"}}/>
            </div>
            :
            <div className={barclasses.search}>

              <div className={barclasses.searchIcon} >
                <SearchIcon />

              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: barclasses.inputRoot,
                  input: barclasses.inputInput
                }}
                inputProps={{ "aria-label": "search" }}
                onChange={props.searchText}

                autoFocus
              />

            </div>
          }

        </div>
      </div>
    ) : (
      <div className={finalclasses.topMain}>
        <div className={finalclasses.upperMain}>
          <div className={finalclasses.flex}>
            <div className={classes.IconWrapper} style={{ width: "57px" }}>
              <img src={UsersIcon} className={classes.icon} alt="" />
            </div>
            <p className={classes.MainHeading} style={{ fontSize: "12px" }}>
              {"User"}
            </p>
          </div>
          <div className={barclasses.SearchContainer}>
            <div className={barclasses.search}>
              <div className={barclasses.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: barclasses.inputRoot,
                  input: barclasses.inputInput
                }}
                inputProps={{ "aria-label": "search" }}
                onChange={props.searchText}
              />
            </div>
          </div>
        </div>

      
      </div>
    )}

  
  </div>
  )
}


export default UserHeader