import React, { useEffect, useContext, useState } from "react";
import Container from '@material-ui/core/Container';
import ReportsHeader from "../../Components/ReportsHeader/ReportsHeader";
import ReportsIcon from "../../assets/ReportsIcon.png"
import ReportDataTable from "../../Components/ReportDataTable/ReportDataTable";
import { useStyles } from './ReportManagementStyle'
import { useHistory } from "react-router";
import {connect, useSelector} from "react-redux";
import { auth } from "../../Store/Actions/Auth";
import { Button } from '@material-ui/core'
import { ReportContext } from "../../context/ReportContext";
import DataFilter from "../../Components/DataFilter/DataFilter";
const ReportManagement = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const [filterTxt, setFilterTxt] = useState('');
  const { reportLocation, setReportLocation } = useContext(ReportContext)
  // const {data,count,loading,current_page} = useSelector(({reports})=>reports)

  const [currentPage,setCurrentPage] = useState(1)
  const [currentDataLength,setCurrentDataLength] = useState(0)
  const [result, setResult] = useState({ count: 0, data: [], loading: false })
  
  const [isFilterCont, setIsFilterCont] = useState(false)

  useEffect(()=>{
    setReportLocation(1)
  },[])
  useEffect(()=>{
    if(isFilterCont && currentPage!==1){
      setCurrentPage(1)
    }
  },[isFilterCont])

  const [tabs, setTabs] = useState([
    {
      index: 1,
      name: "OPEN BALANCE",
      selected: true
    },
    {
      index: 2,
      name: 'CLIENT PAY CALL',
      selected: false
    },
    {
      index: 3,
      name: 'CLIENT REMOVAL',
      selected: false
    },{
      index: 4,
      name: 'SALES BY SERVICE',
      selected: false
    }])


  useEffect(() => {
    if (props.accessToken === null || props.accessToken === "" || props.accessToken === undefined) {
      history.push("/")
    }
  }, [])


  const selectChange = (index) => {
    setCurrentPage(1)
    setReportLocation(index)

    const elementsIndex = tabs.findIndex(element => element.index == index)

    let newArray = [...tabs]

    newArray[elementsIndex] = { ...newArray[elementsIndex], selected: true }

    let updatedArray = newArray;

    let latestArray = [...updatedArray]

    for (let i = 0; i < latestArray.length; i++) {
      if (i !== elementsIndex) {
        latestArray[i] = { ...latestArray[i], selected: false }
      }
    }

    let finalArray = latestArray;

    setTabs(finalArray)

  }

  const postsPerPage = 10

  const initialFilter = {
    city:"",
    state:"",
    zipcode:"",
    court_name:"",
    user_type:"",
    date:"",
    active:"",
    activation_date:"",
  }
  const [dataFilter,setDataFilter] = useState(initialFilter)
  const [filterParams, setFilterParams] = useState({});
  const inputEvent=(event)=>{
    const {name,value}=event.target
    setDataFilter((prevalue)=>{
      return{
        ...prevalue,
        [name]:value
      }
    })
  }

  const getFilterWithValue = (data) => {
    let filterObj = {};
    Object.entries(data).filter(([d, v]) => !!v).map(([key,value]) => filterObj[key] = value);
    return filterObj;
  }

  const filterData = () => {
    setFilterParams(getFilterWithValue(dataFilter));
    setIsFilterCont(false)
  }
  const resetData=()=>{
    setFilterParams(getFilterWithValue(initialFilter));
    setDataFilter(initialFilter)
  }
  const searchFilterData = (e) => {
    let val = e.target.value
    setFilterTxt(val)
  }

  return (
    <div className={classes.MainContainer}>
      <ReportsHeader searchText={searchFilterData} MainHeading="REPORTS MANAGEMENT" lable="+ Create Report" img={ReportsIcon} reportLocation={reportLocation} currentPage={currentPage} setCurrentPage={setCurrentPage}
      result={result}
      setResult={setResult}
      />
      <Container maxWidth="lg" className={!isFilterCont? classes.container : classes.containerMob}>
        {
          !isFilterCont ? (
            <>
            <div className={classes.BtnContainer} style={{ display: 'flex' }}>
              <div className={classes.fixButton}>
              {
                tabs.map((tab, i) => {
                  return (

                    <Button key={tab.index} onClick={selectChange.bind(this, tab.index)} variant="contained" color={tab.selected ? "default" : "secondary"} className={tab.selected ? classes.InvoiceButton : classes.PrepaidButton}
                    disabled={result.loading}>
                      {tab.name}
                    </Button>
                  )
                })
              }
              </div>

            </div>
                  <ReportDataTable passedIndex={tabs} currentPage={currentPage} setCurrentPage={setCurrentPage}
                                   result={result}
                                   setResult={setResult}
                                   setCurrentDataLength={setCurrentDataLength}
                                   currentDataLength={currentDataLength}
                                   filterParams={filterParams}
                                   filterTxt={filterTxt}
                  />
            </>
          ) : (
                <DataFilter
                  close={() => setIsFilterCont(false)}
                  filterData={filterData}
                  dataFilter={dataFilter}
                  setDataFilter={setDataFilter}
                  inputEvent={inputEvent}
                  onReset={resetData}
                  initialFilter={initialFilter}
                />
              )
          }



      </Container>
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.accessToken
  }
}

export default connect(mapStateToProps, { auth })(ReportManagement);