import { makeStyles } from "@material-ui/core/styles";


export const useStyles = makeStyles((theme) => ({
  Container: {
    background: "#F8FBFF"
  },
  MainContainer: {
    width: "100%",
    height: 44,
    background: "#FFFFFF",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.151907)",
    borderRadius: 2,
    margin: "-20px 0px 35px 0px"
  },
  ContainerInner: {
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    padding: "0 15px",
    position: "relative"

  },
  HeadingInner: {
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    padding: "0 15px",
    position: "relative"
  },
  ListItmes: {
    color: "#3971FF",
    fontFamily: "Open Sans",
    fontSize: theme.fonts.sizes.fontM,
    fontWeight: 600,

  },
  ListItmes1: {
    color: "#363E51",
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: 600,

  },
  Heading: {
    color: "#31394D",
    fontFamily: "Open Sans",
    fontSize: theme.fonts.sizes.fontM,
    fontWeight: 400,
  },
  Heading1: {
    color: "#31394D",
    fontFamily: "Open Sans",
    fontSize: theme.fonts.sizes.fontM,
    fontWeight: 400,
    position: "absolute",
    left: 0,
  },
  topDiv:{
    height: '650px',
    width: '500px',
    flexDirection:'row',
    display:'flex',
    justifyContent:'center',
    background:'none',
    alignItems:'center',
    boxShadow:'none'
}
,
upperDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '550px',
    width: '400px',
    alignItems: 'center',
    background:'white'

},

firstDiv: {
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'space-around',
    width: '80%',
},

selectCont: {
    width: '100%',
    height: '38px',
    background: 'rgb(244, 244, 244)',
    color: 'gray',
    borderRadius: '5px',
    paddingLeft: '5px',
    border: '1px solid lightgray',
    fontFamily: theme.fonts.family.Light,
    fontSize: '16px',
    // paddingLeft: '10px'
},

bottomCont: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '80%'
},

bottomContLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '45%'
},

bottomContRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '45%'
},

inputCont: {
    width: '100%',
    height: '38px',
    background: 'rgb(244, 244, 244)',
    color: 'gray',
    borderRadius: '5px',
    border: '1px solid lightgray',
    fontFamily: theme.fonts.family.Light,
    fontSize: '16px',
    paddingLeft: '10px'
},

button: {
    background: "#3971FF",
    margin: "0px 20px",
    "&.MuiButton-containedSecondary": {
        width: '80%',
        height: 38,
        display: "flex",
        alingItems: "center",
        borderRadius: "5px !important",
        "&:hover": {
            background: "#3971FF",
        },
        "& .MuiButton-label": {
            fontFamily: theme.fonts.family.SemiBold,
            fontSize: 15,
            fontWeight: 400,
            textTransform: "capitalize",
            marginTop: 3,
        },
        "& .MuiSvgIcon-root": {
            marginTop: -2
        }
    }
},

relativeText: {
    position: 'absolute',
    top: '72.7%',
    left: '20%',
    fontSize: '18px'
},
relativeText2: {
    position: 'absolute',
    top: '72.7%',
    right: '43%',
    fontSize: '18px'
},

LogoWrapper: {
    backgroundColor: "white",
    borderRadius: 50,
    padding: 10,
    border: "1px solid #FFFFFF",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.203851)",
    position: "absolute",
    top: 17,
},
Logo: {
    width: 34,
    height: 32,
},
  
}));

export const newStyles = makeStyles((theme)=>({
  MainContainer: {
    position: "absolute",
    right: 0,
    top: 11,
    // right: 10,
  },
  Inner: {
    padding: "0px 10px",
    "&:focus": {
      outline: "none"
    }
  },
  Wrapper: {
    display: "flex",
    justifyContent: "flex-end"
  },
  CloseIcon: {
    cursor: "pointer",
    fontSize: 20,
    '&:focus': {
      outline: "none"
    }
  },
  EditIcon: {
    marginRight: 10,
  },
  SeeIcon: {
    marginRight: 10,

  },
  DeleteIcon: {
    marginRight: 10,
    color: "#C2143E"
  },
  VerticalIcon: {
    color: "#004267"
  },
  MenuList: {
    color: "#31394D",
    fontFamily: theme.fonts.family.Medium,
    fontSize: theme.fonts.sizes.fontM,
  },
  MenuListDel: {
    color: "#C2143E",
    fontFamily: theme.fonts.family.Medium,
    fontSize: theme.fonts.sizes.fontM,
  }
}));