import React, {useState, useRef, useEffect} from 'react';
import {addUserStyles, dialogStyles,useStylesEditClient } from "./UserDataTableStyle";
import useToast from "../Toast/useToast";
import {EditUserAction, GetUser} from "../../Store/Actions/ManageUserAction";
import {useSelector,useDispatch} from "react-redux";
import { Button } from "@material-ui/core"
import axios from "./../../Store/request"
import {useStyles} from "./../../Pages/UserManagement/UserManagementStyle"
import CommonClientForm from './CommonClientForm';
import FormValiadtion from './FormValidation';
import { GetAllOfficers } from '../../Store/Actions/Officers';
import CircularSpinner from "../CircularProgressUserReport/CircularSpinner";
import CommonServiceDialog from "./CommonServiceDialog";
import AddClient from "./AddClient";
import {GetTerritoryData} from "../../Store/Actions/Court";
import {useHistory} from "react-router-dom";


const EditClient = ({currentData,setCurrentData,editClientProcessing,setEditClientProcessing,currentPage,postsPerPage,passedIndex, stateList
                      ,territoryData,setTerritoryData,setOpenCreditModal,setFilterTxt
}) => {
  const serviceRef=useRef()
  const userClasses = dialogStyles()
  const [dropDownInput, setDropDownInput] = useState("")
  const [dataFetching,setDataFetching] = useState(true)
  const [serviceDataList,setserviceDataList]=useState([])
  const [serviceDataPriceList,setserviceDataPriceList]=useState([])
  const [offierDataList,setOfficerDataList]=useState([])
  const [numOfServiceDialog, setNumOfServiceDialog] = useState(0)
  const history = useHistory()

  const [media,setMedia] = useState([])
  const { data: paymentPlanData, responseData: paymentPlan } = useSelector(
    ({ paymentPlan }) => paymentPlan
    )
    const { data: courtData, responseData: courtsData, count:courtDataLength, dataExist:courtsDataExist } = useSelector(({ court }) => court)
    const { data: officersData, responseData: officers, count: officersLength ,dataExist:officerDataExist} = useSelector(({ officers }) => officers)

  useEffect(()=>{
    setNumOfServiceDialog(currentData?.services?.length)
    if(serviceRef.current){
      serviceRef?.current.scrollIntoView({behavior:"smooth"})
    }
  },[currentData?.services?.length])

  useEffect(()=>{
    if(officersData.length===officersLength && officersData.length>0)return
    if(officerDataExist)
    dispatch(GetAllOfficers())
  },[officersData])

  
  useEffect(async()=>{
    var response
    if(currentData.user_type===2)
    {
    if(currentData.pay_plan_procedure===1)
    response=await axios.get(`api/v1/services/court_client_pay_price/?court=${currentData.court}&type=1`)
    if(currentData.pay_plan_procedure===2 || currentData.pay_plan_procedure===3)
    response=await axios.get(`api/v1/services/court_client_pay_price/?court=${currentData.court}&type=${currentData.pay_plan_procedure}`)
    if(response===undefined){
      setDataFetching(false)
      return
    }
    const courtDropDownData=response.data.map(ele=>{
      return ({ value: ele.id, label: ele.name})
    })
    const courtDropDownDataPrice=response.data.map(ele=>{
      return ({ id: ele.id, price: ele.price, one_time_fee:ele.one_time_fee})
    })
    setserviceDataList(courtDropDownData)
    setserviceDataPriceList(courtDropDownDataPrice)
  }
    setDataFetching(false)
  },[])

  const { showToast } = useToast()
  const classes1 = useStyles()
  const paymentDropDownData = () => {
    return paymentPlanData?.map(item => ({ value: item.id, label: item.name }))
  }
  const officersDropDownData = async(val) => {
    if(val)
    {
      const response=await axios.get(`api/v1/courts/probation/officer/court_officers/?court=${val}`)
      const require=response?.data?.map(item => ({ value: item.id, label: item.first_name+" "+item.last_name }))
       setOfficerDataList(require)
     }
  }

  useEffect(()=>{
    officersDropDownData(currentData.court)
  },[])


  const [validation, setValidation] = useState({})
  const servicesDropDownData = async(val,courtValue) => {
    if(currentData.user_type===2){
      if(val==="court"  && (courtValue!==undefined && courtValue!=="" && courtValue!==null)){
      const response=await axios.get(`api/v1/services/court_client_pay_price/?court=${courtValue}&type=1`)
      const courtDropDownData=response.data.map(ele=>{
        return ({ value: ele.id, label: ele.name})
      })
      const courtDropDownDataPrice=response.data.map(ele=>{
        return ({ id: ele.id, price: ele.price, one_time_fee:ele.one_time_fee})
      })
      setserviceDataList(courtDropDownData)
      setserviceDataPriceList(courtDropDownDataPrice)
    }
    if (val === "court" && (courtValue===undefined || courtValue=== "" || courtValue === null)){
      setserviceDataList([])
      setserviceDataPriceList([])
      return
    }
  
  
  
      if(val==="client"  && (courtValue!==undefined && courtValue!=="" && courtValue!==null)){
        const response=await axios.get(`api/v1/services/court_client_pay_price/?court=${courtValue}&type=2`)
        const clientDropDownData=response.data.map(ele=>{
          return ({ value: ele.id, label: ele.name})
        })
        const clientDropDownDataPrice=response.data.map(ele=>{
          return ({ id: ele.id, price: ele.price, one_time_fee:ele.one_time_fee})
        })
        setserviceDataList(clientDropDownData)
        setserviceDataPriceList(clientDropDownDataPrice)
      }
      if (val === "client" && (courtValue===undefined || courtValue=== "" || courtValue === null)){
        setserviceDataList([])
        setserviceDataPriceList([])
        return
      }
  
  
  
      if(val==="hybrid"  && (courtValue!==undefined && courtValue!=="" && courtValue!==null)){
        const response=await axios.get(`api/v1/services/court_client_pay_price/?court=${courtValue}&type=3`)
        const hybridDropDownData=response.data.map(ele=>{
          return ({ value: ele.id, label: ele.name})
        })
        const hybridDropDownPriceData=response.data.map(ele=>{
          return ({ id: ele.id, price: ele.price, one_time_fee:ele.one_time_fee})
        })
        setserviceDataList(hybridDropDownData)
        setserviceDataPriceList(hybridDropDownPriceData)
      }
      if (val === "hybrid" && (courtValue===undefined || courtValue=== "" || courtValue === null)){
        setserviceDataList([])
        setserviceDataPriceList([])
        return
      }
    }
  }
  const dispatch = useDispatch()

  const userTypeDropDownData = () => {
  
    const users = [
      { label: 'Admin', value: 1 },
      // { label: 'Client', value: 2 },
      { label: 'Court Admin', value: 3 },
      { label: 'Operator', value:4 },
    ];
    return users
  }
  const openHsitoryPage = () => {
      localStorage.setItem('userId', currentData.id)
    history.push({
      pathname: '/balance',
      state: {
        data: currentData,
        courtData: courtData,
        paymentPlanData: paymentPlanData,
      },
    })
  }

  const serviceDialog = (e) => {
    e.preventDefault();
    let service={services:"",install_date:"",projected_install_date:"",end_date:"",price:"",total_price:0,total_days:0,is_installed:false,is_new:true,is_delete:false,deactive_date:null,override_price:20,user:currentData.id};

    let copyServices=[...currentData.services]
    copyServices.push(service)
    setCurrentData({...currentData,services:copyServices})
  }
  const editClient= async editClientData=>{
    if(FormValiadtion(currentData,false,passedIndex[0].selected).formIsValid===false){
      setValidation(FormValiadtion(currentData,false,passedIndex[0].selected).formErrorObj)
      return
    }
    setEditClientProcessing(true)
    const servicesCopy=[...currentData.services]
    if (isNaN(currentData.court_amount)){
      currentData.court_amount = 0
    } 
    if (isNaN(currentData.user_amount)){
      currentData.user_amount=0
    }
    if (isNaN(currentData.total_price)){
      currentData.total_price=0
    }
    setCurrentData({...currentData})
    const serviceList = servicesCopy.map(elements=>
      {
        const element={...elements}
      const matchedData=serviceDataPriceList.find(item=>item.id===element.services)
      if(matchedData?.one_time_fee)
      {
        delete element.end_date
        delete element.total_days
          if(element.install_date!=="") {
            element.install_date = element.install_date + " 00:00:00"
          }
          else
          {
            delete element.install_date
          }
          element.projected_install_date=element.projected_install_date+ " 00:00:00"
      return {...element,total_price:element.price}}
      if(matchedData?.one_time_fee===false){
        if(element.end_date.length===10){
          element.end_date=element.end_date+ " 00:00:00"
          element.total_price=element.price*element.total_days
        }
        else{
        delete element.end_date
        delete element.total_days
        element.total_price=element.price
      }
          if(element.install_date!=="")
          {
            element.install_date=element.install_date+ " 00:00:00"
          }
          else
          {
            delete element.install_date
          }
          element.projected_install_date=element.projected_install_date+ " 00:00:00"
        return {...element}
      }
    })

    let updatedCurrentData={...currentData,services:serviceList}  
    delete updatedCurrentData.is_active
    delete updatedCurrentData.is_administrator
    delete updatedCurrentData.is_court_admin_user
    delete updatedCurrentData.is_operator_user
    delete updatedCurrentData.credit
    if(updatedCurrentData.pay_plan_procedure!==3){
      delete updatedCurrentData.court_amount
      delete updatedCurrentData.user_amount
    }
    if(updatedCurrentData.user_type!==2){
      updatedCurrentData.total_price=0
      updatedCurrentData.court_amount=0
      updatedCurrentData.user_amount=0
      updatedCurrentData.services=[]
    }
    if(passedIndex[0].selected){
      updatedCurrentData.pay_plan_procedure=1
      delete updatedCurrentData.judge_name
      delete updatedCurrentData.case_number
      delete updatedCurrentData.parole_officer
    }

    try{  
      const response=await axios.put(`/manage-users/${currentData.id}/`,updatedCurrentData)
      if(response.status===200){
        dispatch(GetUser({ limit: postsPerPage, offset: postsPerPage * currentPage - postsPerPage,user_type:currentData.user_type }))
        showToast("User Edited Successfully", "success")
        dispatch(EditUserAction(false))
        setEditClientProcessing(false)
      }
    }
    catch {
      setEditClientProcessing(false)
      setCurrentData(currentData)
      showToast("User Edit Fail", "error")
    }
  }
  const handleClick=async (val)=>{
    setCurrentData({ ...currentData, state: val,territory:null  })
    dispatch(GetTerritoryData(val))
  }
  const territoryList=territoryData?.map((element)=>{
    return ({value:element.id,label:element.name})
  })

  let formError={}
  let editClientJSX=(<div className={userClasses.muiButton} style={{position:'relative'}}>
    {dataFetching?<CircularSpinner/>:<>
      {currentData.user_type===2 &&
      <div className={userClasses.buttonCredit} style={{position:"relative"}}>
        <Button
            onClick={(e)=>{
                                    e.preventDefault()
                                    openHsitoryPage()}}
          color="default"
          variant="contained"
          className={userClasses.creditBtn}
          style={{color:currentData?.balance < 0?"#C2143E" : "#53B500",top:-48,right:currentData.pay_plan_procedure === 3?"300px":"0px"}}
        >
          CREDIT:{parseFloat(currentData?.balance).toFixed(2)}
        </Button>
        {currentData.pay_plan_procedure === 3?
        <>
        <Button
            onClick={(e)=>{
                                    e.preventDefault()
                                    openHsitoryPage()}}
          color="default"
          variant="contained"
          className={userClasses.creditBtn}
          style={{color:currentData?.user_amount < 0?"#C2143E" : "#53B500",top:-48,right:"150px"}}
        >
          CLIENT:{parseFloat(currentData?.user_amount).toFixed(2)}
        </Button>
        <Button
            onClick={(e)=>{
                                    e.preventDefault()
                                    openHsitoryPage()}}
          color="default"
          variant="contained"
          className={userClasses.creditBtn}
          style={{color:currentData?.court_amount < 0?"#C2143E" : "#53B500",top:-48,right:"0"}}
        >
          COURT:{parseFloat(currentData?.court_amount).toFixed(2)}
        </Button></>:""}
      </div>
      }
    </>
    }
  <Button className={classes1.button1} onClick={()=> {
    editClient(currentData)
    setFilterTxt('')
  }} >SAVE</Button>
  <form  style={{marginTop:"-30px"}}>

    <CommonClientForm
      setOpenCreditModal={setOpenCreditModal}
      isAdding={false}
      userData={currentData}
      setUserData={setCurrentData}
      userTypeDropDownData={userTypeDropDownData}
      paymentDropDownData={paymentDropDownData}
      officersDropDownData={officersDropDownData}
      formError={formError}
      validation={validation}
      numOfServiceDialog={numOfServiceDialog}
      setNumOfServiceDialog={setNumOfServiceDialog}
      serviceDialogEdit={serviceDialog}
      passedIndex={passedIndex}
      offierDataList={offierDataList}
      servicesDropDownData={servicesDropDownData}
      media={media}
      setMedia={setMedia}
      stateList={stateList}
      handleClick={handleClick}
      territoryList={territoryList}
    />


    {currentData.user_type===2 && currentData.services?.length>0 && currentData.services.map((ele,i)=>{
      const serviceErrorServices=validation.services?validation.services.services:[];
      const serviceErrorInstallDate=validation.services?validation.services.install_date:[];
      const serviceErrorProjectedEndDate = validation.services ? validation.services.end_date : [];
      const serviceErrorProjectedInstallDate=validation.services?validation.services.projected_install_date:[];
      if(ele.is_new || !currentData.services[i].is_delete){
      return (

        <>
          <CommonServiceDialog
            isServiceDialog={true}
            i={i}
            key={i}
            serviceRef={serviceRef}
            ele={ele}
            validation={validation}
            setValidation={setValidation}
            userData={currentData}
            setUserData={setCurrentData}
            numOfServiceDialog={numOfServiceDialog}
            setNumOfServiceDialog={setNumOfServiceDialog}
            serviceDataList={serviceDataList}
            serviceDataPriceList={serviceDataPriceList}
            setDropDownInput={setDropDownInput}
            serviceErrorId={serviceErrorServices}
            serviceErrorInstallDate={serviceErrorInstallDate}
            serviceErrorProjectedEndDate={serviceErrorProjectedEndDate}
            serviceErrorProjectedInstallDate={serviceErrorProjectedInstallDate}
          />
        </>
        )}})}
  </form>
</div>)
  return (
    <>
      {dataFetching?<CircularSpinner />:!editClientProcessing?editClientJSX:
          <CircularSpinner />}
    </>
  );
};

export default EditClient;
