import React, { useEffect} from "react"
import { GetAllCourt } from "../../Store/Actions/Court"
import { useDispatch, useSelector } from "react-redux"
import SearchableDropDown from "../SearchableDropdown/SearchableDropDown"

const CourtDropDownData = ({handleSelect,openModal,selectedValue,inputValue,islabelValue=false,onKeyDown}) => {
	
	const { data,count,dataExist } = useSelector(({ court }) => court)
	const dispatch=useDispatch()
	useEffect(() => {
		if(count===data.length && data.length>0)return;
		if(dataExist)
			dispatch(GetAllCourt())
	}, [data])
	
	const findActiveCourtData=data.filter((element)=>element.is_active===true)
	
	const filterData = findActiveCourtData?.map(item => {
		if(islabelValue===false)
			return ({value: item.id, label: item.name})
		else
			return ({value: item.name, label: item.name})
	})
	const onClickHandler=(e)=>{
		
		handleSelect(e)
		
	}
	return (
		<div>
			<SearchableDropDown
				inputValue={inputValue}
				data={filterData}
				handleClick={val => onClickHandler(val)}
				openModalHandler={openModal}
				selectedValue={selectedValue}
				onKeyDown={onKeyDown}
			/>
		
		</div>
	)
}

export default CourtDropDownData
