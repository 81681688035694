import React, { useState, useEffect } from "react"
import { useStyles } from "./ResponsiveHeaderStyle"
import { GrMenu } from "react-icons/gr"
import { connect, useDispatch } from "react-redux"
import { auth } from "../../Store/Actions/Auth"
import Actions from "../../Store/Type"
import { Button } from "@material-ui/core"
import Logo from "../../assets/AMSLogo.png"
import { Link, withRouter, useHistory } from "react-router-dom"
import OffcanvasMenu from "react-offcanvas-menu-component"
import "./ResponsiveHeader.css"
import {
  adminDataArray,
  courtDataArray,
  userDataArray,
  operatorDataArray
} from "./ResponsiveHeaderArray"
import useAuth from "../../utils/useAuth"

const ResponsiveHeader = ({ location, redirectTo }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const History = useHistory()
  const [redirect, setRedirect] = useState(false)
  const [dataArray, setDataArray] = useState([])
  const { isAdmin, isCourtAdmin, isOperator } = useAuth()

  useEffect(() => {
    if (isAdmin) return setDataArray(adminDataArray)
    else if (isCourtAdmin) return setDataArray(courtDataArray)
    else if (isOperator) return setDataArray(operatorDataArray)
    else return setDataArray(userDataArray)
  }, [])

  const logout = () => {
    dispatch({
      type: Actions.LOGOUT
    })
  }

  return (
    <div className={classes.aboveCont}>
      <OffcanvasMenu
        Link={Link} // react-router-dom Link
        location={location} // location parameter passed from Router
        config={{
          width: 150, // you can modify default width
          push: false // if you want to enable push feature
        }}
        // this is where you create your menu items
        menu={dataArray}
        header={
          // you can add logo to the header for example
          <div>
            <p className="ohio">Ohio AMS</p>
            <img
              src={Logo}
              className="menu-logo"
              style={{ width: "100px", height: "80px" }}
              alt="logo"
              width="250"
              height="100"
            />
          </div>
        }
        footer={
          <div className={classes.only}>
            <p onClick={logout} className="logouts">
              LogOut
            </p>
          </div>
        }
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    redirectTo: state.auth.redirectTo
  }
}

export default connect(mapStateToProps, { auth })(withRouter(ResponsiveHeader))
