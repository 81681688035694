import React, {useState} from 'react';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchableDropDown from "../SearchableDropdown/SearchableDropDown";
import {addUserStyles, dialogStyles} from "./UserDataTableStyle";
import clsx from "clsx";
const CommonServiceDialog = ({i,isServiceDialog,serviceRef,numOfServiceDialog,validation,setValidation,setNumOfServiceDialog,userData,setUserData,serviceDataList
                               ,serviceDataPriceList,setDropDownInput,serviceErrorId,serviceErrorInstallDate,serviceErrorProjectedInstallDate,serviceErrorProjectedEndDate
                               ,ele,dataUpdate
                             }) => {


  const addUserClasses = addUserStyles()
  const userClasses = dialogStyles()
  const [openServiceModal, setOpenServiceModal] = useState(false)
  const [buttonActive, setButtonActive] = useState(false)

  

const serviceDialogRemove=(e,i)=>{
  e.preventDefault();
  let copyUserData={...userData}
  let copyServices=copyUserData.services

  if(userData.services[i]?.is_new === false){
    copyServices[i]={...copyServices[i],is_new:false,is_delete:true};
    setUserData({...copyUserData,services:copyServices})
  }
  else{
      let filtered_data = copyServices.filter(ele=>ele.id != userData.services[i].id)
      setUserData({...copyUserData,services:filtered_data})
  }
  setNumOfServiceDialog(numOfServiceDialog - 1);
}



  function calculateHybrid (services_data,index,is_delete=false){
    
    if (!is_delete){
      let court_payment = parseFloat(services_data[index].price) - parseFloat(services_data[index].client_payment);
      if (isNaN(court_payment) || court_payment === undefined){
        services_data[index].court_payment = 0
      }
      else
      {
        services_data[index].court_payment = court_payment
      }
      if (isNaN(services_data[index].client_payment) || services_data[index].client_payment === undefined){
        services_data[index].client_payment = 0
        services_data[index].court_payment = services_data[index].price
      }
      else{
        services_data[index].client_payment =   `${services_data[index].client_payment}`;
      }
    }
 
      let total_price = 0.0
      
      const updated_services = services_data.map((service)=>{
        if (!service?.is_delete){
          if (service.one_time_fee===false || service.one_time_fee==="false"){
            if (service.end_date === ""){
              var date2 = new Date();
            }
            else{
              var date2 = service.end_date;
            }
            var days = parseInt(getTotalDays(date2, service.install_date, services_data, i) + 1)
            
            total_price = parseFloat(total_price+service.price).toFixed(2)
            
            total_price = service.price * parseInt(days)
            service.total_price = parseFloat(total_price).toFixed(2)
          }
          else {
            service.total_price = service.price
          }
        }
        return service
      })
      setUserData({...userData,services:updated_services})
  }

  const getTotalDays = (endDate, startDate, copyServices, i) => {
    var date1 = new Date(startDate);
    if (endDate === ""){
      var date2 = new Date();
    }
    else{
      var date2 = new Date(endDate);
    }
    var Difference_In_Time = date2.getTime() - date1.getTime();
    var totalDays = Difference_In_Time / (1000 * 3600 * 24);
    return totalDays
  }

  const resetEndDate = (copyUserData, copyServices, i) => {
    copyServices[i].total_days = 0;
    copyServices[i].total_price = +copyServices[i].price;
    copyServices[i].end_date = "";
  }
  const inputChange=(e,i,isChange)=>{
    const copyUserData = { ...userData }
    const copyServices = copyUserData.services
    const {name,value}=e.target
    setUserData((prevalue)=>{
      return {
        ...prevalue,
        [name]: parseFloat(value)
      }
    })
    
    if(isChange==="projected_install_date")
    {
        copyServices[i].projected_install_date = value
    }
    if(isChange==="client_pay")
    { 
      copyServices[i].client_payment = parseFloat(value)
    }
    if(isChange==="price")
    {
      copyServices[i].price = +value;
    }
    if(isChange==="install_date")
    {
      copyServices[i].install_date=value;
    }
    if(isChange==="end_date")
    {
      copyServices[i].end_date = value;
    }
    calculateHybrid(copyServices,i)
    
    
  }

  // disable pastdate.
  const disableInstallDates=(i)=>{
    const today = new Date(userData.services[i]?.projected_install_date);
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  }
  const disableEndDates=(i)=>{
    const today = new Date(userData.services[i]?.end_date);
    const dd = String(today.getDate() - 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  }


  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };
  return (
    <>
      <Card key={i} classes={{ root: addUserClasses.root }} style={{ overflow: "visible" }} ref={serviceRef}>
        <CardContent>
          <div className={addUserClasses.majorDiv} >
            <div className={userClasses.headerOnForm}>
              <h3 className={userClasses.formHead}>SERVICE DETAILS</h3>
              <div>
                <button className={userClasses.deleteBtn} onClick={(e)=>{
                  console.log("userData before",userData)
                  serviceDialogRemove(e,i)
                  }}><DeleteIcon /><span className={userClasses.deleteTxt} >Delete</span></button>
              </div>
            </div>
            <div className={userClasses.flexer}>
              <div className={addUserClasses.each} style={{ fontWeight: 600 }}>
                <span >SERVICE</span>

                <SearchableDropDown
                  openModalHandler={() => {
                    setOpenServiceModal(true)}}
                  data={serviceDataList}
                  handleClick={(val) => {
                    const copyUserData = { ...userData }
                    const copyServices = copyUserData.services;
                    isServiceDialog===false?copyServices[i].id = val:copyServices[i].services = val
                    const matchedData = serviceDataPriceList.find(ele => ele.id === val)
                    copyServices[i].price = matchedData?.price
                    copyServices[i].one_time_fee = matchedData.one_time_fee;
                    setUserData({ ...copyUserData, services: copyServices })
                  }
                  }
                  inputValue={(input) => setDropDownInput(input)}
                  selectedValue={isServiceDialog===true && ele.services}
                  onKeyDown={handleEnter}
                />
                <span style={{ color: "red" }}>
                {serviceErrorId[i]}
              </span>
              </div>
              <div className={addUserClasses.each} style={{ width: "20%" }}>
                <span style={{ fontWeight: 600 }}>INSTALL DATE</span>
                <input
                  type="date"
                  className={addUserClasses.selectCont}
                  value={userData.services[i]?.install_date}
                  onChange={(e)=>inputChange(e,i,"install_date")}
                  min="0001-01-01"
                  max="9999-12-31"
                  onKeyDown={handleEnter}
                />
                <span style={{ color: "red" }}>
                {serviceErrorInstallDate[i]}
                </span>
              </div>
              <div className={addUserClasses.each} style={{ width: "20%" }}>
                <span style={{ fontWeight: 600 }}>PROJECTED INSTALL DATE</span>
                <input
                  type="date"
                  name="projected_install_date"
                  className={addUserClasses.selectCont}
                  value={userData.services[i]?.projected_install_date}
                  onChange={(e)=>inputChange(e,i,"projected_install_date")}
                  min="0001-01-01"
                  max={disableEndDates(i)}
                  onKeyDown={handleEnter}
                />
                <span style={{ color: "red" }}>
                {serviceErrorProjectedInstallDate[i]}
              </span>
              </div>
              <div className={addUserClasses.each} style={{ width: "20%" }}>
                <span style={{ fontWeight: 600 }}>PRICE</span>
                <input
                  type="number"
                  name="price"
                  placeholder="&nbsp;&nbsp;&nbsp;&nbsp;Type..."
                  className={addUserClasses.selectCont}
                  value={""+userData.services[i]?.price>0?userData.services[i]?.price.toString():0}
                  step="any"
                  onChange={(e)=>inputChange(e,i,"price")}
                  onKeyDown={handleEnter}
                  // readOnly
                />
              </div>
              {isServiceDialog===false && (serviceDataPriceList?.find(ele => ele.id === userData.services[i]?.id))?.one_time_fee === false ?
                <>
                  <div className={addUserClasses.each} style={{ width: "31%" }}>
                    <span style={{ fontWeight: 600 }}>END DATE</span>

                    <input
                      type="date"
                      name="end_date"
                      className={addUserClasses.selectCont}
                      value={userData.services[i]?.end_date}
                      onChange={(e)=>{inputChange(e,i,"end_date")}}
                      min={disableInstallDates(i)}
                      max="9999-12-31"
                      onKeyDown={handleEnter}
                    />
                    <span style={{ color: "red" }}>
                      {serviceErrorProjectedEndDate[i]}
                    </span>
                  </div>

                  <div className={addUserClasses.each} style={{ width: "20%" }}>
                    <span style={{ fontWeight: 600 }}>TOTAL PRICE</span>
                    <input
                      type="text"
                      name="total_price"
                      className={addUserClasses.selectCont}
                      value={parseFloat(userData.services[i]?.total_price).toFixed(2)}
                      readOnly
                      onKeyDown={handleEnter}
                    />
                  </div>
                </> : null}

              {isServiceDialog===true && (serviceDataPriceList?.find(ele => ele.id === userData.services[i]?.services))?.one_time_fee === false ?
                <>
                  <div className={addUserClasses.each} style={{ width: "31%" }}>
                    <span style={{ fontWeight: 600 }}>END DATE</span>
                    <input
                      type="date"
                      name="end_date"
                      className={addUserClasses.selectCont}
                      value={userData.services[i]?.end_date}
                      onChange={(e)=>{inputChange(e,i,"end_date")}}
                      min={disableInstallDates(i)}
                      max="9999-12-31"
                      onKeyDown={handleEnter}
                    />
                    <span style={{ color: "red" }}>
                      {serviceErrorProjectedEndDate[i]}
                    </span>
                  </div>
                  <div className={addUserClasses.each} style={{ width: "20%" }}>
                    <span style={{ fontWeight: 600 }}>TOTAL PRICE</span>
                    <input
                      type="text"
                      name="total_price"
                      className={addUserClasses.selectCont}
                      value={userData.services[i]?.total_price}
                      readOnly
                      onKeyDown={handleEnter}
                    />
                    </div>
                </> : null}

                {
                    userData.pay_plan_procedure === 3?
                    <>
                  <div className={addUserClasses.each} style={{ width: "20%" }}>
                    <span style={{ fontWeight: 600 }}>Client Pay</span>
                    <input
                      type="number"
                      name="client_payment"
                      placeholder="&nbsp;&nbsp;&nbsp;&nbsp;Type..."
                      className={addUserClasses.selectCont}
                      value={userData?.services[i]?.client_payment}
                      step="any"
                      min={0}
                      max={userData.services[i].price}
                      onChange={(e)=>inputChange(e,i,"client_pay")}
                      onKeyDown={handleEnter}
                      disabled={userData.services[i].install_date === ""}
                      // readOnly
                    />
                </div>
                <div className={addUserClasses.each} style={{ width: "20%" }}>
                  <span style={{ fontWeight: 600 }}>Court Pay</span>
                  <input
                    name="courtpay"
                    placeholder="&nbsp;&nbsp;&nbsp;&nbsp;Type..."
                    className={addUserClasses.selectCont}
                    value={userData.services[i].court_payment!==NaN||userData.services[i].court_payment!==undefined?userData.services[i].court_payment:0}
                    step="any"
                    disabled
                    // readOnly
                  />
                </div>
                  </>:""
                  }


              <div className={addUserClasses.each} style={{ width: "20%" }}></div>
              <div className={addUserClasses.each} style={{ width: "20%" }}> </div>

            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default CommonServiceDialog;