import Actions from "../Type"
import axios from "../request"

export const GetServices = ({ limit = 10, offset = 0 } = {}) => {
  return async dispatch => {
    try {
      dispatch({ type: Actions.GET_SERVICE_REQUEST })
      const response = await axios.get(`/api/v1/services/?limit=${limit}&offset=${offset}`)
      dispatch({
        type: Actions.GET_SERVICE_SUCCESS,
        // payload: response.data.results,
        payload: { results: response.data.results, count: response.data.count}
      })
    } catch (e) {
      dispatch({ type: Actions.GET_SERVICE_FAIL })
    }
  }
}

export const addServiceData=(data)=>{
  return async dispatch => {
    try {
      dispatch({
        type: Actions.ADD_SERVICE_DATA,
        payload: data
      })

    } catch (e) {
    }
  }
}

export const GetAllServices = () => {
  return async dispatch => {
    try {
      dispatch({ type: Actions.GET_SERVICE_REQUEST })
      const response = await axios.get(`/api/v1/services/?type=full`)
      dispatch({
        type: Actions.GET_SERVICE_SUCCESS,
        payload: { results: response.data, count: response.data.length, dataExist: response.data.length>0?true:false}
      })
    } catch (e) {
      dispatch({ type: Actions.GET_SERVICE_FAIL })
    }
  }
}