export const DataArray = [
  {
      Name:'Court Paid User Name',     
      ID:1,
      Payment:"$1100",
      Duration:"2 Month"
  },
  {
      Name:'Court Paid User Name',
      ID:2,
      Payment:"$1100",
      Duration:"2 Month"
  },
  {
      Name:'Court Paid User Name',
      ID:3,
      Payment:"$1100",
      Duration:"2 Month"
  },
  {
      Name:'Court Paid User Name',
      ID:4,
      Payment:"$1100",
      Duration:"2 Month"
  },
  {
      Name:'Court Paid User Name',
      ID:5,
      Payment:"$1100",
      Duration:"2 Month"
  },
]