import Actions from "../Type"
import axios from "../request"

export const GetCourt = ({ limit = 10, offset = 0 } = {}) => {
  return async dispatch => {
    try {
      dispatch({ type: Actions.GET_COURT_REQUEST })
      const response = await axios.get(`/api/v1/courts/?limit=${limit}&offset=${offset}`)
      // const res= await axios.get(`/api/v1/courts/?limit=${response.data.count}&offset=${offset}`)
      dispatch({
        type: Actions.GET_COURT_SUCCESS,
        // payload: response.data.results,
        payload: { results: response.data.results, count: response.data.count}
      })
      
    } catch (e) {
      dispatch({ type: Actions.GET_COURT_FAIL })
    }
  }
}

export const addCourtData=(data)=>{
  return async dispatch => {
    try {
      dispatch({
        type: Actions.ADD_COURT_DATA,
        payload: data
      })
    } catch (e) {
    }
  }
}

export const GetAllCourt = () => {
  return async dispatch => {
    try {
      dispatch({ type: Actions.GET_COURT_REQUEST })
      const response = await axios.get(`/api/v1/courts/?type=full`)
      
      dispatch({
        type: Actions.GET_COURT_SUCCESS,
        // payload: response.data.results,
        payload: { results: response.data, count: response.data.length , dataExist: response.data.length>0?true:false}
      })
      
    } catch (e) {
      dispatch({ type: Actions.GET_COURT_FAIL })
    }
  }
}

export const GetAllCourtData = () => {
  return async dispatch => {
    try {
      dispatch({ type: Actions.GET_COURT_REQUEST })
      const response = await axios.get(`/api/v1/courts/?type=page`)

      dispatch({
        type: Actions.GET_COURT_SUCCESS,
        // payload: response.data.results,
        payload: { results: response.data, count: response.data.length , dataExist: response.data.length>0?true:false}
      })

    } catch (e) {
      dispatch({ type: Actions.GET_COURT_FAIL })
    }
  }
}
export const GetStateData=()=> {
  return async dispatch => {
    try {
      const response = await axios.get(`api/v1/courts/state/`)
      dispatch({
        type: Actions.GET_STATE_DATA,
        payload: { results: response.data}
      })
    } catch (e) {
    }
  }
}
export const GetTerritoryData=(id)=>{
  return async dispatch => {
    try {
      const response = await axios.get(`api/v1/courts/territory/?state=${id}`)
      dispatch({
        type: Actions.GET_TERRITORY_DATA,
        payload: { results: response.data}
      })
    } catch (e) {
    }
  }
}