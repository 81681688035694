import React from 'react';
import CloseIcon from "../../assets/CloseIcon.png";
import {useStyles} from "./ReportViewsStyle";

const CourtUserView = ({onClose,today}) => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.aligner}>
        <div className={classes.closeIconDiv}>
          <img src={CloseIcon} onClick={onClose} style={{ cursor: 'pointer' ,height: "5px",width: "5px"}} />
        </div>
        <div className={classes.firstContLayer}>
          <p>INVOICE ID</p>
          <p>{`Today's date: ${today.toLocaleDateString("uk-UA")}`}</p>
        </div>
        <div className={classes.tableDiv}>
          <table className="table table-striped" style={{ width: '100%', height: 'auto', minHeight: '300px' }}>
            <thead className={classes.stickyHeader}>
            <tr>

              <th scope="col" style={{ textAlign: 'start' }}>Client Full Name</th>
              <th scope="col" style={{ textAlign: 'start' }}>Days Delinquent</th>
              <th scope="col" style={{ textAlign: 'start' }}>Paid To Date</th>
              <th scope="col" style={{ textAlign: 'start' }}>Cell Phone</th>
              <th scope="col" style={{ textAlign: 'start' }}>Home Phone</th>
              <th scope="col" style={{ textAlign: 'start' }}>Amount Due</th>
              <th scope="col" style={{ textAlign: 'start' }}>Probation Officer Name</th>
              <th scope="col" style={{ textAlign: 'start' }}>Probation Officer Number</th>
            </tr>
            </thead>
            <tbody style={{ width: '100%' }}>
            <tr style={{ height: '50px', background: '#F8F8F8' }}>

              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
              <td>Mark</td>
              <td>Otto</td>

            </tr>
            <tr style={{ height: '50px' }}>

              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
              <td>Mark</td>
              <td>Otto</td>

            </tr>
            <tr style={{ height: '50px', background: '#F8F8F8' }}>

              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
              <td>Mark</td>
              <td>Otto</td>

            </tr>
            <tr style={{ height: '50px' }}>

              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
              <td>Mark</td>
              <td>Otto</td>

            </tr>
            <tr style={{ height: '50px', background: '#F8F8F8' }}>

              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
              <td>Mark</td>
              <td>Otto</td>

            </tr>
            <tr style={{ height: '50px' }}>

              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
              <td>Mark</td>
              <td>Otto</td>

            </tr>
            <tr style={{ height: '50px', background: '#F8F8F8' }}>

              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
              <td>Mark</td>
              <td>Otto</td>

            </tr>

            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default CourtUserView;