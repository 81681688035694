import Actions from "../Type.js"

const initialState = {
  
  loading: false,
  data: [],
  error: "",
  responseData:{},
  count:0,
  dataExist:true,
  stateData:[],
  territoryData:[]
}


const CourtReducer = (state = initialState, { type, payload }) => {
  switch (type) {
   
    case Actions.GET_COURT_REQUEST:
      return {
        ...state,
        loading: true
      }
    case Actions.GET_COURT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload.results,
        count: payload.count,
        dataExist:payload.dataExist
      }
    case Actions.GET_COURT_FAIL:
      return {
        ...state,
        loading: false,
       
      }
      case Actions.ADD_COURT_DATA:
       return {
         ...state,
         data: [ ...state.data, payload ],
         responseData:payload
       }
    case Actions.GET_STATE_DATA:
      return {
        ...state,
        stateData: payload.results,
      }
    case Actions.GET_TERRITORY_DATA:
      return {
        ...state,
        territoryData: payload.results,
      }
    default:
      return state
  }
}

export default CourtReducer
