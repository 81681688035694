import Actions from "../Type.js"

const initialState = {

  loading: false,
  data: [],
  error: "",
  responseData:{},
  count:0,
  dataExist:true
}


const ClientPricingReducer = (state = initialState, { type, payload }) => {
  switch (type) {

    case Actions.GET_CLIENT_PRICING_REQUEST:
      return {
        ...state,
        loading: true
      }
    case Actions.GET_CLIENT_PRICING_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload.results,
        count: payload.count,
        dataExist:payload.dataExist
      }
    case Actions.GET_CLIENT_PRICING_FAIL:
      return {
        ...state,
        loading: false,

      }

    default:
      return state
  }
}

export default ClientPricingReducer
