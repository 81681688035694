import React,{useState,useEffect} from 'react';
import CloseIcon from "../../assets/CloseIcon.png";
import {useStyles} from "./ReportViewsStyle";
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import moment from 'moment'

const SalesByServiceView = ({onClose,today,futureDate,DataArray}) => {


  const classes = useStyles()

  const TextClipper = (bio, length) => {
    let vb = ""

    if (bio?.length >= length) {
      for (var i = 0; i < length; i++) {
        vb += bio.charAt(i)
      }
      bio = `${vb}...`
    }

    return bio
  }

  const convertDateFormat = (date)=>{
    return moment(date).format('MM-DD-YYYY')
  }

  const [tableRows,setTableRows] = useState([]);
  const [exportDataArray,setExportDataArray] = useState([])

  const fetchInvoices = () => {
    let table_rows = [];
    let teritories = Object.keys(DataArray.data.data)
    if (teritories.length === 0){
      return table_rows
    }
    else{
      let _exportData = []
      teritories.map((teritory,idx)=>{
        let courts = Object.keys(DataArray.data.data[teritory])
        // get total balance for each court
        let total_for_teritory = 0.0

        courts.map((court,CourtIdx)=>{
            // get total balance for each court
            let total_for_court = 0.0
            // get all services name
            let services = Object.keys(DataArray.data.data[teritory][court]);
            services.map((service,serviceIndex)=>{
              let current_service = DataArray.data.data[teritory][court][service];
              total_for_court += parseFloat(current_service['amount'])
              _exportData.push([service,current_service['units'],current_service['days'],current_service['amount']])
              table_rows.push(<tr className={classes.styleRowWithBackground} key={idx+CourtIdx+serviceIndex}>
                  <td className={classes.firstColumn}>{TextClipper(service,15)}</td>
                  <td>{TextClipper(current_service['units'],15)}</td>
                  <td>{TextClipper(current_service['days'],15)}</td>
                  <td>$ {TextClipper(current_service['amount'],15)}</td>
                </tr>)
            })
            total_for_teritory += total_for_court
            _exportData.push([`Total for Court ${court}`,"","",total_for_court])
            table_rows.push(
              <tr className={classes.styleRowWithoutBackground} key={CourtIdx}>
                 <td className={classes.firstColumn}>{`Total for Court ${court}`}</td>
                 <td></td>
                 <td></td>
                 <td>$ {total_for_court}</td>
               </tr>
            )

        })
        _exportData.push([`Total for Teritorry ${teritory}`,"","",total_for_teritory])
        table_rows.push(
          <tr className={classes.styleRowWithoutBackground} key={idx}>
             <td className={classes.firstColumn}>{`Total for Teritorry ${teritory}`}</td>
             <td></td>
             <td></td>
             <td>$ {total_for_teritory}</td>
           </tr>
        )
      })
      setExportDataArray(_exportData)
    }
    setTableRows(table_rows)
  }
  useEffect(()=>{
    fetchInvoices()
  },[])

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = "SALES BY SERVICE REPORT";
    const headers = [["Service","Units","Days","Amount"]];


    const marginLeft = 40;
    let marginTOp = 40;

    doc.text(title, marginLeft, marginTOp);
    marginTOp+=20
    doc.text(`Run Date : ${convertDateFormat(DataArray.data.run_date)}`,marginLeft,marginTOp)

    if(DataArray.data.court){
      marginTOp += 20;
      doc.text(`Court: ${DataArray.data.court}`,marginLeft,marginTOp)
    }
    if(DataArray.data.teritory){
      marginTOp += 20;
      doc.text(`Territory: ${DataArray.data.teritory}`,marginLeft,marginTOp)
    }
    marginTOp += 20;
    doc.text(`From date: ${convertDateFormat(DataArray.data.from_date)}`,marginLeft,marginTOp)
    marginTOp += 20
    doc.text(`To date: ${convertDateFormat(DataArray.data.to_date)}`,marginLeft,marginTOp)
    if(DataArray.data.payment_type){
      marginTOp+=20
      doc.text(`Report Payment Type: ${DataArray.data.payment_type}`,marginLeft,marginTOp)
    }
    let content = {
      startY: marginTOp+20,
      head: headers,
      body: exportDataArray,
      theme:"plain"
    };
    doc.autoTable(content);
    doc.save("sales_by_service_report.pdf")
  }

  return (
    <>
      <button onClick={exportPDF} style={{border:"1px solid black",height:"20px",width:"80px",backgroundColor:"#D8D8D8",color:"black",marginLeft:"20px",color:"blue",cursor:"pointer"}}>Export Pdf</button>
      <div className={classes.aligner}>
        <div className={classes.closeIconDiv}>
          <img src={CloseIcon} onClick={onClose} style={{ cursor: 'pointer',paddingRight:"10px",marginTop:"-10px"}} height="15px" />
        </div>
        <div className={classes.firstContLayer} style={{padding:"0px 25px"}}>
          <p>SALES BY SERVICE REPORT</p>
          <p>{`Run Date : ${convertDateFormat(DataArray.data.run_date)}`}</p>
        </div>
        <div className={classes.firstContLayer} style={{padding:"0px 25px"}}>
          {
            DataArray.data.court !==null?
            <p>{`Court: ${DataArray.data.court}`}</p>:""
          }
          {
            DataArray.data.teritory !==null?
            <p>{`Territory: ${DataArray.data.teritory}`}</p>:""
          }
          {
            DataArray.data.from_date !==null?
            <p>{`From Date: ${convertDateFormat(DataArray.data.from_date)}`}</p>:""
          }
          {
            DataArray.data.to_date !==null?
            <p>{`To Date: ${convertDateFormat(DataArray.data.to_date)}`}</p>:""
          }
        </div>
        <div className={classes.firstContLayer} style={{padding:"0px 25px"}}>
          {
            DataArray.data.payment_type !==null?
            <p>{`Report Payment Type: ${DataArray.data.payment_type}`}</p>:""
          }
        </div>
        <div className={classes.tableDiv}>
        {tableRows.length > 0?<table className="table table-striped" style={{ width: '100%', height: 'auto', borderCollapse:"collapse" }}>
            <thead className={classes.stickyHeader}>
            <tr>

              <th scope="col" className={classes.firstColumn}>Service</th>
              <th scope="col" className={classes.salesByServiceColumn}>Units</th>
              <th scope="col" className={classes.salesByServiceColumn}>Days</th>
              <th scope="col" className={classes.salesByServiceColumn}>Amount</th>
            </tr>
            </thead>
            <tbody style={{ width: '100%', fontFamily:"Open Sans",fontWeight:600  }}>
            {tableRows}
            </tbody>
          </table>:<p className={classes.error}>No Data Found</p>}
        </div>
      </div>
    </>
  );
};

export default SalesByServiceView;