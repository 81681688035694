import React, { useState, useEffect } from 'react';
import axios from 'axios'

import MoreDropDown from "../MoreDropDown/MoreDropDown"
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { FormControl, MenuItem, InputLabel, Select } from '@material-ui/core'
import { useStyles } from './PaymentDataTableStyle'
import Input from "../../Components/Input/Input";

import AddButton from '../Buttons/AddButton.js';
import Logo from '../../assets/AMSLogo.png';


const PaymentDataTable = ({DataArray}) => {
  const classes = useStyles();

  const [currentPayment, setCurrentPayment] = useState({
    paymentType: '',
    planName: '',
    planDuration: '',
    serviceAmount: '',
    oneTimePayment: '',
    id:''
  })
  const [openEdit, setOpenEdit] = useState(false);
  const [paymentData, setPaymentData] = useState({
    paymentType: '',
    planName: '',
    planDuration: '',
    serviceAmount: '',
    oneTimePayment: '',
    id:''
  })

  

  const editModal = () => {
    setOpenEdit(true)
  }

  const EditChangesToApi = (e) => {
      e.preventDefault();

      
      var shiftingData = paymentData;
      var secondShift = ""
      //some checks before sending data to edit api

   /*   if(!paymentData.PaymentDuration.includes('day') || !paymentData.PaymentDuration.includes('month')) {
        let withTime = paymentData.PaymentDuration.includes('day') ? 'days' : 'month'
        let combiner = shiftingData.PaymentDuration.concat(`${ withTime}`)
        secondShift = {...shiftingData,PaymentDuration:combiner}
      }

      else {
        secondShift = shiftingData
      }*/

      let data = JSON.stringify(shiftingData)

   /*   let config = {
        method: 'put',
        url: 'https://ohio-ams-software-25416.botics.co/',
        headers: {
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Content-Type": "application/json",
          "Authorization":"Basic Y3Jvd2Rib3RpY3M6RkRUUG5UR203Tg==",
          "Access-Control-Allow-Credentials": "true",
          "Access-Control-Request-Method": "POST",
          "key":"",
        },
        data: data
      }*/



      

  }

  const TextClipper =(bio,length)=> {

    let vb = "";

    if (bio?.length >= length) {
      
      for (var i = 0; i < length; i++) {
        vb += bio.charAt(i);
      }
      bio = `${vb}...`;
  
    }

    return bio;
  }

  return (
    <div className={classes.Container}>
      <div className={classes.TableHeading}>
        <div className={classes.HeadingInner}>
          <p className={classes.Heading1}>Payment plan name</p>
          <p></p>
          <p className={classes.Heading}>Type of payments</p>
          <p className={classes.Heading}>Duration of payments</p>
          <p className={classes.Heading}>One time payment</p>
          <p className={classes.Heading}>Total amount</p>
          <p className={classes.Heading}></p>
          <p className={classes.Heading}></p>
        </div>
      </div>
      {
        DataArray.map((i) => {
          return (
            <div className={classes.MainContainer}>
              <div className={classes.ContainerInner}>
                <p className={classes.ListItmes}>{TextClipper(i.PlanName,15)}</p>
                <p className={classes.ListItmes}>{TextClipper(i.PaymentType,15)}</p>
                <p className={classes.ListItmes}>{TextClipper(i.PaymentDuration,15)}</p>
                <p className={classes.ListItmes}>{TextClipper(i.OneTimePayment,15)}</p>
                <p className={classes.ListItmes}>{TextClipper(i.TotalPayment,15)}</p>
                <div onClick={() => setCurrentPayment(i)}>
                  <MoreDropDown editPaymentFire={editModal} />
                </div>
              </div>
            </div>
          )
        })
      }

      {/*Edit Modal */}

      <Dialog
        open={openEdit}
        scroll="body"
        // keepMounted
        onClose={() => setOpenEdit(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        overlayStyle={{ backgroundColor: 'transparent' }}
      >
        <div className={classes.topDiv}>
          {/*<div className={classes.LogoWrapper}>
          <img src={Logo} className={classes.Logo} />
    </div>*/}
          <p style={{position:'absolute',top:'5%',left:'40%',fontSize:'20px',fontWeight:'700'}}>Edit Payments</p>
          <form onSubmit={EditChangesToApi} className={classes.upperDiv}>
            <div style={{ cursor: 'pointer' }} onClick={() => setOpenEdit(false)}>
              <i class="fal fa-times fa-lg" style={{ position: 'absolute', top: '3%', right: '4%' }}></i>
            </div>

            <div className={classes.firstDiv}>
              <p style={{ fontWeight: 600 }}>PAYMENT TYPE</p>
              <select className={classes.selectCont}
                onChange={(e) => { setPaymentData({ ...paymentData, paymentType: e.target.value }) }}
                required id="cars" name="paymentTypes" >
                {
                  DataArray.map((dataObj) => {
                    return (
                      <option value={dataObj.PaymentType}>{dataObj.PaymentType}</option>
                    )
                  })
                }
              </select>
            </div>
            <div className={classes.firstDiv}>
              <p style={{ fontWeight: 600 }}>PAYMENT PLAN NAME</p>
              <input
                type="text"
                placeholder={currentPayment.PlanName}
                className={classes.selectCont}
                value={paymentData.planName}
                onChange={(e) => { setPaymentData({ ...paymentData, planName: e.target.value }) }}
                required
              />
            </div>
            <div className={classes.firstDiv}>
              <p style={{ fontWeight: 600 }}>DURATION</p>
              <input
                type="text"
                placeholder={currentPayment.PaymentDuration}
                className={classes.selectCont}
                required
                value={paymentData.planDuration}
                onChange={(e) => { setPaymentData({ ...paymentData, planDuration: e.target.value }) }}
              />
            </div>
            <div className={classes.bottomCont}>
              <div className={classes.bottomContLeft}>
                <p style={{ fontWeight: 600 }}>SERVICE AMOUNT</p>
                
                <input
                  type="text"
                  placeholder={currentPayment.TotalPayment}
                  className={classes.inputCont}
                  required
                  value={paymentData.serviceAmount}
                  onChange={(e) => { setPaymentData({ ...paymentData, serviceAmount: e.target.value }) }}
                />
              </div>
              <div className={classes.bottomContRight}>
                <p style={{ fontWeight: 600 }}>ONE-TIME PAYMENT</p>
               
                <input
                  type="text"
                  placeholder={currentPayment.OneTimePayment}
                  className={classes.inputCont}
                  value={paymentData.oneTimePayment}
                  onChange={(e) => { setPaymentData({ ...paymentData, oneTimePayment: e.target.value }) }}
                  required
                />
              </div>

            </div>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              disabled={paymentData.planName === "" || paymentData.planDuration === "" || paymentData.serviceAmount === ""
                || paymentData.oneTimePayment === "" || paymentData.paymentType === ""}
              type="submit"

            // startIcon={<AddIcon />}
            >
              {'SAVE PLAN'}
            </Button>
          </form>
        </div>
      </Dialog>
    </div>

  );
}

export default PaymentDataTable