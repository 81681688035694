import React from 'react'
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme)=>({

    mainPag: {
        display:'flex',
        flexDirection:'row',
        justifyContent:'flex-end',
        alignItems:'center'
    },

    pagination: {
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        marginLeft:'0px'
    },

    pageItem: {
        marginLeft:'20px'
    },

    pageBtn: {
        width:'30px',
        height:'30px',
        background:'#f50057',
        border:'none',
        color:'white',
        cursor:'pointer',
        borderRadius:'50px'
    },
    selectedBtn: {
        width:'30px',
        height:'30px',
        background:'#fff',
        border:'1px solid #f50057',
        color:'black',
        cursor:'pointer',
        borderRadius:'50px'
    },
    Previous: {
        textTransform: "capitalize",
        "& .MuiButton-label": {
          fontFamily: theme.fonts.family.Bold,
          fontSize: theme.fonts.sizes.fontL,
          fontWeight: 500,
        }
      },
      Next: {
        textTransform: "capitalize",
        color: "#C1123D !important",
        "& .MuiButton-label": {
          fontFamily: theme.fonts.family.Bold,
          fontSize: theme.fonts.sizes.fontL,
          fontWeight: 500,
        }
      }
}))