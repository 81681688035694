import { createTheme } from "@material-ui/core/styles"

export default createTheme({
  fonts: {
    family: {
      Bold: "OpenSans Bold",
      Light: "OpenSans Light",
      Medium: "OpenSans Medium",
      SemiBold: "OpenSans SemiBold"
    },
    sizes: {
      fontXXS: 9,
      fontXS: 10,
      fontS: 12,
      fontM: 14,
      fontL: 16,
      fontXL: 18,
      font2XL: 20,
      font3XL: 36,
      font3XLL: 38
    }
  },
  colors: {
    primary: {
      white: "#FFFFFF"
    },
    secondary: {}
  }
})
