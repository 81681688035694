import React, { useEffect, useState } from "react"
import { DataArray } from "../../Components/PaymentsDataTable/DataArray"
import { useStyles } from "../../Components/PaymentDataTable/PaymentDataTableStyle"
import NewPagination from "../../Components/NewPagination/NewPagination"
import axios from "./../../Store/request"
import CircularSpinner from "../../Components/CircularProgressUserReport/CircularSpinner"
// import CircularSpinner from "../../Components/CircularProgress/CircularSpinner"
import ChequeImage from "../../assets/Group.png"
import Pagination_New from "../../Components/NewPagination/Pagination_New";
const Recharges = ({ paymentData, rechargeData, count ,userId}) => {
  
  const classes = useStyles()
  const [rechargePagedData,setRechargePagedData]=useState(rechargeData)
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const [gettingRecharges,setGettingRecharges]=useState(false)

  let currentPosts = rechargePagedData.slice(indexOfFirstPost,indexOfLastPost);

  const getRechargeData = async ()=>{
      setGettingRecharges(true)
      let response=await axios.get(`manage-users/user_payments/?user=${userId}&&limit=${10}&&offset=${(postsPerPage * currentPage) - postsPerPage}`)
      setRechargePagedData(response.data.results)
      setGettingRecharges(false)
  }


  useEffect(() =>{
    getRechargeData()
  },[currentPage])

  const paginate = pageNumber => setCurrentPage(pageNumber);
  return (
    <div className={classes.Container}>
      {paymentData?.length?<div className={classes.HeadingInner}>
        <h3>Saved Payment Methods</h3>
      </div>:null}
      <br />
      {paymentData?.results?.map(i => {
        return(
        <div className={classes.TableHeading} key={i.id}>
          <div className={classes.MainContainer}>
            <div className={classes.ContainerInner}>
              <p className={classes.ListItmes1}>
                <img src={DataArray[0].img} />
              </p>
              <p className={classes.ListItmes1}>****{i.card_last_four}</p>
              <p className={classes.ListItmes1}>
                {new Date(i.created_on).toISOString().slice(0, 10)}
              </p>
            </div>
          </div>
        </div>
      )})}
      {!!rechargeData?.length?<div className={classes.HeadingInner}>
        <h3>Recharges </h3>
      </div>:null}

      <br />

        {
          rechargePagedData.length > 0?
          rechargePagedData.map((i)=>{
            return <div className={classes.MainContainer} key={i.id}>
              <div className={classes.ContainerInner}>
                <p className={classes.ListItmes1}>
                  {
                    i.is_by_cheque ?
                    i.cheque_image?
                    <a href={i.cheque_image} target="_blank"><img src={i.cheque_image} height={35} width={35} style={{marginTop:"-15px"}}/></a>:<img src={ChequeImage} height={35} width={35} style={{marginTop:"-15px"}}/>
                      // <ChequeImage/>
                    :
                      <img src={DataArray[0].img} />
                  }

                </p>

                <p className={classes.ListItmes1}>
                  {
                    i.is_by_cheque?
                      "ACH Check"
                      :
                    `****${i.user_payment_type.name_on_card}`
                  }



                </p>

                <p className={classes.ListItmes1}>{new Date(i.created_on).toISOString().slice(0, 10)}</p>
                <p className={classes.ListItmes1}>${i.amount}</p>
                {
                  i.court_invoice_number !== null?<p className={classes.ListItmes1}>Invoice#{i.court_invoice_number}</p>:""
                }
              </div>

            </div>
          }):""
        }
        {count>10?
          <NewPagination
          postsPerPage={postsPerPage}
          totalPosts={count}
          paginate={async(pageNumber) => {
            setCurrentPage(pageNumber)
          }}
        />

        :null}
    </div>
  )
}

export default Recharges
