import React, {useContext} from "react"
import { useStyles } from "./PaymentsStyle"
import { Box } from "@material-ui/core"
import { TabContext} from "@material-ui/lab"
import Invoice from "./Invoice"
import Statement from "./Statement"
import Recharges from "./Recharges"
import {Button,InputLabel,MenuItem,FormControl,Select} from "@material-ui/core"
import Credit_Btn from "./Credit_btn"
import {ReportContext} from "../../context/ReportContext";
import useAuth from "../../utils/useAuth";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";


const PaymentInformation = ({
  Balance,
  state,
  paymentData = [],
  rechargeData = [],
  userId,
  isIndividualUser,
  handleUser,
  count,
  total_balance=0
}) => {
  const classes = useStyles()
  const [value, setValue] = React.useState(3)
  const handleChange = (newValue) => {
    setValue(newValue)
  }

    const history = useHistory()


  const { isAdmin } = useAuth()
  const { reportLocation, setReportLocation } = useContext(ReportContext)

  const tabs = [
    {
      index: 1,
      name: 'Invoices',
      selected: true
    },
    {
      index: 2,
      name: 'Statements',
      selected: false
    },
    {
      index: 3,
      name: 'Recharges',
      selected: false
    },
    ];
    const tabs_court = [
      {
        index: 1,
        name: 'Invoices',
        selected: true
      },
      {
        index: 2,
        name: 'Statements',
        selected: false
      },

    ];
  const selectChange = (index) => {
    setReportLocation(index)
  }
  const [selectedOption,setSelectedOption] = React.useState('Select User');

  const handleChange_select = (event) => {
    setSelectedOption(event.target.value);
    if(selectedOption==="Select Court")
    {
      handleUser(false)
    }
    else {
      handleUser(true);
    }
  };
  const { data: courtData, responseData: courtsData, count:courtDataLength, dataExist:courtsDataExist } = useSelector(({ court }) => court)
  const { data: paymentPlanData, responseData: paymentPlan } = useSelector(
    ({ paymentPlan }) => paymentPlan
  )
    const ShowCourt = courtId => {
    // const foundData = courtData?.find(item => item.id === courtId)
    const foundData = history.location.state?.courtData?.find(item => item.id === courtId)
    return foundData?.name
  }
  const ShowPaymentPlan = paymentId => {
    // const foundData = paymentPlanData?.find(item => item.id === paymentId)
    const foundData = history.location.state?.paymentPlanData?.find(item => item.id === paymentId)
    return foundData?.name
  }
    const userData = history.location.state?.data;
    const openHsitoryPage = () => {
      history.push({
      pathname: '/manage-users/clients/page=1',
      state: {
        userDataPayment: userData,
      },
    })
  }
  return (
    <>
      <TabContext value={state}>
        <Box sx={{ borderColor: "divider" }}>
          <div className={classes.relative}>
            {
            isAdmin?
                <>
                  <Button
                        variant="contained"
                        color="secondary"
                        style={{
                              background:"#3971FF",
                              color:"#FFFFFF"
                            }}
                        className={classes.btnpay}
                        onClick= {(e)=>{
                                    e.preventDefault()
                                    openHsitoryPage()}}
                      >
                        Back
                      </Button>
                </>:null

          }
            {isIndividualUser || isAdmin? (
              <>
                <Button
                  color="default"
                  variant="contained"
                  className={classes.creditBtn}
                  style={
                    userData?.balance < 0 || total_balance < 0
                      ? { color: "#C2143E" }
                      : { color: "#53B500" }
                  }
                >
                  {
                    userData !== undefined?
                    `CREDIT : ${parseFloat(userData?.balance).toFixed(2)}`:`CREDIT:${parseFloat(total_balance).toFixed(2)}`
                  }
                </Button>
            <div className={classes.BtnContainer} style={{ display: 'flex',marginBottom:"15px"}}>

            </div>
                </>
            ):
              <>

              <div className={classes.BtnContainer} style={{ display: 'flex' }}>
                {
                  tabs_court.map((tab, i) => {
                    return (
                      <Button onClick={() => setValue(tab.index)} variant="contained" color={tab.index === value ? "" : "secondary"} className={tab.index === value ? classes.InvoiceButton : classes.PrepaidButton}>
                        {tab.name} {}
                      </Button>
                    )
                  })
                }

              </div>
              <div className={classes.option}>
                <Box >
                  <FormControl sx={{ m: 1, minWidth: 120 }} className={classes.formcontrol}>
                    <Select
                      value={selectedOption}
                      onChange={handleChange_select}
                      inputProps={{ 'aria-label': 'Without label' }}
                      className={classes.optionText}

                    >
                      <MenuItem value="Select User" className={classes.optionText}>Select User</MenuItem>
                      <MenuItem value="Select Court"  className={classes.optionText}>Select Court</MenuItem>
                    </Select>

                  </FormControl>

                </Box>
              </div>


              </>
            }
          </div>
          <Credit_Btn />
          { userData?.user_type == 2 ? <>
             <Card>
               <CardContent>
                <strong> Court Name: </strong> <span className="fw-light">{ShowCourt(userData?.court)}</span>,
                <strong> Payment Plan: </strong> {ShowPaymentPlan(userData?.pay_plan_procedure)},
                <strong> Client Name: </strong> {userData?.first_name} {userData?.last_name}
               </CardContent>
             </Card>
        </>: null
        }
        </Box>

        <br />
        {value === 1 && (
          <Invoice paymentData={paymentData} rechargeData={rechargeData} selectuser={selectedOption}/>
        )}
        {value === 2 && (
          <Statement paymentData={paymentData} rechargeData={rechargeData} />
        )}
        {value === 3 && (
          <Recharges paymentData={paymentData} rechargeData={rechargeData} count={count} userId={userId}/>
        )}
      </TabContext>
    </>
  )
}

export default PaymentInformation
