import { makeStyles } from "@material-ui/core/styles";


export const useStyles = makeStyles((theme) => ({
    InputWithPrefix:{
        display: "flex",
        alignItems: "center",
        // maxWidth: "60%",
        background: "#f9f9f9",
        border: "1px solid lightgray",
        borderRadius: "4px",
        paddingLeft: "0.5rem",
        maxHeight: "44px",
        overflow: "hidden",
        fontFamily: "Open Sans",
        // marginTop:"5px"  
      },
      Prefix:{
        fontWeight: 300,
        fontSize: "20px",
        // color: "#999"
      },    
}))