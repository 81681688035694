import React, { useEffect, useRef, useState } from 'react';
import Button from "@material-ui/core/Button";
import axios from "../../Store/request";
import { ManageUserAction } from "../../Store/Actions/ManageUserAction";
import useToast from "../Toast/useToast";
import { useDispatch, useSelector } from "react-redux";
import { addUserStyles, dialogStyles, useStyles } from "./UserDataTableStyle";
import FormValidation from './FormValidation';
import CommonClientForm from './CommonClientForm';
import differenceInDays from "date-fns/differenceInDays";
import { EditUserAction, GetUser } from "../../Store/Actions/ManageUserAction";
import { GetAllOfficers } from '../../Store/Actions/Officers';
import CircularSpinner from "../CircularProgressUserReport/CircularSpinner";
import CommonServiceDialog from "./CommonServiceDialog";
import {GetTerritoryData} from "../../Store/Actions/Court";
import useAuth from "../../utils/useAuth";




const AddClient = ({ setAddClientProcessing, addClientProcessing, postsPerPage, currentPage, passedIndex,stateList }) => {
  
  const { isOperator } = useAuth()

  const serviceRef = useRef()

  const { showToast } = useToast()
  const dispatch = useDispatch()
  const [numOfServiceDialog, setNumOfServiceDialog] = useState(0)
  const userClasses = dialogStyles()
  const classes = useStyles()
  const [formError, setFormError] = useState()
  const addUserClasses = addUserStyles()
  const [media, setMedia] = useState([])
  const [checkDisable1, setCheckDisable1] = useState(false)
  const [validation, setValidation] = useState({})
  const [openUserTypeModal,setOpenUserTypeModal]=useState(false)
  const [openCourtModal, setOpenCourtModal] = useState(false)
  const [openOfficerModal, setOpenOfficerModal] = useState(false)
  const [openPaymentModal, setOpenPaymentModal] = useState(false)
  const [dropDownInput, setDropDownInput] = useState("")
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
      date_of_birth: null,
    address: "",
    emergency_contact: "",
    email: "",
    home_phone: "",
    phone: "",
    alt_phone: "",
    pay_plan_procedure: "",
    user_type: passedIndex[0].selected ? 1 : passedIndex[1].selected ? 2 : passedIndex[2].selected ? 3 : 4,
    court: "",
    parole_officer: "",
    middle_name: "",
    username: "",
    services: [],
    court_amount: 0,
    user_amount: 0,
    total_price: 0,
    attachments: [],
    is_court_admin_user: false,
    is_operator_user: false,
    is_administrator: false,
    address2: "",
    city: "",
    state: "",
    postal_code: "",
    occupation: "",
    case_number: "",
    territory: "",
    judge_name: "",
    mailing_address: "",
    one_time_total_price:'',
    is_auto_recharge:false,
    threshold:0,
    refill_amount:0,

  })
  useEffect(() => {
    if (serviceRef.current) {
      serviceRef?.current.scrollIntoView({ behavior: "smooth" })
    }
  }, [numOfServiceDialog])


  const addUser = async addData => {
    try {
      const response = await axios.post("/manage-users/", addData)
      if (response.status === 200) {
        setAddClientProcessing(false)
        setCheckDisable1(false)
        dispatch(GetUser({ limit: postsPerPage, offset: postsPerPage * currentPage - postsPerPage, user_type: current_user_type }))
        showToast("User Added Successfully", "success")
        setMedia([])
        dispatch(ManageUserAction(false))
        setNumOfServiceDialog(0)
        setUserData({
          first_name: "",
          last_name: "",
          date_of_birth: null,
          address: "",
          emergency_contact: "",
          email: "",
          home_phone: "",
          phone: "",
          alt_phone: "",
          pay_plan_procedure: "",
          user_type: "",
          court: "",
          parole_officer: "",
          middle_name: "",
          username: "",
          services: [],
          attachments: [],
          court_amount: 0,
          user_amount: 0,
          total_price: 0,
          is_court_admin_user: false,
          is_operator_user: false,
          is_administrator: false,
          address2: "",
          city: "",
          state: "",
          postal_code: "",
          occupation: "",
          case_number: "",
          territory: "",
          judge_name: ""
        })
      }
    } catch (error) {
      setAddClientProcessing(false)
      setCheckDisable1(false)
      showToast("Could not add user, please try later", "error")
    }
  }
  const { current_user_type } = useSelector(({ manageUser }) => manageUser)
  const { data: courtData, responseData: courtsData } = useSelector(({ court }) => court)
  const { data: officersData, responseData: officers, count: officerDataLength, dataExist: officerDataExist } = useSelector(({ officers }) => officers)
  const { data: paymentPlanData, responseData: paymentPlan } = useSelector(
    ({ paymentPlan }) => paymentPlan
  )
  const { data: servicesData, responseData: service, count: serviceDataLength } = useSelector(({ services }) => services)
  const [offierDataList, setOfficerDataList] = useState([])
  const [serviceDataList, setserviceDataList] = useState([])
  const [serviceDataPriceList, setserviceDataPriceList] = useState([])
  const userTypeDropDownData = () => {

    const users = [
      { label: 'Admin', value: 1 },
      // { label: 'Client', value: 2 },
      { label: 'Court Admin', value: 3 },
      { label: 'Operator', value:4 },
    ];
    return users
  }
  const paymentDropDownData = () => {
    return paymentPlanData?.map(item => ({ value: item.id, label: item.name }))
  }
  const officersDropDownData =async (val) => {
    if (val) {
      const response=await axios.get(`api/v1/courts/probation/officer/court_officers/?court=${val}`)
      const require=response?.data?.map(item => ({ value: item.id, label: item.first_name+" "+item.last_name }))
      setOfficerDataList(require)
    }

  }
  const servicesDropDownData = async (val, courtValue) => {
    if (userData.user_type === 2) {
      if (val === "court" && (courtValue !== undefined && courtValue !== "")) {
        const response = await axios.get(`api/v1/services/court_client_pay_price/?court=${courtValue}&type=1`)
        const courtDropDownData = response.data.map(ele => {
          return ({ value: ele.id, label: ele.name })
        })
        const courtDropDownDataPrice = response.data.map(ele => {
          return ({ id: ele.id, price: ele.price, one_time_fee: ele.one_time_fee })
        })
        setserviceDataList(courtDropDownData)
        setserviceDataPriceList(courtDropDownDataPrice)
        return
      }
      if (val === "court" && (courtValue===undefined || courtValue=== "")){
        setserviceDataList([])
        setserviceDataPriceList([])
        return
      }
  
      if (val === "client" && (courtValue !== undefined && courtValue !== "")) {
        const response = await axios.get(`api/v1/services/court_client_pay_price/?court=${courtValue}&type=2`)
        const clientDropDownData = response.data.map(ele => {
          return ({ value: ele.id, label: ele.name })
        })
        const clientDropDownDataPrice = response.data.map(ele => {
          return ({ id: ele.id, price: ele.price, one_time_fee: ele.one_time_fee })
        })
        setserviceDataList(clientDropDownData)
        setserviceDataPriceList(clientDropDownDataPrice)
        return
      }
      if (val === "client" && (courtValue===undefined || courtValue=== "")){
        setserviceDataList([])
        setserviceDataPriceList([])
        return
      }
    
      if (val === "hybrid" && (courtValue !== undefined && courtValue !== "")) {
        const response = await axios.get(`api/v1/services/court_client_pay_price/?court=${courtValue}&type=3`)
        const hybridDropDownData = response.data.map(ele => {
          return ({ value: ele.id, label: ele.name })
        })
        const hybridDropDownPriceData = response.data.map(ele => {
          return ({ id: ele.id, price: ele.price, one_time_fee: ele.one_time_fee })
        })
        setserviceDataList(hybridDropDownData)
        setserviceDataPriceList(hybridDropDownPriceData)
        return
      }
      if (val === "hybrid" && (courtValue===undefined || courtValue=== "")){
        setserviceDataList([])
        setserviceDataPriceList([])
        return
      }
      
    }
  }

  const addUserFunc = e => {
    e.preventDefault()  

    if (FormValidation(userData, true, passedIndex[0].selected).formIsValid === false) {
      setValidation(FormValidation(userData, true, passedIndex[0].selected).formErrorObj)
    }
    if (FormValidation(userData, true, passedIndex[0].selected).formIsValid) {
      setAddClientProcessing(true)
      setCheckDisable1(true)
      const serviceList = userData.services.map(element => {
        const matchedData = serviceDataPriceList.find(item => item.id === element.id)
        if (matchedData?.one_time_fee) {
          delete element.end_date
          delete element.total_days
          return { ...element, total_price: element.price }
        }

        if (matchedData?.one_time_fee === false) {
          if (element.end_date.length !== 10) {
            delete element.end_date
            delete element.total_days
          }
          return { ...element }
        }

      })
      let finalObj = {
        ...userData,
        name: userData.first_name,
        services: serviceList,

      }
      
        const convertData=(new Date(userData.date_of_birth))
        const mm=convertData.getMonth()+1;
        const dd=convertData.getDate();
        const yy=convertData.getFullYear();
        finalObj.date_of_birth=yy+'-'+mm+'-'+dd

      if (+userData.pay_plan_procedure !== 3) {
        delete finalObj.user_amount;
        delete finalObj.court_amount;
      }
      if (+userData.user_type === 1) {
        finalObj.is_administrator = true
      }
      if (+userData.user_type === 3) {
        finalObj.is_court_admin_user = true
      }
      if (+userData.user_type === 4) {
        finalObj.is_operator_user = true
      }
      if (+userData.user_type !== 2) {
        finalObj.total_price = 0
        finalObj.user_amount = 0
        finalObj.court_amount = 0
      }
      if (passedIndex[0].selected) {
        finalObj.pay_plan_procedure = 1
        delete finalObj.judge_name
        delete finalObj.case_number
        delete finalObj.parole_officer
      }
      addUser(finalObj)
    }
  }


  let serviceDialogs = [];
  const serviceDialog = (e) => {
    e.preventDefault();
    setNumOfServiceDialog(numOfServiceDialog + 1);
    const currentDate=new Date()
    let service = { id: "", install_date:"", projected_install_date: "", end_date: "", price: "", total_price: 0, total_days: 0,client_payment:0,court_payment:0,is_new:true};
    let copyServices = [...userData.services]
    copyServices.push(service)
    setUserData({ ...userData, services: copyServices })
  }


  for (let i = 0; i < numOfServiceDialog; i++) {
    const serviceErrorId = validation.services ? validation.services.id : [];
    const serviceErrorInstallDate = validation.services ? validation.services.install_date : [];
    const serviceErrorProjectedInstallDate = validation.services ? validation.services.projected_install_date : [];
    const serviceErrorProjectedEndDate = validation.services ? validation.services.end_date : [];

    serviceDialogs.push(
        <CommonServiceDialog
            isServiceDialog={false}
            i={i}
            key={i}
            serviceRef={serviceRef}
            numOfServiceDialog={numOfServiceDialog}
            setNumOfServiceDialog={setNumOfServiceDialog}
            validation={validation}
            setValidation={setValidation}
            userData={userData}
            setUserData={setUserData}
            serviceDataList={serviceDataList}
            serviceDataPriceList={serviceDataPriceList}
            setDropDownInput={setDropDownInput}
            serviceErrorId={serviceErrorId}
            serviceErrorInstallDate={serviceErrorInstallDate}
            serviceErrorProjectedEndDate={serviceErrorProjectedEndDate}
            serviceErrorProjectedInstallDate={serviceErrorProjectedInstallDate}
        />
        )
  }


  useEffect(() => {
    if (officerDataLength === officersData.length && officersData.length > 0) return;
    if (officerDataExist)
      dispatch(GetAllOfficers())
  }, [officersData])



  useEffect(() => {
    if (paymentPlan.id) {
      setUserData({ ...userData, pay_plan_procedure: paymentPlan.id })
    }
  }, [paymentPlan.id])

  useEffect(() => {
    if (courtsData.id) {
      setUserData({ ...userData, court: courtsData.id })
    }
  }, [courtsData.id])

  useEffect(() => {
    if (officers.id) {
      setUserData({ ...userData, parole_officer: officers.id })
    }
  }, [officers.id])


  useEffect(() => {

    if (service.id) {
      setUserData({ ...userData, services: [...userData.services, service.id] })
    }
  }, [service.id])

  // const [territoryData,setTerritoryData]=useState()
  const { territoryData:TerritoryData} = useSelector(({ court }) => court)
  const handleClick=async (val)=>{
    setUserData({ ...userData, state: val })
    dispatch(GetTerritoryData(val))
  }
  const territoryList=TerritoryData?.map((element)=>{
    return ({value:element.id,label:element.name})
  })

  let addClient = (<div>
    <form onSubmit={addUserFunc} style={{ marginTop: "-30px" }}>
      <CommonClientForm
        isAdding={true}
        userTypeDropDownData={userTypeDropDownData}
        userData={userData}
        setUserData={setUserData}
        formError={formError}
        validation={validation}
        setOpenCourtModal={setOpenCourtModal}
        setDropDownInput={setDropDownInput}
        paymentDropDownData={paymentDropDownData}
        setOpenPaymentModal={setOpenPaymentModal}
        officersDropDownData={officersDropDownData}
        setOpenOfficerModal={setOpenOfficerModal}
        serviceDialog={serviceDialog}
        setNumOfServiceDialog={setNumOfServiceDialog}
        passedIndex={passedIndex}
        offierDataList={offierDataList}
        servicesDropDownData={servicesDropDownData}
        serviceDataList={serviceDataList}
        media={media}
        setMedia={setMedia}
        stateList={stateList}
        handleClick={handleClick}
        territoryList={territoryList}
        setOpenUserTypeModal={setOpenUserTypeModal}
      />



      {serviceDialogs}
      <Button 
        variant="contained"
        color="secondary"
        className={addUserClasses.button}
        style={{
          marginTop: "20px",
          alignSelf: "center",
          width: "96%"
        }}
        disabled={checkDisable1}
        type="submit"

      >
        {!isOperator && passedIndex[0].selected ? "Add Admin" : "Add Client"}
      </Button>
    </form>

  </div>)
  return (
    <>
      {!addClientProcessing ? addClient : <div className={classes.root}>
       <CircularSpinner/>
      </div>}
    </>
  );
};

export default AddClient;
