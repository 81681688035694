import React,{useEffect,useState,useContext} from "react";
import Container from '@material-ui/core/Container';
import ManagementHeader from "../../Components/OfiicerManagementHeader/OfiicerManagementHeader";
import ManagementDataTable from "../../Components/ManagementDataTable/ManagementDataTable";
import Pagination from "../../Components/Pagination/Pagination"
import { useStyles } from "./OfficersManagementStyle"
import { useHistory } from "react-router";
import {connect, useDispatch, useSelector} from "react-redux";
import { auth } from "../../Store/Actions/Auth";
import axios from '../../Store/request';
import {ScreenContext} from './../../context/context'
import NewPagination from "../../Components/NewPagination/NewPagination";
import {GetAllOfficers, GetOfficers} from "../../Store/Actions/Officers";
import {CircularProgress} from "@material-ui/core";
import CircularSpinner from "../../Components/CircularProgress/CircularSpinner";
import Pagination_New from "../../Components/NewPagination/Pagination_New";

const Management = (props) => {
  const classes = useStyles()

  const history = useHistory()
  const {screenSize, setScreenSize} = useContext(ScreenContext)
  const [officerCount,setOfficerCount]=useState(0);
  const dispatch = useDispatch()
  const {data,count,loading} = useSelector(({ officers }) => officers)
  // useEffect(() => {
  //   if(data.length>0 && data.length===count)return;
  //   dispatch(GetAllOfficers())
  // }, [data.length])

  useEffect(()=>{
    if (props.accessToken === null || props.accessToken === "" || props.accessToken === undefined){
      history.push("/")
    }
  },[])
  const [isDisabled,setIsDisabled]=useState(false);
  const [filterTxt, setFilterTxt] = useState('');
  var filteredData = []


  const filterData = (e) => {
    let val = e.target.value
    setFilterTxt(val)
  }

  filteredData = data?.filter(element => {
    if(element.court.name?.toLowerCase().includes(filterTxt?.toLowerCase()))
    {
      return (
        element.court.name?.toLowerCase().includes(filterTxt?.toLowerCase())
      )
    }
    // if(element.first_name?.toLowerCase().includes(filterTxt?.toLowerCase()))
    // {
    //   return (
    //     element.first_name?.toLowerCase().includes(filterTxt?.toLowerCase())

    //   )
    // }
  })
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <div className={classes.MainContainer}>
      <ManagementHeader searchText={filterData} isDisabled={isDisabled} setIsDisabled={setIsDisabled} data={data}/>
      {loading?<CircularSpinner/>
        :
      <Container maxWidth="lg" className={screenSize > 1400 ? classes.container:classes.containerMob}>
        {filterTxt!==""?<ManagementDataTable DataArray={filteredData} isDisabled={isDisabled} setIsDisabled={setIsDisabled}/>
        :<ManagementDataTable DataArray={currentPosts} isDisabled={isDisabled} setIsDisabled={setIsDisabled}/>
        }
      </Container>}


        {count>10 &&

        <Container maxWidth="lg" style={{visibility:loading || filterTxt.length>0?"hidden":"visible"}}>

          {/*<NewPagination*/}
          {/*  postsPerPage={postsPerPage}*/}
          {/*  totalPosts={count}*/}
          {/*  // paginate={paginate}*/}
          {/*  paginate={count===data.length && data.length>0?(pageNumber)=>{setCurrentPage(pageNumber)}:(pageNumber) => {*/}
          {/*    dispatch(GetOfficers({limit: postsPerPage, offset: (postsPerPage * pageNumber - postsPerPage)}))*/}
          {/*  }}*/}
          {/*/>*/}

          <Pagination_New  count={count}
                           activePage={currentPage}
                           postsPerPage={postsPerPage}
                           paginate={(pageNumber)=>{setCurrentPage(pageNumber)}}
          />
        </Container>
        }
      {/*<button onclick={showCount}>show count</button>*/}
    </div>
  )
}

const mapStateToProps = (state) => {
  return{
    accessToken:state.auth.accessToken
  }
}

export default connect(mapStateToProps,{auth})(Management);