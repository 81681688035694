import { makeStyles } from "@material-ui/core/styles";
import calender from "./../../assets/calender.png"

export const useStyles = makeStyles((theme) => ({
  
  InputWithPrefix:{
    display: "flex",
    alignItems: "center",
    maxWidth: "60%",
    background: "#f9f9f9",
    border: "1px solid lightgray",
    borderRadius: "4px",
    paddingLeft: "0.5rem",
    maxHeight: "44px",
    overflow: "hidden",
    fontFamily: "Open Sans"
  },
  Prefix:{
    fontWeight: 300,
    fontSize: "20px",
    // color: "#999"
  },

  CreditTopDiv: {
    height: "550px",
    width: "412px",
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    background: "none",
    alignItems: "center",
    boxShadow: "none"
  },
  CreditLogoWrapper: {
    backgroundColor: "white",
    borderRadius: 50,
    padding: 18,
    border: "1px solid #FFFFFF",
    // boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.203851)",
    position: "absolute",
    top: -35
  },
  CreditLogo: {
    width: 34,
    height: 32
  },
  CreditupperDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "412px",
    height: "520px",
    alignItems: "center",
    background: "white"
  },
  CreditfirstDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "80%",
    marginBottom: "20px"
  },
  CreditfirstDiv1: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "65%",
    marginBottom: "20px"
  },
  CreditinputCont: {
    width: "100%",
    height: "44px",
    background: "#F9F9F9",
    color: "#333333",
    borderRadius: "5px",
    border: "1px solid lightgray",
    fontFamily: "Open Sans",
    fontSize: "16px",
    paddingLeft: "15px",
    // marginBottom: "5px",
    outline:"none"
  },
  CreditinputContAmount: {
    width: "100%",
    height: "44px",
    background: "#F9F9F9",
    color: "#333333",
    borderRadius: "5px",
    border: "none",
    fontFamily: "Open Sans",
    fontSize: "16px",
    paddingLeft: "5px",
    marginBottom: "2px",
    outline:"none"
  },
  inputFileWrapper:{
    width: "100%",
    height: "100px",
    background: "#F8FAFF",
    color: "gray",
    borderRadius: "5px",
    border: "1px dashed #3971FF",
    fontFamily: theme.fonts.family.Light,
    boxSizing: "border-box",
    fontSize: "16px",
    // paddingLeft: "15px",
    marginBottom: "5px",
    display: "flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    textAlign:"center",
    cursor:"pointer"
  },
  inputFileWrapper1:{
    width: "100%",
    height: "45px",
    background: "#F8FAFF",
    color: "gray",
    borderRadius: "5px",
    border: "1px dashed #3971FF",
    fontFamily: theme.fonts.family.Light,
    boxSizing: "border-box",
    fontSize: "16px",
    // paddingLeft: "15px",
    marginBottom: "5px",
    display: "flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    textAlign:"center",
    cursor:"pointer"
  },
  imageText:{
    // textAlign:"center",
    // fontFamily: theme.fonts.family.Light,
    // fontSize: "18px",
    // paddingTop:"20px"

  },
  uploadInstruction:{
    color: "#3971FF",

  },
  imageContainer:{
    display: "flex",
    flexWrap:" wrap",
    // justifyContent: "space-between", 
    marginTop: 10

    //background: "red"
  },
  imageWrapper:{
    border:"1px solid #C7C7C7",
    padding: "5px 10px",
    boxSizing: "border-box",
    borderRadius: 80,
    width: "fit-content",
    marginBottom:"5px"

  },
  button: {
    background: "#3971FF",
    margin: "0px 20px 30px 20px",
    "&.MuiButton-containedSecondary": {
      width: "80%",
      height: 38,
      display: "flex",
      alingItems: "center",
      borderRadius: "5px !important",
      marginTop: "5px",
      "&:hover": {
        background: "#3971FF"
      },
      "& .MuiButton-label": {
        fontFamily: "Open Sans",
        fontSize: 16,
        fontWeight: 700,
        textTransform: "capitalize",
        marginTop: 3
      },
      "& .MuiSvgIcon-root": {
        marginTop: -2
      }
    }
  },

  MainContainer: {
    width: "100%",
    height: 44,
    background: "#FFFFFF",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.151907)",
    borderRadius: 2,
    margin: "-20px 0px 35px 0px"
  },
  ContainerInner: {
    display: "grid",
    gridTemplateColumns: "2fr 5fr 1fr 1fr",
    padding: "0 15px",
    position: "relative"

  },
  HeadingInner: {
    display: "grid",
    gridTemplateColumns: "repeat(5,1fr)",
    padding: "0 15px",
    position: "relative",
    paddingBottom: 20,
  },
  ListItmes: {
    color: "#31394D",
    fontFamily: "Open Sans",
    fontSize: theme.fonts.sizes.fontM,
    fontWeight: 600,
  },
  Heading: {
    color: "#31394D",
    fontFamily: "Open Sans",
    fontSize: theme.fonts.sizes.fontM,
    fontWeight: 400,
  },
  Heading1: {
    color: "#31394D",
    fontFamily: "Open Sans",
    fontSize: theme.fonts.sizes.fontM,
    fontWeight: 400,
    position: "absolute",
    left: 0,
  },

  root: {
    display: 'flex',
    justifyContent:"center",

  },
  upperDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '400px',
    width: '350px',
    alignItems: 'center',
    background:'white'

  },
  topDiv:{
    height: '450px',
    width: '400px',
    flexDirection:'row',
    display:'flex',
    justifyContent:'center',
    background:'none',
    alignItems:'center',
    boxShadow:'none'
  },
  topDiv1:{
    height: '550px',
    width: '400px',
    flexDirection:'row',
    display:'flex',
    justifyContent:'center',
    background:'none',
    alignItems:'center',
    boxShadow:'none'
  },
  topDivUser:{
    height: '800px',
    width: '420px',
    flexDirection:'row',
    display:'flex',
    justifyContent:'center',
    background:'none',
    alignItems:'center',
    boxShadow:'none'
  },
  firstDiv: {
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'space-around',
    width: '80%',
  },
  selectCont: {
    width: '100%',
    height: '38px',
    background: 'rgb(244, 244, 244)',
    color: 'gray',
    borderRadius: '5px',
    // paddingLeft: '5px',
    border: '1px solid lightgray',
    fontFamily: theme.fonts.family.Light,
    fontSize: '16px',
    paddingLeft: '10px'
  },
  // button: {
  //   background: "#3971FF",
  //   margin: "0px 20px",
  //   "&.MuiButton-containedSecondary": {
  //     width: '80%',
  //     height: 38,
  //     display: "flex",
  //     alingItems: "center",
  //     borderRadius: "5px !important",
  //     "&:hover": {
  //       background: "#3971FF",
  //     },
  //     "& .MuiButton-label": {
  //       fontFamily: theme.fonts.family.SemiBold,
  //       fontSize: 15,
  //       fontWeight: 400,
  //       textTransform: "capitalize",
  //       marginTop: 3,
  //     },
  //     "& .MuiSvgIcon-root": {
  //       marginTop: -2
  //     }
  //   }
  // },
  button1: {
    background: "#3971FF",
    // margin: "0px 20px",
    marginTop:"20px",
    "&.MuiButton-containedSecondary": {
      width: '80%',
      height: 38,
      display: "flex",
      alingItems: "center",
      borderRadius: "5px !important",
      "&:hover": {
        background: "#3971FF",
      },
      "& .MuiButton-label": {
        fontFamily: theme.fonts.family.SemiBold,
        fontSize: 15,
        fontWeight: 400,
        textTransform: "capitalize",
        marginTop: 3,
      },
      "& .MuiSvgIcon-root": {
        marginTop: -2
      }
    }
  },
  descModal:{
    height:'auto',
    width:'350px',
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-around',
    alignItems: 'center'
  },
  innerModal:{
    width:'85%',
    marginTop:'40px',
    marginBottom:'40px'
  },
  holder:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-around',
    margin: '20px'
  },
  modalHead:{
    fontSize:'20px',
    fontWeight:700 ,
    marginTop:'15px',
    marginBottom: '15px'
  },
  modalText:{
    fontSize:'18px',
    marginTop:'15px',
    marginBottom: '15px'
  },

}));
export const useStylesUserManagement = makeStyles((theme) => ({
  // MainContainer: {
  //   // position:"relative",
  //   // left:50,
  //   margin:"auto",
  //   width: "60%",
  //   height: "60%",
  //   backgroundColor: "#F8FBFF"
  // },
}));
export const useStylesEditClient = makeStyles((theme) => ({

  CreditTopDiv: {
    height: "500px",
    width: "412px",
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    background: "none",
    alignItems: "center",
    boxShadow: "none"
  },
  CreditLogoWrapper: {
    backgroundColor: "white",
    borderRadius: 50,
    padding: 10,
    border: "1px solid #FFFFFF",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.203851)",
    position: "absolute",
    top: -25
  },
  CreditLogo: {
    width: 34,
    height: 32
  },
  CreditupperDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "412px",
    alignItems: "center",
    background: "white"
  },
  CreditfirstDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "80%",
    marginBottom: "20px"
  },
  CreditfirstDiv1: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "65%",
    marginBottom: "20px"
  },
  CreditinputCont: {
    width: "100%",
    height: "44px",
    background: "#F9F9F9",
    color: "#333333",
    borderRadius: "5px",
    border: "1px solid lightgray",
    fontFamily: "Open Sans",
    fontSize: "16px",
    paddingLeft: "15px",
    marginBottom: "5px"
  },
  inputFileWrapper:{
    width: "100%",
    height: "100px",
    background: "#F8FAFF",
    color: "gray",
    borderRadius: "5px",
    border: "1px dashed #3971FF",
    fontFamily: theme.fonts.family.Light,
    boxSizing: "border-box",
    fontSize: "16px",
    // paddingLeft: "15px",
    marginBottom: "5px",
    display: "flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    textAlign:"center",
    cursor:"pointer"
  },
  inputFileWrapper1:{
    width: "100%",
    height: "45px",
    background: "#F8FAFF",
    color: "gray",
    borderRadius: "5px",
    border: "1px dashed #3971FF",
    fontFamily: theme.fonts.family.Light,
    boxSizing: "border-box",
    fontSize: "16px",
    // paddingLeft: "15px",
    marginBottom: "5px",
    display: "flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    textAlign:"center",
    cursor:"pointer"
  },
  imageText:{
    // textAlign:"center",
    // fontFamily: theme.fonts.family.Light,
    // fontSize: "18px",
    // paddingTop:"20px"

  },
  uploadInstruction:{
    color: "#3971FF",

  },
  imageContainer:{
    display: "flex",
    flexWrap:" wrap",
    justifyContent: "space-between",
    marginTop: 10

    //background: "red"
  },
  imageWrapper:{
    border:"1px solid #C7C7C7",
    padding: "5px 10px",
    boxSizing: "border-box",
    borderRadius: 80,
    width: "fit-content",
    marginBottom:"5px"

  },
  button: {
    background: "#3971FF",
    margin: "0px 20px 30px 20px",
    "&.MuiButton-containedSecondary": {
      width: "80%",
      height: 38,
      display: "flex",
      alingItems: "center",
      borderRadius: "5px !important",
      marginTop: "5px",
      "&:hover": {
        background: "#3971FF"
      },
      "& .MuiButton-label": {
        fontFamily: theme.fonts.family.SemiBold,
        fontSize: 15,
        fontWeight: 400,
        textTransform: "capitalize",
        marginTop: 3
      },
      "& .MuiSvgIcon-root": {
        marginTop: -2
      }
    }
  },

  MainContainer: {
    width: "100%",
    height: 44,
    background: "#FFFFFF",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.151907)",
    borderRadius: 2,
    margin: "-20px 0px 35px 0px"
  },
  ContainerInner: {
    display: "grid",
    gridTemplateColumns: "2fr 5fr 1fr 1fr",
    padding: "0 15px",
    position: "relative"

  },
  HeadingInner: {
    display: "grid",
    gridTemplateColumns: "2fr 5fr 1fr 2fr",
    padding: "0 15px",
    position: "relative",
    paddingBottom: 20,
  },
  ListItmes: {
    color: "#31394D",
    fontFamily: "Open Sans",
    fontSize: theme.fonts.sizes.fontM,
    fontWeight: 600,
  },
  Heading: {
    color: "#31394D",
    fontFamily: "Open Sans",
    fontSize: theme.fonts.sizes.fontM,
    fontWeight: 400,
  },
  Heading1: {
    color: "#31394D",
    fontFamily: "Open Sans",
    fontSize: theme.fonts.sizes.fontM,
    fontWeight: 400,
    position: "absolute",
    left: 0,
  },

  root: {
    display: 'flex',
    justifyContent:"center",

  },
  upperDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '400px',
    width: '350px',
    alignItems: 'center',
    background:'white'

  },
  topDiv:{
    height: '450px',
    width: '400px',
    flexDirection:'row',
    display:'flex',
    justifyContent:'center',
    background:'none',
    alignItems:'center',
    boxShadow:'none'
  },
  topDiv1:{
    height: '550px',
    width: '400px',
    flexDirection:'row',
    display:'flex',
    justifyContent:'center',
    background:'none',
    alignItems:'center',
    boxShadow:'none'
  },
  topDivUser:{
    height: '800px',
    width: '420px',
    flexDirection:'row',
    display:'flex',
    justifyContent:'center',
    background:'none',
    alignItems:'center',
    boxShadow:'none'
  },
  firstDiv: {
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'space-around',
    width: '80%',
  },
  selectCont: {
    width: '100%',
    height: '38px',
    background: 'rgb(244, 244, 244)',
    color: 'gray',
    borderRadius: '5px',
    // paddingLeft: '5px',
    border: '1px solid lightgray',
    fontFamily: theme.fonts.family.Light,
    fontSize: '16px',
    paddingLeft: '10px'
  },
  // button: {
  //   background: "#3971FF",
  //   margin: "0px 20px",
  //   "&.MuiButton-containedSecondary": {
  //     width: '80%',
  //     height: 38,
  //     display: "flex",
  //     alingItems: "center",
  //     borderRadius: "5px !important",
  //     "&:hover": {
  //       background: "#3971FF",
  //     },
  //     "& .MuiButton-label": {
  //       fontFamily: theme.fonts.family.SemiBold,
  //       fontSize: 15,
  //       fontWeight: 400,
  //       textTransform: "capitalize",
  //       marginTop: 3,
  //     },
  //     "& .MuiSvgIcon-root": {
  //       marginTop: -2
  //     }
  //   }
  // },
  button1: {
    background: "#3971FF",
    // margin: "0px 20px",
    marginTop:"20px",
    "&.MuiButton-containedSecondary": {
      width: '80%',
      height: 38,
      display: "flex",
      alingItems: "center",
      borderRadius: "5px !important",
      "&:hover": {
        background: "#3971FF",
      },
      "& .MuiButton-label": {
        fontFamily: theme.fonts.family.SemiBold,
        fontSize: 15,
        fontWeight: 400,
        textTransform: "capitalize",
        marginTop: 3,
      },
      "& .MuiSvgIcon-root": {
        marginTop: -2
      }
    }
  },
  descModal:{
    height:'auto',
    width:'350px',
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-around',
    alignItems: 'center'
  },
  innerModal:{
    width:'85%',
    marginTop:'40px',
    marginBottom:'40px'
  },
  holder:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-around',
    margin: '20px'
  },
  modalHead:{
    fontSize:'20px',
    fontWeight:700 ,
    marginTop:'15px',
    marginBottom: '15px'
  },
  modalText:{
    fontSize:'18px',
    marginTop:'15px',
    marginBottom: '15px'
  },

}));

export const newStyles = makeStyles((theme) => ({
  error:{
    fontFamily:"Open Sans",
    fontWeight:"800px",
    color:"#f50057",
    fontSize:"20px"
  },
  MainContainer: {
    width: "100%",
    height: 44,
    background: "#FFFFFF",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.151907)",
    borderRadius: 2,
    margin: "-20px 0px 35px 0px"
  },
  ContainerInner: {
    display: "grid",
    gridTemplateColumns: "repeat(7, 2fr)",
    padding: "0 15px",
    position: "relative"

  },
  ClientContainerInner: {
    display: "grid",
    gridTemplateColumns: "repeat(8, 2fr)",
    padding: "0 15px",
    position: "relative"
    
  },
  ContainerInner1: {
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    padding: "0 15px",
    position: "relative"

  },
  HeadingInner: {
    display: "grid",
    gridTemplateColumns: "repeat(7, 2fr)",
    padding: "0 15px",
    position: "relative"
  },
  ClientHeadingInner: {
    display: "grid",
    gridTemplateColumns: "repeat(8, 2fr)",
    padding: "0 15px",
    position: "relative"
  },
  ListItmes: {
    color: "#31394D",
    fontFamily: "Open Sans",
    fontSize: theme.fonts.sizes.fontM,
    fontWeight: 600,

  },
  ListUsername: {
    color: "#3971FF",
    fontFamily: "Open Sans",
    fontSize: theme.fonts.sizes.fontM,
    fontWeight: 600,
    cursor:"pointer",

  },

  Heading: {
    color: "#31394D",
    fontFamily: "Open Sans",
    fontSize: theme.fonts.sizes.fontM,
    fontWeight: 400,
  },
  Heading1: {
    color: "#31394D",
    fontFamily: "Open Sans",
    fontSize: theme.fonts.sizes.fontM,
    fontWeight: 400,
    position: "absolute",
    left: 0,
  },
  topDiv:{
    height: '750px',
    width: '450px',
    flexDirection:'row',
    display:'flex',
    justifyContent:'center',
    background:'none',
    alignItems:'center',
    boxShadow:'none'
}
,
upperDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '650px',
    width: '400px',
    alignItems: 'center',
    background:'white'

},

firstDiv: {
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'space-around',
    width: '80%',
},

selectCont: {
    width: '100%',
    height: '38px',
    background: 'rgb(244, 244, 244)',
    color: 'gray',
    borderRadius: '5px',
    // paddingLeft: '5px',
    border: '1px solid lightgray',
    fontFamily: theme.fonts.family.Light,
    fontSize: '16px',
    paddingLeft: '10px'
},

bottomCont: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '80%'
},

bottomContLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '45%'
},

bottomContRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '45%'
},

inputCont: {
    width: '100%',
    height: '38px',
    background: 'rgb(244, 244, 244)',
    color: 'gray',
    borderRadius: '5px',
    border: '1px solid lightgray',
    fontFamily: theme.fonts.family.Light,
    fontSize: '16px',
    paddingLeft: '10px'
},

button: {
    background: "#3971FF",
    margin: "0px 20px",
    "&.MuiButton-containedSecondary": {
        width: '80%',
        height: 38,
        display: "flex",
        alingItems: "center",
        borderRadius: "5px !important",
        "&:hover": {
            background: "#3971FF",
        },
        "& .MuiButton-label": {
            fontFamily: theme.fonts.family.SemiBold,
            fontSize: 15,
            fontWeight: 400,
            textTransform: "capitalize",
            marginTop: 3,
        },
        "& .MuiSvgIcon-root": {
            marginTop: -2
        }
    }
},

relativeText: {
    position: 'absolute',
    top: '72.7%',
    left: '20%',
    fontSize: '18px'
},
relativeText2: {
    position: 'absolute',
    top: '72.7%',
    right: '43%',
    fontSize: '18px'
},

LogoWrapper: {
    backgroundColor: "white",
    borderRadius: 50,
    padding: 10,
    border: "1px solid #FFFFFF",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.203851)",
    position: "absolute",
    top: 17,
},
Logo: {
    width: 34,
    height: 32,
},
}));

export const dialogStyles=makeStyles((theme)=>({
  paper: {
  //  maxWidth: "1200px",
   // width: "100%",
  //  maxHeight: "800px",
  maxWidth: "1200px",
  // width: "100%",
   // position: "relative",
    //overflowY: "initial",
  },
  dialogRoot:{
    '& .MuiBackdrop-root':{
      backgroundColor: 'rgba(0,0,0,0.5)',

    },
    '& .MuiPaper-elevation24':{
      // boxShadow:"none",
      boxShadow:"0px 11px 15px -7px rgba(0,0,0,0.2)"
    }
  },
  paper1: {
    overflowY: "initial",
    position: "relative",
  },
  muiButton:{
    "& .MuiButtonBase-root:hover": {
      backgroundColor: "#3971FF"
    }
  },
  buttonCredit:{
    "& .MuiButtonBase-root:hover": {
      boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
      backgroundColor: "#d5d5d5",
    }
  },
  creditBtn: {
    position: 'absolute',
    right: 0,
    fontFamily: "Open Sans",
    fontSize: "16px",
    borderRadius: "5px",
    backgroundColor: "#F9F9F9",
    top:"-58px",
    marginRight:90,
    padding: "2px 10px",
    // " &.makeStyles-muiButton-113": {
    //   "&.MuiButtonBase - root:hover":{
    //     backgroundColor: "none",
    //   }
    // }
  },
  buttonEdit: {
    background: "#3971FF",
    color: "white",
    position:"absolute",
    right:0,
    fontSize:"16px",
    fontFamily: "Open Sans",
    top:"-48px",
    marginRight:10,
    padding: "2px 0px",
    textTransform:"unset",
    "&.MuiButton-containedSecondary": {
      // width: "80%",
      height: 25,
      display: "flex",
      alingItems: "center",
      borderRadius: "5px !important",
      marginTop: "5px",
      "&:hover": {
        background: "#3971FF",
        backgroundColor: "#3971FF"
      },
      "& .MuiButton-label": {
        fontFamily: "Open Sans",
        fontSize: 16,
        fontWeight: 700,

        marginTop: 3
      },
      "& .MuiSvgIcon-root": {
        marginTop: -2
      },
      "& .MuiButton-root":{
        padding: "0px"
      }
    }
  },
  root:{
    overflowY:"auto",
    // maxHeight:"678px",
    marginTop: "20px"
  },
  topDiv: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    background: "none",
    alignItems: "center",
    boxShadow: "none"
  },
  modalTopDiv: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    background: "none",
    alignItems: "center",
    boxShadow: "none",
    overflowY: "overlay"
  },
  formHead:{
    padding:0,
    marginTop:1
  },
  formHead1:{
    padding:0,
    margin:-16,
    display:"flex"
  },
  deleteBtn:{
    color:"#C2143E",
    background:"none",
    border:"none",
    display:"flex",
    alignItems:"center",
    "&:hover": {
      cursor: "pointer"
    }
  },
  intialDeleteBtn:{
    color:"#C2143E",
    background:"none",
    border:"none",
    marginBottom:28,
    display:"flex",
    alignItems:"center",
    "&:hover": {
      cursor: "pointer"
    }
  },
  deleteTxt:{
    display:"inline-block",
    fontFamily:"Open Sans",
    fontSize:"14px",
    fontWeight:"600",
  },
  headerOnForm: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 15
  },

  headerSpan:{
    display:"flex",
    marginLeft:"20px",
    padding:"2px",
    background:"#E5E5E5",
    width:"190px",
    height:"39px",
    borderRadius:"8px",
    justifyContent:"center"
  },

  installationBtn:{
    marginTop:"1px",
    border:"none",
    width:"91px",
    height:"33px",
    fontWeight: 600,
    fontFamily:"Open Sans",
    fontSize: "16px",
    background:"#E5E5E5",
    color:"#3D5A80"
  },
  installationDeactiveBtn:{
    marginTop:"1px",
    border:"none",
    width:"91px",
    height:"33px",
    fontWeight: 600,
    fontFamily:"Open Sans",
    fontSize: "16px",
    background:"#E5E5E5",
    color:"#C2143E"
  },
  btnActive:{
    borderRadius:"8px",
    background: "#5ECB9E",
  },



  HorizontalLine: {
    width: "100%",
    border: "1px solid #DADADA",
    backgroundColor: "#F0F0F0",
    marginBottom: 25
  },

  majorDiv: {
    display: "flex",
    flexDirection: "column",
    margin: "1% 2% 1% 2%"
  },
  flexer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },

  each: {
    width: "31%",
    minWidth: "200px",
    marginBottom: "20px"
  },
  attachments: {
    display: "flex",
    width: "100%",
    minWidth: "200px",
    marginBottom: "20px",

  },
  selectCont: {
    width: "95%",
    height: "44px",
    background: "rgb(244, 244, 244)",
    color: "#071A32",
    borderRadius: "5px",
    border: "1px solid lightgray",
    // fontFamily: "Open Sans",
    fontSize: "16px",
    paddingLeft: "10px",
    // marginTop: "5px",
    "&:focus":{
      outline:"none !important"
    }
  },
  selectCont2: {
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    width: "40%",
    height: "40px",
    background: "#81A4FF",
    color:"#fff",
    borderRadius: "8px",
    border: "1px solid lightgray",
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight:600,
    cursor: "pointer",
    textDecoration:"none",
    marginTop:"5px",
    marginLeft:5
  },
}))



export const addUserStyles = makeStyles(theme => ({  
 spanErr:{
   color:"#FF0000"
 },
  MainHeading: {
    color: "#363E51",
    fontFamily: theme.fonts.family.Medium,
    fontSize: theme.fonts.sizes.fontXL,
    fontWeight: 500,
    textTransform: "uppercase"
  },
  Wrapper: {
    display: "flex",
    alignItems: "center"
  },

  topDiv: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    background: "none",
    alignItems: "center",
    boxShadow: "none"
  },
  modalTopDiv: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    background: "none",
    alignItems: "center",
    boxShadow: "none",
    overflowY: "overlay"
  },

  selectCont: {
    width: "100%",
    height: "44px",
    background: "#F9F9F9",
    color:"#071A32",
    borderRadius: "5px",
    border: "1px solid lightgray",
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight:400,
    paddingLeft: "10px",
    marginTop: "0px",
    outline: "none",
    
   
  },
  selectContDate: {
    width: "82%",
    height: "44px",
    background: "#F9F9F9",
    color:"#071A32",
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
    // borderRadius: "5px",
    border: "1px solid lightgray",
    borderRight: "none",
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight:400,
    paddingLeft: "10px",
    // marginTop: "5px",
    outline: "none"
  },
  selectContDateType: {
    width: "18%",
    height: "44px",
    background: "#F9F9F9",
    color:"#071A32",
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
    border: "1px solid lightgray",
    borderLeft: "none",
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight:400,
    paddingLeft: "10px",
    // marginTop: "5px",
    outline: "none",
    "&::-webkit-calendar-picker-indicator":{
      // backgroundColor: "rgba(5, 155, 111, .5)",
      opacity: 1,
      display: "block",
      background: `url(${calender}) center/100% no-repeat`,
      width: "5%",
      height: "100%",
      borderWidth: "thin",
      position:"absolute",
      right:10,
      // paddingRight: "20px",
      // marginRight: "5px"
      
  },
  "&::-webkit-datetime-edit-text" :{
    "-webkit-appearance": "none",
    display: "none",
  },
  "&::-webkit-datetime-edit-month-field" :{
      "-webkit-appearance": "none",
      display: "none"
  },
  "&::-webkit-datetime-edit-day-field" :{
      "-webkit-appearance": "none",
      display: "none"
  },
  "&::-webkit-datetime-edit-year-field" :{
      "-webkit-appearance": "none",
      display: "none"
  }
  },
  selectContPrice: {
    width: "100%",
    height: "44px",
    background: "#F9F9F9",
    color:"#071A32",
    borderRadius: "5px",
    border: "none",
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight:400,
    // paddingLeft: "5px",
    // marginTop: "3px",
    outline: "none",

  },
  selectCont1: {
    width: "100%",
    height: "44px",
    background: "#3971FF",
    color:"white",
    cursor: "pointer",
    borderRadius: "5px",
    border: "1px solid lightgray",
    fontFamily: "Open Sans",
    fontSize: "16px",
    paddingLeft: "10px",
    // marginTop: "5px",
    animation:"scrollDown"
  },
  disableSelectCont1:{
    width: "100%",
    height: "44px",
    background: "#3971FF",
    color:"not-allowed",
    cursor: "pointer",
    borderRadius: "5px",
    border: "1px solid lightgray",
    fontFamily: "Open Sans",
    fontSize: "16px",
    paddingLeft: "10px",
    // marginTop: "5px",
    animation:"scrollDown"
  },

  bottomCont: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "80%"
  },

  bottomContLeft: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "45%"
  },

  bottomContRight: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "45%"
  },

  inputCont: {
    width: "100%",
    height: "38px",
    background: "rgb(244, 244, 244)",
    color: "gray",
    borderRadius: "5px",
    border: "1px solid lightgray",
    fontFamily: theme.fonts.family.Light,
    fontSize: "16px",
    paddingLeft: "25px"
  },

  button: {
    background: "#3971FF",
    margin: "0px 20px",
    "&.MuiButton-containedSecondary": {
      width: "80%",
      height: 38,
      display: "flex",
      alingItems: "center",
      borderRadius: "5px !important",
      "&:hover": {
        background: "#3971FF"
      },
      "& .MuiButton-label": {
        fontFamily: "Open Sans",
        fontSize: 16,
        fontWeight: 600,
        textTransform: "capitalize",
        // marginTop: 3
      },
      "& .MuiSvgIcon-root": {
        marginTop: -2
      }
    }
  },

  multipleSelect: {
    height: "unset"
  },

 

  majorDiv: {
    display: "flex",
    flexDirection: "column",
    margin: "1% 2% 1% 2%"
  },
  flexer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },

  each: {
    width: "31%",
    minWidth: "200px",
    marginBottom: "25px",
    fontWeight: "600",
  },
  eachHide:{
    width: "31%",
    minWidth: "200px",
    marginBottom: "25px",
    fontWeight: "600",
    display:"none",
  },
  eachDate: {
    width: "31%",
    minWidth: "200px",
    marginBottom: "25px",
    fontWeight: "600",
    position:"relative",
    "&.MuiFormHelperText-root.Mui-error":{
      // marginTop:"10px",
      fontStyle:"bold",
      fontFamily:"Open Sans",
      fontWeight:"400px",
     
    },
    "& .MuiInputBase-root": {
      padding: 0,
      "& .MuiButtonBase-root": {
        padding: 0,
        paddingLeft: 10,
      },
      "& .MuiInputBase-input": {
        padding: 13,
        paddingLeft: 0,
        fontFamily:"Open Sans",
        color:"#071A32",
        fontSize: "16px",
      },
      "&.MuiInput-underline:before":{
        borderBottom:"none",
      },
      "&.MuiInput-underline:after":{
        borderBottom:"none",
      },
      "&.MuiInputLabel-shrink":{
          transform: "none",
        
      },
    },
    
   
  },
  eachDeactive:{
    minWidth: "275px",
    marginBottom: "25px"
  },
  paper: {
    maxWidth: "1200px",
    width: "100%",
    // maxHeight: "775px",
    //height: "90%",
    // position: "relative",
    // overflowY: "auto"
  },
  paper1: {
    maxWidth: "400px",
    width: "100%",
   // position: "relative",
    //overflowY: "auto"
  },
  root:{
    overflowY:"auto",
    // maxHeight:"663px",
    marginTop: "20px"
  },
  each1: {
    width: "50%",
    minWidth: "300px",
    marginBottom: "15px"
  },
  flexer1: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    marginTop: "30px"
  },
  selectWrapper: {
    width: "100%",

    background: "rgb(244, 244, 244)",
    borderRadius: "5px",

    "& .MuiOutlinedInput-root": {
      height: "38px"
    },
    "& .MuiInputLabel-formControl": {
      left: "10px",
      top: "10px",
      color: "gray",
      fontSize: "14px",
      fontFamily: theme.fonts.family.Light,
      // fontSize: "16px",
      transform: "translate(0, 0px) scale(1)"
    }
  },
  formControl: {
    marginTop: "5px"
  },
  selectDropDown: {
    "& .MuiSelect-iconOutlined": {
      right: "0px"
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px",
      color: "gray",
    // width: "315px",
     //background: "rgb(244, 244, 244)",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "19px",
      fontWeight: "bolder"
    },
    "& .MuiSelect-icon": {
      top: "10px"
    },
    "& .MuiOutlinedInput-notchedOutline ": {
      borderColor: "lightgray !important",
      borderWidth: "1px !important"
    }
   
    
  },
  dropdownWrapper:{
    position: "absolute",
    height: 130,
    width: 267,
   // top: 226,
    //padding: 5,
    zIndex: 100,
    overflowY:"auto" 
  },
  searchDropDown:{
    background: 'rgb(244, 244, 244)',
    cursor: "pointer",
    padding: "5px 10px",
    "&:hover":{
      background: "lightgray"
    }
  },

   
}))

