import React, {useEffect, useState} from 'react';
import NewMoreDropDown from "../NewMoreDropDown/NewMoreDropDown";
import format from 'date-fns/format'
import {useStyles} from "./CourtsDataTableStyle";
import axios from "../../Store/request"
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import useToast from "../Toast/useToast";
import {Button,Checkbox} from "@material-ui/core";
import InvoiceDetail from "./InvoiceDetail";
const CourtInvoice = ({DataArray,triedFetching, onBack, currentData: courtData}) => {
  const classes = useStyles()


  const { showToast } = useToast()
  const TextClipper = (bio, length) => {
    let vb = ""
    if (bio?.length >= length) {
      for (var i = 0; i < length; i++) {
        vb += bio.charAt(i)
      }
      bio = `${vb}...`
      
    }
    
    return bio
  }
  const [currentData, setCurrentData] = useState({})
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  
  const [creditLoading, setCreditLoading] = useState(false);
  
  const applyCredit =async() => {
    setCreditLoading(true)
    try {
      const userId=currentData.users?.map((element)=>element.id)
      const response = await axios.post(`/api/v1/courts/court_invoice/apply_credit/`, {id: currentData.id,court_users_invoice: userId})
      setOpenConfirmModal(false)
      setCreditLoading(false)
      currentData.is_paid = true
      currentData.users?.map((element)=>element.is_paid=true)
      showToast("Apply Credit Succesfully", "success")
    }
    catch(error){
      setOpenConfirmModal(false)
      setCreditLoading(false)
      // setUsers(DataArray)
      setSelected([])
      showToast("Unable to Apply Credit, please try again later", "error")
    }
  }
  const getMoreOptions = (i) => {
    const buttons = [
      {
        actionFunction:() => {
          window.open(`${i.invoice}`)
          setCurrentData(i)
        },
        icon:"Visibility",
        name:"View Statement"
      },
    ];
    if(!i?.is_paid) {
      return [
        ...buttons,
        {
          actionFunction:async() => {
            setCurrentData(i)
            setOpenConfirmModal(true)
          },
          icon:"Apply Credit",
          name:"Apply Credit"
        },
      ]
    }
    return buttons;
  };
  
  const getDate = (created_at) => {
    try {
      return format(new Date(created_at), "yyyy-MM-dd")
    }catch (e) {
      return ""
    }
  }
  const [users, setUsers] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isALLPaid,setIsAllPaid] = useState(false)
  
  useEffect(() => {
    if(DataArray?.length){
      setUsers(DataArray);
      setIsAllPaid(!DataArray.some((item)=>item.is_paid===false))
    }
  }, [DataArray]);
  const handleChange = (e,i=[]) => {
    const { name, checked } = e.target;
    if (name === "allSelect") {
      if(checked) {
        const allId = users.map(user => user.id);
        setSelected(allId);
      }else {
        setSelected([]);
      }
    } else {
      if(checked) {
        setSelected([...selected, i.id]);
      }else {
        const prevData = [...selected];
        const index = prevData.findIndex(d => d === i.id);
        if (index > -1) {
          prevData.splice(index, 1);
          setSelected([...prevData]);
        }
      }
      
    }
  };
  
  const onSubmitHandle=()=>{
    setOpenConfirmModal(true)
  }
  const [invoiceDetail,setInvoiceDetail]=useState()
  const [isOpenInvoice,setIsOpenInvoice]=useState(false)
  const [courtDetail,setCourtDetail]=useState()
  const handleInvoiceDetail=async(i)=>{
    const response=await axios.get(`/api/v1/courts/court_invoice/${i.id}/`)
    setInvoiceDetail(response?.data?.users)
    setIsOpenInvoice(true)
    setCurrentData(i)
    setCourtDetail(response?.data?.court)
  }
  const onInvoiceBack = () => {
    setIsOpenInvoice(false);
    setInvoiceDetail([])
  }
  return (
    <>
      {
        !isOpenInvoice &&
        <Button
          variant="contained"
          className={classes.button}
          onClick={onBack}
          style={{ margin: 0}}
        >
          Back
        </Button>
      }
      {
        isOpenInvoice ?
          <InvoiceDetail
            DataArray={invoiceDetail}
            triedFetching={triedFetching}
            onBack={onInvoiceBack}
            currentData={currentData}
            courtDetail={courtDetail}
            isOpenInvoice={isOpenInvoice}
          />
          :
          !!DataArray?.length ? <>
            <div className={classes.TableHeading}>
              <div className={classes.HeadingInner}>
                
                <p className={classes.Heading}>Court name</p>
                <p className={classes.Heading}>Created Date</p>
                <p className={classes.Heading}>Is Paid?</p>
                <p className={classes.Heading}>Total Amount</p>
                <p className={classes.Heading}> </p>
                <p className={classes.Heading}> </p>
                <p className={classes.Heading}> </p>
                <p> </p>
              </div>
            </div>
            {users.map(i => {
              return (
                <div className={classes.MainContainer} key={i?.id}>
                  <div className={classes.ContainerInner}>
                    
                    <p className={classes.ListItmes} onClick={()=>handleInvoiceDetail(i)} style={{cursor:"pointer",color:"#3971FF"}}>{i?.court?.name}</p>
                    <p className={classes.ListItmes}>
                      {getDate(i?.created_on)}
                    </p>
                    <p className={classes.ListItmes}>
                      {i?.is_paid ? "Yes" : "No"}
                    </p>
                    <p className={classes.ListItmes}>
                      {i?.total_price}
                    </p>
                    <NewMoreDropDown
                      MoreDropDownItem={getMoreOptions(i)}
                    />
                  </div>
                </div>
              )
            })}
          </> : <p className={classes.error} style={{visibility:!triedFetching?"hidden":"visible"}}>No Data Found</p>
      }
      <ConfirmModal
        signal={openConfirmModal}
        handleyes={applyCredit}
        handleno={() => {
          setOpenConfirmModal(false)
          setUsers(DataArray);
        } }
        msg={`Are you sure you want to apply credit "${currentData?.total_credit}"?`}
        loading={creditLoading}
      />
    </>
  );
};

export default CourtInvoice;
