import React, { useEffect, useState } from "react"
import { useStyles } from "./PaymentsStyle"
import Container from "@material-ui/core/Container"
import NewPagination from "../../Components/NewPagination/NewPagination"
import PaymentsHeader from "../../Components/PaymentsHeader/PaymentsHeader"
import PaymentsDataTable from "../../Components/PaymentsDataTable/PaymentsDataTable"
import PaymentInformation from "./PaymentInformation"
import Invoice from "./Invoice"
import Credit_Btn from "./Credit_btn"
import axios from "../../Store/request"
import useAuth from "../../utils/useAuth";
const Payments = () => {
  const classes = useStyles()
  const [count,setCount] = useState(0)
  const [state, setState] = React.useState(0)
  const [paymentData, setPaymentData] = useState([])
  const [rechargeData, setRechargeData] = useState([])
  const Balance = number => {
    setState(number)
  }
  const {isIndividualUser, isAdmin } = useAuth()
  let userId = localStorage.getItem('userId');

  let [total_balance,setTotalBalance] = useState(0);

  const savePayments = async () => {
    try {
      if ( isAdmin && userId ) {
        const response = await axios.get("manage-users/saved_payment_method/?user="+userId)
        setPaymentData(response.data)
      } else {
        const response = await axios.get("manage-users/saved_payment_method/")
        setPaymentData(response.data)
      }
    } catch (e) {
    }
  }

  const getRechargeData = async () => {
    try {
      if ( isAdmin && userId ) {
        const response = await axios.get("manage-users/user_payments/?user="+userId)
        setRechargeData(response.data.results)
        setCount(response.data.count)
      } else {
        const user_data_response = await axios.get("manage-users/user_profile/")
        const _data = user_data_response.data;
        if (_data.pay_plan_procedure === 3){
          setTotalBalance(_data.user_amount)
        }
        else if (_data.pay_plan_procedure === 1){
          setTotalBalance(_data.balance)
        }
        const response = await axios.get("manage-users/user_payments/")
        setRechargeData(response.data.results)
        setCount(response.data.count)
      }
    } catch (e) {
    }
  }
  useEffect(() => {
    reloadData()
  }, [])

  const reloadData = () => {
    savePayments()
    getRechargeData()
  }
  const [userManage,setUserManage]=useState(false);
  const handleUser=(val)=>{

      setUserManage(val);
  }
  return (
    <div className={classes.MainContainer}>
      <PaymentsHeader
        Balance={Balance}
        state={state}
        paymentData={paymentData}
        rechargeData={rechargeData}
        reloadData={reloadData}
        isIndividualUser={isIndividualUser}
        userManage={userManage}
        handleUser={handleUser}
        // selectUser={selectedOption}
      />
      <Container maxWidth="lg" className={classes.containerMob}>
        <PaymentInformation
          Balance={Balance}
          state={state}
          reloadData={reloadData}
          paymentData={paymentData}
          rechargeData={rechargeData}
          userId = {userId}
          isIndividualUser={isIndividualUser}
          handleUser={handleUser}
          count={count}
          total_balance={total_balance}
        />


      </Container>
    </div>
  )
}

export default Payments
