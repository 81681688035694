import React, {useState} from 'react';
import CloseIcon from "../../assets/CloseIcon.png";
import {useStyles} from "./ReportViewsStyle";
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import moment from 'moment'

const ClientRemovalView = ({onClose,today,futureDate,DataArray}) => {
  const classes = useStyles()
  const TextClipper = (bio, length) => {
    let vb = ""

    if (bio?.length >= length) {
      for (var i = 0; i < length; i++) {
        vb += bio.charAt(i)
      }
      bio = `${vb}...`
    }

    return bio
  }

  const convertDateFormat = (date)=>{
    return moment(date).format('MM-DD-YYYY')
  }
  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "CLIENT INSTALL/REMOVAL REPORT";
    const headers = [["Court Name","Client Name","Service Name","Install Date","Removal Date"]];
    const data = DataArray.data.users_services.map(elt=> [elt.court.name, `${elt.user.first_name} ${elt.user.last_name}`,elt.services.name,convertDateFormat(elt.install_date),elt.end_date?convertDateFormat(elt.end_date):""]);

    const marginLeft = 40;
    let marginTop = 40;

    doc.text(title, marginLeft, marginTop);
    marginTop+=20
    doc.text(`To date: ${convertDateFormat(DataArray.data.to_date)}`,marginLeft,marginTop)
    marginTop+=20
    doc.text(`From date: ${convertDateFormat(DataArray.data.from_date)}`,marginLeft,marginTop)
    marginTop+=20
    doc.text(`REPORT TYPE : ${DataArray.data.report_type===1?"INSTALL":"REMOVAL"}`,marginLeft,marginTop)

    let content = {
      startY: marginTop+20,
      head: headers,
      body: data,
      theme:"plain"
    };
    doc.autoTable(content);
    doc.save("client_removal_report.pdf")
  }

  return (
    <>
      <button onClick={exportPDF} style={{border:"1px solid black",height:"20px",width:"80px",backgroundColor:"#D8D8D8",color:"black",marginLeft:"20px",color:"blue",cursor:"pointer"}}>Export Pdf</button>
      <div className={classes.aligner}>
        <div className={classes.closeIconDiv}>
          <img src={CloseIcon} onClick={onClose} style={{ cursor: 'pointer',paddingRight:"10px",marginTop:"-10px"}} height="15px"/>
        </div>
        <div className={classes.firstContLayer}  style={{padding:"0px 25px"}}>
          <p>CLIENT INSTALL/REMOVAL REPORT</p>
          <p>{`FROM Date : ${convertDateFormat(DataArray.data.from_date)}`}</p>
          <p>{`TO Date : ${convertDateFormat(DataArray.data.to_date)}`}</p>
        </div>
        <div className={classes.firstContLayer}  style={{padding:"0px 25px"}}>
          <p>{`REPORT TYPE : ${DataArray.data.report_type===1?"INSTALL":"REMOVAL"}`}</p>
        </div>
        <div className={classes.tableDiv}>
          {DataArray.data.users_services.length > 0 ?<table className="table table-striped" style={{ width: '100%', height: 'auto', borderCollapse:"collapse" }}>
            <thead className={classes.stickyHeader}>
            <tr>

              <th scope="col" className={classes.firstColumn}>Court Name</th>
              <th scope="col" className={classes.clientRemovalColumn}>Client Name</th>
              <th scope="col" className={classes.clientRemovalColumn}>Service Name</th>
              <th scope="col" className={classes.clientRemovalColumn}>Install Date</th>
              <th scope="col" className={classes.clientRemovalLastColumn}>Removal Date</th>

            </tr>
            </thead>
            <tbody style={{ width: '100%',fontFamily:"Open Sans",fontWeight:600 }}>
            {
              DataArray.data.users_services.map((element,index)=>{
                return  <tr className={index%2===0?classes.styleRowWithBackground:classes.styleRow} key={index}>
                <td className={classes.firstColumn}>{TextClipper(element.court.name,15)}</td>
                <td>{TextClipper(element.user.first_name,15)} {TextClipper(element.user.last_name,15)}</td>
                <td>{TextClipper(element.services.name,15)}</td>
                <td>{TextClipper(convertDateFormat(element.install_date),15)}</td>
                <td style={{paddingRight:"25px"}}>{element.end_date?convertDateFormat(element.end_date):""}</td>
              </tr>

              })
            }
            </tbody>
          </table>:<p className={classes.error}>No Data Found</p>}
        </div>
      </div>
    </>
  );
};

export default ClientRemovalView;