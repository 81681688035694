import React from "react"
import {
  newStyles,
} from "./UserDataTableStyle"
import {useSelector,useDispatch } from "react-redux"
import {
  GetUser, SetCurrentPage,
} from "../../Store/Actions/ManageUserAction"
import NewPagination from "../NewPagination/NewPagination";
import Container from "@material-ui/core/Container";
import NewMoreDropDown from "../NewMoreDropDown/NewMoreDropDown"
import Pagination_New from "../NewPagination/Pagination_New";

const CourtAdminTable= ({setCurrentData,setOpenCreditModal,setOpenConfirmModal,editDialogHandler,dialogHandler,filteredData,editUser,postsPerPage,count,setCurrentPage,current_page,
                          getTerritory}) => {
    const newclasses = newStyles()
    const dispatch = useDispatch()
    const {
        addUser: isAddUser,
        clientDetail,
        data: manageUserData,
        loading: userLoading
      } = useSelector(({ manageUser }) => manageUser)

    const TextClipper = (bio = "", length) => {
      let vb = ""
      
      if (bio?.length >= length) {
        for (var i = 0; i < length; i++) {
          vb += bio.charAt(i)
        }
        bio = `${vb}...`
      }

      return bio
    }

    const paymentDialogHandler = e => {
        e.preventDefault()
        setOpenCreditModal(false)
    }
    

    const arrayList = [];
    for (let i = 0; i < filteredData.length; i++) {
        if (filteredData[i].user_type===3) {
          arrayList.push(filteredData[i])
        }
    }


    return (<div className={newclasses.Container}>
      {!!manageUserData.length?<div className={newclasses.TableHeading}>
          <div className={newclasses.HeadingInner}>
            <p className={newclasses.Heading1}>Court Admin Name</p>
            <p></p>
            <p className={newclasses.Heading}>ID</p>
            <p className={newclasses.Heading}>First Name</p>
            <p className={newclasses.Heading}>Last Name</p>

            <p></p>
            <p></p>
            <p></p>

            <p></p>
          </div>
        </div>:<p className={newclasses.error}>No Data Found</p>}
        {!!manageUserData.length &&
          arrayList?.map(i => {
            return i.user_type===3 ? (

              <div className={newclasses.MainContainer} key={i.id}>
                <div className={newclasses.ContainerInner}>
                  <p className={newclasses.ListItmes}>
                    <span className={newclasses.ListUsername} onClick={()=>{dialogHandler(i)
                      getTerritory(i)
                    }}>{i.name === null || i.name === ""
                      ? "-"
                      : TextClipper(i.name, 15)}</span>
                  </p>
                  <p className={newclasses.ListItmes}>{i.id}</p>
                  <p className={newclasses.ListItmes}>
                    {i.first_name === null || i.first_name === ""
                      ? "-"
                      : TextClipper(i.first_name, 15)}
                  </p>
                  <p className={newclasses.ListItmes}>
                    {i.last_name === null || i.last_name === ""
                      ? "-"
                      : TextClipper(i.last_name, 15)}
                  </p>
                  <NewMoreDropDown 
                  MoreDropDownItem={[
                    {actionFunction:()=>{
                      setCurrentData(i)
                      if(i.id)
                      setOpenCreditModal(true)
                    },
                    icon:"Apply Credit",
                    name:"Apply Credit"
                    },
                    {actionFunction:() => {
                        getTerritory(i)
                      editDialogHandler(i)
                    },
                    icon:"Edit",
                    name:"Edit"
                    },
                    {actionFunction:() => {
                      setCurrentData(i)
                      setOpenConfirmModal(true)
                    },
                    icon:"Delete",
                    name:"Delete"
                    }
                  ]}/>

                </div>

              </div>


            ) : null
          })}
      {
        count>10 &&
        <Container
          maxWidth="lg" style={{visibility:userLoading===true || (isAddUser===true || editUser===true || clientDetail===true)?"hidden":"visible"}}>
          <Pagination_New  count={count}
                           postsPerPage={postsPerPage}
                           activePage={current_page}
                           paginate={(pageNumber)=>{
                             dispatch(SetCurrentPage(pageNumber))
                             dispatch(GetUser({ limit: postsPerPage, offset: (postsPerPage * pageNumber - postsPerPage),user_type:1}))
                           }}
          />
        </Container>
      }

      </div>);
}

export default CourtAdminTable
