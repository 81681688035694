import { makeStyles } from "@material-ui/core/styles";


export const useStyles = makeStyles((theme) => ({
  MainContainer: {
    position: "absolute",
    // right: 0,
    top: 11,
    right: 10,
  },
  Inner: {
    padding: "0px 10px",
    "&:focus": {
      outline: "none"
    }
  },
  Wrapper: {
    display: "flex",
    justifyContent: "flex-end"
  },
  CloseIcon: {
    cursor: "pointer",
    fontSize: 20,
    '&:focus': {
      outline: "none"
    }
  },
  EditIcon: {
    marginRight: 10,
  },
  CreditCardIcon: {
    marginRight: 10,
  },
  SeeIcon: {
    marginRight: 10,

  },
  DeleteIcon: {
    marginRight: 10,
    color: "#C2143E"
  },
  VerticalIcon: {
    color: "#004267"
  },
  MenuList: {
    color: "#31394D",
    fontFamily: theme.fonts.family.Medium,
    fontSize: theme.fonts.sizes.fontM,
  },
  MenuListDel: {
    color: "#C2143E",
    fontFamily: theme.fonts.family.Medium,
    fontSize: theme.fonts.sizes.fontM,
  }

}));

