import React, {useEffect, useState} from 'react'
import { useStyles } from './NewPaginationStyle'
import Button from '@material-ui/core/Button';

const NewPagination = ({ postsPerPage, totalPosts, paginate,activePage=1}) => {

    const classes = useStyles()

    const pageNumbers = [];

    const [currentPage, setCurrentPage] = useState(1)


    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
    }

    useEffect(()=>{
         setCurrentPage(activePage)
    },[activePage])

    const backwardChange = () => {
        setCurrentPage(currentPage-1);
        paginate(currentPage-1)
    }

    const forwardChange = () => {
        setCurrentPage(currentPage+1);
        paginate(currentPage+1)
    }

    let selectedBtn=[classes.pageBtn,classes.selectedBtn].join(" ")

    return (
        <nav className={classes.mainPag}>

            <div className={classes.pagination}>
                <Button color="secondary" disabled={currentPage === 1}  onClick={() => currentPage > 0 ? backwardChange() : null }
                style={{visibility:currentPage === 1 && "hidden"}}
                className={classes.Previous}>Previous entries</Button>

                {pageNumbers.map(number => {
                    return(
                    <p key={number} className={classes.pageItem}>
                        <button onClick={() => { paginate(number); setCurrentPage(number); }}
                        className={number===currentPage?selectedBtn:classes.pageBtn}>
                            {number}
                        </button>
                    </p>
                )})}
                <Button color="secondary" className={classes.Previous} style={{ marginLeft: '20px' }}   disabled={currentPage > pageNumbers.length - 1} onClick={() => forwardChange()}
                style={{visibility:currentPage > pageNumbers.length - 1 && "hidden"}}
                >Next entries</Button>

            </div>
        </nav>
    );
};


export default NewPagination
