import React, { useState, useEffect, useContext } from "react"
import ServicesIcon from "../../assets/ServicesIcon.png"
import AddButton from "../Buttons/AddButton"
import { useStyles } from "./ServicesHeaderStyle"
import { connect, useDispatch } from "react-redux"
import { auth } from "../../Store/Actions/Auth"
import { barStyles } from "../SearchBar/SearchBarStyle"
import SearchIcon from "@material-ui/icons/Search"
import InputBase from "@material-ui/core/InputBase"
import { ScreenContext } from "./../../context/context"
import { finalStyles } from "./../CourtsHeader/CourtsHeaderStyle"

import AddServicesDialog from "../AddServicesDialog/AddServicesDialog"
import AddCourtDialog from "../AddCourtDialog/AddCourtDialog";
import {GetAllCourtPricing} from "../../Store/Actions/CourtPricing";
import Upload_Csv_File from "../UploadCsvModal/Upload_CSV_File";
import UploadCsvBtn from "../Buttons/UploadCsvBtn";
import {GetAllServices} from "../../Store/Actions/Services";

const ServicesHeader = ({searchText,isDisabled,setIsDisabled,data})=> {
  const classes = useStyles()

  const barclasses = barStyles() 
  const [openAddModal, setOpenAddModal] = useState(false)

  const finalclasses = finalStyles()
  const { screenSize, setScreenSize } = useContext(ScreenContext)

  const [openCSV, setOpenCSV] = useState(false)
  useEffect(() => {
    setScreenSize(window.innerWidth)
  }, [screenSize])

  const [search,setSearch]=useState(false);
  const searchBtn =(e)=>{
    setSearch(!search);
  }
  return (
    <div
      className={
        screenSize > 800 ? classes.MainContainer : finalclasses.MainCont
      }
    >
      {screenSize > 800 ? (
        <div className={classes.HeaderInner}>
          <div className={classes.Wrapper}>
            <div className={classes.IconWrapper}>
              <img src={ServicesIcon} className={classes.icon} />
            </div>
            <p className={classes.MainHeading}>Services management</p>
            <div onClick={() => setOpenAddModal(true)}>
              <AddButton lable="+ Add service" />
            </div>
            <div
              onClick={() => {
                setOpenCSV(true)
              }}
            >

              {/*UPLOAD CSV BUTTON CODE*/}
              <UploadCsvBtn />
            </div>
          </div>
          <div className={barclasses.SearchContainer}>
            {search===false?
              <div className={barclasses.search1}>
                <SearchIcon  onClick={searchBtn}  style={{ backgroundColor:"none"}}/>
              </div>:
              <div className={barclasses.search}>

                <div className={barclasses.searchIcon} >
                  <SearchIcon />

                </div>
                <InputBase
                  placeholder="Search…"
                  classes={{
                    root: barclasses.inputRoot,
                    input: barclasses.inputInput
                  }}
                  inputProps={{ "aria-label": "search" }}
                  onChange={searchText}

                  autoFocus
                />

              </div>
            }

          </div>
        </div>
      ) : (
        <div className={finalclasses.topMain}>
          <div className={finalclasses.upperMain}>
            <div className={finalclasses.flex}>
              <div className={classes.IconWrapper} style={{ width: "57px" }}>
                <img src={ServicesIcon} className={classes.icon} alt="" />
              </div>
              <p className={classes.MainHeading} style={{ fontSize: "12px" }}>
                Services Management
              </p>
            </div>
            <div className={barclasses.SearchContainer}>
              <div className={barclasses.search}>
                <div className={barclasses.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search…"
                  classes={{
                    root: barclasses.inputRoot,
                    input: barclasses.inputInput
                  }}
                  inputProps={{ "aria-label": "search" }}
                  onChange={searchText}
                />
              </div>
            </div>
          </div>

          <div className={finalclasses.bottomCont}>
            <div onClick={() => setOpenAddModal(true)}>
              <AddButton lable="+ Add service" />
              <div
                onClick={() => {
                  setOpenCSV(true)
                }}
              >

                {/*UPLOAD CSV BUTTON CODE*/}
                <UploadCsvBtn />
              </div>
            </div>
          </div>
        </div>
      )}
       <AddServicesDialog  isDisabled={isDisabled}
                           setIsDisabled={setIsDisabled}
                           serviceData={data}
                           openModal={openAddModal} closeModal={() => {
                           setOpenAddModal(false)
                            setIsDisabled(false)

       }} />

      <Upload_Csv_File
        openCSV={openCSV}
        setOpenCSV={setOpenCSV}
        fileName="Services"
        formateType=" name, description, one_time_fee "
        apiUrl={`/api/v1/services/service_from_csv/`}
        actionName={GetAllServices}

      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    accessToken: state.auth.accessToken
  }
}

export default connect(mapStateToProps, { auth })(ServicesHeader)
