import React, { useState } from "react"
import { useStyles } from "./UserStyle"
import Container from "@material-ui/core/Container"
import UserHeader from "../../Components/UserHeader/UserHeader"
import UserTable from "../../Components/UserTable/UserTable"
import NewPagination from "../../Components/NewPagination/NewPagination"
import SliderIcon from "./../../assets/Slider.png"
import SendFileIcon from "./../../assets/Send File.svg"
import DataFilter from "../../Components/DataFilter/DataFilter"
import {FormControl,Select,MenuItem} from "@material-ui/core";

const User = () => {

  const classes = useStyles()
  const [isFilterCont, setIsFilterCont] = useState(false)



  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 9;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150,
        color:"#3971FF"
      },
    }
  };

  const names = ["Export as .CSV", "Export as .PDF"];


   const [personName, setPersonName] = React.useState([]);

   const handleChange = (event) => {
     setPersonName(event.target.value);
   };



    return (
    <div className={classes.MainContainer}>
      <UserHeader />
      <Container maxWidth="lg" className={classes.containerMob}>
        {!isFilterCont ? (

          <div className={classes.BtnContainer}>

            <div className={classes.courtTitle}>Court Paid User Name</div>
            <div
              className={classes.filterdiv}
              onClick={() => setIsFilterCont(true)}
            >
              <p>Filters</p>
              <img src={SliderIcon} style={{ height: "16px", width: "16px" }} />

            </div>



            <div  className={classes.exportdiv}>
              <FormControl className={classes.formcontrol} sx={{ m: 1, width: 300, mt: 2 }}>
                <Select
                  multiple
                  displayEmpty
                  value={personName}
                  onChange={handleChange}
                  // input={<OutlinedInput />}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <p>Exports &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img src={SendFileIcon} style={{ height: "16px", width: "16px" }} />
                      </p>;

                    }
                    return selected.join(", ");
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                  style={{appearance:"none"}}

                >
                  {names.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>


       ) : (
          <DataFilter close={() => setIsFilterCont(false)} />
        )}
        {!isFilterCont && (
          <>
            <UserTable />
            <NewPagination />
          </>
        )}
      </Container>
    </div>

  )
}

export default User
