export const DataArray = [
    {
        PlanName:'Prepaid payment plan',
        PaymentType:'Daily',
        PaymentDuration:'14 days',
        OneTimePayment:'$10',
        TotalPayment:'$140',
        id: Math.floor(Math.random() * 100),
        uid:1
    },
    {
        PlanName:'Future payment plan',
        PaymentType:'Monthly',
        PaymentDuration:'2 months',
        OneTimePayment:'$1200',
        TotalPayment:'$2400',
        id: Math.floor(Math.random() * 100),
        uid:2
    },
    {
        PlanName:'Mixed payment plan',
        PaymentType:'30% court, 70% user',
        PaymentDuration:'8 months',
        OneTimePayment:'$3, $7',
        TotalPayment:'$24, $56',
        id: Math.floor(Math.random() * 100),
        uid:3
    },
   
  ]