import React,{useState,useEffect} from "react"
import {
	useStyles,
	newStyles,
} from "./UserDataTableStyle"
import NewMoreDropDown from "../NewMoreDropDown/NewMoreDropDown"
import {useDispatch, useSelector} from "react-redux"


import {
	GetUser, SetCurrentPage,
} from "../../Store/Actions/ManageUserAction"
import Container from "@material-ui/core/Container";
import NewPagination from "../NewPagination/NewPagination";
import axios from "../../Store/request";
import Pagination_New from "../NewPagination/Pagination_New";


const AdminTable = ({
	                    setOpenCreditModal, setOpenConfirmModal, currentData, setCurrentData, editDialogHandler, dialogHandler, filteredData, editUser, postsPerPage, count, setCurrentPage, current_page
	                    , territoryData, setTerritoryData, getTerritory, filterTxt, searchCount
                    }) => {
	const classes = useStyles()
	const newclasses = newStyles()
	const dispatch = useDispatch()
	const [arrayList, setArrayList] = useState([])
	const {
		addUser: isAddUser,
		clientDetail,
		data: manageUserData,
		loading: userLoading
	} = useSelector(({manageUser}) => manageUser)
	const TextClipper = (bio = "", length) => {
		let vb = ""
		
		if (bio?.length >= length) {
			for (var i = 0; i < length; i++) {
				vb += bio.charAt(i)
			}
			bio = `${vb}...`
		}
		
		return bio
	}
	
	const getData = () => {
		// const data = filteredData.filter((ele) => ele.user_type === 1)
		setArrayList(filteredData)
	}
	
	useEffect(()=>{
		if(filteredData?.length){
			getData()
		}
	},[filteredData])
	return (
		<>
			{
				filterTxt === "" || searchCount > 0 ?
					<div className={classes.Container}>
						{!!manageUserData.length ? <div className={classes.TableHeading}>
							<div className={classes.HeadingInner}>
								<p className={classes.Heading1}>User Name</p>
								<p></p>
								<p className={classes.Heading}>First Name</p>
								<p className={classes.Heading}>Last Name</p>
								<p className={classes.Heading}>Admin_ID</p>
								<p className={classes.Heading}>Role</p>
								
							</div>
						</div> : <p className={newclasses.error}>No Data Found</p>}
						
						
						{!!manageUserData.length &&
						arrayList?.map(i => {
							return (
								<div className={newclasses.MainContainer} key={i.id}>
									<div className={newclasses.ContainerInner1}>
										<p className={newclasses.ListItmes}>
                    {/*<span className={newclasses.ListUsername} onClick={async () => {*/}
	                {/*    getTerritory(i)*/}
	                {/*    dialogHandler(i)*/}
                    {/*}}>{TextClipper(i?.username, 15)}</span>*/}
											<span className={newclasses.ListUsername} onClick={async () => {
	                    getTerritory(i)
	                    editDialogHandler(i)
                    }}>{TextClipper(i?.username, 15)}</span>
										</p>
										
										<p className={newclasses.ListItmes}>
											{TextClipper(i?.first_name === null ? "-" : i?.first_name, 15)}
										</p>
										<p className={newclasses.ListItmes}>
											{TextClipper(i?.last_name === null ? "-" : i?.last_name, 15)}
										</p>
										<p className={newclasses.ListItmes}>
											{TextClipper(i?.id, 15)}
										</p>
										<p className={newclasses.ListItmes}>
											{TextClipper(i?.user_type===1?"Admin":i?.user_type===3?"Court Admin":"Operator", 15)}
										</p>
										
										<NewMoreDropDown
											MoreDropDownItem={[
												{
													actionFunction: () => {
														if (i.id)
															setCurrentData(i)
														setOpenCreditModal(true)
													},
													icon: "Apply Credit",
													name: "Apply Credit"
												},
												{
													actionFunction: () => {
														getTerritory(i)
														
														editDialogHandler(i)
													},
													icon: "Edit",
													name: "Edit"
												},
												{
													actionFunction: () => {
														setCurrentData(i)
														setOpenConfirmModal(true)
													},
													icon: "Delete",
													name: "Delete"
												}
											]}/>
									
									</div>
								</div>
							)
						})}
						{
							count > 10 &&
							<Container
								maxWidth="lg"
								style={{visibility: userLoading === true || (isAddUser === true || editUser === true || clientDetail === true) ? "hidden" : "visible"}}>
								<Pagination_New count={count}
								                postsPerPage={postsPerPage}
								                activePage={current_page}
								                paginate={(pageNumber) => {
									                dispatch(SetCurrentPage(pageNumber))
									                dispatch(GetUser({
										                limit: postsPerPage,
										                offset: (postsPerPage * pageNumber - postsPerPage),
										                user_type: 1
									                }))
								                }}
								/>
							</Container>
						}
					
					</div>
					: <p className={newclasses.error}>No Data Found</p>
			}
		</>)
	
}

export default AdminTable;
