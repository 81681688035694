import React, {useEffect, useState} from 'react';
import NewMoreDropDown from "../NewMoreDropDown/NewMoreDropDown";
import format from 'date-fns/format'
import {useStyles} from "./CourtsDataTableStyle";
import axios from "../../Store/request"
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import useToast from "../Toast/useToast";
import {Button,Checkbox} from "@material-ui/core";
const InvoiceDetail = ({DataArray,triedFetching, onBack, currentData: courtData,courtDetail,isOpenInvoice}) => {
	const classes = useStyles()
	// DataArray
	const { showToast } = useToast()
	const TextClipper = (bio, length) => {
		let vb = ""
		if (bio?.length >= length) {
			for (var i = 0; i < length; i++) {
				vb += bio.charAt(i)
			}
			bio = `${vb}...`
			
		}
		
		return bio
	}
	const [currentData, setCurrentData] = useState({})
	const [openConfirmModal, setOpenConfirmModal] = useState(false)
	
	const [creditLoading, setCreditLoading] = useState(false);
	
	const applyCredit =async() => {
		setCreditLoading(true)
		try {
			const response = await axios.post(`/api/v1/courts/court_invoice/apply_credit/`, {id: courtData.id,court_users_invoice: selected})
			users.map((item)=>{
				if (selected.includes(item.id)){
					item.is_paid = true;
				}
			})
			setSelected([])
			setOpenConfirmModal(false)
			setCreditLoading(false)
			showToast("Apply Credit Succesfully", "success")
		}
		catch(error){
			setOpenConfirmModal(false)
			setCreditLoading(false)
			// setUsers(DataArray)
			setSelected([])
			showToast("Unable to Apply Credit, please try again later", "error")
		}
	}
	const getMoreOptions = (i) => {
		const buttons = [
			{
				actionFunction:() => {
					window.open(`${i.invoice}`)
					setCurrentData(i)
				},
				icon:"Visibility",
				name:"View Invoice"
			},
		];
		return buttons;
	};
	
	const getDate = (created_at) => {
		try {
			return format(new Date(created_at), "yyyy-MM-dd")
		}catch (e) {
			return ""
		}
	}
	const [users, setUsers] = useState([]);
	const [selected, setSelected] = useState([]);
	const [isALLPaid,setIsAllPaid] = useState(false)


	useEffect(() => {
		if(DataArray?.length){
			setUsers(DataArray);
			setIsAllPaid(!DataArray.some((item)=>item.is_paid===false))
		}
	}, [DataArray]);
	const handleChange = (e,i=[]) => {
		const { name, checked } = e.target;
		if (name === "allSelect") {
			if(checked) {
				
				const allId = [];
				users.map((user) =>{
					if (!user.is_paid){
						allId.push(user.id)	
					}
				})
				setSelected(allId);
			}else {
				setSelected([]);
			}
		} else {
			if(checked) {
				setSelected([...selected, i.id]);
			}else {
				const prevData = [...selected];
				const index = prevData.findIndex(d => d === i.id);
				if (index > -1) {
					prevData.splice(index, 1);
					setSelected([...prevData]);
				}
			}
			
		}
	};
	
	const onSubmitHandle=()=>{
		setOpenConfirmModal(true)
	}
	return (
		<>
			{
				isOpenInvoice &&
				<Button
					variant="contained"
					className={classes.button}
					onClick={onBack}
					style={{ margin: 0}}
				>
					Back
				</Button>
			}
		
			{
				!!DataArray?.length ? <>
					<div className={classes.TableHeading}>
						<div className={classes.HeadingInvoiceInner}>
							<p className={classes.Heading}>
								<Checkbox name="allSelect"
											checked={users.length === selected.length}
											onChange={handleChange}
											color="primary"
											className={classes.CheckBoxAll}
											disabled={isALLPaid}
								/>
								Pay All
							</p>
							<p className={classes.Heading}>Court name</p>
							<p className={classes.Heading}>Invoice #</p>
							<p className={classes.Heading}>Client Last Name</p>
							<p className={classes.Heading}>Client First Name</p>
							<p className={classes.Heading}>Created Date</p>
							<p className={classes.Heading}>Is Paid?</p>
							<p className={classes.Heading}>Total Amount</p>
						</div>
					</div>
					{users.map(i => {
						return (
							<div className={classes.MainContainer} key={i?.id}>
								<div className={classes.ContainerInvoiceInner}>
									<span className={classes.ListItmes}>
									<Checkbox name={i?.id}
									checked={selected.includes(i.id)}
									onChange={(e)=>handleChange(e,i)}
									color="primary"
									className={classes.CheckBox}
									disabled={i?.is_paid}
											/>
									</span>
									<p className={classes.ListItmes}>{courtDetail?.name}</p>
									<p className={classes.ListItmes}>{i?.id}</p>
									<p className={classes.ListItmes}>{i?.user.last_name}</p>
									<p className={classes.ListItmes}>{i?.user.first_name}</p>
									<p className={classes.ListItmes}>
										{getDate(i?.created_on)}
									</p>
									<p className={classes.ListItmes}>
										{i?.is_paid ? "Yes" : "No"}
									</p>
									<p className={classes.ListItmes}>
										{i?.amount}
									</p>
									<NewMoreDropDown className={classes.ListItmes}
										MoreDropDownItem={getMoreOptions(i)}
									/>
								</div>
							</div>
						)
					})}
					<Button
						variant="contained"
						className={classes.button}
						onClick={onSubmitHandle}
						style={{ margin: 0}}
						disabled={selected.length===0}
					>
						Submit
					</Button>
				</> : <p className={classes.error} style={{visibility:!triedFetching?"hidden":"visible"}}>No Data Found</p>
			}
			<ConfirmModal
				signal={openConfirmModal}
				handleyes={applyCredit}
				handleno={() => {
					setOpenConfirmModal(false)
					setUsers(DataArray);
				} }
				msg={selected.length===DataArray.length?`Are you sure you want to apply credit for All Invoices ?`:`Are you sure you want to apply credit for the invoices you have checked?`}
				loading={creditLoading}
			/>
		
		</>
	);
};

export default InvoiceDetail;
