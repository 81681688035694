import React, { useState, useEffect } from "react"
import { useStyles } from "./ManagementDataTableStyle"
import NewMoreDropDown from "../NewMoreDropDown/NewMoreDropDown"
import axios from "../../Store/request"
import {connect, useDispatch, useSelector} from "react-redux"
import { auth } from "../../Store/Actions/Auth"
import ConfirmModal from "../ConfirmModal/ConfirmModal"
import ViewModal from "../ViewModal/ViewModal"
import {Button, Dialog} from "@material-ui/core"
import useToast from "../Toast/useToast"
import { GetAllOfficers } from "../../Store/Actions/Officers";
import CircularSpinner from "../CircularProgressUserReport/CircularSpinner";
import CourtDropDownData from "../CourtDropDownData/CourtDropDownData"
import Logo from "../../assets/AMSLogo.png"
import SearchableDropDown from "../SearchableDropdown/SearchableDropDown"
import useAuth from "../../utils/useAuth";

const ManagementDataTable = ({DataArray,isDisabled,setIsDisabled}) => {
  const classes = useStyles()
  const { showToast } = useToast()
  const [currentData, setCurrentData] = useState({})
  const [triedFetching,setTriedFetching] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [openViewModal, setOpenViewModal] = useState(false)
  const [arrayOfData, setArrayOfData] = useState([])
  const [creditLoading,setCreditLoading]=useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)
  const dispatch = useDispatch()
  const { isOperator } = useAuth();

  const [boolenOption] = useState([
    {value:true,label:"Yes"},
    {value:false,label:"No"}
  ])
  const {data,count,loading,dataExist} = useSelector(({ officers }) => officers)
  const deleteOfficer = () => {
    setCreditLoading(true)
    axios
      .delete(`/api/v1/courts/probation/officer/${currentData.id}/`)
      .then(function (response) {
        setOpenConfirmModal(false)
        showToast("Officer Deleted Succesfully", "success")
        setCreditLoading(false)
        dispatch(GetAllOfficers())
      })
      .catch(function (error) {
        setOpenConfirmModal(false)
        setCreditLoading(false)
        showToast("Unable to delete please try again later", "error")
      })
  }
  useEffect(() => {
    setTriedFetching(true)
    if(data.length>0 && data.length===count )return;
    if(dataExist){
      dispatch(GetAllOfficers())}
  }, [])

  const [officerErrror,setOfficerError]=useState("")
  let existsCourt=[]
  data.map((element)=>{
    if(element.court.id===currentData.court)
    {
      existsCourt.push(element)
    }
  })

  const EditOfficer = e => {
    e.preventDefault()
    let existOfficer=existsCourt.find((element)=>element.first_name===currentData.first_name)
    if(existOfficer?.id!==currentData.id)
    {
      if(existOfficer) {
        setOfficerError(`*officer is already exists on the court of ${existOfficer?.court?.name}`)
        return
      }
    }
    if (!currentData.email.includes("@")) {
      showToast("Invalid Email", "error")
      return
    }

    if (!currentData.email.includes(".")) {
      showToast(
        "The entered email address is invalid, please try again",
        "error"
      )
      return
    }

    //first condition checks XXX-XXX-XXXX format next condition checks for length
    if (
      currentData.phone_number.length !== 12 || !currentData.phone_number.match(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
      )
    ) {
      showToast(
        "invalid phone number, please make sure the entered phone number is in XXX-XXX-XXXX format e.g 216-999-9999",
        "error"
      )
      return
    }


    let finalObj = {
      ...currentData,
    }
    const data = JSON.stringify(finalObj)
    setIsDisabled(true)
    setCreditLoading(true)
    axios
      .put(`/api/v1/courts/probation/officer/${currentData.id}/`, data)
      .then(function (response) {
        setOpenEditModal(false)
        setCurrentData({})
        showToast("Officer Edited Succesfully", "success")
       setIsDisabled(false)
        setCreditLoading(false)
        setOfficerError("")
        dispatch(GetAllOfficers())
      })
      .catch(function (error) {
        setIsDisabled(false)
        setCreditLoading(false)
        setOfficerError("")
        showToast("Unable to edit please try again later", "error")
      })
  }

  const TextClipper = (bio, length) => {
    let vb = ""

    if (bio?.length >= length) {
      for (var i = 0; i < length; i++) {
        vb += bio.charAt(i)
      }
      bio = `${vb}...`

    }

    return bio
  }

  const numberFormatter = num => {
    let firstAdd = "("
    let firstPos = 0
    let originString = num

    originString = originString.split("")
    originString.splice(firstPos, 0, firstAdd)

    let secondString = originString.join("")

    let secondAdd = ")"
    let secondPos = 4

    secondString = secondString.split("")
    secondString.splice(secondPos, 0, secondAdd)

    let thirdString = secondString.join("")

    let thirdAdd = " "
    let thirdPos = 5

    thirdString = thirdString.split("")
    thirdString.splice(thirdPos, 1, thirdAdd)

    return thirdString
  }
  const inputEvent=(event)=>{
    const {name,value}=event.target;
    setCurrentData((prevalue)=>{
      return {
        ...prevalue,
        [name]: value,
      }
    })
  }
  const users = [
    { name: 'King', value: 21 },
    { name: 'raj', value: 37 },
    { name: 'Aana', value: 45 },
    { name: 'Bob', value: -12 },
    { name: 'Jim', value: 13 },
    { name: 'Doll', value: 37 }
  ];
  const sortArray=[]
  DataArray.map((element)=>{
    sortArray.push(element)
  })

  const sortedusers = sortArray.sort(function(a, b) {
    const nameA = a.court.name?.toUpperCase(); // ignore upper and lowercase
    const nameB = b.court.name?.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1; //nameA comes first
    }
    if (nameA > nameB) {
      return 1; // nameB comes first
    }
    return 0;  // names must be equal
  });

  const normalizeInput = (value, previousValue) => {
    if (!value) return value; 
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length; 
  
    if (!previousValue || value.length > previousValue.length) {
  
      if (cvLength < 4) return currentValue; 
  
      if (cvLength < 7) return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`; 
  
      return `${currentValue.slice(0, 3)}-${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`; 
    }
    return value
  };

  const getMoreDropDownData = (i)=>{
    if(!isOperator){
      return [
        {actionFunction:() => {
          const updateCurrentData={...i}
          updateCurrentData.court=i.court.id
          setCurrentData(updateCurrentData)
          setOpenEditModal(true)
        },
        icon:"Edit",
        name:"Edit"},
        {actionFunction:() => {
          setArrayOfData([
            {
              title: "Court Name/ID",
              val: i.court.name
            },
            {
              title: "Officer Name",
              val: i.first_name
            },
            {
              title: "Email Address",
              val: i.email
            },
            {
              title: "Phone Number",
              val: i.phone_number
            },
            {
              title: "Receive Statement",
              val: i.receive_statement === true ? "yes" : "no"
            }
          ])
          setCurrentData(i)
          setOpenViewModal(true)
        },
        icon:"Visibility",
        name:"See Details"},
        {actionFunction:() => {
          setCurrentData(i)
          setOpenConfirmModal(true)
        },
        icon:"Delete",
        name:"Delete"},
      ]
    }
    else{
      return [
        {actionFunction:() => {
          setArrayOfData([
            {
              title: "Court Name/ID",
              val: i.court.name
            },
            {
              title: "Officer Name",
              val: i.first_name
            },
            {
              title: "Email Address",
              val: i.email
            },
            {
              title: "Phone Number",
              val: i.phone_number
            },
            {
              title: "Receive Statement",
              val: i.receive_statement === true ? "yes" : "no"
            }
          ])
          setCurrentData(i)
          setOpenViewModal(true)
        },
        icon:"Visibility",
        name:"See Details"},
      ]
    }
  }

  const handlePhNoChange = (event) => { 
    const {name,value}=event.target; 
    setCurrentData(prevState=>{
      return ({...prevState,[name]:normalizeInput(value,prevState[name])})});
  };
  return (

    <div className={classes.Container}
    >
      {!!DataArray.length?<>
        <div className={classes.TableHeading}>
          <div className={classes.HeadingInner}>
            <p className={classes.Heading1}>Court name</p>
            <p></p>
            <p className={classes.Heading}>Officer name</p>
            <p className={classes.Heading}>Email address:</p>
            <p className={classes.Heading}>Phone number</p>
            <p className={classes.Heading}>Receive Statement:</p>
            <p className={classes.Heading}></p>
            <p className={classes.Heading}></p>
          </div>
        </div>

        {sortedusers.map((i,index) => {
            return (
              <div className={classes.MainContainer} key={i.id}>
                <div className={classes.ContainerInner}>
                  <p className={classes.ListItmes}>
                    {i.court.name}
                  </p>
                  <p className={classes.ListItmes}>
                    {i.first_name+" "+i.last_name}
                  </p>

                  <p className={classes.ListItmes}>{i.email}</p>
                  <p className={classes.ListItmes}>
                    {numberFormatter(i.phone_number)}
                  </p>
                  <p
                    className={classes.ListItmes}
                    style={
                      i.receive_statement === false
                        ? { color: "#FF2A13" }
                        : { color: "#4C8210" }
                    }
                  >
                    {i.receive_statement === true ? "yes" : "no"}
                  </p>
                  <NewMoreDropDown 
                  MoreDropDownItem={getMoreDropDownData(i)}
                  />
                </div>
              </div>
            )
          })}
          </>
        :<p className={classes.error} style={{visibility:!triedFetching?"hidden":"visible"}}>No Data Found</p>
      }


      <ConfirmModal
        signal={openConfirmModal}
        handleyes={deleteOfficer}
        handleno={() => setOpenConfirmModal(false)}
        msg={`Are you sure you want to delete the officer "${currentData.first_name}"?`}
        loading={creditLoading}
      />

      <ViewModal
        signal={openViewModal}
        handleyes={() => setOpenViewModal(false)}
        arrayOfData={arrayOfData}
      />

      <Dialog
        open={openEditModal}
        scroll="body"
        onClose={() => {
          setOpenEditModal(false)
          setIsDisabled(false)
          setOfficerError("")
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: {
            overflow: "unset",
            margin:40
          }
        }}
      >
        {creditLoading===true?
          (
            <CircularSpinner onModal/>
          )
          :(
            <div className={classes.topDiv} style={{height:"700px"}}>
              <div className={classes.LogoWrapper}>
                <img src={Logo} className={classes.Logo} />
              </div>
              <div className={classes.editTitle}>
                Edit Officer
              </div>
              <form onSubmit={EditOfficer} className={classes.upperDiv}>
                <div
                  style={{cursor: "pointer"}}
                  onClick={() => {
                    setOpenEditModal(false)
                    setOfficerError("")
                    }
                  }
                >
                  <i
                    className="fal fa-times fa-lg"
                    style={{position: "absolute", top: "3%", right: "4%"}}
                  />
                </div>
                <div className={classes.firstDivCourt}>
                  <p className={classes.styleTag}>COURT NAME</p>

                  <CourtDropDownData
                    handleSelect={val =>
                      setCurrentData({...currentData, court: val})
                    }
                    selectedValue={currentData.court}
                  />
                </div>
                <div className={classes.firstDiv}>
                  <p className={classes.styleTag}>Officer First Name</p>
                  <input
                    type="text"
                    name="first_name"
                    placeholder={currentData.first_name}
                    className={classes.selectCont}
                    value={currentData.first_name}
                    onChange={(inputEvent)}
                    required
                  />
                  <span style={{color:"red"}}>{officerErrror}</span>
                </div>
                <div className={classes.firstDiv}>
                  <p className={classes.styleTag}>Officer Last Name</p>
                  <input
                    type="text"
                    name="last_name"
                    placeholder={currentData.last_name}
                    className={classes.selectCont}
                    value={currentData.last_name}
                    onChange={(inputEvent)}
                    required
                  />
                  <span style={{color:"red"}}>{officerErrror}</span>
                </div>
                <div className={classes.firstDiv}>
                  <p className={classes.styleTag}>Email</p>
                  <input
                    type="email"
                    name="email"
                    placeholder={currentData.email}
                    className={classes.selectCont}
                    value={currentData.email}
                    onChange={(e)=>{inputEvent(e)}}

                    required
                  />
                </div>
                <div className={classes.firstDiv}>
                  <p className={classes.styleTag}>Phone Number</p>
                  <input
                    type="text"
                    name="phone_number"
                    placeholder={currentData.phone_number}
                    className={classes.selectCont}
                    value={currentData.phone_number}
                    onChange={handlePhNoChange}
                    required
                  />
                </div>
                <div className={classes.firstDivCourt}>
                  <p className={classes.styleTag}>Receive Statement</p>
                  <SearchableDropDown
                      data={boolenOption}
                      handleClick={val =>{
                        setCurrentData({ ...currentData, receive_statement: val })}
                      }
                      selectedValue={currentData.receive_statement}
                      />
                </div>
                {isDisabled === false ?
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    disabled={
                      currentData.first_name === "" ||
                      currentData.email === "" ||
                      currentData.phone_number === "" ||
                      currentData.receive_statement === ""
                    }
                    type="submit"
                  >
                    {"SAVE OFFICER"}
                  </Button>
                  : <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    disabled
                    type="submit"
                  >
                    {"SAVE OFFICER"}
                  </Button>
                }

              </form>
            </div>
          )
        }

      </Dialog>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    accessToken: state.auth.accessToken
  }
}

export default connect(mapStateToProps, { auth })(ManagementDataTable)
