import Actions from "../Type"
import axios from "../request"

export const GetOfficers = ({ limit = 10, offset = 0 } = {}) => {
  return async dispatch => {
    try {
      dispatch({ type: Actions.GET_OFFICER_REQUEST })
      const response = await axios.get(`/api/v1/courts/probation/officer/?limit=${limit}&offset=${offset}`)
      // const res= await axios.get(`/api/v1/courts/probation/officer/?limit=${response.data.count}&offset=${offset}`)
      dispatch({
        type: Actions.GET_OFFICER_SUCCESS,
        payload: { results: response.data.results, count: response.data.count}
        // payload: response.data.results,
        // count:response.data.count,
      })
    } catch (e) {
      dispatch({ type: Actions.GET_OFFICER_FAIL })
    }
  }
}

export const addOfficersData=(data)=>{
  return async dispatch => {
    try {
      dispatch({
        type: Actions.ADD_OFFICER_DATA,
        payload: data
      })
    } catch (e) {
    }
  }
}

export const GetAllOfficers = () => {
  return async dispatch => {
    try {
      dispatch({ type: Actions.GET_OFFICER_REQUEST })
      const response = await axios.get(`/api/v1/courts/probation/officer/?type=full`)
      // if(response.data.length===0){
      //   response.data=[{court:{id:"No Data Found",name:"NO Data found"}}]
      // }
      dispatch({
        type: Actions.GET_OFFICER_SUCCESS,
        payload: { results: response.data, count: response.data.length ,dataExist:response.data.length>0?true:false}
      })
    } catch (e) {
      dispatch({ type: Actions.GET_OFFICER_FAIL })
    }
  }
}