import React, { useEffect, useContext, useState } from "react";
import Container from '@material-ui/core/Container';
import CourtsHeader from "../../Components/CourtsHeader/CourtsHeader";
import DocumentationIcon from "../../assets/DocumentationIcon.png";
import DocumentationBtn from "../../Components/DocumentationBtn/DocumentationBtn";
import DocumentationDataTable from "../../Components/DocumentationDataTable/DocumentationDataTable";
import Pagination from "../../Components/Pagination/Pagination";
import { useStyles } from "./DocumentationStyle"
import { useHistory } from "react-router";
import { connect, useDispatch } from "react-redux";
import { auth } from "../../Store/Actions/Auth";
import { ScreenContext } from './../../context/context'
import { Button } from "@material-ui/core";
import {BtnStyles} from "./../FileStorageManagement/FileStorageManagementStyle"

const Documentation = (props) => {
  const classes = useStyles()
  const btnclasses = BtnStyles()

  const history = useHistory()
  const { screenSize, setScreenSize } = useContext(ScreenContext)

  useEffect(() => {
    if (props.accessToken === null || props.accessToken === "" || props.accessToken === undefined) {
      history.push("/")
    }
  }, [])

  const [tabs, setTabs] = useState([
    {
        index: 1,
        name: 'INVOICE TABLE',
        selected: true
    },
    {
        index: 2,
        name: 'PREPAID TABLE',
        selected: false
    }])

    const selectChange = (index) => {
      //filter the selected object to update its selected attribute

      const elementsIndex = tabs.findIndex(element => element.index == index)
      let newArray = [...tabs]

      newArray[elementsIndex] = { ...newArray[elementsIndex], selected: true }

      let updatedArray = newArray;

      let latestArray = [...updatedArray]

      for (let i = 0; i < latestArray.length; i++) {
          if (i !== elementsIndex) {
              latestArray[i] = { ...latestArray[i], selected: false }
          }
      }

      let finalArray = latestArray;

      setTabs(finalArray)

  }

  

  return (
    <div className={classes.MainContainer}>
      <CourtsHeader MainHeading="Documentation management" lable="+ Add document" img={DocumentationIcon} />
      <Container maxWidth="lg" className={screenSize > 1400 ? classes.container : classes.containerMob}>
        <div className={btnclasses.BtnContainer}>
          {
            tabs.map((tab, i) => {
              return (
                <Button onClick={selectChange.bind(this, tab.index)} variant="contained" color={tab.selected ? "" : "secondary"} className={tab.selected ? btnclasses.InvoiceButton : btnclasses.PrepaidButton}>
                  {tab.name}
                </Button>
              )
            })
          }

        </div>
        
        <DocumentationDataTable passedIndex={tabs}/>
        <Pagination />
      </Container>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.accessToken
  }
}

export default connect(mapStateToProps, { auth })(Documentation);