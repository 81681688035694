import { makeStyles } from "@material-ui/core/styles";


export const useStyles = makeStyles((theme) => ({
    MainContainer: {
        width: "100%",
        height: 97,
        background: "#FFFFFF",
        boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    HeaderInner: {
        padding: "2px 10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },

    IconWrapper: {
        borderRadius: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#81A4FF",
        margin: "0px 20px",
        width: 47,
        height: 44,
    },
    icon: {
        width: 24,
        height: 24
    },
    MainHeading: {
        color: "#363E51",
        fontFamily: "Open Sans",
        fontSize: theme.fonts.sizes.fontXL,
        fontWeight: 600,
        textTransform: "uppercase"
    },
    Wrapper: {
        display: "flex",
        alignItems: "center",
    },


    topDiv: {
        height: '600px',
        width: '100%',
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'center',
        background: 'none',
        alignItems: 'center',
        boxShadow: 'none'
    }
    ,
    upperDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        height: '550px',
        width: '412px',
        alignItems: 'center',
        background: 'white'

    },

    firstDiv: {
        display: 'flex',
        flexDirection: "column",
        justifyContent: 'space-around',
        width: '80%',
    },

    firstDivCourt: {
        display: 'flex',
        padding: 0,
        flexDirection: "column",
        justifyContent: 'space-around',
        width: '80%',
        fontFamily:"Open Sans",
        fontWeight:600,
        fontSize:16,
        "& .css-1qauysc-control":{
        height:"44px",
        backgroundColor:"#F9F9F9",
        fontFamily:"Open Sans",
        fontWeight:600,
        fontSize:16
        },
        "& :focus":{
        height:"30px",
        backgroundColor:"#F9F9F9"
        },
        "& .css-1okebmr-indicatorSeparator": {
        background:"none"
        },
        "& .css-tlfecz-indicatorContainer": {
        color: "black"
        },
        "& .makeStyles-selectDropDown-242": {
        marginTop: 0
        }
    },

    bottomCont: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '80%'
    },

    bottomContLeft: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        width: '45%',
        fontFamily:"Open Sans",
        fontWeight:600,
        fontSize:16,
        "& .css-1qauysc-control":{
        height:"44px",
        backgroundColor:"#F9F9F9",
        fontFamily:"Open Sans",
        fontWeight:600,
        fontSize:16
        },
        "& :focus":{
        height:"30px",
        backgroundColor:"#F9F9F9"
        },
        "& .css-1okebmr-indicatorSeparator": {
        background:"none"
        },
        "& .css-tlfecz-indicatorContainer": {
        color: "black"
        },
        "& .makeStyles-selectDropDown-242": {
        marginTop: 0
        }
    },

    bottomContRight: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        width: '45%',
        fontFamily:"Open Sans",
        fontWeight:600,
        fontSize:16,
        "& .css-1qauysc-control":{
        height:"44px",
        backgroundColor:"#F9F9F9",
        fontFamily:"Open Sans",
        fontWeight:600,
        fontSize:16
        },
        "& :focus":{
        height:"30px",
        backgroundColor:"#F9F9F9"
        },
        "& .css-1okebmr-indicatorSeparator": {
        background:"none"
        },
        "& .css-tlfecz-indicatorContainer": {
        color: "black"
        },
        "& .makeStyles-selectDropDown-242": {
        marginTop: 0
        }
    },

    inputCont: {
        // width: '326px',
        height: '44px',
        background: '#F9F9F9',
        color: '#333333',
        borderRadius: '5px',
        border: '1px solid lightgray',
        fontFamily: "Open Sans",
        fontSize: '16px',
        paddingLeft: '25px'
    },
    inputContDate: {
        width: '100%',
        height: '44px',
        background: '#F9F9F9',
        color: '#333333',
        borderRadius: '5px',
        border: '1px solid lightgray',
        fontFamily: "Open Sans",
        fontSize: '16px',
        paddingLeft: '10px',
        outline: "none",
        "&:focus":{
            height:"44px"
        }
    },
    tagStyle:{
        fontFamily: 'Open Sans',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 14,
        textTransform: "uppercase",
        color: "#071A32",
        marginBottom: 0
    },

    button: {
        background: "#3971FF",
        margin: "0px 20px",
        marginTop: 10,
        // marginLeft: 25,
        "&.MuiButton-containedSecondary": {
            width: "80%",
            height: 44,
            display: "flex",
            alingItems: "center",
            borderRadius: "5px !important",
            "&:hover": {
                background: "#3971FF",
            },
            "& .MuiButton-label": {
                fontFamily: "Open Sans",
                fontSize: 16,
                fontWeight: 700,
                textTransform: "capitalize",
                // marginTop: 3,
            },
            "& .MuiSvgIcon-root": {
                // marginTop: -2
            }
        }
    },

    relativeText: {
        position: 'absolute',
        top: '72.7%',
        left: '20%',
        fontSize: '18px'
    },
    relativeText2: {
        position: 'absolute',
        top: '72.7%',
        right: '43%',
        fontSize: '18px'
    },

    LogoWrapper: {
        backgroundColor: "white",
        borderRadius: 50,
        padding: 18,
        border: "1px solid #FFFFFF",
        // boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.203851)",
        position: "absolute",
        top: -35,
    },
    Logo: {
        width: 34,
        height: 32,
    },
    selectCont: {
        width: '151px',
        height: '44px',
        background: '#F9F9F9',
        color: '#333333',
        borderRadius: '5px',
        // paddingLeft: '5px',
        border: '1px solid lightgray',
        fontFamily: "Open Sans",
        fontSize: '16px',
        paddingLeft: '10px',
        outline: "none"
    },
    selectContTerritory: {
        // width: '326px',
        height: '44px',
        background: '#F9F9F9',
        color: '#333333',
        borderRadius: '5px',
        // paddingLeft: '5px',
        border: '1px solid lightgray',
        fontFamily: "Open Sans",
        fontSize: '16px',
        paddingLeft: '10px',
        outline: "none"
    },
    selection: {
        width: '150px',
        height: '30px',
        borderRadius: '5px',
        paddingLeft: '7px',
        color: 'rgb(70,70,250)',
        border: '1px solid lightgray',
        marginLeft: '10px'

    },

}));

export const useStylesPayCall = makeStyles((theme) => ({
    MainContainer: {
        width: "100%",
        height: 97,
        background: "#FFFFFF",
        boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around'
    },
    HeaderInner: {
        padding: "2px 10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },

    IconWrapper: {
        borderRadius: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#81A4FF",
        margin: "0px 20px",
        width: 47,
        height: 44,
    },
    icon: {
        width: 24,
        height: 24
    },
    MainHeading: {
        color: "#363E51",
        fontFamily: "Open Sans",
        fontSize: theme.fonts.sizes.fontXL,
        fontWeight: 600,
        textTransform: "uppercase"
    },
    Wrapper: {
        display: "flex",
        alignItems: "center",
    },


    topDiv: {
        height: '344px',
        width: '412px',
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'center',
        background: 'none',
        alignItems: 'center',
        boxShadow: 'none'
    }
    ,
    upperDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        height: '320px',
        width: '100%', 
        alignItems: 'center',
        background: 'white'

    },

    firstDiv: {
        display: 'flex',
        flexDirection: "column",
        justifyContent: 'space-around',
        width: '80%',
    },
    firstDivReportSchedule: {
        display: 'flex',
        padding: 0,
        flexDirection: "column",
        justifyContent: 'space-around',
        width: '80%',
        fontFamily:"Open Sans",
        fontWeight:600,
        fontSize:16,
        "& .css-1qauysc-control":{
        height:"44px",
        backgroundColor:"#F9F9F9",
        fontFamily:"Open Sans",
        fontWeight:600,
        fontSize:16
        },
        "& :focus":{
        height:"30px",
        backgroundColor:"#F9F9F9"
        },
        "& .css-1okebmr-indicatorSeparator": {
        background:"none"
        },
        "& .css-tlfecz-indicatorContainer": {
        color: "black"
        },
        "& .makeStyles-selectDropDown-242": {
        marginTop: 0
        }
    },

    bottomCont: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '80%'
    },

    bottomContLeft: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        width: '45%'
    },

    bottomContRight: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        width: '45%'
    },

    inputCont: {
        width: '326px',
        height: '44px',
        background: '#F9F9F9',
        color: '#333333',
        borderRadius: '5px',
        border: '1px solid lightgray',
        fontFamily: "Open Sans",
        fontSize: '16px',
        paddingLeft: '25px'
    },
    inputContDate: {
        width: '100%',
        height: '44px',
        background: '#F9F9F9',
        color: '#333333',
        borderRadius: '5px',
        border: '1px solid lightgray',
        fontFamily: "Open Sans",
        fontSize: '16px',
        paddingLeft: '10px',
        outline: "none",
        "&:focus":{
            height:"44px"
        }
    },
    tagStyle:{
        fontFamily: 'Open Sans',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 14,
        textTransform: "uppercase",
        color: "#071A32",
        marginBottom: 0
    },


    button: {
        background: "#3971FF",
        marginTop: 10,
        "&.MuiButton-containedSecondary": {
            width: '326px',
            height: 44,
            display: "flex",
            alingItems: "center",
            borderRadius: "5px !important",
            "&:hover": {
                background: "#3971FF",
            },
            "& .MuiButton-label": {
                fontFamily: "Open Sans",
                fontSize: 16,
                fontWeight: 700,
                textTransform: "capitalize",
                // marginTop: 3,
            },
            "& .MuiSvgIcon-root": {
                // marginTop: -2
            }
        }
    },

    relativeText: {
        position: 'absolute',
        top: '72.7%',
        left: '20%',
        fontSize: '18px'
    },
    relativeText2: {
        position: 'absolute',
        top: '72.7%',
        right: '43%',
        fontSize: '18px'
    },

    LogoWrapper: {
        backgroundColor: "white",
        borderRadius: 50,
        padding: 18,
        border: "1px solid #FFFFFF",
        // boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.203851)",
        position: "absolute",
        top: -35,
    },
    Logo: {
        width: 34,
        height: 32,
    },
    selectCont: {
        // width: '326px',
        height: '44px',
        background: '#F9F9F9',
        color: '#333333',
        borderRadius: '5px',
        // paddingLeft: '5px',
        border: '1px solid lightgray',
        fontFamily: "Open Sans",
        fontSize: '16px',
        paddingLeft: '10px',
        outline: "none"
    },
    selection: {
        width: '150px',
        height: '30px',
        borderRadius: '5px',
        paddingLeft: '7px',
        color: 'rgb(70,70,250)',
        border: '1px solid lightgray',
        marginLeft: '10px'

    },

}));

export const useStylesClientRemoval = makeStyles((theme) => ({
    MainContainer: {
        width: "100%",
        height: 97,
        background: "#FFFFFF",
        boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around'
    },
    HeaderInner: {
        padding: "2px 10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },

    IconWrapper: {
        borderRadius: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#81A4FF",
        margin: "0px 20px",
        width: 47,
        height: 44,
    },
    icon: {
        width: 24,
        height: 24
    },
    MainHeading: {
        color: "#363E51",
        fontFamily: "Open Sans",
        fontSize: theme.fonts.sizes.fontXL,
        fontWeight: 600,
        textTransform: "uppercase"
    },
    Wrapper: {
        display: "flex",
        alignItems: "center",
    },


    topDiv: {
        height: '514px',
        width: '412px',
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'center',
        background: 'none',
        alignItems: 'center',
        boxShadow: 'none'
    }
    ,
    upperDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        height: '485px',
        width: '100%',
        alignItems: 'center',
        background: 'white'

    },

    firstDiv: {
        display: 'flex',
        flexDirection: "column",
        justifyContent: 'space-around',
        width: '80%',
    },

    firstDivCourt: {
        display: 'flex',
        flexDirection: "column",
        justifyContent: 'space-around',
        width: '80%',
        fontFamily:"Open Sans",
        fontWeight:600,
        fontSize:16,
        "& .css-1qauysc-control":{
        height:"44px",
        backgroundColor:"#F9F9F9",
        fontFamily:"Open Sans",
        fontWeight:600,
        fontSize:16
        },
        "& :focus":{
        height:"30px",
        backgroundColor:"#F9F9F9"
        },
        "& .css-1okebmr-indicatorSeparator": {
        background:"none"
        },
        "& .css-tlfecz-indicatorContainer": {
        color: "black"
        },
        "& .makeStyles-selectDropDown-242": {
        marginTop: 0
        }
    },

    selectCont: {
        width: '100%',
        height: '44px',
        background: '#F9F9F9',
        color: '#333333',
        borderRadius: '5px',
        // paddingLeft: '5px',
        border: '1px solid lightgray',
        fontFamily: "Open Sans",
        fontSize: '16px',
        paddingLeft: '10px',
        outline: "none"
    },

    bottomCont: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '80%'
    },

    bottomContLeft: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        width: '45%'
    },

    bottomContRight: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        width: '45%'
    },

    inputCont: {
        // width: '326px',
        height: '44px',
        background: '#F9F9F9',
        color: '#333333',
        borderRadius: '5px',
        border: '1px solid lightgray',
        fontFamily: "Open Sans",
        fontSize: '16px',
        paddingLeft: '25px'
    },
    inputContDate : {
        width: '151px',
        height: '44px',
        background: '#F9F9F9',
        color: '#333333',
        borderRadius: '5px',
        border: '1px solid lightgray',
        fontFamily: "Open Sans",
        fontSize: '16px',
        paddingLeft: '25px',
        outline: "none"
    },
    tagStyle:{
        fontFamily: 'Open Sans',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 14,
        textTransform: "uppercase",
        color: "#071A32",
        marginBottom: 0
    },
    button: {
        background: "#3971FF",
        margin: "0px 20px",
        marginTop: "10px",
        marginLeft: "28px",
        "&.MuiButton-containedSecondary": {
            width: '80%',
            height: 44,
            display: "flex",
            alingItems: "center",
            borderRadius: "5px !important",
            "&:hover": {
                background: "#3971FF",
            },
            "& .MuiButton-label": {
                fontFamily: "Open Sans",
                fontSize: 16,
                fontWeight: 700,
                textTransform: "capitalize",
                // marginTop: 3,
            },
            "& .MuiSvgIcon-root": {
                // marginTop: -2
            }
        }
    },

    relativeText: {
        position: 'absolute',
        top: '72.7%',
        left: '20%',
        fontSize: '18px'
    },
    relativeText2: {
        position: 'absolute',
        top: '72.7%',
        right: '43%',
        fontSize: '18px'
    },

    LogoWrapper: {
        backgroundColor: "white",
        borderRadius: 50,
        padding: 18,
        border: "1px solid #FFFFFF",
        // boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.203851)",
        position: "absolute",
        top: -35,
    },
    Logo: {
        width: 34,
        height: 32,
    },

    selection: {
        width: '150px',
        height: '30px',
        borderRadius: '5px',
        paddingLeft: '7px',
        color: 'rgb(70,70,250)',
        border: '1px solid lightgray',
        marginLeft: '10px'

    },

}));

export const useStylesSalesByServiceRemoval = makeStyles((theme) => ({
    MainContainer: {
        width: "100%",
        height: 97,
        background: "#FFFFFF",
        boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around'
    },
    HeaderInner: {
        padding: "2px 10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },

    IconWrapper: {
        borderRadius: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#81A4FF",
        margin: "0px 20px",
        width: 47,
        height: 44,
    },
    icon: {
        width: 24,
        height: 24
    },
    MainHeading: {
        color: "#363E51",
        fontFamily: "Open Sans",
        fontSize: theme.fonts.sizes.fontXL,
        fontWeight: 600,
        textTransform: "uppercase"
    },
    Wrapper: {
        display: "flex",
        alignItems: "center",
    },


    topDiv: {
        height: '346px',
        width: '412px',
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'center',
        background: 'none',
        alignItems: 'center',
        boxShadow: 'none'
    }
    ,
    upperDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        height: '330px',
        width: '100%',
        alignItems: 'center',
        background: 'white'

    },

    firstDiv: {
        display: 'flex',
        flexDirection: "column",
        justifyContent: 'space-around',
        width: '80%',
    },

    tagStyle:{
        fontFamily: 'Open Sans',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 14,
        textTransform: "uppercase",
        color: "#071A32",
        marginBottom: 0
    },

    bottomCont: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '80%'
    },

    bottomContLeft: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        width: '45%',
        // marginLeft: '-8px'

    },

    bottomContRight: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        width: '45%',
        marginRight: '5px'
    },

    inputCont: {
        width: '326px',
        height: '44px',
        background: '#F9F9F9',
        color: '#333333',
        borderRadius: '5px',
        border: '1px solid lightgray',
        fontFamily: "Open Sans",
        fontSize: '16px',
        paddingLeft: '25px'
    },
    inputContDate: {
        width: '151px',
        height: '44px',
        background: '#F9F9F9',
        color: '#333333',
        borderRadius: '5px',
        border: '1px solid lightgray',
        fontFamily: "Open Sans",
        fontSize: '16px',
        paddingLeft: '25px'
    },

    button: {
        background: "#3971FF",
        margin: "0px 20px",
        marginTop: "10px",
        // marginLeft: "28px",
        "&.MuiButton-containedSecondary": {
            width: '80%',
            height: 44,
            display: "flex",
            alingItems: "center",
            borderRadius: "5px !important",
            "&:hover": {
                background: "#3971FF",
            },
            "& .MuiButton-label": {
                fontFamily: "Open Sans",
                fontSize: 16,
                fontWeight: 700,
                textTransform: "capitalize",
                // marginTop: 3,
            },
            "& .MuiSvgIcon-root": {
                // marginTop: -2
            }
        }
    },

    relativeText: {
        position: 'absolute',
        top: '72.7%',
        left: '20%',
        fontSize: '18px'
    },
    relativeText2: {
        position: 'absolute',
        top: '72.7%',
        right: '43%',
        fontSize: '18px'
    },

    LogoWrapper: {
        backgroundColor: "white",
        borderRadius: 50,
        padding: 18,
        border: "1px solid #FFFFFF",
        // boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.203851)",
        position: "absolute",
        top: -35,
    },
    Logo: {
        width: 34,
        height: 32,
    },
    selectCont: {
        width: '100%',
        height: '38px',
        background: '#F9F9F9',
        color: 'gray',
        borderRadius: '5px',
        // paddingLeft: '5px',
        border: '1px solid lightgray',
        fontFamily: theme.fonts.family.Light,
        fontSize: '16px',
        paddingLeft: '10px'
    },
    selection: {
        width: '150px',
        height: '30px',
        borderRadius: '5px',
        paddingLeft: '7px',
        color: 'rgb(70,70,250)',
        border: '1px solid lightgray',
        marginLeft: '10px'

    },

}));
