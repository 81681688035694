
export const DataArray = [
  {
    InvoiceNumber: "Invoice number",
    Service: "Name of the service",
    Days: "Days",
    ServiceAmount: "$11000",
    Fees: "$ 800",
    CreatedDate: "09.28.21",
    CreatedBy: "John D.",
    Updated: "09.28.21",
    UpdatedBy: "John D.",
    id: 1
  },
  
  {
    InvoiceNumber: "Invoice number",
    Service: "Name of the service",
    Days: "Days",
    ServiceAmount: "$11000",
    Fees: "$ 800",
    CreatedDate: "09.28.21",
    CreatedBy: "John D.",
    Updated: "09.28.21",
    UpdatedBy: "John D.",
    id: 2
  },
  
  {
    InvoiceNumber: "Invoice number",
    Service: "Name of the service",
    Days: "Days",
    ServiceAmount: "$11000",
    Fees: "$ 800",
    CreatedDate: "09.28.21",
    CreatedBy: "John D.",
    Updated: "09.28.21",
    UpdatedBy: "John D.",
    id: 3
  },
  
  {
    InvoiceNumber: "Invoice number",
    Service: "Name of the service",
    Days: "Days",
    ServiceAmount: "$11000",
    Fees: "$ 800",
    CreatedDate: "09.28.21",
    CreatedBy: "John D.",
    Updated: "09.28.21",
    UpdatedBy: "John D.",
    id: 4
  },
  
  {
    InvoiceNumber: "Invoice number",
    Service: "Name of the service",
    Days: "Days",
    ServiceAmount: "$11000",
    Fees: "$ 800",
    CreatedDate: "09.28.21",
    CreatedBy: "John D.",
    Updated: "09.28.21",
    UpdatedBy: "John D.",
    id: 5
  },
  
  {
    InvoiceNumber: "Invoice number",
    Service: "Name of the service",
    Days: "Days",
    ServiceAmount: "$11000",
    Fees: "$ 800",
    CreatedDate: "09.28.21",
    CreatedBy: "John D.",
    Updated: "09.28.21",
    UpdatedBy: "John D.",
    id: 6
  },
  
  {
    InvoiceNumber: "Invoice number",
    Service: "Name of the service",
    Days: "Days",
    ServiceAmount: "$11000",
    Fees: "$ 800",
    CreatedDate: "09.28.21",
    CreatedBy: "John D.",
    Updated: "09.28.21",
    UpdatedBy: "John D.",
    id: 7
  },
  
  {
    InvoiceNumber: "Invoice number",
    Service: "Name of the service",
    Days: "Days",
    ServiceAmount: "$11000",
    Fees: "$ 800",
    CreatedDate: "09.28.21",
    CreatedBy: "John D.",
    Updated: "09.28.21",
    UpdatedBy: "John D.",
    id: 8
  },
  


]
