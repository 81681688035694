import { makeStyles } from "@material-ui/core/styles"

export const useStyle = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent:"center",

  },
  topDiv: {
    height: "650px",
    width: "412px",
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    background: "none",
    alignItems: "center",
    boxShadow: "none"
  },
  upperDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "500px",
    width: "412px",
    alignItems: "center",
    background: "white",
    
  },

  firstDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "80%",
    marginTop: "10px"
  },

  selectCont: {
    width: "100%",
    height: "44px",
    background: "#F9F9F9",
    color: "#333333",
    borderRadius: "5px",
    border: "1px solid lightgray",
    fontFamily: "Open Sans",
    fontSize: "16px",
    outline: "none",
    paddingLeft: "10px"
    
  },

  bottomCont: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "80%",
    marginTop: "10px"

  },

  bottomContLeft: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "45%"
  },

  bottomContRight: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "45%"
  },
  bottomContRightState: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "45%",
    fontFamily:"Open Sans",
    fontWeight:600,
    fontSize:16,
    "& .css-1qauysc-control":{
    height:"44px",
    backgroundColor:"#F9F9F9",
    fontFamily:"Open Sans",
    fontWeight:600,
    fontSize:16
    },
    "& :focus":{
    height:"30px",
    backgroundColor:"#F9F9F9"
    },
    "& .css-1okebmr-indicatorSeparator": {
    background:"none"
    },
    "& .css-tlfecz-indicatorContainer": {
    color: "black"
    },
    "& .makeStyles-selectDropDown-169": {
    marginTop: 0
    }
  },

  inputCont: {
    width: "100%",
    height: "44px",
    background: "#F9F9F9",
    color: "#333333",
    borderRadius: "5px",
    border: "1px solid lightgray",
    fontFamily: "Open Sans",
    fontSize: "16px",
    paddingLeft: "10px",
    outline: "none"
  },
  tagStyle: {
    fontFamily: 'Open Sans',
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 14,
    textTransform: "uppercase",
    color: "#071A32",
    marginBottom: 0
  },
  button: {
    background: "#3971FF",
    margin: "0px 20px",
    marginTop:"30px",
    "&.MuiButton-containedSecondary": {
      width: "80%",
      height: 44,
      display: "flex",
      alingItems: "center",
      borderRadius: "5px !important",
      "&:hover": {
        background: "#3971FF"
      },
      "& .MuiButton-label": {
        fontFamily: "Open Sans",
        fontSize: 16,
        fontWeight: 700,
        textTransform: "capitalize",
        marginTop: 3
      },
      "& .MuiSvgIcon-root": {
        marginTop: -2
      }
    }
  },

  relativeText: {
    position: "absolute",
    top: "72.7%",
    left: "20%",
    fontSize: "18px"
  },
  relativeText2: {
    position: "absolute",
    top: "72.7%",
    right: "43%",
    fontSize: "18px"
  },

  LogoWrapper: {
    backgroundColor: "white",
    borderRadius: 50,
    padding: 18,
    border: "1px solid #FFFFFF",
    // boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.203851)",
    position: "absolute",
    top: -35
  },
  Logo: {
    width: 34,
    height: 32
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },

  MainContainer: {
    width: "100%",
    height: 44,
    background: "#FFFFFF",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.151907)",
    borderRadius: 2,
    margin: "-20px 0px 35px 0px"
  },
  ContainerInner: {
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    padding: "0 15px",
    position: "relative"
  },
  HeadingInner: {
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    padding: "0 15px",
    position: "relative"
  },
  ListItmes: {
    color: "#31394D",
    fontFamily: theme.fonts.family.Medium,
    fontSize: theme.fonts.sizes.fontM,
    fontWeight: 500
  },
  Heading: {
    color: "#31394D",
    fontFamily: theme.fonts.family.Light,
    fontSize: theme.fonts.sizes.fontM,
    fontWeight: 500
  },
  Heading1: {
    color: "#31394D",
    fontFamily: theme.fonts.family.Light,
    fontSize: theme.fonts.sizes.fontM,
    fontWeight: 500,
    position: "absolute",
    left: 0
  },
  

paper: {
  overflowY: "unset",
  margin:40
},

paper1: {
    overflowY: "initial",
    position: "relative",
  },
}))