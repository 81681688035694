import React, {useState,useEffect} from 'react';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {dialogStyles} from "./UserDataTableStyle";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "@material-ui/core";
import {ClientDetailAction} from "../../Store/Actions/ManageUserAction";
import { GetAllOfficers } from '../../Store/Actions/Officers';
import { GetAllServices } from '../../Store/Actions/Services';
import { GetAllCourt } from '../../Store/Actions/Court';
import axios from "./../../Store/request"
import CircularSpinner from "../CircularProgressUserReport/CircularSpinner";
import useAuth from "../../utils/useAuth";
import {useHistory} from "react-router-dom";

const ClientDetail = ({currentData,editDialogHandler,passedIndex}) => {

  const history = useHistory()
  const { isOperator } = useAuth()
  const userClasses = dialogStyles()
  const [dataFetching,setDataFetching] = useState(true)
  const { data: courtData, responseData: courtsData, count:courtDataLength, dataExist:courtsDataExist } = useSelector(({ court }) => court)
  const { data: paymentPlanData, responseData: paymentPlan } = useSelector(
    ({ paymentPlan }) => paymentPlan
  )
  const { data: officersData, responseData: officers, count: officersLength, dataExist: officerDataExist } = useSelector(({ officers }) => officers)
  const { data: servicesData, responseData: service, count: servicesLength, dataExist: servicesDataExist } = useSelector(({ services }) => services)


  const ShowCourt = courtId => {
    const foundData = courtData?.find(item => item.id === courtId)
    return foundData?.name
  }
  const ShowPaymentPlan = paymentId => {
    const foundData = paymentPlanData?.find(item => item.id === paymentId)
    return foundData?.name
  }
  const ShowParoleOfficer = officerId => {
    const foundData = officersData?.find(item => item.id === officerId)
    if(foundData===undefined)
    return null;
    else
    return foundData?.first_name+" "+foundData?.last_name
  }
  const ShowUserType = userType => {
    switch (userType) {
      case 1:
        return "Admin"
      case 2:
        return "Client"
      case 3:
        return "Court Admin"
      case 4:
        return "Operator"
      default:
        return ""
    }
  }
  const ShowServiceType = type => {
    switch (type) {
      case 1:
        return "Single Time"
      case 2:
        return "Recurring"
      default: 
        return "" 
    }
  }
 const [needService,setNeedService]=useState([])
  const ShowServices = (services) => {
    const foundData = needService?.find(item => item.id === services)
      return foundData?.name
    }
    const dispatch=useDispatch()
  const clientDetailHandler = () => {
    dispatch(ClientDetailAction(false))
  }
  useEffect(async()=>{
    var response
    if(currentData.user_type===2)
    {if(currentData.pay_plan_procedure===1)
    response=await axios.get(`api/v1/services/court_client_pay_price/?court=${currentData.court}&type=1`)
    if(currentData.pay_plan_procedure===2 || currentData.pay_plan_procedure===3)
    response=await axios.get(`api/v1/services/court_client_pay_price/?court=${currentData.court}&type=${currentData.pay_plan_procedure}`)
    if(response===undefined){
      setDataFetching(false)
      return
    }
    setNeedService(response.data)}
    setDataFetching(false)
  },[])


  useEffect(()=>{
    if(officersData.length===officersLength && officersData.length>0)return
    if(officerDataExist)
    dispatch(GetAllOfficers())
  },[officersData])
  useEffect(()=>{
    if(servicesData.length===servicesLength && servicesData.length>0)return
    if(servicesDataExist)
    dispatch(GetAllServices())
  },[servicesData])
  useEffect(()=>{
    if(courtData.length===courtDataLength && courtData.length>0)return
    if(courtsDataExist)
    dispatch(GetAllCourt())
  },[courtData])
  const openHsitoryPage = () => {
      localStorage.setItem('userId', currentData.id)
    history.push({
      pathname: '/balance',
      state: {
        data: currentData,
        courtData: courtData,
        paymentPlanData: paymentPlanData,
      },
    })
  }
  return (
    <>

      {dataFetching?<CircularSpinner/>:<>
        {currentData.user_type===2 &&
              <div className={userClasses.buttonCredit} style={{position:"relative"}}>
                <Button
                    onClick= {(e)=>{
                                    e.preventDefault()
                                    openHsitoryPage()}}
                  color="default"
                  variant="contained"
                  className={userClasses.creditBtn}
                  style={
                    currentData?.balance < 0
                      ? { color: "#C2143E" }
                      : { color: "#53B500" }
                  }
                >
                  CREDIT:{parseFloat(currentData?.balance).toFixed(2)}
                </Button>
              </div>
        }

        </>
       }

    <div className={userClasses.muiButton} style={{position:"relative"}}>

      {dataFetching?<CircularSpinner/>:<>
        <Button className={userClasses.buttonEdit}
        onClick={()=> {
          clientDetailHandler()
          editDialogHandler(currentData)
        }}
      >EDIT</Button>

      <form style={{marginTop:"-30px"}}>

        <Card classes={{ root: userClasses.root }}>
          <CardContent>
            <div className={userClasses.majorDiv}>
              <p className={userClasses.formHead}>{passedIndex[0].selected  && !isOperator?
                    <>
                    <strong>
                        Admin ID#
                    </strong>
                    </>:<strong>Client ID# </strong>
                    }
                </p>


              <div className={userClasses.flexer}>
                <span className={userClasses.HorizontalLine}></span>
                
                {passedIndex[0].selected &&
                <div className={userClasses.each}>
                  <span style={{ fontWeight: 600 }}>USER TYPE</span>
                  <input
                    type="text"
                    className={userClasses.selectCont}
                    value={ShowUserType(currentData.user_type)}
                    readOnly
                  />
                </div>
                }
  
                {currentData.user_type===2 || currentData.user_type===3 || currentData.user_type===4 ?
               
                <div className={userClasses.each}>
                  <span style={{ fontWeight: 600 }}>COURT SYSTEM</span>
                  <input
                    type="text"
                    className={userClasses.selectCont}
                    value={ShowCourt(currentData.court)}

                    readOnly
                  />
                </div>:null}
                {passedIndex[0].selected===false && <div className={userClasses.each}>
                  <span style={{ fontWeight: 600 }}>PAYMENT PLAN</span>
                  <input
                    type="text"
                    className={userClasses.selectCont}
                    value={ShowPaymentPlan(currentData.pay_plan_procedure)}

                    readOnly
                  />
                </div>}
                <div className={userClasses.each}>
                  <span style={{ fontWeight: 600 }}>FIRST NAME</span>
                  <input
                    type="text"
                    className={userClasses.selectCont}
                    value={currentData.first_name}
                    readOnly
                  />
                </div>
                <div className={userClasses.each}>
                  <span style={{ fontWeight: 600 }}>MIDDLE NAME</span>
                  <input
                    type="text"
                    className={userClasses.selectCont}
                    value={currentData.middle_name}
                    readOnly
                  />
                </div>
                <div className={userClasses.each}>
                  <span style={{ fontWeight: 600 }}>LAST NAME</span>
                  <input
                    type="text"
                    className={userClasses.selectCont}
                    value={currentData.last_name}
                    readOnly
                  />
                </div>
                <div className={userClasses.each}>
                  <span style={{ fontWeight: 600 }}>DATE OF BIRTH</span>
                  <input
                    type="text"
                    className={userClasses.selectCont}
                    value={currentData.date_of_birth}
                    readOnly
                  />
                </div>
                {passedIndex[0].selected===false && <div className={userClasses.each}>
                  <span style={{ fontWeight: 600 }}>PAROLE OFFICER</span>
                  <input
                    type="text"
                    className={userClasses.selectCont}
                    value={ShowParoleOfficer(currentData.parole_officer)}
                    readOnly
                  />
                </div>}
                <div className={userClasses.each}>
                  <span style={{ fontWeight: 600 }}>ADDRESS 1</span>
                  <input
                    type="text"
                    className={userClasses.selectCont}
                    value={currentData.address}
                    readOnly
                  />
                </div>
                <div className={userClasses.each}>
                  <span style={{ fontWeight: 600 }}>ADDRESS 2</span>
                  <input
                    type="text"
                    className={userClasses.selectCont}
                    value={currentData.address2}
                    readOnly
                  />
                </div>
                <div className={userClasses.each}>
                  <span style={{ fontWeight: 600 }}>CITY</span>
                  <input
                    type="text"
                    className={userClasses.selectCont}
                    value={currentData.city}
                    readOnly
                  />
                </div>
                <div className={userClasses.each}>
                  <span style={{ fontWeight: 600 }}>STATE</span>
                  <input
                    type="text"
                    className={userClasses.selectCont}
                    value={currentData.state?.name}
                    readOnly
                  />
                </div>
                <div className={userClasses.each}>
                  <span style={{ fontWeight: 600 }}>POSTAL CODE</span>
                  <input
                    type="text"
                    className={userClasses.selectCont}
                    value={currentData.postal_code}
                    readOnly
                  />
                </div>
                <div className={userClasses.each}>
                  <span style={{ fontWeight: 600 }}>OCCUPATION</span>
                  <input
                    type="text"
                    className={userClasses.selectCont}
                    value={currentData.occupation}
                    readOnly
                  />
                </div>
                {passedIndex[0].selected===false && <div className={userClasses.each}>
                  <span style={{ fontWeight: 600 }}>CASE NUMBER</span>
                  <input
                    type="text"
                    className={userClasses.selectCont}
                    value={currentData.case_number}
                    readOnly
                  />
                </div>}
                <div className={userClasses.each}>
                  <span style={{ fontWeight: 600 }}>TERRITORY</span>
                  <input
                    type="text"
                    className={userClasses.selectCont}
                    value={currentData.territory?.name}
                    readOnly
                  />
                </div>
                {passedIndex[0].selected===false && <div className={userClasses.each}>
                  <span style={{ fontWeight: 600 }}>JUDGE NAME</span>
                  <input
                    type="text"
                    className={userClasses.selectCont}
                    value={currentData.judge_name}
                    readOnly
                  />
                </div>}
                <div className={userClasses.each}>
                  <span style={{ fontWeight: 600 }}>EMERGENCY CONTACT</span>
                  <input
                    type="text"
                    className={userClasses.selectCont}
                    value={currentData.emergency_contact}
                    readOnly
                  />
                </div>
                <div className={userClasses.each}>
                  <span style={{ fontWeight: 600 }}>EMAIL ADDRESS</span>
                  <input
                    type="text"
                    className={userClasses.selectCont}
                    value={currentData.email}
                    readOnly
                  />
                </div>
                <div className={userClasses.each}>
                  <span style={{ fontWeight: 600 }}>HOME PHONE</span>
                  <input
                    type="text"
                    className={userClasses.selectCont}
                    value={currentData.home_phone}
                    readOnly
                  />
                </div>
                <div className={userClasses.each}>
                  <span style={{ fontWeight: 600 }}>CELL PHONE</span>
                  <input
                    type="text"
                    className={userClasses.selectCont}
                    value={currentData.phone}
                    readOnly
                  />
                </div>
                <div className={userClasses.each}>
                  <span style={{ fontWeight: 600 }}>ALTERNATIVE PHONE</span>
                  <input
                    type="text"
                    className={userClasses.selectCont}
                    value={currentData.alt_phone}
                    readOnly
                  />
                </div>
                {currentData.user_type===2 &&  <div className={userClasses.each}>
                  <span style={{ fontWeight: 600 }}>AMOUNT</span>
                  <input
                    type="text"
                    className={userClasses.selectCont}
                    value={currentData.total_price}
                    readOnly
                  />
                </div>}

                {currentData?.user_type===2 && currentData?.pay_plan_procedure===3?(
                  <>
                  <div className={userClasses.each}>
                  <span style={{ fontWeight: 600 }}>CLIENT PAY</span>
                  <input
                    type="text"
                    className={userClasses.selectCont}
                    value={currentData.user_amount}
                    readOnly
                  />
                </div>
                <div className={userClasses.each}>
                  <span style={{ fontWeight: 600 }}>COURT PAY</span>
                  <input
                    type="text"
                    className={userClasses.selectCont}
                    value={currentData.court_amount}
                    readOnly
                  />
                </div>

                </>
                ):null}
                {currentData.attachments?.length>0? <div className={userClasses.each}>
                  <span style={{ fontWeight: 600 }}>Recite Attachment</span>
                  <div className={userClasses.attachments}>
                  {currentData.attachments?.map((element,index)=>{

                    return(
                      <a href={element.file} className={userClasses.selectCont2} target="_blank" key={element.id}> Attachment-{index+1}</a>
                    )
                  })}
                  </div>
                </div>:<div className={userClasses.each}><span style={{ fontWeight: 600 }}>No Attachment to Show</span></div>}
                <div className={userClasses.each}></div>
              </div>
            </div>
          </CardContent>
        </Card>


        {currentData.user_type===2 && currentData.services?.length>0?currentData.services?.map((ele,i)=>{
          return (<Card classes={{ root: userClasses.root }} key={ele.id}>
            <CardContent>
              <div className={userClasses.majorDiv}>
                <div className={userClasses.headerOnForm}>
                  <h3 className={userClasses.formHead}>SERVICE DETAILS</h3>
                  {(needService?.find(ele=>{
                    return (ele.id===currentData.services[i]?.services.id || ele.id===currentData.services[i]?.services)}))?.one_time_fee===false?<div className={userClasses.formHead1}>
                    <h3 style={{ display: "inline", marginTop: "12px" }}>INSTALLATION</h3>
                      {ele.is_installed?(<span className={userClasses.headerSpan}>
                        <button className={[userClasses.installationBtn, userClasses.btnActive].join(' ')} disabled>Active</button>
                        <button className={userClasses.installationDeactiveBtn} disabled>Deactive</button>
                      </span>):(<span className={userClasses.headerSpan}>
                        <button className={userClasses.installationBtn} disabled>Active</button>
                        <button className={[userClasses.installationDeactiveBtn, userClasses.btnActive].join(' ')} disabled>Deactive</button>
                      </span>)}
                  </div>:null}
                </div>
                <div className={userClasses.flexer}>
                  <div className={userClasses.each} style={{ width: "30%" }}>
                    <span style={{ fontWeight: 600 }}>SERVICE NAME</span>
                    <input
                      type="text"
                      className={userClasses.selectCont}
                      value={ShowServices(ele.services?.id?ele.services.id:ele.services)}
                      readOnly
                    />
                  </div>
                  {
                    ele.install_date!==null?
                      <div className={userClasses.each} style={{ width: "20%" }}>
                        <span style={{ fontWeight: 600 }}>INSTALL DATE</span>
                        <input
                          type="text"
                          className={userClasses.selectCont}
                          value={ele.install_date?.split(" ")[0]}
                          readOnly
                        />
                      </div>
                      :null
                  }

                  <div className={userClasses.each} style={{ width: "20%" }}>
                    <span style={{ fontWeight: 600 }}>PROJECTED INSTALL DATE</span>
                    <input
                      type="text"
                      className={userClasses.selectCont}
                      value={ele.projected_install_date.split(" ")[0]}
                      readOnly
                    />
                  </div>
                  <div className={userClasses.each} style={{ width: "20%" }}>
                    <span style={{ fontWeight: 600 }}>PRICE</span>
                    <input
                      type="number"
                      className={userClasses.selectCont}
                      value={ele.price}
                      readOnly
                    />
                  </div>
                  {(needService?.find(ele=>{
                    return (ele.id===currentData.services[i]?.services.id || ele.id===currentData.services[i]?.services)}))?.one_time_fee===false?(<><div className={userClasses.each} style={{ width: "30%" }}>
                    <span style={{ fontWeight: 600 }}>END DATE</span>
                    <input
                      type="text"
                      className={userClasses.selectCont}
                      value={ele.end_date?.split(" ")[0]}
                      readOnly
                    />
                  </div>
                  <div className={userClasses.each} style={{ width: "20%" }}>
                    <span style={{ fontWeight: 600 }}>TOTAL PRICE</span>
                    <input
                      type="number"
                      className={userClasses.selectCont}
                      value={ele.total_price}
                      readOnly
                    />
                  </div>
                  </>):null}
                  <div className={userClasses.each} style={{ width: "20%" }}></div>
                  <div className={userClasses.each} style={{ width: "20%" }}></div>
                </div>
              </div>
            </CardContent>
          </Card>)
        }):null}

      </form></>}
    </div>
    </>
  );
};

export default ClientDetail;