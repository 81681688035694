import { makeStyles } from "@material-ui/core/styles";

export const newStyles = makeStyles((theme) => ({

  topDiv: {
    height: '500px',
    width: '100%',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'center',
    background: 'none',
    alignItems: 'center',
    boxShadow: 'none'
  }
  ,
  upperDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '450px',
    width: '100%',
    alignItems: 'center',
    background: 'white'

  },

  firstDiv: {
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'space-around',
    width: '80%',
  },

  selectCont: {
    width: '100%',
    height: '38px',
    background: 'rgb(244, 244, 244)',
    color: 'gray',
    borderRadius: '5px',
    paddingLeft: '5px',
    border: '1px solid lightgray',
    fontFamily: theme.fonts.family.Light,
    fontSize: '16px',
    paddingLeft: '10px'
  },

  bottomCont: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '80%'
  },

  bottomContLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '45%'
  },

  bottomContRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '45%'
  },

  inputCont: {
    width: '100%',
    height: '38px',
    background: 'rgb(244, 244, 244)',
    color: 'gray',
    borderRadius: '5px',
    border: '1px solid lightgray',
    fontFamily: theme.fonts.family.Light,
    fontSize: '16px',
    paddingLeft: '25px'
  },

  button: {
    background: "#3971FF",
    margin: "0px 20px",
    "&.MuiButton-containedSecondary": {
      width: '80%',
      height: 38,
      display: "flex",
      alingItems: "center",
      borderRadius: "5px !important",
      "&:hover": {
        background: "#3971FF",
      },
      "& .MuiButton-label": {
        fontFamily: theme.fonts.family.SemiBold,
        fontSize: 15,
        fontWeight: 400,
        textTransform: "capitalize",
        marginTop: 3,
      },
      "& .MuiSvgIcon-root": {
        marginTop: -2
      }
    }
  },

  relativeText: {
    position: 'absolute',
    top: '72.7%',
    left: '20%',
    fontSize: '18px'
  },
  relativeText2: {
    position: 'absolute',
    top: '72.7%',
    right: '43%',
    fontSize: '18px'
  },

  LogoWrapper: {
    backgroundColor: "white",
    borderRadius: 50,
    padding: 10,
    border: "1px solid #FFFFFF",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.203851)",
    position: "absolute",
    top: 10,
  },
  Logo: {
    width: 34,
    height: 32,
  },

  dragCont: {
    border: '1px dashed #3971FF',
    borderRadius: '5px',
    width: '80%',
    height: '120px',
    background: '#F8FAFF',
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    alignItems:'center'
  },

  docHolder: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    width: '85%'
  },

  eachDoc: {
    border: '1px solid #C7C7C7',
    borderRadius: '80px',
    width: '180px',
    height: '32px',
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-around',
    alignItems:'center'
  },

  bottomCont: {
    width:'80%',
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-around',
    alignItems:'center',
    flexWrap:'wrap'
  },

  bottomleft: {
    width:'300px',
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-around',
    height:'100px',
    
  },
  bottomRight: {
    width:'300px',
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-around',
    height:'100px',
    
  }
}))

export const useStyles = makeStyles((theme) => ({
  MainContainer: {
    width: "100%",
    height: 97,
    background: "#FFFFFF",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around'
  },
  HeaderInner: {
    padding: "2px 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },

  IconWrapper: {
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#81A4FF",
    margin: "0px 20px",
    width: 47,
    height: 44,
  },
  icon: {
    width: 24,
    height: 24
  },
  MainHeading: {
    color: "#363E51",
    fontFamily: theme.fonts.family.Medium,
    fontSize: theme.fonts.sizes.fontXL,
    fontWeight: 500,
    textTransform: "uppercase"
  },
  Wrapper: {
    display: "flex",
    alignItems: "center",
  },
  LogoWrapper: {
    backgroundColor: "white",
    borderRadius: 50,
    padding: 10,
    border: "1px solid #FFFFFF",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.203851)",
    position: "absolute",
    top: -25,
  },
  Logo: {
    width: 34,
    height: 32,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
    borderRadius: 5,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: "100%"
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },

  button: {
    background: "#3971FF",
    margin: "0px 20px",
    "&.MuiButton-containedSecondary": {
      width: 150,
      height: 30,
      display: "flex",
      alingItems: "center",
      borderRadius: "5px !important",
      "&:hover": {
        background: "#3971FF",
      },
      "& .MuiButton-label": {
        fontFamily: theme.fonts.family.SemiBold,
        fontSize: 10,
        fontWeight: "bold",
        textTransform: "capitalize",
        marginTop: 3,
      },
      "& .MuiSvgIcon-root": {
        marginTop: -2
      },
      "&.MuiButton-root": {
        lineHeight: "0 !important",
        letterSpacing: 0
      }
    }
  }
}));