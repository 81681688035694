  import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent:"center",

  },
  topDiv: {
    height: "540px",
    width: "400px",
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    background: "none",
    alignItems: "center",
    boxShadow: "none",
    marginTop: "25px"
  },
  upperDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    height: "500px",
    width: "400px",
    alignItems: "center",
    background: "white"
  },

  firstDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "80%",
    
  },
  firstDivCourt: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "80%",
    fontFamily:"Open Sans",
    fontWeight:600,
    fontSize:16,
    "& .css-1qauysc-control":{
      height:"44px",
      backgroundColor:"#F9F9F9",
      fontFamily:"Open Sans",
      fontWeight:600,
      fontSize:16
    },
    "& :focus":{
      height:"30px",
      backgroundColor:"#F9F9F9"
    },
    "& .css-1okebmr-indicatorSeparator": {
    background:"none"
    },
    "& .css-tlfecz-indicatorContainer": {
    color: "black"
    },
    "& .makeStyles-selectDropDown-269": {
      marginTop: 0
    }
  },

  selectCont: {
    width: "100%",
    height: "45px",
    background: "#F9F9F9",
    color: "#333333",
    borderRadius: "5px",
    paddingLeft: "10px",
    border: "1px solid lightgray",
    fontFamily: "Open Sans",
    fontSize: "18px",
    outline:"none",
    // paddingLeft: "10px"
    '&::placeholder': {
      color: "#BCBCBC",
    },
  },

  bottomCont: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "80%"
  },

  bottomContLeft: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "45%"
  },

  bottomContRight: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "45%",
    fontFamily:"Open Sans",
    fontWeight:600,
    fontSize:16,
    "& .css-1qauysc-control":{
    height:"44px",
    backgroundColor:"#F9F9F9",
    fontFamily:"Open Sans",
    fontWeight:600,
    fontSize:16
    },
    "& :focus":{
    height:"30px",
    backgroundColor:"#F9F9F9"
    },
    "& .css-1okebmr-indicatorSeparator": {
    background:"none"
    },
    "& .css-tlfecz-indicatorContainer": {
    color: "black"
    },
    "& .makeStyles-selectDropDown-269": {
    marginTop: 0
    }
  },

  inputCont: {
    width: "100%",
    height: "44px",
    background: "#F9F9F9",
    color: "#333333",
    borderRadius: "5px",
    border: "1px solid lightgray",
    fontFamily: "Open Sans",
    fontSize: "16px",
    paddingLeft: "10px",
    outline:"none"
  },

  button: {
    background: "#3971FF",
    margin: "0px 20px",
    marginTop:"10px",
    "&.MuiButton-containedSecondary": {
      width: "80%",
      height: 44,
      display: "flex",
      alingItems: "center",
      borderRadius: "5px !important",
      // marginTop: "15px",
      "&:hover": {
        background: "#3971FF"
      },
      "& .MuiButton-label": {
        fontFamily: "Open Sans",
        fontSize: 16,
        fontWeight: 700,
        textTransform: "capitalize",
        marginTop: 3
      },
      "& .MuiSvgIcon-root": {
        marginTop: -2
      }
    }
  },

  relativeText: {
    position: "absolute",
    top: "72.7%",
    left: "20%",
    fontSize: "18px"
  },
  relativeText2: {
    position: "absolute",
    top: "72.7%",
    right: "43%",
    fontSize: "18px"
  },

  LogoWrapper: {
    backgroundColor: "white",
    borderRadius: 50,
    padding: 18,
    border: "1px solid #FFFFFF",
    position: "absolute",
    top: -35
  },
  Logo: {
    width: 34,
    height: 32
  },
  paper: {
    overflowY: "initial",
    margin:40
  },
  heading:{
    fontFamily: 'Open Sans',
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 14,
    textTransform: "uppercase",
    color: "#071A32",
    marginBottom: 0
},
}))
