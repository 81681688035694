import React, { useState } from "react"
import { useStyles } from "./AddOfficersDialogStyle"
import { Dialog } from "@material-ui/core"
import Logo from "../../assets/AMSLogo.png"
import { Button } from "@material-ui/core"
import axios from "../../Store/request"
import useToast from "../../Components/Toast/useToast"
import { useDispatch } from "react-redux"
import CourtDropDownData from "../CourtDropDownData/CourtDropDownData"
import { addOfficersData, GetAllOfficers } from "../../Store/Actions/Officers"
import CircularSpinner from "../CircularProgressUserReport/CircularSpinner";
import SearchableDropDown from "../SearchableDropdown/SearchableDropDown"
import ActiveCourtDropDownData from "../CourtDropDownData/ActiveCourtDropDownData";

const AddOfficerDialog = ({ openModal, closeModal, data, isDisabled, setIsDisabled, officerData }) => {
  const classes = useStyles()
  const { showToast } = useToast()
  const dispatch = useDispatch()
  const [creditLoading, setCreditLoading] = useState(false)
  const [boolenOption] = useState([
    {value:true,label:"Yes"},
    {value:false,label:"No"}
])
  const [addOfficer, setAddOfficer] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    court: "",
    receive_statement: ""
  })

  const inputEvent = (event) => {
    const { name, value } = event.target;
    setAddOfficer((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      }
    })
  }

  const normalizeInput = (value, previousValue) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {

      if (cvLength < 4) return currentValue;

      if (cvLength < 7) return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;

      return `${currentValue.slice(0, 3)}-${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    }
  };

  const handlePhNoChange = (event) => {
    const { name, value } = event.target;
    setAddOfficer(prevState => ({ ...prevState, [name]: normalizeInput(value, prevState[name]) }));
  };
  const [officerErrror, setOfficerError] = useState("")
  let existsCourt = []
  officerData?.forEach((element) => {
    if (element.court.id === addOfficer.court) {
      existsCourt.push(element)
    }
  })

  const addOfficerFunc = async e => {
    e.preventDefault()
    e.stopPropagation()
    let boolConverter = addOfficer.receive_statement === true
    let finalObj = { ...addOfficer, receive_statement: boolConverter }
    let addData = JSON.stringify(finalObj)
    setIsDisabled(true)
    setCreditLoading(true)
    const response = await axios.post(
      "/api/v1/courts/probation/officer/",
      addData
    ).then(response => {
      dispatch(addOfficersData(response.data))
        closeModal()
        showToast("Officer Added Successfully", "success")
        dispatch(GetAllOfficers())
        setAddOfficer({
          first_name: "",
          last_name: "",
          email: "",
          phone_number: "",
          court: "",
          receive_statement: ""

        })

        setIsDisabled(false)
        setCreditLoading(false)
        setOfficerError("")
    }).catch(error => {
      setIsDisabled(false)
      setCreditLoading(false)
      setOfficerError("")
      showToast("Officer Already Exist in this court with same name","error")
    })

    
  }
  return (
    <div>
      <Dialog
        open={openModal}
        scroll="body"
        onClose={() => {
          closeModal()
          setAddOfficer({
            first_name: "",
            last_name: "",
            email: "",
            phone_number: "",
            court: "",
            receive_statement: ""

          })
          setOfficerError("")
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        // overlaystyle={{ backgroundColor: "transparent" }}
        classes={{ paper: classes.paper }}
      >
        {creditLoading === true ? (
          <CircularSpinner onModal/>
        )
          : (
            <div className={classes.topDiv}>
              <div className={classes.LogoWrapper}>
                <img src={Logo} className={classes.Logo} alt="Logo"/>
              </div>
              <form onSubmit={addOfficerFunc} className={classes.upperDiv}>
                <div style={{ cursor: "pointer" }} onClick={() => {
                  closeModal()
                  setAddOfficer({
                    first_name: "",
                    last_name: "",
                    email: "",
                    phone_number: "",
                    court: "",
                    receive_statement: ""

                  })
                  setOfficerError("")
                }}>
                  <i
                    className="fal fa-times fa-lg"
                    style={{ position: "absolute", top: "3%", right: "4%" }}
                  > </i>
                </div>

                <div className={classes.firstDiv}>
                  <span className={classes.heading}>OFFICER FIRST NAME</span>
                  <input
                    type="text"
                    name="first_name"
                    placeholder="Type..."
                    className={classes.selectCont}
                    value={addOfficer.first_name}
                    onChange={inputEvent}
                    required
                  />
                  <span style={{ color: "red" }}>{officerErrror}</span>
                </div>

                <div className={classes.firstDiv}>
                  <span className={classes.heading}>OFFICER LAST NAME</span>
                  <input
                    type="text"
                    name="last_name"
                    placeholder="Type..."
                    className={classes.selectCont}
                    value={addOfficer.last_name}
                    onChange={inputEvent}
                    required
                  />
                </div>

                <div className={classes.firstDivCourt}>
                  <span className={classes.heading}>COURT NAME</span>

                  <ActiveCourtDropDownData
                    handleSelect={val =>

                      setAddOfficer({ ...addOfficer, court: val })
                    }
                    selectedValue={addOfficer.court}
                  />
                </div>

                <div className={classes.firstDiv}>
                  <span className={classes.heading}>EMAIL ADDRESS</span>
                  <input
                    type="email"
                    name="email"
                    placeholder="Type..."
                    className={classes.selectCont}
                    required
                    value={addOfficer.email}
                    onChange={inputEvent}

                  />
                </div>
                <div className={classes.bottomCont}>
                  <div className={classes.bottomContLeft}>
                    <span className={classes.heading}>PHONE NUMBER</span>
                    <input
                      type="text"
                      name="phone_number"
                      placeholder="XXX-XXX-XXXX"
                      className={classes.inputCont}
                      required
                      value={addOfficer.phone_number}
                      onChange={handlePhNoChange}
                    />
                  </div>
                  <div className={classes.bottomContRight}>
                    <span className={classes.heading}>STATEMENT</span>
                    <SearchableDropDown
                      data={boolenOption}
                      handleClick={val =>{
                        setAddOfficer({ ...addOfficer, receive_statement: val })}
                      }
                      selectedValue={addOfficer.receive_statement}
                      />
                  </div>
                </div>
                {isDisabled === false ?
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    disabled={
                      addOfficer.name === "" ||
                      addOfficer.receive_statement === "" ||
                      addOfficer.email === "" ||
                      addOfficer.court === "" ||
                      addOfficer.phone_number === ""
                    }
                    type="submit"
                  >
                    {"ADD OFFICER"}
                  </Button>

                  : <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    disabled
                    type="submit"
                  >
                    {"ADD OFFICER"}
                  </Button>


                }

              </form>
            </div>
          )
        }

      </Dialog>
    </div>
  )
}

export default AddOfficerDialog
