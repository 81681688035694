import React, { useEffect, useState } from 'react';
import CloseIcon from "../../assets/CloseIcon.png";
import {useStyles} from "./ReportViewsStyle";
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import moment from 'moment'

const ClientPayCallView = ({onClose,today,DataArray}) => {

  const classes = useStyles()
    const TextClipper = (bio, length) => {
      let vb = ""

      if (bio?.length >= length) {
        for (var i = 0; i < length; i++) {
          vb += bio.charAt(i)
        }
        bio = `${vb}...`
      }

      return bio
    }
  const convertDateFormat = (date)=>{
    return moment(date).format('MM-DD-YYYY')
  }
  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "CLIENT PAY CALL LIST REPORT";
    const headers = [["Client First Name", "Client Last Name","Cell Phone","Home Phone","Daily Recurring Amount","Wallet amount","Probation officer First Name","Probation officer Last Name","Probation officer Phone Number"]];
    const data = DataArray.data.map(elt=> [elt?.first_name, elt?.last_name,elt?.cell_phone,elt?.home_name,`$ ${elt?.recurring_amount}`,`$ ${elt?.balance}`,elt?.po_first_name,elt?.po_last_name,elt?.po_phone_number]);

    const marginLeft = 40;
    let marginTop = 40;

    doc.text(title, marginLeft, marginTop);
    marginTop+=20
    doc.text(`Run date: ${convertDateFormat(DataArray.data[0]?.run_on)}`,marginLeft,marginTop)
    if(DataArray.data[0]?.from_date){
      doc.text(`From date: ${convertDateFormat(DataArray.data[0]?.from_date)}`,marginLeft,marginTop+20)
      doc.text(`To date: ${convertDateFormat(DataArray.data[0]?.to_date)}`,marginLeft,marginTop+40)
    }

    let content = {
      startY: marginTop+60,
      head: headers,
      body: data,
      theme:"plain"
    };
    doc.autoTable(content);
    doc.save("client_pay_call_list_report.pdf")
  }

  return (
    <> 
      <button onClick={exportPDF} style={{border:"1px solid black",height:"20px",width:"80px",backgroundColor:"#D8D8D8",color:"black",marginLeft:"20px",color:"blue",cursor:"pointer"}}>Export Pdf</button>
      <div className={classes.aligner}>
        <div className={classes.closeIconDiv}>
          <img src={CloseIcon} onClick={onClose} style={{ cursor: 'pointer',paddingRight:"10px",marginTop:"-10px"}} height="15px" />
        </div>
        <div className={classes.firstContLayer} style={{padding:"0px 25px"}}>
          <p>CLIENT PAY CALL LIST REPORT</p>
          {
            DataArray.data.length > 0 ?<p>{`Run Date: ${convertDateFormat(DataArray.data[0].run_on)}`}</p>:""
          }
        </div>
        <div className={classes.firstContLayer} style={{padding:"0px 25px"}}>
          {
            DataArray.data.length > 0 && DataArray.data[0].from_date  ?<p>{`From Date: ${convertDateFormat(DataArray.data[0].from_date)}`}</p>:""
          }
          {
            DataArray.data.length > 0 && DataArray.data[0].to_date ?<p>{`To Date: ${convertDateFormat(DataArray.data[0].to_date)}`}</p>:""
          }
        </div>
        <div className={classes.tableDiv} id="table-div">
        {DataArray.data.length > 0 ?<table className="table table-striped" style={{ width: '100%', height: 'auto', minHeight: '80px',borderCollapse:"collapse" }}>
            <thead className={classes.stickyHeader}>
            <tr>
              <th scope="col" className={classes.firstColumn}>Client First Name</th>
              <th scope="col" className={classes.firstColumn}>Client Last Name</th>
              <th scope="col" className={classes.firstColumn}>Cell Phone</th>
              <th scope="col" className={classes.firstColumn}>Home Phone</th>
              <th scope="col" className={classes.firstColumn}>Daily Recurring Amount</th>
              <th scope="col" className={classes.firstColumn}>Wallet amount</th>
              <th scope="col" className={classes.firstColumn}>Probation officer First Name</th>
              <th scope="col" className={classes.firstColumn}>Probation officer Last Name</th>
              <th scope="col" className={classes.firstColumn}>Probation officer Phone Number</th>
            </tr>
            </thead>  
            <tbody style={{ width: '100%',fontFamily:"Open Sans",fontWeight:600  }}>
            {
              DataArray.data.map((object,idx)=>{
                  return <tr className={idx%2===0?classes.styleRowWithBackground:classes.styleRow} key={idx}>
                  <td className={classes.firstColumn}>{TextClipper(object?.first_name,15)}</td>
                  <td className={classes.firstColumn}>{TextClipper(object?.last_name,15)}</td>
                  <td className={classes.firstColumn}>{TextClipper(object?.cell_phone,15)}</td>
                  <td className={classes.firstColumn}>{TextClipper(object?.home_phone,15)}</td>
                  <td className={classes.firstColumn}>$ {TextClipper(object?.recurring_amount,15)}</td>
                  <td className={classes.firstColumn}>$ {TextClipper(object?.balance,15)}</td>
                  <td className={classes.firstColumn}>{TextClipper(object?.po_first_name,15)}</td>
                  <td className={classes.firstColumn}>{TextClipper(object?.po_last_name,15)}</td>
                  <td className={classes.firstColumn}>{TextClipper(object?.po_phone_number,15)}</td>
                </tr>
              })
            }
            </tbody>
          </table>:<p className={classes.error}>No Data Found</p>}
        </div>
      </div>
    </>
  );
};

export default ClientPayCallView;