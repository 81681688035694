import React, { useEffect, useContext, useState } from "react"
import Container from "@material-ui/core/Container"
import UsersHeader from "../../Components/UsersHeader/UsersHeader"
import UsersIcon from "../../assets/UsersIcon.png"
import UserDataTable from "../../Components/UserDataTable/UserDataTable"
import { useHistory, useParams, useLocation } from "react-router"
import { connect, useDispatch, useSelector } from "react-redux"
import { auth } from "../../Store/Actions/Auth"
import { useStyles } from "./UserManagementStyle"
import { ScreenContext } from "./../../context/context"
import { BtnStyles } from "../FileStorageManagement/FileStorageManagementStyle"
import { Button } from "@material-ui/core"
import useAuth from "../../utils/useAuth"
import {
  ManageUserAction,
  ClientDetailAction,
  EditUserAction,
  SetCurrentPage
} from "../../Store/Actions/ManageUserAction"
import axios from "../../Store/request";
import SliderIcon from "../../assets/Slider.png";
import UserDataFilter from "./UserDataFilter";

const UserManagement = (props,{}) => {
  const classes = useStyles()
  const btnclasses = BtnStyles()
  const location = useLocation()

  const { screenSize, setScreenSize } = useContext(ScreenContext)
  const { filterdata,filterCount } = useSelector(({ manageUser }) => manageUser )
  const { current_user_type } = useSelector(({ manageUser }) => manageUser )
  const history = useHistory()
  const { param } = useParams()
  const dispatch = useDispatch()
  const { addUser: isAddUser, clientDetail,editUser,data,loading } = useSelector(
    ({ manageUser }) => manageUser
  )
  const { isOperator } = useAuth()
  const [filterDataCount,setFilterDataCount]=useState()
  useEffect(() => {
    if (
      props.accessToken === null ||
      props.accessToken === "" ||
      props.accessToken === undefined
    ) {
      history.push("/")
    }
  }, [])
  useEffect(() => {
    if(location.pathname.includes("clients")){
      setTabs([{
        index: 1,
        name: "ADMINS",
        selected: false
      },
        {
          index: 4,
          name: "CLIENTS",
          selected: true
        }])
    }
  },[])

  const [tabs, setTabs] = useState([
    {
      index: 1,
      name: "ADMINS",
      selected: true
    },
    {
      index: 4,
      name: "CLIENTS",
      selected: false
    }
  ])

  useEffect(() => {
    if (isOperator) {
      setTabs([
        {
          index: 1,
          name: "ADMINS",
          selected: false
        },
        {
          index: 2,
          name: "OPERATORS",
          selected: true
        },
        {
          index: 4,
          name: "CLIENTS",
          selected: false
        }
      ])
    }
  }, [isOperator])
  const selectChange = index => {
    //filter the selected object to update its selected attribute
    const elementsIndex = tabs.findIndex(element => element.index == index)
    let newArray = [...tabs]

    newArray[elementsIndex] = { ...newArray[elementsIndex], selected: true }
    
    let updatedArray = newArray

    let latestArray = [...updatedArray]
    for (let i = 0; i < latestArray.length; i++) {
      if (i !== elementsIndex) {
        latestArray[i] = { ...latestArray[i], selected: false }
      }
    }

    let finalArray = latestArray
    setTabs(finalArray)
    setFilterPage(false)
    setInitialFilter({
      date_added:"",
      active:"",
      date_removed:"",
      balance_owed:"",
    })
    // setFilterTxt('')


   
    if(index===1){
      dispatch(SetCurrentPage(1))
      history.replace('/manage-users/admins/page=1')
    }
    else
    {
      dispatch(SetCurrentPage(1))
      history.push('/manage-users/clients/page=1')
    }
  }
  const addUserHandler = () => {
    dispatch(ManageUserAction(false))
  }
  const clientDetailHandler = () => {
    dispatch(ClientDetailAction(false))
  }
  const editUserHandler = () => {
    dispatch(EditUserAction(false))
  }

  // searching data:
  const [filterTxt, setFilterTxt] = useState('');
  var filteredData = []
  const [searchData,setSearchData]=useState()
  const [searchCount,setSearchCount]=useState()
  const filterData = async(e) => {
    let val = e.target.value
    setFilterTxt(val)
    const response=await axios.get(`manage-users/search_user/?search_query=${val}`)
    setSearchData(response.data)
  }
  filteredData = searchData?.results?.filter(element => {
    return (element.username.toLowerCase().includes(filterTxt?.toLowerCase()))
  })
  const defaultInitialFilter = {
    date_added:"",
    active:"",
    date_removed:"",
    balance_owed:"",
  }
  const [isFilterCont, setIsFilterCont] = useState(false)
  const [filterPage,setFilterPage]=useState(false)
  const [initialFilter,setInitialFilter]=useState(defaultInitialFilter)
  return (
    <div className={classes.MainContainer}>
      <UsersHeader
        MainHeading="CLIENTS MANAGEMENT"
        lable={!isOperator? tabs[0].selected?"+ Add Admin":"+ Add Client":"+ Add Client"}
        img={UsersIcon}
        searchText={filterData}
        passedIndex={tabs}
      />
      <Container
        maxWidth="lg"
        className={screenSize > 1400 ? classes.container : classes.containerMob}
      >
        {
          !isFilterCont?
            (
              <>
                <div className={btnclasses.BtnContainer}>
                  {isAddUser ? (
                    <div>
                      <Button
                        variant="contained"
                        // color="secondary"
                        className={classes.button}
                        onClick={addUserHandler}
                      >
                        Back
                      </Button>
                      <span className={classes.heading}>{!isOperator && tabs[0].selected?"Add Admin":"Add Client"}</span>
                    </div>
                  ) : clientDetail ? (
                    <div>
                      <Button
                        variant="contained"
                        // color="secondary"
                        className={classes.button}
                        onClick={clientDetailHandler}
                      >
                        Back
                      </Button>
                      <span className={classes.heading}>{!isOperator && tabs[0].selected?"Admin Details":"Client Details"}</span>
                    </div>
                  ) :editUser ? (
                    <div>
                      <Button
                        variant="contained"
                        // color="secondary"
                        className={classes.button}
                        onClick={editUserHandler}
                      >
                        Back
                      </Button>
                      <span className={classes.heading}>{tabs[0].selected?"Admin Details":"Client Details"}</span>
                    </div>
    
                  ) : (
                      !isOperator && tabs.map((tab, i) => {
                          return (
                            <Button
                              key={tab.index}
                              disabled={loading}
                              onClick={
                                selectChange.bind(this, tab.index)
                              }
                              variant="contained"
                              color={tab.name.toLowerCase() === param?  "default" : "secondary"}
                              className={
                                tab.selected
                                  ? btnclasses.InvoiceButton
                                  : btnclasses.PrepaidButton
                              }
                            >
                              {tab.name}
                            </Button>
                          )
                        })
                  )
                  }
                  {
                    current_user_type===2 && !isAddUser && !clientDetail && !editUser &&
                    <div className={classes.filterFix}>
                      <div className={isOperator?classes.filterdivOperator:classes.filterdiv} onClick={() => {
                        setIsFilterCont(true)
                      }}>
                        <p>Filters</p>
                        <img src={SliderIcon} style={{ height: '16px', width: '16px' }} />
                      </div>
                    </div>
                  }
                
  
                </div>
                {filterTxt===""?
                  JSON.stringify(initialFilter) === JSON.stringify(defaultInitialFilter)?
                    (
                      <UserDataTable passedIndex={tabs} filterTxt={filterTxt} filteredData={data}  searchCount={searchData?.count} setFilterTxt={setFilterTxt}/>)
                    :(<UserDataTable passedIndex={tabs} filterTxt={filterTxt} filteredData={filterdata}
                                     searchCount={searchData?.count} filterPage={filterPage} initialFilter={initialFilter}
                                     setFilterTxt={setFilterTxt}
                    />)
                    
                 
                  : <UserDataTable passedIndex={tabs} filterTxt={filterTxt} filteredData={searchData?.results} searchCount={searchData?.count} setFilterTxt={setFilterTxt}/>}
              </>
            )
            :
            (
              <UserDataFilter
                close={() => setIsFilterCont(false)}
                filterData={filterData}
                setIsFilterCont={setIsFilterCont}
                setFilterDataCount={setFilterDataCount}
                setFilterPage={setFilterPage}
                initialFilter={initialFilter}
                setInitialFilter={setInitialFilter}
              />
            )
        }
        
        {/*<UserDataTable passedIndex={tabs} filterTxt={filterTxt} filteredData={filteredData}/>*/}

        {/* {!isAddUser && !clientDetail && <Pagination />} */}
      </Container>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    accessToken: state.auth.accessToken
  }
}

export default connect(mapStateToProps, { auth })(UserManagement)
