import React from "react";
import TextField from '@material-ui/core/TextField';

import { useStyles } from "./InputStyle";

const Input = ({ label, type, onChange, isRequired }) => {
  const classes = useStyles();
  return (
    <div className={classes.InputContainer}>
      <TextField
        id="standard-basic"
        label={label}
        className={classes.Input}
        type={type} onChange={onChange} 
        required={isRequired ? true:false}
        />
    </div>
  )
}

Input.defaultProps = {
  isRequired: false
}
export default Input