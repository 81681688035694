
import React, { useState, useEffect, useContext } from "react";
import AddButton from "../Buttons/AddButton";
import UploadCsvBtn from "../Buttons/UploadCsvBtn";
import { useStyles } from "./ReportsHeaderStyle";
import { finalStyles } from "./../CourtsHeader/CourtsHeaderStyle"
import { ScreenContext } from "./../../context/context"
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { barStyles } from "../SearchBar/SearchBarStyle"
import ClientRemovalReport from "./ClientRemovalReport";
import OpenBalaceReport from "./OpenBalanceReport";
import ClientPayCallReport from "./ClientPayCallReport";
import SalesByServiceReport from "./SalesByServiceReport";

const ReportsHeader = ({ searchText,MainHeading, lable, img,reportLocation,currentPage,setCurrentPage,result,setResult }) => {
    const classes = useStyles()
    const barclasses = barStyles();
    const finalclasses = finalStyles()

    const { screenSize, setScreenSize } = useContext(ScreenContext)
    // const { reportLocation, setReportLocation } = useContext(ReportContext)
    const [addOpenBalanceModal, setAddOpenBalanceModal] = useState(false)
    const [addClientPayCallModal, setAddClientPayCallModal] = useState(false)
    const [addClientRemovalModal, setAddClientRemovalModal] = useState(false)
    const [addSalesByServiceReportModal, setAddSalesByServiceReportModal] = useState(false)
    
    useEffect(() => {
        setScreenSize(window.innerWidth)
    }, [screenSize])

    const [search,setSearch]=useState(false);
    const searchBtn =(e)=>{
        setSearch(!search);
    }
    const componentHeading=()=>{
        switch(reportLocation){
            case 1:
                return (
                  <div onClick={() => setAddOpenBalanceModal(true)}>
                      <AddButton lable={`${lable}`} />
                  </div>
                )
            case 2:
                return (
                  <div onClick={() => setAddClientPayCallModal(true)}>
                      <AddButton lable={lable} />
                  </div>
                )
            case 3:
                return (
                  <div onClick={() => setAddClientRemovalModal(true)}>
                      <AddButton lable={lable} />
                  </div>
                )
                case 4:
                    return (
                        <div onClick={() => setAddSalesByServiceReportModal(true)}>
                            <AddButton lable={lable} />
                        </div>
                    )
            default:
                return(<></>)
        }
    }
    const headerProps = { result, setResult, currentPage, setCurrentPage }
    return (
        <div
            className={
                screenSize > 800 ? classes.MainContainer : finalclasses.MainCont
            }
        >
            {screenSize > 800 ? (
                <div className={classes.HeaderInner}>
                    <div className={classes.Wrapper}>
                        <div className={classes.IconWrapper}>
                            <img src={img} className={classes.icon} alt="" />
                        </div>
                        <p className={classes.MainHeading}>{MainHeading}</p>
                        {
                            componentHeading()
                        }


                    </div>
                    <div className={barclasses.SearchContainer}>
                        {search===false?
                          <div className={barclasses.search1}>
                              <SearchIcon  onClick={searchBtn}  style={{ backgroundColor:"none"}}/>
                          </div>:
                          <div className={barclasses.search}>

                              <div className={barclasses.searchIcon} >
                                  <SearchIcon />

                              </div>
                              <InputBase
                                placeholder="Search…"
                                classes={{
                                    root: barclasses.inputRoot,
                                    input: barclasses.inputInput
                                }}
                                inputProps={{ "aria-label": "search" }}
                                onChange={searchText}
                                autoFocus
                              />

                          </div>
                        }

                    </div>
                </div>
            ) : (
                <div className={finalclasses.topMain}>
                    <div className={finalclasses.upperMain}>
                        <div className={finalclasses.flex}>
                            <div className={classes.IconWrapper} style={{ width: '57px' }}>
                                <img src={img} className={classes.icon} alt="" />
                            </div>
                            <p className={classes.MainHeading} style={{ fontSize: "12px" }}>
                                Reports Management
                            </p>
                        </div>
                        <div className={barclasses.SearchContainer}>
                            <div className={barclasses.search}>
                                <div className={barclasses.searchIcon}>
                                    <SearchIcon />
                                </div>
                                <InputBase
                                    placeholder="Search…"
                                    classes={{
                                        root: barclasses.inputRoot,
                                        input: barclasses.inputInput
                                    }}
                                    onChange={searchText}
                                    inputProps={{ "aria-label": "search" }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={finalclasses.bottomCont}>
                        {
                            componentHeading()
                        }

                        <div>
                            <UploadCsvBtn />
                        </div>

                    </div>
                </div>
            )}

            {/*Open client list */}
            <OpenBalaceReport 
            addOpenBalanceModal={addOpenBalanceModal}
            setAddOpenBalanceModal={setAddOpenBalanceModal}
            {...headerProps}
            />

            {/*Client Pay Call Create Dialog */}
            <ClientPayCallReport
            addClientPayCallModal={addClientPayCallModal}
            setAddClientPayCallModal={setAddClientPayCallModal}
            {...headerProps}
            />

            {/*Client Removal Report */}
            <ClientRemovalReport 
            addClientRemovalModal={addClientRemovalModal}
            setAddClientRemovalModal={setAddClientRemovalModal}
            {...headerProps}
            />

            <SalesByServiceReport 
                addSalesByServiceReportModal={addSalesByServiceReportModal}
                setAddSalesByServiceReportModal={setAddSalesByServiceReportModal}
                {...headerProps}
            />      

        </div>
    )
}

export default ReportsHeader;