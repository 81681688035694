import Actions from "../Type.js"

const initialState = {
  addUser: false,
  clientDetail: false,
  editUser:false,
  loading: false,
  data: [],
  error: "",
  count:0,
  current_user_type:0,
  current_page:1,
  filterdata:[],
  filterCount:0,
}

const ManageUserReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case Actions.SHOW_ADD_USER:
      return {
        ...state,
        addUser: payload
      }
    case Actions.SHOW_CLIENT_DETAILS:
      return {
        ...state,
        clientDetail: payload
      }
    case Actions.SHOW_EDIT_USER:
      return {
        ...state,
        editUser: payload
      }
    case Actions.GET_USER_REQUEST:
      return {
        ...state,
        addUser:false,
        clientDetail:false,
        editUser:false,
        loading: true
      }
    case Actions.GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload.results,
        count: payload.count,
        current_user_type: payload.current_user_type
      }
    case Actions.GET_USER_FAIL:
      return {
        ...state,
        loading: false,
       
      }
    case Actions.SET_USER_TYPE:
      return {
        ...state,
        current_user_type:payload
      }
    case Actions.SET_CURRENT_PAGE:
      return {
        ...state,
        current_page:payload
      }
  
    case Actions.GET_FILTER_DATA_REQUEST:
      return {
        ...state,
        loading: true
      }
    case Actions.GET_FILTER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        filterdata: payload.results,
        filterCount: payload.count,
      }
    case Actions.GET_FILTER_DATA_FAIL:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

export default ManageUserReducer
