import React, {useEffect, useState} from "react"
import { Dialog } from "@material-ui/core"
import Logo from "../../assets/AMSLogo.png"
import { useStyle } from "./AddCourtDialogStyle"
import { Button } from "@material-ui/core"
import axios from "../../Store/request"
import useToast from "../Toast/useToast"
import { useDispatch, useSelector } from "react-redux"
import {addCourtData, GetAllCourt, GetTerritoryData} from "../../Store/Actions/Court"
import CircularSpinner from "../CircularProgressUserReport/CircularSpinner";
import SearchableDropDown from "../SearchableDropdown/SearchableDropDown"

const AddCourtDialog = ({ openModal, closeModal, data, isDisabled, setIsDisabled,stateList }) => {
  const { data: CourtDataArray } = useSelector(({ court }) => court)
  const classes = useStyle()
  const { showToast } = useToast()
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(false)
  const [courtData, setCourtData] = useState({
    name: "",
    state: "",
    address_1: "",
    address_2: "",
    city: "",
    postal_code: "",
    territory: "",
    phone_number: "",
  })

  const normalizeInput = (value, previousValue) => {
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {

      if (cvLength < 5) return currentValue;

      return `${currentValue.slice(0, 5)}`;
    }
    if (!value) return value
  };

  const normalizePhoneInput = (value, previousValue) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {

      if (cvLength < 4) return currentValue;

      if (cvLength < 7) return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;

      return `${currentValue.slice(0, 3)}-${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    }
  };

  const handlePhNoChange = (event) => {
    const { name, value } = event.target;
    setCourtData(prevState => ({ ...prevState, ['phone_number']: normalizePhoneInput(value, prevState['phone_number']) }));
  };

  const [validation, setValidation] = useState({})
  const inputEvent = (event) => {
    const { name, value } = event.target;
    setCourtData((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      }
    })
  }
  
  
  const addCourt = async e => {
    e.preventDefault()
    e.stopPropagation()
    let trueData = courtData
    const existedData = CourtDataArray.find(ele => ele.name === courtData.name)
    if (existedData !== undefined) {
      const formValidation = {}
      formValidation["name"] = "*Court name already exist"
      if (("" + courtData.postal_code).length !== 5) {
        formValidation["postal_code"] = "*Insert Valid ZIP Code"
      }
      setValidation(formValidation)
      return;
    }
    if (("" + courtData.postal_code).length !== 5) {
      const formValidation = {}
      formValidation["postal_code"] = "*Insert Valid ZIP Code"
      setValidation(formValidation)
      return;
    }


    if (courtData.address_2 === "" || courtData.address_2 === null) {
      trueData = { ...courtData, address_2: "-" }
    }
    let addData = JSON.stringify(trueData)
    try {
      setIsDisabled(true)
      setLoader(true)
      const response = await axios.post("/api/v1/courts/", addData)
      if (response.statusText === "Created") {
        dispatch(addCourtData(response.data))
        closeModal()
        dispatch(GetAllCourt())
        showToast("Court Added Successfully", "success")
        setCourtData({
          name: "",
          state: "",
          address_1: "",
          address_2: "",
          city: "",
          postal_code: "",
          territory: ""
        })
        setValidation({})
        setIsDisabled(false)
        setLoader(false)
      }
    } catch (e) {
      setIsDisabled(false)
      setLoader(false)
      showToast("Couldnot add court details, please try later", "error")
    }
  }

  const { territoryData:TerritoryData} = useSelector(({ court }) => court)

  const handleClick=async (val)=>{
    setCourtData({ ...courtData, state: val })
    dispatch(GetTerritoryData(val))
  }
  const territoryList=TerritoryData?.map((element)=>{
    return ({value:element.id,label:element.name})
  })
  return (
    <div>
      <Dialog
        open={openModal}
        scroll="body"
        onClose={() => closeModal()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        classes={{ paper: classes.paper}}
      >
        {loader === true ? <CircularSpinner onModal/> :
          <div className={classes.topDiv}>
            <div className={classes.LogoWrapper}>
              <img src={Logo} className={classes.Logo} alt="Logo"/>
            </div>
            <form onSubmit={addCourt} className={classes.upperDiv}>
              <div style={{ cursor: "pointer" }} onClick={() => {
                closeModal();
                setCourtData({
                  name: "",
                  state: "",
                  address_1: "",
                  address_2: "",
                  city: "",
                  postal_code: "",
                  territory: "",
                  phone_number: ""
                })
                setValidation({})
              }}>
                <i
                  className="fal fa-times fa-lg"
                  style={{ position: "absolute", top: "3%", right: "4%" }}
                />
              </div>

              <div className={classes.firstDiv}>
                <p className={classes.tagStyle}>COURT NAME</p>
                <input
                  type="text"
                  placeholder="Type..."
                  name="name"
                  className={classes.inputCont}
                  required
                  value={courtData.name}
                  onChange={inputEvent}
                />
                <span style={{ color: "red" }}>
                  {validation.name}
                </span>
              </div>

              <div className={classes.firstDiv}>
                <p className={classes.tagStyle}>ADDRESS 1</p>
                <input
                  type="text"
                  name="address_1"
                  placeholder="Type..."
                  className={classes.selectCont}
                  value={courtData.address_1}
                  onChange={inputEvent}
                  required
                />
              </div>
              <div className={classes.firstDiv}>
                <p className={classes.tagStyle}>ADDRESS 2</p>
                <input
                  type="text"
                  name="address_2"
                  placeholder="Type...(optional)"
                  className={classes.selectCont}
                  value={courtData.address_2}
                  onChange={inputEvent}
                />
              </div>
              <div className={classes.firstDiv}>
                <p className={classes.tagStyle}>Phone number</p>
                <input
                      type="text"
                      name="phone_number"
                      placeholder="XXX-XXX-XXXX"
                      className={classes.inputCont}
                      required
                      value={courtData.phone_number}
                      onChange={handlePhNoChange}
                    />
              </div>
              <div className={classes.bottomCont}>
                <div className={classes.bottomContLeft}>
                  <p className={classes.tagStyle}>CITY</p>
                  <input
                    type="text"
                    name="city"
                    placeholder="Type..."
                    className={classes.inputCont}
                    required
                    value={courtData.city}
                    onChange={inputEvent}
                  />
                </div>
                <div className={classes.bottomContRightState}>
                  <p className={classes.tagStyle}>STATE</p>
                  <SearchableDropDown
                        data={stateList}
                        handleClick={handleClick}
                        selectedValue={courtData.state}

                    />
                </div>
              </div>

              <div className={classes.bottomCont}>
                <div className={classes.bottomContRightState}>
                  <p className={classes.tagStyle}>TERRITORY</p>
                  <SearchableDropDown
                    data={territoryList}
                    handleClick={val =>{
                      setCourtData({ ...courtData, territory: val })}
                    }
                    // handleClick={handleClick}
                    selectedValue={courtData.territory}

                  />

                </div>
                <div className={classes.bottomContRight}>
                  <p className={classes.tagStyle}>ZIP CODE</p>
                  <input
                    type="number"
                    name="postal_code"
                    placeholder="Type..."
                    className={classes.inputCont}
                    value={courtData.postal_code}
                    onChange={(e) => {
                      setCourtData(prevalue => ({ ...prevalue, postal_code: normalizeInput(e.target.value, prevalue["postal_code"]) }))
                      }
                    }
                    max="99999"
                    required
                  />
                  <span style={{ color: "red" }}>
                    {validation.postal_code}
                  </span>
                </div>
              </div>
              {isDisabled === false ?
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  disabled={
                    courtData.postal_code === "" ||
                    courtData.address_1 === "" ||
                    courtData.city === "" ||
                    courtData.state === "" ||
                    courtData.name === "" ||
                    courtData.territory === ""
                  }
                  type="submit"
                >
                  {"ADD COURT"}
                </Button>
                : <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  disabled
                  type="submit"
                >
                  {"ADD COURT"}
                </Button>

              }
            </form>
          </div>
        }

      </Dialog>
    </div>
  )
}

export default AddCourtDialog
