import React, { useEffect, useState } from "react"
import { useStyles } from "../Payments/PaymentsStyle"
import Container from "@material-ui/core/Container"
import UserNoteHeader from "../../Components/UserNotesHeader/UserNotesHeader"
import axios from "../../Store/request"
import UserNotesInformation from "./UserNotesInformation"


const Notes = () => {
  const classes = useStyles()
  const [state, setState] = React.useState(0)
  const [notesData, setNotesData] = useState([])

  let userId = localStorage.getItem('userId');
  const saveNotes = async () => {
    try {
        const response = await axios.get("manage-users/user_notes/?user-id="+userId)
        setNotesData(response.data)
    } catch (e) {
    }
  }

  useEffect(() => {
    reloadData()
  }, [])

  const reloadData = () => {
    saveNotes()
  }
  return (
    <div className={classes.MainContainer}>
      <UserNoteHeader/>
      <Container maxWidth="lg" className={classes.containerMob}>
        <UserNotesInformation state={state} notesData={notesData} setNotesData={setNotesData}/>
      </Container>
    </div>
  )
}

export default Notes
