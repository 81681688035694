import React, {useState} from 'react'
import { DataArray } from './DataArray'
import { useStyles } from './UserTableStyle'
import MoreDropDown from "../MoreDropDown/MoreDropDown";

import ReportViews from "../ReportViews/ReportViews";
const UserTable = () => {

  const classes = useStyles()
  const [currentData, setCurrentData] = useState({
    service: null,
    court: null
  })

  const [openReportViewModal, setOpenReportViewModal] = useState(false)
  const [viewReportType, setViewReportType] = useState('courtUser')
  const [dataArray, setDataArray] = useState([])


  return (
    <div className={classes.Container} >
      <div className={classes.TableHeading}>
        <div className={classes.HeadingInner}>
          <p></p>
         
        </div>
      </div>
      {DataArray?.map(i => {
        return (
          <>
          <div className={classes.MainContainer}>
            <div className={classes.ContainerInner}>
              <p className={classes.ListItmes}>
              {i.Name}
               
              </p>
              <p className={classes.ListItmes}>
                {i.Payment}
              </p>
              <p className={classes.ListItmes}>
                {i.Duration}
              </p>

              <MoreDropDown courtDescriptionView={() => { setOpenReportViewModal(true); setViewReportType('courtUser') }}/>
              <ReportViews
                open={openReportViewModal}
                onClose={() => setOpenReportViewModal(false)}
                reportType={viewReportType}
                DataArray={dataArray}
              />

            </div>


          </div>
          </>
        )
      })}
    
    </div>
  )
}

export default UserTable