import React from "react";
import { useStyles } from "./CourtPricingHeaderStyle";
import Button from '@material-ui/core/Button';

const AddButton = ({signal,searchDropDown}) => {
  const classes = useStyles();
  return (
    <div className={classes.ButtonContainer}>
      <Button
        variant="contained"
        color="secondary"
        disabled={!signal}
        className={signal ? classes.newbutton : classes.button}
        onClick={searchDropDown}
      >
        + Add pricing
      </Button>
    </div>
  )
}

export default AddButton