import React, {useState} from 'react';
import Pagination from '@material-ui/lab/Pagination';

import { useStyles } from './Pagination_NewStyle'
const Pagination_New = ({count,postsPerPage,paginate,activePage}) => {
  const classes=useStyles()
  const pageCount=Math.ceil(count / postsPerPage)
  return (
    <div className={classes.MainPage}>

      <Pagination
        count={pageCount}
        defaultPage={activePage}
        // shape="rounded"
        page={activePage}
        size="large"
        // variant="outlined"
        color="primary"
        onChange={(event,value)=>{
          paginate(value)
        }}
      />
    </div>
  );
};

export default Pagination_New;