import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 250;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width:"100%",
    overflowX:"hidden"
  },
  parentDiv: {
    overflowX:"hidden",
    overflowY:"scroll",
    // scrollbars:"hidden"
    "&::-webkit-scrollbar ":{
      display: "none"
  }
  },
  sticky:{
    position:"sticky",
    top:0,
    left:0,
  },
  testWrapper:{
    // border:"2px solid black",
    width:"200px"
  },
  NameWrapper:{
    display:"flex",
    width:"140px",
    justifyContent:"space-between",
    alignItems:"center",
    // border:"2px solid black",

  },
  userdetail:{
    // border:"2px solid yellow",
    display:"flex",
    flexDirection:"column",
    // width:"125px",
  },
  username:{
    whiteSpace:" nowrap",
    width: "140px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize:18,
    // border: "1px solid #000000",
  },
  arrowDropdown:{
    marginRight:"12px",
    position:"sticky",
    // border:"2px solid black",
  },
  //LOGOUT BUTTON

  logBtn: {
    backgroundColor: "#3971FF !important",
    borderRadius: "22px !important",
    color: "white !important",
    fontFamily: theme.fonts.family.Medium,
    width:'80px',
    fontSize:'14px',
    marginLeft:'10px',
    
  },

  //HEADER STYLING

  LogoWrapper: {
    display: "flex",
    alignItems: "center",
    padding: "5px 5px",
    width: '100%',
    marginLeft:'8%'

  },
  Logo: {
    width: 34,
    height: 32,
    marginRight: 20,
  },
  mainHeading: {
    fontFamily: 'Open Sans',
    fontWeight: 700,
    fontSize:18,
    lineHeight:"25px",
    color: "#363E51",
  },

  //PROFILE STYLING
  imgbg:{
    backgroundColor:"red",
  },
  ProfileContainer: {
    padding: "10px 30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#363E51"
  },
  ProfileContainerActive: {
    padding: "5px 30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#81A4FF",
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: 18,
    color: "#FFFFFF",
  },
  ActiveBorderProfile: {
    position: "absolute",
    background: "#3971FF",
    width: 7,
    height: 50,
    left: 0,
    right: 0,
  },
  ProfileWrapper: {
    display: "flex",
    alignItems:"Center"

  },
  ProfileAvatar: {
    width: 34,
    height: 34,
    marginRight: 15,
    borderRadius: "50%"
  },
  Name: {
    margin: 0,
    fontFamily: theme.fonts.family.Medium,
    fontSize: theme.fonts.sizes.fontXL,
    // color: "#363E51"
  },
  Admin: {
    margin: 0,
    fontFamily: theme.fonts.family.Bold,
    fontSize: theme.fonts.sizes.fontS,
    // color: "#363E51",
    fontWeight: 700,

  },
  usernameLabel:{
    fontFamily: 'Open Sans',
    fontweight: "700",
    fontSize: "12px",
    lineHeight: "16px",
    // color: "#363E51",
},
  arrowDown: {
    color: "#363E51",
    height: 19,
    width: 18,
    cursor: "pointer",
    marginRight: "12px",
  },

  //LISTITMES STYLING

  ListWrapper: {
    width: "100%",
    marginBottom: 10,
    cursor: "pointer",
    "& .MuiListItemIcon-root": {
      height: 40,
      width: 40,
      minWidth: 0,
    },

  },
  IconWrapper: {
    borderRadius: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#81A4FF",
    marginLeft: 12,
  },
  icon: {
    width: 24,
    height: 24,
  },
  StyledFont: {
    fontFamily: "Open Sans",
    fontWeight: 600,
  },
  styledFont1: {
    fontFamily: "Open Sans",
    fontWeight: 700,
    fontSize:12,
  },
  ListName: {
    marginLeft: 10,
    fontSize: theme.fonts.sizes.fontXL,
    "& .MuiTypography-root": {
      fontFamily: theme.fonts.family.Bold,
      color: "#363E51",
      fontWeight: 500,
    }
  },
  ActiveList: {
    "& .MuiTypography-root": {
      fontFamily: theme.fonts.family.AvenirSemiBold,
      color: "white",
      fontWeight: 500,
    }
  },
  Active: {
    background: "red",
    backgroundColor: "#81A4FF",
    height: 44,
  },
  ActiveBorder: {
    position: "absolute",
    background: "#3971FF",
    width: 7,
    height: 44,
    left: 0,
    right: 0,
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    overflowY: "unset",
    position: 'relative',
    height:"100vh",
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarSpacer: theme.mixins.toolbar,
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },

  content: {
    width: "100%",
    height: "100vh",
    backgroundColor: "#e8f2fe"
  },
  logoutBtn:{
    cursor:"pointer",
    color: "#81a4ff",
    marginLeft:80,
    marginTop:10,
    
  }

}));

