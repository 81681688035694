import React, { useEffect, useState, useContext, useRef } from "react"
import { ScreenContext } from "./../../context/context"
import { useStyles,finalStyles} from "../UserProfileHeader/UserProfileHeaderStyle"
import UsersIcon from "../../assets/UsersIcon.png"

const UserNoteHeader = props => {
  const classes = useStyles()
  const { screenSize, setScreenSize } = useContext(ScreenContext)
  const finalclasses = finalStyles()
  return (
    <div
    className={
      screenSize > 800 ? classes.MainContainer : finalclasses.MainCont
    }
  >
    {screenSize > 800 ? (
      <div className={classes.HeaderInner}>
        <div className={classes.Wrapper}>
          <div className={classes.IconWrapper}>
            <img src={UsersIcon} className={classes.icon} />
          </div>
          <p className={classes.MainHeading}>User Notes</p>
        </div>
      </div>
    ) : (
      <div className={finalclasses.topMain}>
        <div className={finalclasses.upperMain}>
          <div className={finalclasses.flex}>
            <div className={classes.IconWrapper} style={{ width: "57px" }}>
              <img src={UsersIcon} className={classes.icon} alt="" />
            </div>
            <p className={classes.MainHeading} style={{ fontSize: "12px" }}>
              {"User Notes"}
            </p>
          </div>
        </div>

      
      </div>
    )}  
  </div>
  )
}


export default UserNoteHeader