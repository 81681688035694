import React,{useState, createContext} from 'react'

export const ReportContext = createContext()

export const ReportProvider = (props) => {

    //default value admin 
    const [reportLocation, setReportLocation] = useState(1)

    return(
        <ReportContext.Provider value={{reportLocation, setReportLocation}}>
            {props.children}
        </ReportContext.Provider>
    );
}
