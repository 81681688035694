import { makeStyles } from "@material-ui/core/styles"
import Ellipse1 from "../../assets/Ellipse1.png"
export const useStyles = makeStyles(theme => ({
  mainContainer: {
   
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",

  },
  cardpic: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    marginRight:"15px",
    borderRadius:"8px",
    textAlign:"center",
    width:"403px",
    height:"347px",
    fontFamily: "Open Sans",
    '@media(max-width:1100px)' : {
      // border:"2px solid black",
      width:"275px",
    }

  },
  cardpic1: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    marginRight:"15px",
    borderRadius:"8px",
    textAlign:"center",
    width:"403px !important",
    height:"347px",
    fontFamily: "Open Sans",
    position:"relative"
  },
  pic:{
    width:"229px",
    borderRadius:"50%"
  },
  picImg:{
    width:"183px",
    height:"168px",
    borderRadius:"50%",
    '@media(max-width:1100px)' : {

      width:"150px",
      height:"135px"
    }
  },
  editImg:{
    position:"absolute",
    marginTop:"-123px",
    marginLeft:"-121px",
    cursor:"pointer ",
  },
  editImgUser:{
    position:"absolute",
    marginTop:"-96px",
    marginLeft:"-120px",
    cursor:"pointer ",
  },
  imageDetail: {
   textAlign:"center",
    fontSize:"22px",
    '@media(max-width:1100px)' : {

      fontSize:"20px",
    }
  },
  maincardinfo: {
    width:"90%",
    display: "flex",
    justifyContent: "center",
    flexDirection:"column",
    borderRadius:"8px",
    fontFamily: "Open Sans",
    fontSize:"14px",
    overflow:"visible",
    '@media(min-width:801px) and (max-width:1100px)' : {
      // border:"2px solid black",
      overflowX:"scroll",
      width:"485px",
      height:"345px",
      paddingTop:"35px"
    },
    '@media(max-width:800px)' : {
      // border:"2px solid black",
      overflowX:"scroll",
      // width:"358px",
      height:"345px",
      paddingTop:"35px"
    }

  },
  cardWrapper: {
    display: "flex",
    justifyContent: "center",
    '@media(max-width:1100px)' : {
      justifyContent: "flexStart",
    }

  },
  infofficer:{
    marginTop:"15px",
    flexDirection:"column",
    display: "flex",
    borderRadius:"8px",
    fontFamily: "Open Sans",
    fontSize:"14px",
  },
  buttonWrapper:{
    display:"flex",
    justifyContent: "flex-end"

  },
  button: {
    marginTop: "20px",
    background: "#3971FF",

    width:"250px",
    "&.MuiButton-containedSecondary": {
      height: 38,
      alingItems: "center",
      borderRadius: "5px !important",
      "&:hover": {
        background: "#3971FF"
      },
      "& .MuiButton-label": {
        fontFamily: "Open Sans",
        fontSize: 16,
        fontWeight: "bold",
        textTransform: "capitalize",
        marginTop: 2,

      },
      "& .MuiSvgIcon-root": {

      }
    }
  },

    btnCancle:{
      background: "#F8FBFF" ,
      color:"#3971FF",
      marginTop: "20px",
      boxShadow:"none",
      "&.MuiButton-containedSecondary": {
        height: 38,
        alingItems: "center",
        borderRadius: "5px !important",
        "&:hover": {
          background: "#F8FBFF"
        },
        "& .MuiButton-label": {
          fontFamily: "Open Sans",
          fontSize: 15,
          fontWeight: "bold",
          textTransform: "capitalize",
          marginTop: 3
        },
        "& .MuiSvgIcon-root": {

        }
      }
  },
  detailWrapper:{
    display: "flex",
    flexDirection:"row",
    justifyContent:"space-around",
    marginBottom:"15px",
    marginTop:"15px",

  },
  cardDetail:{
    width:"25%",
    marginLeft:"75px",
    '@media(max-width:1100px)' : {

      marginLeft:"20px",
    }
  },
  userInfo:{

    display:"flex",
    justifyContent:"space-around",
    marginBottom:"15px",
    marginTop:"15px",
    padding:"10px",
  },
  userInfo1:{

    display:"flex",
    justifyContent:"flex-start",
    marginBottom:"15px",
    marginTop:"15px",
    padding:"10px",
  },

  subinfo1:{

    width:"19%",
  },
  subinfo1_email:{
    width:"19%",
    whiteSpace:" nowrap",
    // width: "140px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize:15,
  },


  span:{
    color:"#979797",
    // width:"19%",
    whiteSpace:" nowrap",
    width: "99%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize:15,

  },

  hr:{
    width:"90%",
    border:"1px solid #EDEFF0",
  },
  editprofileSelect:{
    width:"85%",
    padding:"7px",
    background: "#F9F9F9",
    border: "1px solid #EDEDED",
    boxSizing: "border-box",
    borderRadius: "5px",
    fontSize:14,
  },
  editprofileData:{
    width:"80%",
    padding:"7px",
    marginTop:5,
    background: "rgb(244, 244, 244)",
    border: "1px solid hsl(0, 0%, 80%)",
    boxSizing: "border-box",
    borderRadius: "5px",
    fontSize:14,
    height:"63%"
  },
  editReadonlyData:{
    whiteSpace:" nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "80%",
    padding:"7px",
    marginTop:5,
    background: "rgb(244, 244, 244)",
    border: "1px solid hsl(0, 0%, 80%)",
    boxSizing: "border-box",
    borderRadius: "5px",
    fontSize:14,
    height:"63%"
  },
  // editprofileSelect1:{
  //   width:"85%",
  //   padding:"7px",
  //   background: "#FFFFFF",
  //   border:"none",
  //   boxSizing: "border-box",
  //   fontSize:14,
  //   "&:focus-visible":{
  //     // backgroundColor:'red',
  //     width:"85%",
  //     outline:"none",
  //     boxShadow:"none",
  //     "& + $borderEffect":{
  //       width:"85%",
  //       backgroundColor:"black",
  //     },
  //   }
  //
  // },
  // borderEffect:{
  //   height:"2px",
  //   width:"0px",
  //   backgroundColor:"white",
  //   transition:"width 0.5s",
  // }

}))
