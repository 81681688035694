import React, { useState, useEffect, useContext } from "react"
import AddButton from "../Buttons/AddButton"
import UploadCsvBtn from "../Buttons/UploadCsvBtn"
import useStyles from "./UsersHeaderStyle"
import SearchBar from "../SearchBar/SearchBar"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import Logo from "../../assets/AMSLogo.png"
import { finalStyles } from "../CourtsHeader/CourtsHeaderStyle"
import { ScreenContext } from "../../context/context"
import { barStyles } from "../SearchBar/SearchBarStyle"
import SearchIcon from "@material-ui/icons/Search"
import InputBase from "@material-ui/core/InputBase"
import { makeStyles } from "@material-ui/core/styles"
import axios from "../../Store/request"
import useToast from "../../Components/Toast/useToast"
import { DataArray } from "../../Components/PaymentDataTable/PaymentDataArray"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ManageUserAction } from "../../Store/Actions/ManageUserAction"
//import { DataArray } from "../UsersDataTable/DataTableArray";
import { useDispatch,useSelector } from "react-redux"

const UsersHeader = ({ MainHeading, lable, img,searchText,passedIndex }) => {
  const classes = useStyles()
  const barclasses = barStyles()
  const { showToast } = useToast()
  const finalclasses = finalStyles()
  const dispatch=useDispatch()
  const { screenSize, setScreenSize } = useContext(ScreenContext)
  const [addUserModal, setAddUserModal] = useState(false)
  // const [court, setCourt] = useState([])
  // const [officerData, setOfficerData] = useState([])
  // const [servicesArray, setServicesArray] = useState([])
  // const [payPlanArray, setPayPlanArray] = useState([])
  const {clientDetail,editUser,data,loading } = useSelector(
    ({ manageUser }) => manageUser
  )
  const isAddUser = useSelector(({ manageUser }) => manageUser.addUser)
  const [userData, setUserData] = useState({
    // userStatus: "",
    //userType: "",
    //courtSystem: "",
    //paymentPlan: "",
    //middleName: "",
    // paroleOfficer: "",
    //residentAddress: "",
    first_name: "",
    last_name: "",
    date_of_birth: "",
    address: "",
    emergency_contact: "",
    email: "",
    home_phone: "",
    phone: "",
    alt_phone: "",
    // is_court_admin_user: false,
    //is_operator_user: false,
    //is_administrator: false,
    pay_plan_procedure: "",
    user_type: "",
    court: "",
    parole_officer: "",
    middle_name: "",
    username: "",
    services: []
  })

  
  const MenuProps = {
    getContentAnchorEl: null,
    anchorOrigin: { vertical: 'bottom',  horizontal: 'center',maxHeight:"100px" },
        transformOrigin: { vertical: 'top', horizontal: 'center',maxHeight:'100px' },
    PaperProps: {
        style: {
            maxHeight: 120,
            maxWidth: "30%",
        },
    },
  };
  useEffect(() => {
    setScreenSize(window.innerWidth)
  }, [screenSize])

  const servicesHandler = ({ target: { value, selected } }) => {
    let updatedUserServices = [...userData.services]
    const findCurrentService = updatedUserServices.findIndex(
      item => item === value
    )
  
    if (findCurrentService > -1) {
      updatedUserServices = [
        ...updatedUserServices.slice(0, findCurrentService),
        ...updatedUserServices.slice(findCurrentService + 1)
      ]
    } else {
      updatedUserServices.push(+value)
    }
    setUserData({
      ...userData,
      services: [...new Set(updatedUserServices)]
    })
  }
  const handleCortSystemSelect = ({ target: { value } }) => {
    let updatedState = {}
    switch (value) {
      case "Admin":
        updatedState = {
          ...userData,
          is_administrator: true
        }
        setUserData(updatedState)
        return
      case "Operators":
        updatedState = {
          ...userData,
          is_operator_user: true
        }
        setUserData(updatedState)
        return
      case "Courts":
        updatedState = {
          ...userData,
          is_court_admin_user: true
        }
        setUserData(updatedState)
        return
      default:
        return
    }
  }

  const openModal = () => {
    setAddUserModal(true)
    dispatch(ManageUserAction(true))
  }

  const isPhoneNumberValid = number => {
    return (
      !number.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/) ||
      number.length !== 12
    )
  }
  
  const addUserFunc = e => {
    e.preventDefault()

    let finalObj = {
      ...userData,
      name: userData.first_name,
      user_type: +userData.user_type,
      pay_plan_procedure: +userData.pay_plan_procedure,
      parole_officer: +userData.parole_officer,
      court: +userData.court
    }

    addUser(finalObj)
  }

  const addUser = async addData => {
    try {
      const response = await axios.post("/manage-users/", addData)
      if (response.statusText === "Created") {
        showToast("User Added Successfully", "success")
      }
    } catch (e) {
      showToast("Could not add user, please try later", "error")
    }
  }

  const isDisabled = () => {
    return false
    return (
      !userData.userType ||
      !userData.courtSystem ||
      !userData.paymentPlan ||
      !userData.first_name ||
      !userData.middleName ||
      !userData.last_name ||
      !userData.date_of_birth ||
      !userData.paroleOfficer ||
      !userData.address ||
      !userData.emergency_contact ||
      !userData.email ||
      !userData.residentAddress ||
      !userData.home_phone ||
      !userData.phone ||
      !userData.alt_phone
    )
  }
  const [search,setSearch]=useState(false);
  const searchBtn =(e)=>{
    setSearch(!search);
  }
  return (
    <div
      className={
        screenSize > 800 ? classes.MainContainer : finalclasses.MainCont
      }
    >
      {screenSize > 800 ? (
        <div className={classes.HeaderInner}>
          <div className={classes.Wrapper}>
            <div className={classes.IconWrapper}>
              <img src={img} className={classes.icon} />
            </div>
            <p className={classes.MainHeading}>{MainHeading}</p>
            <div onClick={openModal} >
             {!isAddUser && <AddButton lable={lable} />}
            </div>
          {/* CSV Btn */}
           {/* {!isAddUser &&  <UploadCsvBtn />} */}
          </div>
          {
            !isAddUser && !clientDetail && !editUser &&
            <div className={barclasses.SearchContainer}>
              {search===false?
                <div className={barclasses.search1}>
                  <SearchIcon  onClick={searchBtn}  style={{ backgroundColor:"none"}}/>
                </div>:
                <div className={barclasses.search}>
      
                  <div className={barclasses.searchIcon} >
                    <SearchIcon />
      
                  </div>
                  <InputBase
                    placeholder="Search…"
                    classes={{
                      root: barclasses.inputRoot,
                      input: barclasses.inputInput
                    }}
                    inputProps={{ "aria-label": "search" }}
                    onChange={searchText}
        
                    autoFocus
                  />
    
                </div>
              }

            </div>
          }
         
        </div>
      ) : (
        <div className={finalclasses.topMain}>
          <div className={finalclasses.upperMain}>
            <div className={finalclasses.flex}>
              <div className={classes.IconWrapper} style={{ width: "57px" }}>
                <img src={img} className={classes.icon} alt="" />
              </div>
              <p className={classes.MainHeading} style={{ fontSize: "12px" }}>
                {MainHeading}
              </p>
            </div>
            <div className={barclasses.SearchContainer}>
              {search===false?
                <div className={barclasses.search1}>
                  <SearchIcon  onClick={searchBtn}  style={{ backgroundColor:"none"}}/>
                </div>:
                <div className={barclasses.search}>

                  <div className={barclasses.searchIcon} >
                    <SearchIcon />

                  </div>
                  <InputBase
                    placeholder="Search…"
                    classes={{
                      root: barclasses.inputRoot,
                      input: barclasses.inputInput
                    }}
                    inputProps={{ "aria-label": "search" }}
                    onChange={searchText}

                    autoFocus
                  />

                </div>
              }

            </div>
          </div>

          <div className={finalclasses.bottomCont}>
            <div onClick={openModal}>
              <AddButton lable={lable} />
            </div>
            <div>
              {/* CSV Btn */}
              {/* <UploadCsvBtn /> */}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default UsersHeader
