import React,{ useState,useEffect } from "react";
import { Dialog,Button,makeStyles } from "@material-ui/core";
import { useStyles } from "./ReportsHeaderStyle";
import Logo from '../../assets/AMSLogo.png';
import useToast from "../Toast/useToast";
import axios from "../../Store/request";
import CircularSpinner from "../CircularProgressUserReport/CircularSpinner";
import CourtDropDownData from "../CourtDropDownData/CourtDropDownData";
import { useDispatch, useSelector } from "react-redux";
import SearchableDropDown from "../SearchableDropdown/SearchableDropDown";

const SalesByServiceReport = ({addSalesByServiceReportModal,setAddSalesByServiceReportModal,currentPage,result,setResult}) => {
    // const classes = useStylesSalesByServiceRemoval()
    // const useNewStyles = makeStyles(() => ({
    //     paper: { width: '25%' },
    // }));
    const classes = useStyles()
    const useNewStyles = makeStyles(() => ({
        paper: { width: '25%' },
    }));
    const [postsPerPage] = useState(10)
    const defClasses = useNewStyles()
    const { showToast } = useToast()
    const [SalesByServiceReport,setSalesByServiceReport] = useState({
        from_date:"",
        to_date:"",
        court:"",
        territory:"",
        payment_type:""
    })
    const [SalesByServiceReportLoading,setSalesByServiceReportLoading] = useState(false)
    const [territories,setTerritories] = useState([]);
    const [paymentType] = useState([{value:"1",label:"Client Payment"},{value:"2",label:"Court Payment"},{value:"3",label:"Hybrid Payment"}])

    const getTeritories = async () =>{
        setSalesByServiceReportLoading(true)
        const response=await axios.get(`/api/v1/courts/territory/`)
        if(response.status===200){
            response.data.map((object)=>{
                territories.push({value:object.id,label:object.name})
            })
            setTerritories(territories)
        }
        setSalesByServiceReportLoading(false)
    }

    useEffect(()=>{
        getTeritories();
    },[])

    const addSalesByServiceReport = async (e) => {
        e.preventDefault();
        setSalesByServiceReportLoading(true)
        try{
        const response=await axios.post(`/api/v1/reports/sales_by_service/`,SalesByServiceReport)
        if(response.status===201){
            setSalesByServiceReportLoading(false)
            setSalesByServiceReport({
                from_date:"",
                to_date:"",
                court:"",
                territory:"",
                payment_type:""
            })
            setAddSalesByServiceReportModal(false)
            showToast("SalesByService Report Added Successfully", "Success")
            setResult({...result,loading:true})
            try {
                const { data: { results, count }} = await axios.get(`/api/v1/reports/sales_by_service/?limit=${postsPerPage}&offset=${postsPerPage * currentPage - postsPerPage}`)
                setResult({ data: results, count ,loading:false});
            }catch (e) {
                showToast("Unable to fetch new data", "error")
              setResult({...result,loading:false})
            }
        }
        }
        catch (e){
            setSalesByServiceReportLoading(false)
            showToast("SalesByService Report Posting Fail", "error")
        }   
    }
    // disable pastdate.
    const disableToDates=()=>{
            const today = new Date(SalesByServiceReport.from_date);
            const dd = String(today.getDate() + 1).padStart(2, "0");
            const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
            const yyyy = today.getFullYear();
            return yyyy + "-" + mm + "-" + dd;
    }
    const disableFromDates=()=>{
        const today = new Date(SalesByServiceReport.to_date);
        const dd = String(today.getDate() - 1).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    }

    return (
            <Dialog
                scroll="body"
                open={addSalesByServiceReportModal}
                onClose={() => {setAddSalesByServiceReportModal(false)
                    setSalesByServiceReport({
                    from_date : "",
                    to_date : "",
                    court : "", 
                    territory : "", 
                    payment_type:""
                })}}
                PaperProps={{
                    style: {
                    overflow: "unset",
                    margin:40
                    }
                }}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                {SalesByServiceReportLoading?<CircularSpinner onModal/>:(<div className={classes.topDiv}>
                    <div className={classes.LogoWrapper}>
                        <img src={Logo} className={classes.Logo} />
                    </div>
                      <form onSubmit={addSalesByServiceReport} className={classes.upperDiv}>
                        <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {setAddSalesByServiceReportModal(false)
                                setSalesByServiceReport({
                                    from_date : "",
                                    to_date : "",
                                    court : "", 
                                    territory : "", 
                                    payment_type:""
                                })}}
                        >
                            <i
                                className="fal fa-times fa-lg"
                                style={{ position: "absolute", top: "3%", right: "4%" }}
                            />
                        </div>

                        <div className={classes.firstDiv}>
                            <p className={classes.tagStyle}>REPORT TYPE</p>
                            <input
                                type="text"
                                placeholder="Sales By Service Report"
                                className={classes.inputCont}
                                required
                                disabled
                                style={{paddingLeft:'15px'}}
                            />
                        </div>

                        <div className={classes.firstDivCourt}>
                            <p className={classes.tagStyle}>Payment Type</p>
                            <SearchableDropDown
                                data={paymentType}
                                handleClick={val =>{
                                    setSalesByServiceReport({ ...SalesByServiceReport, payment_type: val })}
                                }
                                selectedValue={SalesByServiceReport.payment_type}

                            />
                        </div>

                        <div className={classes.firstDiv}>
                            <p className={classes.tagStyle}>COURT NAME</p>
                            <CourtDropDownData
                                handleSelect={val => setSalesByServiceReport({...SalesByServiceReport,court:val})}
                                selectedValue={SalesByServiceReport.court}
                            />
                        </div>
                        <div className={classes.firstDiv}>
                            <p className={classes.tagStyle}>TERRITORY</p>
                            <SearchableDropDown
                                data={territories}
                                isDisabled={territories.length===0}
                                handleClick={val =>{
                                    setSalesByServiceReport({ ...SalesByServiceReport, territory: val })}
                                }
                                selectedValue={SalesByServiceReport.territory}

                            />
                        </div>
                        <div className={classes.bottomCont} >
                            <div className={classes.bottomContLeft}>
                                <p className={classes.tagStyle}>FROM DATE</p>
                                <input
                                    type="date"
                                    name="from_date"
                                    className={classes.inputContDate}
                                    required
                                    // style={{ paddingLeft: '15px' }}
                                    value={SalesByServiceReport.from_date}
                                    onChange={(e) => { setSalesByServiceReport({ ...SalesByServiceReport, from_date: e.target.value })
                                    }}
                                />
                            </div>
                            <div className={classes.bottomContRight}>
                                <p className={classes.tagStyle}>TO DATE</p>
                                <input
                                    type="date"
                                    className={classes.inputContDate}
                                    name="to_date"
                                    required
                                    // style={{ paddingLeft: '15px' }}
                                    value={SalesByServiceReport.to_date}
                                    onChange={(e) => { setSalesByServiceReport({ ...SalesByServiceReport, to_date: e.target.value })
                                    }}
                                />
                            </div>

                        </div>

                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            type="submit"
                            disabled={SalesByServiceReport.to_date===""||SalesByServiceReport.from_date==="" || SalesByServiceReport.payment_type===""}

                        >
                            {"CREATE REPORT"}
                        </Button>
                    </form>
                </div>)}
            </Dialog>)
}

export default SalesByServiceReport