import { makeStyles } from "@material-ui/core/styles";

export const useStyles=makeStyles((theme)=>({
  selectDropDown: { 
  
    //  marginTop:"5px",
     "& .css-1s2u09g-control":{
       backgroundColor:"#F9F9F9 !important",   
      },  
      "& .css-fzo73z-control":{
        height: "44px"
      },
      "& :focus": {
        height: "30px"
      }


        
   },
   addNewOption:{
     background: "#F9F9F9 !important",
     padding: 5,
    
   }
}))