import Actions from "../Type.js"

const initialState = {
  
  loading: false,
  data: [],
  error: "",
  responseData:{},
  count:0,
  dataExist:true
}


const PaymentPlanReducer = (state = initialState, { type, payload }) => {
  switch (type) {
   
    case Actions.GET_PAYMENT_PLAN_REQUEST:
      return {
        ...state,
        loading: true
      }
    case Actions.GET_PAYMENT_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload.results,
        count: payload.count,
        dataExist: payload.dataExist
      }
    case Actions.GET_PAYMENT_PLAN_FAIL:
      return {
        ...state,
        loading: false,
       
      }
      case Actions.ADD_PAYMENT_PLAN_DATA:
       return {
         ...state,
         data: [ ...state.data, payload ],
         responseData:payload
       } 

    default:
      return state
  }
}

export default PaymentPlanReducer
