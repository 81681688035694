import { FormControl } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  MainContainer: {
    width: "100%",
    height: 97,
    background: "#FFFFFF",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  HeaderInner: {
    padding: "2px 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },

  IconWrapper: {
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#81A4FF",
    margin: "0px 20px",
    width: 47,
    height: 44
  },
  icon: {
    width: 24,
    height: 24
  },
  MainHeading: {
    color: "#363E51",
    fontFamily: "Open Sans",
    fontSize: theme.fonts.sizes.fontXL,
    fontWeight: 600,
    textTransform: "uppercase"
  },
  Wrapper: {
    display: "flex",
    alignItems: "center"
  },

  topDiv: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    background: "none",
    alignItems: "center",
    boxShadow: "none"
  },
  modalTopDiv: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    background: "none",
    alignItems: "center",
    boxShadow: "none",
    overflowY: "overlay"
  },

  selectCont: {
    width: "100%",
    height: "38px",
    background: "rgb(244, 244, 244)",
    color: "gray",
    borderRadius: "5px",
    border: "1px solid lightgray",
    fontFamily: theme.fonts.family.Light,
    fontSize: "16px",
    paddingLeft: "10px",
    marginTop: "5px"
  },

  bottomCont: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "80%"
  },

  bottomContLeft: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "45%"
  },

  bottomContRight: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "45%"
  },

  inputCont: {
    width: "100%",
    height: "38px",
    background: "rgb(244, 244, 244)",
    color: "gray",
    borderRadius: "5px",
    border: "1px solid lightgray",
    fontFamily: theme.fonts.family.Light,
    fontSize: "16px",
    paddingLeft: "25px"
  },

  button: {
    background: "#3971FF",
    margin: "0px 20px",
    "&.MuiButton-containedSecondary": {
      width: "80%",
      height: 38,
      display: "flex",
      alingItems: "center",
      borderRadius: "5px !important",
      "&:hover": {
        background: "#3971FF"
      },
      "& .MuiButton-label": {
        fontFamily: theme.fonts.family.SemiBold,
        fontSize: 15,
        fontWeight: 400,
        textTransform: "capitalize",
        marginTop: 3
      },
      "& .MuiSvgIcon-root": {
        marginTop: -2
      }
    }
  },

  multipleSelect: {
    height: "unset"
  },

  LogoWrapper: {
    backgroundColor: "white",
    borderRadius: 50,
    padding: 10,
    border: "1px solid #FFFFFF",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.203851)",
    position: "absolute",
    top: -25
  },
  Logo: {
    width: "100%",
    height: 32
  },

  HorizontalLine: {
    width: "100%",
    border: "1px solid #DADADA",
    backgroundColor: "#F0F0F0",
    marginBottom: 25
  },

  majorDiv: {
    display: "flex",
    flexDirection: "column",
    margin: "10% 3% 5% 3%"
  },
  flexer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  flexerScreen700: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginTop: "200px"
  },
  flexerScreen490: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    flexWrap: "wrap",
    marginTop: "900px"
  },
  each: {
    width: "30%",
    minWidth: "200px",
    marginBottom: "25px"
  },
  paper: {
    maxWidth: "1200px",
    width: "100%",
    //maxHeight: "775px",
    //height: "90%",
    position: "relative",
    overflowY: "initial"
  },
  paper1: {
    maxWidth: "400px",
    width: "100%",
    position: "relative",
    overflowY: "initial"
  },
  each1: {
    width: "50%",
    minWidth: "300px",
    marginBottom: "15px"
  },
  flexer1: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    marginTop: "30px"
  },
  selectWrapper: {
    width: "100%",

    background: "rgb(244, 244, 244)",
    borderRadius: "5px",

    "& .MuiOutlinedInput-root": {
      height: "38px"
    },
    "& .MuiInputLabel-formControl": {
      left: "10px",
      top: "10px",
      color: "gray",
      fontSize: "14px",
      transform: "translate(0, 0px) scale(1)"
    }
  },
  formControl: {
    marginTop: "5px"
  },
  selectDropDown: {
    "& .MuiSelect-iconOutlined": {
      right: "0px"
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px",
      color: "gray"
    },
    "& .MuiSvgIcon-root": {
      fontSize: "19px",
      fontWeight: "bolder"
    },
    "& .MuiSelect-icon": {
      top: "10px"
    },
    "& .MuiOutlinedInput-notchedOutline ": {
      borderColor: "lightgray !important",
      borderWidth: "1px !important"
    }
   
    
  },
  
   
}))

export default useStyles
