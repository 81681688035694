import React, { useState, useEffect, useContext } from "react"
import OfficersIcon from "../../assets/OfficersIcon.png"
import AddButton from "../Buttons/AddButton"
import { useStyles } from "./OfiicerManagementHeaderStyle"
import {connect, useDispatch} from "react-redux"
import { auth } from "../../Store/Actions/Auth"
import { barStyles } from "../SearchBar/SearchBarStyle"
import SearchIcon from "@material-ui/icons/Search"
import InputBase from "@material-ui/core/InputBase"
import { ScreenContext } from "./../../context/context"
import {finalStyles, newStyles} from "./../CourtsHeader/CourtsHeaderStyle"
import AddOfficerDialog from "../AddOfficersDialog/AddOfficerDialog"
import UploadCsvBtn from "../Buttons/UploadCsvBtn";
import UploadCsvModal from "../UploadCsvModal/UploadCsvModal";
import {GetAllOfficers} from "../../Store/Actions/Officers";
import Upload_Csv_File from "../UploadCsvModal/Upload_CSV_File";
import {GetAllCourt} from "../../Store/Actions/Court";
import useAuth from "../../utils/useAuth"

const ManagementHeader = ({searchText,isDisabled,setIsDisabled,data}) => {
  const classes = useStyles()
  const barclasses = barStyles()
  const finalclasses = finalStyles()
  const dispatch=useDispatch()
  const [addModal, setAddModal] = useState(false)
  const { screenSize, setScreenSize } = useContext(ScreenContext)
  const [openCSV, setOpenCSV] = useState(false)
  const { isOperator } = useAuth();

  useEffect(() => {
    setScreenSize(window.innerWidth)
  }, [screenSize])

  const [search,setSearch]=useState(false);
  const searchBtn =(e)=>{
    setSearch(!search);
  }

  return (
    <div
      className={
        screenSize > 800 ? classes.MainContainer : finalclasses.MainCont
      }
    >
      {screenSize > 800 ? (
        <div className={classes.HeaderInner}>
          <div className={classes.Wrapper}>
            <div
              className={classes.IconWrapper}
              style={{
                width: screenSize > 800 ? "47px !important" : "57px !important"
              }}
            >
              <img src={OfficersIcon} className={classes.icon} />
            </div>
            <p className={classes.MainHeading}>Officers management</p>
            {!isOperator?
            <div onClick={() => setAddModal(true)}>
            <AddButton lable="+ Add officer" />
            </div>:""
            }

            <div
              onClick={() => {
                setOpenCSV(true)
              }}
            >

              {/*UPLOAD CSV BUTTON CODE*/}
              {!isOperator?<UploadCsvBtn />:""}
            </div>

          </div>

          <div className={barclasses.SearchContainer}>
            {search===false?
              <div className={barclasses.search1}>
                <SearchIcon  onClick={searchBtn}  style={{ backgroundColor:"none"}}/>
              </div>:
              <div className={barclasses.search}>

                <div className={barclasses.searchIcon} >
                  <SearchIcon />

                </div>
                <InputBase
                  placeholder="Search…"
                  classes={{
                    root: barclasses.inputRoot,
                    input: barclasses.inputInput
                  }}
                  inputProps={{ "aria-label": "search" }}
                  onChange={searchText}

                  autoFocus
                />

              </div>
            }

          </div>
        </div>
      ) : (
        <div className={finalclasses.topMain}>
          <div className={finalclasses.upperMain}>
            <div className={finalclasses.flex}>
              <div className={classes.IconWrapper} style={{ width: "57px" }}>
                <img src={OfficersIcon} className={classes.icon} alt="" />
              </div>
              <p className={classes.MainHeading} style={{ fontSize: "12px" }}>
                Officers Management
              </p>
            </div>
            <div className={barclasses.SearchContainer}>
              <div className={barclasses.search}>
                <div className={barclasses.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search…"
                  classes={{
                    root: barclasses.inputRoot,
                    input: barclasses.inputInput
                  }}
                  inputProps={{ "aria-label": "search" }}
                  onChange={searchText}
                />
              </div>
            </div>
          </div>

          <div className={finalclasses.bottomCont}>
            <div onClick={() => setAddModal(true)}>
              <AddButton lable=" + Add officer" />
            </div>
            <div
              onClick={() => {
                setOpenCSV(true)
              }}
            >

              {/*UPLOAD CSV BUTTON CODE*/}
              <UploadCsvBtn />
            </div>
          </div>
        </div>
      )}
      <AddOfficerDialog openModal={addModal} isDisabled={isDisabled} setIsDisabled={setIsDisabled} officerData={data}  closeModal={() => {
        setAddModal(false)
        setIsDisabled(false)
      }}/>

      <Upload_Csv_File
        openCSV={openCSV}
        setOpenCSV={setOpenCSV}
        fileName="Officer"
        formateType="court, first_name, last_name, email, phone_number, receive_statement "
        apiUrl={`/api/v1/courts/probation/officer/probation_officer_from_csv/`}
        actionName={GetAllOfficers}

      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    accessToken: state.auth.accessToken
  }
}

export default connect(mapStateToProps, { auth })(ManagementHeader)
