import React from 'react'
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme)=>({
  MainPage: {
    display:'flex',
    flexDirection:'row',
    justifyContent:'flex-end',
    alignItems:'center',
    "& .MuiPaginationItem-textPrimary.Mui-selected": {
      color: "#fff",
      background: "#3971FF",
    }
  }

}))