import React, { useEffect, useRef, useState } from 'react';
import Card from "@material-ui/core/Card";
import {Button, Switch} from '@material-ui/core';
import CardContent from "@material-ui/core/CardContent";
import SearchableDropDown from "../SearchableDropdown/SearchableDropDown";
import { addUserStyles, dialogStyles, useStyles } from "./UserDataTableStyle";
import InputPrefix from '../InputPrefix/InputPrefix';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import useAuth from "../../utils/useAuth";
import ActiveCourtDropDownData from "../CourtDropDownData/ActiveCourtDropDownData";
import CourtDropDownData from "../CourtDropDownData/CourtDropDownData";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
const CommonClientForm = ({ isAdding,  userData, setUserData, formError, validation, setOpenCourtModal, setDropDownInput,
                              paymentDropDownData, setOpenPaymentModal, officersDropDownData, setOpenOfficerModal, serviceDialog,
                              serviceDialogEdit, passedIndex, offierDataList, servicesDropDownData,media,setMedia,stateList
                              ,handleClick,territoryList,setOpenCreditModal,userTypeDropDownData,setOpenUserTypeModal
}) => {
    const history = useHistory()
    const { isOperator } = useAuth()
    const addUserClasses = addUserStyles()
    const userClasses = dialogStyles()
    const classes = useStyles()
    const imageUploader = useRef(null)
    const [isDisableService,setIsDisableService]=useState(false)
    const imageUploadHandler = () => {
        const found = imageUploader.current.click()
    }
      const label = { inputProps: { 'aria-label': 'Switch demo' } };
    const fileUploadHandler = async event => {
        let reader = new FileReader()
        let files = event.target.files[0]

        reader.onloadend = () => {
            if(isAdding){const updatedMedia = [...media, { name: files.name, url: reader.result }]
            setMedia(updatedMedia)
            const updateAttachment = userData.attachments;
            updateAttachment.push({ file: reader.result })
            setUserData({ ...userData, attachments: updateAttachment })}
            else{
                const updateAttachment=userData.attachments;
                updateAttachment.push({file:reader.result,is_new:true,is_delete:false,name:files.name})
                setUserData({...userData,attachments:updateAttachment})
            }
        }
        
        reader.readAsDataURL(files)

        imageUploader.current.value = ""

    }
    const imageDeleteHandler = (index, docs) => {
        media.splice(index, 1)
        setMedia([...media])
        const updateAttachment = userData.attachments;
        updateAttachment.splice(index, 1)
        setUserData({ ...userData, attachments: updateAttachment })

    }

    const inputEvent=(event)=>{
        const {name,value}=event.target;
        setUserData((prevalue)=>{
            return {
                ...prevalue,
                [name]: value,
            }
        })
    }

    const normalizeInput = (value, previousValue) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;
        if (!previousValue || value.length > previousValue.length) {
            // (111) 111-1111
            if (cvLength < 4) return currentValue;
            if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
        }
        return value
    };
     const handlePhNoChange = (event) => { 
        const {name,value}=event.target;  
        setUserData(prevState=>({...prevState,[name]:normalizeInput(value,prevState[name])}));
      };
    const handleEnter = (event) => {
        if (event.key.toLowerCase() === "enter") {
            event.preventDefault();
            setTimeout( () => {
                const form = event.target.form;
                const index = [...form].indexOf(event.target);
                form.elements[index + 1].focus();
            }, 200 );
        }
    };
    const handleDateChange = (event) => {
        let updatedDate=new Date(event).toLocaleDateString('fr-CA')
        if(event===null)
        {
            setUserData({...userData,date_of_birth:null})
        }
        else {
            setUserData({...userData, date_of_birth:updatedDate})
        }
        
    };

    const openHsitoryPage = () => {
      localStorage.setItem('userId', userData.id)
      history.push({
      pathname: '/balance',
      state: {
        data: userData,
        courtData: courtData,
        paymentPlanData: paymentPlanData,
      },
    })
  }
    const { data: courtData, responseData: courtsData, count:courtDataLength, dataExist:courtsDataExist } = useSelector(({ court }) => court)
  const { data: paymentPlanData, responseData: paymentPlan } = useSelector(
    ({ paymentPlan }) => paymentPlan
  )
  const ShowCourt = courtId => {
    const foundData = courtData?.find(item => item.id === courtId)
    return foundData?.name
  }
  const ShowPaymentPlan = paymentId => {
    const foundData = paymentPlanData?.find(item => item.id === paymentId)
    return foundData?.name
  }
    return (<Card classes={{ root: addUserClasses.root }}>
        <CardContent>
            <div className={addUserClasses.majorDiv}>
                <p className={userClasses.formHead}>{passedIndex[0].selected && !isOperator ?
                    <>
                        <strong>
                            Admin ID#
                        </strong>
                    </> : <strong>Client ID# </strong>
                }
                </p>
                <div className={userClasses.flexer}>
                    <span className={userClasses.HorizontalLine}></span>
                    {
                        passedIndex[0].selected && !isOperator &&
                        <div className={addUserClasses.each}>
                            <span>USER TYPE</span>
                        <SearchableDropDown
                          inputValue={(input)=>setDropDownInput(input)}
                          data={userTypeDropDownData()}
                          handleClick={val => {
                            setUserData({...userData,user_type:val,court:""})
                          }}
                          openModalHandler={() => setOpenUserTypeModal(true)}
                          selectedValue={userData.user_type}
                          className={addUserClasses.selectCont}
                          onKeyDown={handleEnter}
                        />
                        </div>
                    }
                    {userData.user_type===2 || userData.user_type===3 ?
                      <div className={addUserClasses.each}>
                          <span >COURT SYSTEM</span>
                          <ActiveCourtDropDownData
                            handleSelect={val =>
                            {
                                officersDropDownData(val)
                                if(userData.pay_plan_procedure===1){
                                    servicesDropDownData("court",val)
                                }
                                if(userData.pay_plan_procedure===2){
                                    servicesDropDownData("client",val)
                                }
                                if(userData.pay_plan_procedure===3){
                                    servicesDropDownData("hybrid",val)
                                }
                                setUserData({ ...userData, court: val })
                                setIsDisableService(true)
                            }
                            }
                            openModal={() => setOpenCourtModal(true)}
                            inputValue={(input) => setDropDownInput(input)}
                            selectedValue={userData.court}
                            onKeyDown={handleEnter}
                          />
                          <span className={addUserClasses.spanErr}>
                            {formError?.court} {validation?.court}
                        </span>
                      </div>:null
                    
                    }
                   


                    {passedIndex[0].selected===false && <div className={addUserClasses.each}>
                        <span >PAYMENT PLAN</span>
                        <SearchableDropDown
                            data={paymentDropDownData()}
                            handleClick={val =>{
                                if(userData.pay_plan_procedure!==2 && val===2)
                                {servicesDropDownData("client",userData.court)
                            }
                                if(userData.pay_plan_procedure!==1 && val===1)
                                {servicesDropDownData("court",userData.court)
                            }
                                if(userData.pay_plan_procedure!==3 && val===3)
                                {servicesDropDownData("hybrid",userData.court)
                            }
                                setUserData({ ...userData, pay_plan_procedure: val})}
                            }
                            openModalHandler={() => setOpenPaymentModal(true)}
                            inputValue={(input) => {setDropDownInput(input)}}
                            selectedValue={userData.pay_plan_procedure}
                            onKeyDown={handleEnter}
                        />

                        <span className={addUserClasses.spanErr}>
                            {formError?.pay_plan_procedure}
                            {validation?.pay_plan_procedure}
                        </span>
                    </div>}



                    <div className={addUserClasses.each}>
                        <span>FIRST NAME</span>
                        <input
                            type="text"
                            name="first_name"
                            placeholder="First Name"
                            className={addUserClasses.selectCont}
                            value={userData.first_name}
                            onChange={inputEvent}
                            onKeyDown={handleEnter}
                        />
                        <span className={addUserClasses.spanErr}>
                            {formError?.first_name} {validation?.first_name}
                        </span>
                    </div>


                    <div className={addUserClasses.each}>
                        <span>MIDDLE NAME</span>
                        <input
                            type="text"
                            name="middle_name"
                            placeholder="Middle Name"
                            className={addUserClasses.selectCont}
                            value={userData.middle_name}
                            onChange={(e)=>{
                                inputEvent(e)}}
                            onKeyDown={handleEnter}
                        />
                        <span className={addUserClasses.spanErr}>
                            {formError?.middle_name}
                            {validation?.middle_name}
                        </span>
                    </div>


                    <div className={addUserClasses.each}>
                        <span>LAST NAME</span>
                        <input
                            type="text"
                            name="last_name"
                            placeholder="Last Name"
                            className={addUserClasses.selectCont}
                            value={userData.last_name}
                            onChange={inputEvent}
                            onKeyDown={handleEnter}
                        />
                        <span className={addUserClasses.spanErr}>
                            {formError?.last_name}
                            {validation?.last_name}
                        </span>
                    </div>

                    <div className={addUserClasses.eachDate}>
                        <span>DATE OF BIRTH</span>
                        <input
                          type="date"
                          className={addUserClasses.selectCont}
                          value={userData.date_of_birth}
                          onChange={e=>setUserData({...userData,date_of_birth:e.target.value })}
                          min="0001-01-01"
                          max="9999-12-31"
                          placeholder="dd/mm/yyyy"
                          onKeyDown={handleEnter}
                        />
                        <span className={addUserClasses.spanErr}>
                            {formError?.date_of_birth}
                            {validation?.date_of_birth}
                        </span>
                    </div>


                    {passedIndex[0].selected===false && <div className={addUserClasses.each}>
                        <span >PROBATION OFFICER</span>
                        <SearchableDropDown
                            data={offierDataList}
                            handleClick={val =>
                                setUserData({ ...userData, parole_officer: val })
                            }
                            openModalHandler={() => setOpenOfficerModal(true)}
                            inputValue={(input) => setDropDownInput(input)}
                            selectedValue={userData.parole_officer}
                            onKeyDown={handleEnter}
                        />

                        <span className={addUserClasses.spanErr}>
                            {formError?.parole_officer}
                            {validation?.parole_officer}
                        </span>
                    </div>}


                    <div className={addUserClasses.each}>
                        <span>ADDRESS 1</span>
                        <input
                            type="text"
                            name="address"
                            placeholder="Full address"
                            className={addUserClasses.selectCont}
                            value={userData.address}
                            onChange={inputEvent}
                            onKeyDown={handleEnter}
                        />
                        <span className={addUserClasses.spanErr}>
                            {formError?.address}
                            {validation?.address}
                        </span>
                    </div>

                    <div className={addUserClasses.each}>
                        <span>ADDRESS 2</span>
                        <input
                            type="text"
                            name="address2"
                            placeholder="Full address"
                            className={addUserClasses.selectCont}
                            value={userData.address2}
                            onChange={inputEvent}
                            onKeyDown={handleEnter}
                        />
                        <span className={addUserClasses.spanErr}>
                            {validation?.address2}
                        </span>
                    </div>

                    <div className={addUserClasses.each}>
                        <span>CITY</span>
                        <input
                            type="text"
                            name="city"
                            placeholder="City"
                            className={addUserClasses.selectCont}
                            value={userData.city}
                            onChange={inputEvent}
                            onKeyDown={handleEnter}
                        />
                        <span className={addUserClasses.spanErr}>
                            {validation?.city}
                        </span>
                    </div>

                    <div className={addUserClasses.each}>
                        <span>STATE</span>
                        <SearchableDropDown
                          data={stateList}
                          handleClick={handleClick}
                          selectedValue={userData.state.id}
                          onKeyDown={handleEnter}
                        />

                        <span className={addUserClasses.spanErr}>
                            {validation?.state}
                        </span>
                    </div>

                    <div className={addUserClasses.each}>
                        <span>POSTAL CODE</span>
                        <input
                            type="text"
                            name="postal_code"
                            maxLength={5}
                            placeholder="Postal Code"
                            className={addUserClasses.selectCont}
                            value={""+userData.postal_code}
                            onChange={inputEvent}
                            min="0"
                            onKeyDown={handleEnter}
                        />
                        <span className={addUserClasses.spanErr}>
                            {validation?.postal_code}
                        </span>
                    </div>

                    <div className={addUserClasses.each}>
                        <span>OCCUPATION</span>
                        <input
                            type="text"
                            name="occupation"
                            placeholder="Occupation"
                            className={addUserClasses.selectCont}
                            value={userData.occupation}
                            onChange={inputEvent}
                            onKeyDown={handleEnter}
                        />
                        <span className={addUserClasses.spanErr}>
                            {validation?.occupation}
                        </span>
                    </div>

                    {passedIndex[0].selected===false && <div className={addUserClasses.each}>
                        <span>CASE NUMBER</span>
                        <input
                            type="text"
                            name="case_number"
                            placeholder="Case Number"
                            className={addUserClasses.selectCont}
                            value={userData.case_number}
                            onChange={inputEvent}
                            onKeyDown={handleEnter}
                        />
                        <span className={addUserClasses.spanErr}>
                            {validation?.case_number}
                        </span>
                    </div>}

                    <div className={addUserClasses.each}>
                        <span>TERRITORY</span>
                        <SearchableDropDown
                          data={territoryList}
                          handleClick={val =>{

                              setUserData({ ...userData, territory: val })}
                          }
                          selectedValue={userData?.territory.id?userData?.territory.id:""}
                          onKeyDown={handleEnter}
                        />
                        <span className={addUserClasses.spanErr}>
                            {validation?.territory}
                        </span>
                    </div>

                    {passedIndex[0].selected===false && <div className={addUserClasses.each}>
                        <span>JUDGE NAME</span>
                        <input
                            type="text"
                            name="judge_name"
                            placeholder="Judge Name"
                            className={addUserClasses.selectCont}
                            value={userData.judge_name}
                            onChange={inputEvent}
                            onKeyDown={handleEnter}
                        />
                        <span className={addUserClasses.spanErr}>
                            {validation?.judge_name}
                        </span>
                    </div>}

                    <div className={addUserClasses.each}>
                        <span>EMERGENCY CONTACT</span>

                        <input
                            type="text"
                            name="emergency_contact"
                            placeholder="(000) 000-0000"
                            className={addUserClasses.selectCont}
                            value={userData.emergency_contact}
                            onChange={handlePhNoChange}
                            // required
                            min="0"
                            onKeyDown={handleEnter}
                        />
                        <span className={addUserClasses.spanErr}>
                            {formError?.emergency_contact}
                            {validation?.emergency_contact}
                        </span>
                    </div>


                    <div className={addUserClasses.each}>
                        <span>EMAIL ADDRESS</span>
                        <input
                            type="text"
                            name="email"
                            placeholder="user@mail.com"
                            className={addUserClasses.selectCont}
                            value={userData.email}
                            onChange={inputEvent}
                            onKeyDown={handleEnter}
                        // required
                        />
                    </div>
                    <div className={addUserClasses.each}>
                        <span>HOME PHONE</span>
                        <input
                            type="text"
                            name="home_phone"
                            placeholder="(000) 000-0000"
                            className={addUserClasses.selectCont}
                            value={userData.home_phone}
                            onChange={e=>{handlePhNoChange(e)}}
                            // required
                            min="0"
                            onKeyDown={handleEnter}
                        />
                        <span className={addUserClasses.spanErr}>
                            {formError?.home_phone}
                            {validation?.home_phone}
                        </span>
                    </div>


                    <div className={addUserClasses.each}>
                        <span>CELL PHONE</span>
                        <input
                            type="text"
                            name="phone"
                            placeholder="(000) 000-0000"
                            className={addUserClasses.selectCont}
                            value={userData.phone}
                            onChange={handlePhNoChange}
                            // required
                            min="0"
                            onKeyDown={handleEnter}
                        />
                        <span className={addUserClasses.spanErr}>
                            {formError?.phone}
                            {validation?.phone}
                        </span>
                    </div>


                    <div className={addUserClasses.each}>
                        <span>ALTERNATIVE PHONE</span>
                        <input
                            type="text"
                            name="alt_phone"
                            placeholder="(000) 000-0000"
                            className={addUserClasses.selectCont}
                            value={userData.alt_phone}
                            onChange={handlePhNoChange}
                            //required
                            min="0"
                            onKeyDown={handleEnter}
                        />
                        <span className={addUserClasses.spanErr}>
                            {formError?.alt_phone}
                            {validation?.alt_phone}
                        </span>
                    </div>
                    {userData.user_type===1 ?
                      <div className={addUserClasses.each}>
  
                      </div>:null
                    }
                   

                    {isAdding?(<div className={classes.CreditfirstDiv1}>
                        <span style={{ fontWeight: 600 }}>Recite Attachment</span>
                        <div
                            className={classes.inputFileWrapper1}
                            onClick={imageUploadHandler}
                        >
                            <input
                                type="file"
                                ref={imageUploader}
                                style={{ display: "none" }}
                                onChange={fileUploadHandler}
                                onKeyDown={handleEnter}
                            />

                            <div className={classes.imageText}>
                                <span className="uploadImageText">
                                    Drop document to upload. Or
                                </span>
                                <br />
                                <span className={classes.uploadInstruction}>
                                    press here to browse
                                </span>
                            </div>
                        </div>
                        <span className={addUserClasses.spanErr}>
                            {validation?.attachments}
                        </span>

                        <div className={classes.imageContainer}>
                            {media?.map((doc, index) => {
                                return(
                                <span className={classes.imageWrapper} key={index}>
                                    <span onClick={() => imageDeleteHandler(index, doc)}>
                                        <i
                                            className="fal fa-times fa-lg"
                                            style={{
                                                paddingRight: "10px",
                                                fontSize: "16px",
                                                cursor: "pointer"
                                            }}
                                        />
                                    </span>{" "}
                                    {doc.name}
                                </span>
                            )})}
                        </div>


                    </div>):(<div className={userClasses.each}>
              <span>Recite Attachment</span>
              <div
                  className={classes.inputFileWrapper1}
                  onClick={imageUploadHandler}>
                  <input
                    type="file"
                    ref={imageUploader}
                    style={{display: "none"}}
                    onChange={fileUploadHandler}
                    accept="image/png,image/jpeg,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    onKeyDown={handleEnter}
                  />

                  <div className={classes.imageText}>
                      <span className="uploadImageText">
                        Drop document to upload. Or
                      </span>
                    <br/>
                    <span className={classes.uploadInstruction}>
                            press here to browse
                          </span>
                  </div>
              </div>
              <div className={userClasses.attachments}>
              {userData.attachments?.map((element,index)=>{
                if(!userData.attachments[index]?.id)
                return(<div className={classes.imageContainer} key={index}>
                    <span className={classes.imageWrapper}>
                              <span onClick={() => imageDeleteHandler(index, element)}>
                                <i
                                  className="fal fa-times fa-lg"
                                  style={{
                                    paddingRight: "10px",
                                    fontSize: "16px",   
                                    cursor: "pointer"
                                  }}
                                />
                              </span>{" "}
                          {element.name !== "attachment"?element.name:`Attachment-${index+1}`}
                            </span>
                    </div>)
                if(!userData.attachments[index].is_delete)
                return(
                <React.Fragment key={index}>
                  <span>
                    <i
                      className="fal fa-times fa-lg"
                      style={{
                        paddingLeft: "10px",
                        fontSize: "16px",
                        cursor: "pointer"
                      }}
                      onClick={()=>{
                        let newAttachment=userData.attachments;
                        newAttachment[index]["is_delete"]=true;
                        newAttachment[index]["is_new"]=false;
                        setUserData({...userData,attachments:newAttachment})}
                      }
                        
                    />
                  </span>
                  <a href={element.file} className={userClasses.selectCont2} target="_blank">{element.name !== "attachment"?element.name:`Attachment-${index+1}`}</a>
                  </React.Fragment>
                )
                })}
    
              
              </div>
            </div>)}

                    {+userData.user_type===2 && <div className={addUserClasses.each}>
                        <span>ADD SERVICE FOR CLIENT</span>
                        <button
                          className={userData.court ? addUserClasses.selectCont1:  !isDisableService? addUserClasses.disableSelectCont1 :addUserClasses.selectCont1} onClick={isAdding?serviceDialog:serviceDialogEdit} disabled={userData.court?false :!isDisableService}>ADD SERVICE</button>
                    </div>}
                    {+userData.user_type===2 &&
                      <>
                    <div className={userData.total_price?addUserClasses.each:addUserClasses.eachHide}>
                        <span>RECURRING AMOUNT</span>
                        <InputPrefix>
                        <input
                            type="number"
                            style={{outline:"none"}}
                            placeholder="&nbsp;&nbsp;&nbsp;&nbsp;Type..."
                            className={addUserClasses.selectContPrice}
                            value={""+userData.total_price>0?userData.total_price.toFixed(2):0}
                            readOnly
                            onKeyDown={handleEnter}
                        />
                        </InputPrefix>
                    </div>
                      <div className={userData.one_time_total_price?addUserClasses.each:addUserClasses.eachHide}>
                          <span>ONE-TIME AMOUNT</span>
                          <InputPrefix>
                              <input
                                type="number"
                                style={{outline:"none"}}
                                placeholder="&nbsp;&nbsp;&nbsp;&nbsp;Type..."
                                className={addUserClasses.selectContPrice}
                                value={""+userData.one_time_total_price>0?userData.one_time_total_price.toFixed(2):0}
                                readOnly
                                // min="0"
                                onKeyDown={handleEnter}
                              />
                          </InputPrefix>

                      </div>
                          {!isAdding && <>
                              <div className={addUserClasses.each}>
                              <span>ADD CREDIT FOR CLIENT</span>
                              <button
                                className={addUserClasses.selectCont1} onClick={(e)=>{
                                    e.preventDefault()
                                    setOpenCreditModal(true)}}>ADD CREDIT
                              </button>
                          </div>
                              <div className={addUserClasses.each}>
                              <span>ADD CREDIT CARD CLIENT</span>
                              <button
                                className={addUserClasses.selectCont1} onClick={(e)=>{
                                    e.preventDefault()
                                    openHsitoryPage()}}>ADD CREDIT CARD
                              </button>
                          </div>
                              </>
                          }

                    </>
                    }


                    {(isAdding===true && +userData?.pay_plan_procedure === 3 && +userData?.user_type===2) ||(isAdding===false && +userData?.pay_plan_procedure === 3 && +userData?.user_type===2) ? <div className={addUserClasses.each}>
                        <span>CLIENT PAY</span>
                        <InputPrefix>
                        <input
                            type="number"
                            placeholder="Type..."
                            className={addUserClasses.selectContPrice}
                            value={userData.user_amount}
                            onKeyDown={handleEnter}
                            disabled
                            // min="0"
                        />
                        </InputPrefix>
                        <span className={addUserClasses.spanErr}>
                            {validation?.user_amount}
                        </span>
                    </div> : null}
                    {(isAdding===true && +userData?.pay_plan_procedure === 3 && +userData?.user_type===2) ||(isAdding===false && +userData?.pay_plan_procedure === 3 && +userData?.user_type===2)? <div className={addUserClasses.each}>
                        <span>COURT PAY</span>
                        <InputPrefix>
                        <input
                            type="number"
                            style={{outline:"none"}}
                            placeholder="&nbsp;&nbsp;&nbsp;&nbsp;Type..."
                            className={addUserClasses.selectContPrice}
                            value={userData.court_amount}
                            readOnly
                            disabled
                            onKeyDown={handleEnter}
                        />
                        </InputPrefix>
                        <span className={addUserClasses.spanErr}>
                            {validation?.court_amount}
                        </span>
                    </div> : null}
                    {userData.user_type == 2?
                    <>
                        <div className={addUserClasses.each}>
                    <span>Auto Recharge</span>
                       <br/>
                    <Switch {...label} checked={userData?.is_auto_recharge} onChange={e=>setUserData({...userData,is_auto_recharge:e.target.checked })} color="primary"/>
                </div>
                    </>:null
                    }

                    {userData?.is_auto_recharge ?
                        <>
                        <div className={addUserClasses.each}>
                        <span>Threshold</span>
                        <input
                            type="number"
                            name="threshold"
                            placeholder="Threshold"
                            className={addUserClasses.selectCont}
                            value={userData?.threshold}
                            onChange={e=>{
                                let value = 0;
                                if(e.target.value.length === 0){
                                    value = 0
                                }
                                else{
                                    value = parseFloat(e.target.value)
                                }
                                setUserData({...userData,threshold: `${value}`})
                            }}
                            onKeyDown={handleEnter}
                        />

                    </div>
                        <div className={addUserClasses.each}>
                        <span>Refill Amount</span>
                        <input
                            type="number"
                            name="threshold"
                            placeholder="Refill Amount"
                            className={addUserClasses.selectCont}
                            value={userData?.refill_amount}
                            onChange={e=>{
                                let value = 0;
                                if(e.target.value.length === 0){
                                    value = 0
                                }
                                else{
                                    value = parseFloat(e.target.value)
                                }
                                setUserData({...userData,refill_amount:`${value}` })
                            }}
                            onKeyDown={handleEnter}
                        />

                    </div>
                        </>
                        : null
                    }
                    <div className={addUserClasses.each}></div>

                </div>
            </div>
        </CardContent>
    </Card>)
}

export default CommonClientForm