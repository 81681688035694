import { makeStyles } from "@material-ui/core/styles";


export const useStyles = makeStyles((theme) => ({
  Container: {
    display: "flex",
    justifyContent: "flex-end",
  },
  Previous: {
    textTransform: "capitalize",
    "& .MuiButton-label": {
      fontFamily: theme.fonts.family.Bold,
      fontSize: theme.fonts.sizes.fontL,
      fontWeight: 500,
    }
  },
  Next: {
    textTransform: "capitalize",
    color: "#C1123D !important",
    "& .MuiButton-label": {
      fontFamily: theme.fonts.family.Bold,
      fontSize: theme.fonts.sizes.fontL,
      fontWeight: 500,
    }
  }
}));

