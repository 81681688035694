import React,{useState} from 'react'
import {Dialog, Button, Switch} from "@material-ui/core"
import Logo from "../../assets/AMSLogo.png"
import useToast from "../Toast/useToast"
import { useDispatch } from "react-redux"
import { useStyles } from './AddServicesDialogStyles'
import axios from "../../Store/request"
import { addServiceData, GetAllServices } from '../../Store/Actions/Services'
import CircularSpinner from "../CircularProgressUserReport/CircularSpinner";


const AddServicesDialog = ({openModal,closeModal,serviceData,isDisabled,setIsDisabled}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [creditLoading,setCreditLoading]=useState(false)
  const { showToast } = useToast()

  const [addData, setAddData] = useState({
    name: "",
    description: "",
    one_time_fee: false
  })
  
  const [errorService,setErrorService]=useState("")

  const addService =async e => {
    e.preventDefault()
    e.stopPropagation()
    let existsService=serviceData.find((element)=>element.name===addData.name)
    if(existsService)
    {
      setErrorService("*Service is already Exists")
      return
    }
    let data = JSON.stringify(addData)
    try{
      setIsDisabled(true)
      setCreditLoading(true)
      const response=await axios.post("/api/v1/services/", data)
      if (response.status === 201) {
        showToast("Service Added Successfully", "success")
        dispatch(addServiceData(response.data))
        closeModal()
        dispatch(GetAllServices())
        setAddData({ name: "",
        description: "",
        one_time_fee: false})
        setCreditLoading(false)
        setIsDisabled(false)
        setErrorService("")
      }
    }catch(error){
      setCreditLoading(false)
      setIsDisabled(false)
      showToast("An error occured please try again later", "error")
      setErrorService("")
    }
    
  }
  const label = { inputProps: { 'aria-label': 'Switch demo' } };


  return (
    <div>
       <Dialog
        open={openModal}
        scroll="body"
        onClose={()=> {
          closeModal()
          setAddData({ name: "",
            description: "",
          one_time_fee:false})
          setErrorService("")
        }}
        classes={{ paper: classes.paper }}
      >
         {
           creditLoading===true?
             (
               <CircularSpinner onModal/>
             )
             :(
               <div className={classes.topDiv}>
                 <div className={classes.LogoWrapper}>
                   <img src={Logo} className={classes.Logo} alt="Logo"/>
                 </div>
                 <form onSubmit={addService} className={classes.upperDiv}>
                   <div
                     style={{cursor: "pointer"}}
                     onClick={()=> {
                       closeModal()
                       setAddData({ name: "",
                         description: "",
                        one_time_fee:false})
                       setErrorService("")
                     }}
                   >
                     <i
                       className="fal fa-times fa-lg"
                       style={{position: "absolute", top: "3%", right: "4%"}}
                     ></i>
                   </div>

                   <div className={classes.firstDiv}>
                     <p className={classes.styleTag}>SERVICES NAME</p>
                     <input
                       type="text"
                       placeholder="Type..."
                       className={classes.selectCont}
                       value={addData.name}
                       onChange={e => {
                         setAddData({...addData, name: e.target.value})
                       }}
                       required
                     />
                     <span style={{color:"red"}}>{errorService}</span>
                   </div>
                   <div className={classes.firstDiv}>
                     <p className={classes.styleTag}>SERVICES DESCRIPTION</p>
                     <input
                       type="text"
                       placeholder="Type..."
                       className={classes.selectCont}
                       value={addData.description}
                       onChange={e => {
                         setAddData({...addData, description: e.target.value})
                       }}
                       required
                     />
                   </div>
                   <div className={classes.firstDiv}>
                     <span className={classes.styleTag}>One Time Fee</span>
                     <Switch {...label} onChange={e=>setAddData({...addData,one_time_fee:e.target.checked })} color="primary"/>
                   </div>
                   {isDisabled === false ?
                     <Button
                       variant="contained"
                       color="secondary"
                       className={classes.button}
                       disabled={addData.name === "" || addData.description === "" }
                       type="submit"

                     >
                       {"ADD SERVICE"}
                     </Button>
                     : <Button
                       variant="contained"
                       color="secondary"
                       className={classes.button}
                       disabled
                       type="submit"

                     >
                       {"ADD SERVICE"}
                     </Button>

                   }
                 </form>
               </div>
             )
         }

      </Dialog>
    </div>
  )
}

export default AddServicesDialog