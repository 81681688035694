import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  MainContainer: {
    width: "100%",
    // height: "100vh",
    backgroundColor: "#e8f2fe"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  containerMob: {
    // paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    // width: '1400px'
  },
  BtnContainer: {
    paddingBottom: 30,
    "& .MuiButton-contained": {
      "&:hover": {
        background: "#3971FF",
      }
    },
    "& .MuiButton-containedSecondary": {
      "&:hover": {
        background: "#F9F9F9 !important",
      }
    }
  },
  SelectedButton: {
    background: "#3971FF",
    marginRight: "20px",
    color: "white",
    width: 171,
    height: 36,
    borderRadius: 85,
    fontFamily: theme.fonts.family.Bold,
  },
  UnSelectedButton: {
    background: "#F9F9F9",
    color: "#3971FF",
    marginRight: "20px",
    width: 171,
    height: 36,
    borderRadius: 85,
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
    fontFamily: theme.fonts.family.Bold,

  },
  BtnContainerAlt: {
    paddingBottom: 30,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    height: '150px',
    "& .MuiButton-contained": {
      "&:hover": {
        background: "#3971FF",
      }
    },
    "& .MuiButton-containedSecondary": {
      "&:hover": {
        background: "#F9F9F9 !important",
      }
    }
  },
  InvoiceButton: {
    background: "#3971FF",
    marginRight: "20px",
    color: "white",
    width: 185,
    height: 36,
    borderRadius: 85,
    fontFamily: "Open Sans",
    fontWeight:600,
    fontSize: 16,
    '&:disabled':{
      background: "#3971FF",
      marginRight: "20px",
      color: "white",
      width: 185,
      height: 36,
      borderRadius: 85,
      fontFamily: "Open Sans",
      fontWeight:600,
      fontSize: 16,
    }
  },
  PrepaidButton: {
    background: "#F9F9F9",
    marginRight: "20px",
    color: "#3971FF",
    width: 185,
    height: 36,
    borderRadius: 85,
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
    fontFamily: "Open Sans",
    fontWeight:600,
    fontSize: 16,
    '&:disabled':{
      background: "#F9F9F9",
      marginRight: "20px",
      color: "#3971FF",
      width: 185,
      height: 36,
      borderRadius: 85,
      boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
      fontFamily: "Open Sans",
      fontWeight:600,
      fontSize: 16,
    }

  },
  filterdiv: {
    background: '#FFFFFF',
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    width: '130px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    height: '37px',
    alignItems: 'center',
    cursor: 'pointer',
    fontFamily:"Open Sans",
    fontWeight:400,
    fontSize:14,
  },
  fixButton:{

    width:"150%"
  },
  // filetrFix:{
  //   border:"2px solid black"
  // }
 
}));