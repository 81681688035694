import Actions from "../Type"
import axios from "../request"

export const GetPaymentPlan = () => {
  return async dispatch => {
    try {
      dispatch({ type: Actions.GET_PAYMENT_PLAN_REQUEST })
      const response = await axios.get("manage-users/pay_plan/")
      dispatch({
        type: Actions.GET_PAYMENT_PLAN_SUCCESS,
        payload: { results: response.data.results, count: response.data.count, dataExist: response.data.results.length>0?true:false}
      })
    } catch (e) {
      dispatch({ type: Actions.GET_PAYMENT_PLAN_FAIL })
    }
  }
}


export const addPaymentPlanData=(data)=>{
  return async dispatch => {
    try {
      dispatch({
        type: Actions.ADD_PAYMENT_PLAN_DATA,
        payload: data
      })
     
    } catch (e) {
    }
  }
}