import Vector12 from "../../assets/Vector12.png"
import OfficersIcon from "../../assets/OfficersIcon.png"
import PricingIcon from "../../assets/PricingIcon.png"
import ServicesIcon from "../../assets/ServicesIcon.png"
import UsersIcon from "../../assets/UsersIcon.png"
import PaymentIcon from "../../assets/PaymentIcon.png"
import ReportsIcon from "../../assets/ReportsIcon.png"



export const adminDataArray = [
  {
    Name: "Courts",
    icon: Vector12,
    path: "/courts",
    id: 1
  },
  {
    Name: "Officers",
    icon: OfficersIcon,
    path: "/officers",
    id: 2
  },
  {
    Name: "Court Pricing",
    icon: PricingIcon,
    path: "/court-pricing",
    id: 3
  },
  {
    Name: "Client Pricing",
    icon: PricingIcon,
    path: "/client-pricing",
    id: 4,
  },
  {
    Name: "Services",
    icon: ServicesIcon,
    path: "/services",
    id: 5,
  },
  {
    Name: "Manage Clients",
    icon: UsersIcon,
    path: "/manage-users",
    id: 6
  },
  {
    Name: "Reports",
    icon: ReportsIcon,
    path: "/reports",
    id: 8
  },
]

export const courtDataArray = [

  {
    Name: "Users",
    icon: UsersIcon,
    path: "/User",
    id: 1
  },
  {
    Name: "Payments",
    icon: PaymentIcon,
    path: "/Balance",
    id: 2
  },
]

export const operatorDataArray = [
  {
    Name: "Courts",
    icon: Vector12,
    path: "/courts",
    id: 1
  },
  {
    Name: "Officers",
    icon: OfficersIcon,
    path: "/officers",
    id: 2
  },
  {
    Name: "Court Pricing",
    icon: PricingIcon,
    path: "/court-pricing",
    id: 3
  },
  {
    Name: "Client Pricing",
    icon: PricingIcon,
    path: "/client-pricing",
    id: 4,
  },
  {
    Name: "Manage Clients",
    icon: UsersIcon,
    path: "/manage-users",
    id: 6
  },
  {
    Name: "Reports",
    icon: ReportsIcon,
    path: "/reports",
    id: 8
  },
]

export const userDataArray = [
  {
    Name: "Balance",
    icon: PaymentIcon ,
    path: "/balance",
    id: 2
  }
]
