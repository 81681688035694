import React, { useState } from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import { useStyles } from "./AddPaymentModalStyle.js"
import { DataArray } from "../PaymentDataTable/PaymentDataArray.js"
import Logo from "../../assets/AMSLogo.png"
import { useDispatch } from "react-redux"
import axios from "../../Store/request"
import useToast from "../../Components/Toast/useToast"
import { addPaymentPlanData } from "../../Store/Actions/PaymentPlan"

export const AddPaymentModal = ({ open, close,data }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { showToast } = useToast()
  const [paymentData, setPaymentData] = useState({
    paymentType: "",
    planName: "",
    planDuration: "",
    serviceAmount: "",
    oneTimePayment: "",
    userName: "",
    courtName: "",
    amount: "",
    overRide: ""
  })

  const addPaymentPlanFunc =async e => {
    e.preventDefault()
    e.stopPropagation()
 
    let finalObj = {name:paymentData.userName }
    let addData = JSON.stringify(finalObj)
    try{
      const response=await axios.post("manage-users/pay_plan/", addData)
      if (response.statusText === "Created") {
        dispatch(addPaymentPlanData(response.data))
        showToast("Payment Plan Added Successfully", "success")
        close()
        setPaymentData({
          paymentType: "",
          planName: "",
          planDuration: "",
          serviceAmount: "",
          oneTimePayment: "",
          userName: "",
          courtName: "",
          amount: "",
          overRide: ""
        })  
      }
    }catch(error){
      showToast("Couldnot add payment plan, please try later", "error")
    }
   
  }

  return (
    <Dialog
      open={open}
      scroll="body"
      // keepMounted
      onClose={close}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      overlayStyle={{ backgroundColor: "transparent" }}
      classes={{ paper: classes.paper }}
    >
      <div className={classes.topDiv}>
        <div className={classes.LogoWrapper}>
          <img src={Logo} className={classes.Logo} alt="Logo"/>
        </div>
        <form className={classes.upperDiv} onSubmit={addPaymentPlanFunc}>
          <div style={{ cursor: "pointer" }} onClick={close}>
            <i
              class="fal fa-times fa-lg"
              style={{ position: "absolute", top: "3%", right: "4%" }}
            />
          </div>

          <div className={classes.firstDiv}>
            <span style={{ fontWeight: 600, marginTop: "60px" }}>
              USER ID/NAME
            </span>
            <input
              type="text"
              placeholder="Type..."
              className={classes.selectCont}
              required
              value={paymentData.userName}
              onChange={e => {
                setPaymentData({ ...paymentData, userName: e.target.value })
              }}
            />
          </div>
          <div className={classes.firstDiv}>
            <span style={{ fontWeight: 600 }}>COURT NAME</span>
            <input
              type="text"
              placeholder="Type..."
              className={classes.selectCont}
              required
              value={paymentData.courtName}
              onChange={e => {
                setPaymentData({ ...paymentData, courtName: e.target.value })
              }}
            />
          </div>
          <div className={classes.firstDiv}>
            <span style={{ fontWeight: 600 }}>AMOUNT</span>
            <input
              type="number"
              placeholder="Type..."
              className={classes.selectCont}
              required
              value={paymentData.amount}
              onChange={e => {
                setPaymentData({ ...paymentData, amount: e.target.value })
              }}
            />
          </div>

          <div className={classes.checkboxWrapper}>
            <div className={classes.checkboxContLeft}>
              <input
                type="checkbox"
                className={classes.checkboxInput}
                             
                value={paymentData.amount}
                onChange={e => {
                  setPaymentData({
                    ...paymentData,
                    serviceAmount: e.target.value
                  })
                }}
              />

              <span style={{ fontWeight: 600, lineHeight: "35px" }}>
                CLIENT PAYMENT
              </span>
            </div>
            <div className={classes.checkboxContLeft}>
              <input
                type="checkbox"
                className={classes.checkboxInput}
                value={paymentData.oneTimePayment}
                onChange={e => {
                  setPaymentData({
                    ...paymentData,
                    oneTimePayment: e.target.value
                  })
                }}
               
              />
              <span style={{ fontWeight: 600, lineHeight: "35px" }}>
                COURT PAYMENT
              </span>
            </div>
          </div>

          <div className={classes.checkboxWrapper1}>
            <div className={classes.checkboxContLeft}>
              <input
                type="checkbox"
                className={classes.checkboxInput}
                value={paymentData.oneTimePayment}
                defaultChecked={true}
                onChange={e => {
                  setPaymentData({
                    ...paymentData,
                    oneTimePayment: e.target.value
                  })
                }}
              
              />
              <span style={{ fontWeight: 600, lineHeight: "35px" }}>
                HYBRID PAYMENT
              </span>
            </div>
          </div>

          {/* <div className={classes.bottomCont}>
            <div className={classes.bottomContLeft}>
              <span style={{ fontWeight: 600 }}>COURT</span>

              <input
                type="number"
                placeholder="$ Type..."
                className={classes.inputCont}
                required
                value={paymentData.serviceAmount}
                onChange={e => {
                  setPaymentData({
                    ...paymentData,
                    serviceAmount: e.target.value
                  })
                }}
              />
            </div>
            
            <div className={classes.bottomContRight}>
              <span style={{ fontWeight: 600 }}>CLIENT</span>

              <input
                type="number"
                placeholder="$ Type..."
                className={classes.inputCont}
                value={paymentData.oneTimePayment}
                onChange={e => {
                  setPaymentData({
                    ...paymentData,
                    oneTimePayment: e.target.value
                  })
                }}
                required
              />
            </div>
          </div> */}

          <div className={classes.firstDiv}>
            <span style={{ fontWeight: 600 }}>OVERRIDE</span>
            <input
              type="text"
              placeholder="Type..."
              className={classes.selectCont}
              required
              value={paymentData.overRide}
              onChange={e => {
                setPaymentData({ ...paymentData, overRide: e.target.value })
              }}
            />
          </div>
          <div className={classes.firstDiv}>
            <span style={{ fontWeight: 600 }}>PAYMENT TYPE</span>
            <select
              className={classes.selectCont}
              onChange={e => {
                setPaymentData({ ...paymentData, paymentType: e.target.value })
              }}
              required
              id="cars"
              name="paymentTypes"
            >
              <option disabled selected>
                Select
              </option>
              {DataArray.map(dataObj => {
                return (
                  <option value={dataObj.PaymentType} key={dataObj.id}>
                    {dataObj.PaymentType}
                  </option>
                )
              })}
            </select>
          </div>
          {/* <div className={classes.firstDiv}>
           
              <p style={{ fontWeight: 600 }}>PAYMENT PLAN NAME</p>
              <select className={classes.selectCont}
                onChange={(e) => { setPaymentData({ ...paymentData, planName: e.target.value }) }}
                required id="cars" name="paymentTypes" >
               <option value={'50 person /50 court'}>{'50 person / 50 court'}</option>
               <option value={'30 person /70 court'}>{'30 person / 70 court'}</option>
               <option value={'70 person /30 court'}>{'70 person / 30 court'}</option>
              </select>
        
          </div>
          <div className={classes.firstDiv}>
            <p style={{ fontWeight: 600 }}>DURATION</p>
            <input
              type="number"
              placeholder="Type..."
              className={classes.selectCont}
              required
              value={paymentData.planDuration}
              onChange={e => {
                setPaymentData({ ...paymentData, planDuration: e.target.value })
              }}
            />
          </div> */}

          <div className={classes.bottomCont}>
            <div className={classes.bottomContLeft}>
              <span style={{ fontWeight: 600 }}>PAYMENT AMOUNT</span>

              <input
                type="number"
                placeholder="$ Type..."
                className={classes.inputCont}
                required
                value={paymentData.serviceAmount}
                onChange={e => {
                  setPaymentData({
                    ...paymentData,
                    serviceAmount: e.target.value
                  })
                }}
              />
            </div>
            <div className={classes.bottomContRight}>
              <span style={{ fontWeight: 600 }}>START/END DATE</span>

              <input
                type="number"
                placeholder="$ Type..."
                className={classes.inputCont}
                value={paymentData.oneTimePayment}
                onChange={e => {
                  setPaymentData({
                    ...paymentData,
                    oneTimePayment: e.target.value
                  })
                }}
                required
              />
            </div>
          </div>

          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            
            type="submit"

            // startIcon={<AddIcon />}
          >
            {"CREATE PAYMENT  PLAN"}
          </Button>
        </form>
      </div>
    </Dialog>
  )
}
