import React from 'react'
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme)=>({
    customDialog:{
        '& .MuiBackdrop-root':{
            backgroundColor: 'rgba(0, 0, 0, 0.5 )'

        },
        '& .MuiPaper-elevation24':{
            boxShadow:"none",
        }
    },
    error:{
        fontFamily:"Open Sans",
        fontWeight:"800px",
        color:"#f50057",
        fontSize:"20px",
        padding: "0px 25px",
        marginTop:0
        // textAlign:"center"
      },

    styleRowWithBackground: {
        height: '40px',
        background: '#F8F8F8',
    },
    styleRowWithoutBackground: {
        height: '40px',
        background: 'none',
        fontWeight: 700,
        fontSize: 14,
        fontFamily: "Open Sans"
    },
    styleRowWithBorder: {
        height: '40px',
        background: 'none',
        fontWeight: 700,
        fontSize: 14,
        fontFamily: "Open Sans",
        boxShadow:'0px -2px 3px lightgray'
    },
    styleRow: {
        height: '40px',
    },

    closeIconDiv: {
        display:'flex',
        flexDirection:'row',
        justifyContent:'flex-end',
        alignItems:'center',
        width:'100%',
    },

    firstContLayer: {
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
        height:'50px',
        width:'100%',
        fontSize:'18px',
        fontWeight:'500',
        // marginTop:'30px'
    },

    aligner: {
        display:'flex',
        flexDirection:'column',
        justifyContent:'space-around',
        alignItems:'center'
    },

    tableDiv: {
        width:'100%',
        // height: "300px",
        marginTop:'20px',
        overflowY:"auto",
        overFlowX:"auto",
        maxHeight:"356px",
        "&::-webkit-scrollbar": {
            width: "8px",
            borderRadius:"50px",
            height:"8px"
        },
        "&::-webkit-scrollbar-track": {
            background: "lightGray" ,       /* color of the tracking area */
            borderRadius:"100px",
            ScrollbarWidth:" thin"
        },
        "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#888",    /* color of the scroll thumb */
        borderRadius: "50px",       /* roundness of the scroll thumb */
            "&:hover":{
                background:"#555"
            }
        }
    },
    tableDivOpenBalance: {
        width:'100%',
        // height: "300px",
        marginTop:'20px',
        // overflowY:"auto"
    },
    stickyHeader: {
        position: 'sticky',
        backgroundColor: 'white',
        top:0,
        width: '100%',
    },
    firstColumn: {
        textAlign: 'start',
        width: 200,
        paddingLeft: '25px',
        fontWeight:100
    },
    firstColumnClientRemoval: {
        textAlign: 'start',
        width: "20%",
        paddingLeft: '25px',
        fontWeight:100
    },
    openBalanceColumn:{
        textAlign: 'start',
        width:"16%"
    },
    openBalanceLastColumn:{
        textAlign: 'start',
        width:"16%",
        paddingRight:"25px"
    },
    clientRemovalColumn:{
        textAlign: 'start',
        width:"20%",
        fontWeight:100
    },
    clientRemovalLastColumn:{
        textAlign: 'start',
        width:"20%",
        fontWeight:100,
        paddingRight:"25px"
    },
    clientPayCallColumn:{
        textAlign: 'start',
        width:"12.5%",
        fontWeight:100
    },
    clientPayCallLastColumn:{
        textAlign: 'start',
        width:"12.5%",
        fontWeight:100,
        paddingRight: "25px"
    },
    salesByserviceColumn:{
        textAlign: 'start',
        width:"20%",
        fontWeight:100
    },
    salesByserviceLastColumn:{
        textAlign: 'start',
        width:"20%",
        fontWeight:100,
        paddingRight: "25px"
    },

    openBalanceBottom: {
        // borderTop:'1px solid rgba(0, 0, 0, 0.1519)',
        // marginTop:'20px',
        width:'100%',
        
        paddingLeft:'25px',
        paddingRight:'10px',
        alignItems:'center',
        fontWeight:'500',
        boxShadow:'0px -2px 3px lightgray'
    },
    openBalanceInner: {
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        width:'71%',
        fontSize:14,
        fontWeight:700
        
    }
}))