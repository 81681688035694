import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  MainContainer: {
    width: "100%",
    height: "100vh",
    backgroundColor: "#F8FBFF"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  containerMob: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: '1400px'
  },
  BtnContainer: {
    paddingBottom: 30,
    "& .MuiButton-contained": {
      "&:hover": {
        background: "#3971FF",
      }
    },
    "& .MuiButton-containedSecondary": {
      "&:hover": {
        background: "#F9F9F9 !important",
      }
    }
  },
  InvoiceButton: {
    background: "#3971FF",
    marginRight: "20px",
    color: "white",
    width: 151,
    height: 36,
    borderRadius: 85,
    fontFamily: theme.fonts.family.Bold,
  },
  PrepaidButton: {
    background: "#F9F9F9",
    color: "#3971FF",
    width: 151,
    height: 36,
    borderRadius: 85,
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
    fontFamily: theme.fonts.family.Bold,

  }
}));
