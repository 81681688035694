import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  MainContainer: {
    width: "100%",
  //  height: "100vh",
    backgroundColor: "#e8f2fe"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
   
  },
  containerMob:{
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
   // width:'1400px'
  },
  heading:{
   fontFamily: "Open Sans",
    fontSize: 16,
    fontWeight: 500,
  },
  button: {
    background: "#3971FF",
    color: "white",
    marginRight:10,
    padding: "2px 0px",
    fontFamily: "Open Sans",
    fontSize: "16px",
    textTransform:"unset",
    "&.MuiButton-containedSecondary": {
     // width: "80%",
      height: 25,
      display: "flex",
      alingItems: "center",
      borderRadius: "5px !important",
      marginTop: "5px",
      "&:hover": {
        background: "#3971FF"
      },
      "& .MuiButton-label": {
        fontFamily: "Open Sans",
        fontSize: 16,
        fontWeight: 600,
       
        marginTop: 3
      },
      "& .MuiSvgIcon-root": {
        marginTop: -2
      },
      "& .MuiButton-root":{
        padding: "0px"
      }
    }
  },
  button1: {
    background: "#3971FF",
    color: "white",
    position:"absolute",
    right:0,
    top:"-48px",
    fontSize: "16px",
    fontFamily: "Open Sans",
    marginRight:10,
    padding: "2px 0px",
    textTransform:"unset",
    "&.MuiButton-containedSecondary": {
     // width: "80%",
      height: 25,
      display: "flex",
      alingItems: "center",
      borderRadius: "5px !important",
      marginTop: "5px",
      "&:hover": {
        background: "#3971FF",
        backgroundColor: "#3971FF"
      },
      "& .MuiButton-label": {
        fontFamily: "Open Sans",
        fontSize: 16,
        fontWeight: 600,
       
        marginTop: 3
      },
      "& .MuiSvgIcon-root": {
        marginTop: -2
      },
      "& .MuiButton-root":{
        padding: "0px"
      }
    }
  },
  filterFix:{
    display:"flex",
    justifyContent:"flex-end",
  },
  filterdiv: {
    background: '#FFFFFF',
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    width: '130px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    height: '37px',
    alignItems: 'center',
    cursor: 'pointer',
    fontFamily:"Open Sans",
    fontWeight:400,
    fontSize:14,
    marginTop:"-35px",
  },
  filterdivOperator: {
    background: '#FFFFFF',
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    width: '130px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    height: '37px',
    alignItems: 'center',
    cursor: 'pointer',
    fontFamily:"Open Sans",
    fontWeight:400,
    fontSize:14,
    // marginTop:"-35px",
  },
}));
