import React, { useEffect, useState, useContext, useRef } from "react"
import PricingIcon from "../../assets/PricingIcon.png"
import { useStyles, newStyles } from "./ClientPricingHeaderStyle"
import AddBtn from "./AddBtn"
import { Dialog, Button } from "@material-ui/core"
import Logo from "../../assets/AMSLogo.png"
import { barStyles } from "../SearchBar/SearchBarStyle"
import SearchIcon from "@material-ui/icons/Search"
import InputBase from "@material-ui/core/InputBase"
import { finalStyles } from "./../CourtsHeader/CourtsHeaderStyle"
import { ScreenContext } from "./../../context/context"
import useToast from "../Toast/useToast"
import axios from "../../Store/request";
import {useDispatch, useSelector} from "react-redux";
import CircularSpinner from "../CircularProgressUserReport/CircularSpinner";
import SearchableDropDown from "./../SearchableDropdown/SearchableDropDown"
import { GetAllClientPricing } from "../../Store/Actions/ClientPricing"
import InputPrefix from "../InputPrefix/InputPrefix"
import UploadCsvBtn from "../Buttons/UploadCsvBtn";
import UploadCsvModal from "../UploadCsvModal/UploadCsvModal";
import CourtDropDownData from "../CourtDropDownData/CourtDropDownData";
import Upload_Csv_File from "../UploadCsvModal/Upload_CSV_File";
import {GetAllCourt} from "../../Store/Actions/Court";
import ActiveCourtDropDownData from "../CourtDropDownData/ActiveCourtDropDownData";
import useAuth from "../../utils/useAuth";

const ClientPricingHeader = ({ searchText, isDisabled, setIsDisabled, serviceList, setServiceList, addedClientPricing, setAddedClientPricing,
                               clientPricingData,setClientPricingData
}) => {
  const classes = useStyles()
  const newclasses = newStyles()
  const barclasses = barStyles()
  const ref = useRef()
  const finalclasses = finalStyles()
  const { screenSize, setScreenSize } = useContext(ScreenContext)
  const [openServiceModal, setOpenServiceModal] = useState(false)

  const dispatch = useDispatch()
  const [openCSV, setOpenCSV] = useState(false)
  const [creditLoading, setCreditLoading] = useState(false)
  const [isDropDownOpen, setIsDropDownOpen] = useState(true)
  const [dropDownInput, setDropDownInput] = useState("")
  // const [addedClientPricing,setAddedClientPricing] = useState(true)

  const { data: courts, count: courtsLength, dataExist: courtsDataExist } = useSelector(({ court }) => court)
  const { showToast } = useToast()
  const [open, setOpen] = useState(false)
  const { isOperator } = useAuth()

  const courtName = courts.find((element) => element.id === clientPricingData.court)

  useEffect(() => {
    setScreenSize(window.innerWidth)
  }, [screenSize])


  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (ref.current && !ref.current.contains(e.target)) {
        setIsDropDownOpen(false)

      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [ref])

  const addClientPricing = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    setCreditLoading(true)
    let finalObj = { ...clientPricingData, override_price:0}
    try {
      setIsDisabled(true)
      const response = await axios.post(
        "/manage-users/client_pricing/",
        finalObj
      )
      if (response.statusText === "OK") {
        dispatch(GetAllClientPricing())
        setOpen(false)
        showToast("Client Pricing Added Successfully", "success")
        setClientPricingData({
          ...clientPricingData,
          service: "",
          price: "",
        })
        setAddedClientPricing(true)
        setIsDisabled(false)
        setCreditLoading(false)
      }

    } catch (error) {
      setIsDisabled(false)
      setCreditLoading(false)
      if (error.response.data.service?.non_field_errors) {
        showToast(
          `${error.response.data.service?.non_field_errors}`,
          "error"
        )
      }
      if(error.response.status===403){
        showToast(
          `Couldnot add client pricing details, please try later`,
          "error"
        )
      }
    }
  }

  const [search, setSearch] = useState(false);
  const searchBtn = (e) => {
    setSearch(!search);
  }

  // const [serviceList, setServiceList] = useState([])
  const searchDropDown = async () => {
    if(addedClientPricing){
    const response = await axios.get(`/api/v1/services/services_add_client_pricing/?court=${clientPricingData.court}`)
    const serviceData = response.data?.map(item => {
      return ({ value: item.id, label: item.name })
    })
    setServiceList(serviceData)
    setAddedClientPricing(false)
  }
  }

  return (
    <div
      className={
        screenSize > 800 ? classes.MainContainer : finalclasses.MainCont
      }
    >
      {screenSize > 800 ? (
        <div className={classes.HeaderInner}>
          <div className={classes.Wrapper}>
            <div className={classes.IconWrapper}>
              <img src={PricingIcon} className={classes.icon} alt="Logo"/>
            </div>
            <p className={classes.MainHeading}>Client Pricing</p>
            <div className={classes.selection}>
              <ActiveCourtDropDownData
                handleSelect={val =>{
                  setAddedClientPricing(true)
                  setClientPricingData({ ...clientPricingData, court: val })
                }
                }
              />
            </div>
          <div
              onClick={() => {
                if (clientPricingData.court) {
                  setOpen(true)
                }
              }}
            >
            {!isOperator ? (
              <AddBtn signal={clientPricingData?.court ?true:false} searchDropDown={searchDropDown} />
            ): null
        }
            </div>


            <div
              onClick={() => {
                setOpenCSV(true)
              }}
            >

              {/*UPLOAD CSV BUTTON CODE*/}
              {!isOperator ? (
              <UploadCsvBtn />
            ): null
        }

            </div>
          </div>

        </div>
      ) : (
        <div className={finalclasses.topMain}>
          <div className={finalclasses.upperMain}>
            <div className={finalclasses.flex}>
              <div className={classes.IconWrapper} style={{ width: "57px" }}>
                <img src={PricingIcon} className={classes.icon} alt="" />
              </div>
              <p className={classes.MainHeading} style={{ fontSize: "12px" }}>
                {"Client Pricing"}
              </p>
              <div className={classes.selection}>
                <ActiveCourtDropDownData
                  handleSelect={val =>{
                    setAddedClientPricing(true)
                    setClientPricingData({ ...clientPricingData, court: val })
                  }
                  }
                />
              </div>
            </div>

          </div>

          <div className={finalclasses.bottomCont}>
            <div
              onClick={() => {
                if (clientPricingData.court) {
                  setOpen(true)
                }
              }}
            >
              <AddBtn signal={clientPricingData?.court ?true:false} searchDropDown={searchDropDown} />
            </div>
            <div
              onClick={() => {
                setOpenCSV(true)
              }}
            >

              {/*UPLOAD CSV BUTTON CODE*/}
              <UploadCsvBtn />
            </div>
          </div>
        </div>
      )}

      <Dialog
        open={open}
        scroll="body"
        // keepMounted
        onClose={() => {
          setOpen(false)
          setIsDisabled(false)
          setClientPricingData({
            service: "",
            price: "",
          })
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        // overlayStyle={{ backgroundColor: "transparent" }}
        classes={{ paper: newclasses.paper }}
      >

        {creditLoading === true ?
          (
            <CircularSpinner onModal/>
          ) :
          (<div className={newclasses.topDiv}>
            <div className={newclasses.LogoWrapper}>
              <img src={Logo} className={newclasses.Logo} alt="Logo"/>
            </div>
            <form onSubmit={addClientPricing} className={newclasses.upperDiv}>
              <div style={{ cursor: "pointer" }} onClick={() => {
                setOpen(false)
                setClientPricingData({
                  ...clientPricingData,
                  service: "",
                  price: "",
                })
              }}>
                <i
                  className="fal fa-times fa-lg"
                  style={{ position: "absolute", top: "3%", right: "4%" }}
                />
              </div>


              <div className={newclasses.firstDivCourt}>
                <p className={newclasses.heading}>SERVICE NAME</p>
                <SearchableDropDown
                  openModalHandler={() => setOpenServiceModal(true)}
                  data={serviceList}
                  handleClick={val => {
                    setClientPricingData({
                      ...clientPricingData,
                      service: val
                    })
                  }}
                  inputValue={(input) => setDropDownInput(input)}
                  selectedValue={clientPricingData.service}
                />

              </div>

              <div className={newclasses.firstDiv}>
                <p className={newclasses.styleTag}>COURT NAME</p>
                <input
                  className={newclasses.inputCont}
                  required
                  value={courtName?.name}
                  type="text"
                  placeholder="Type..."
                  readOnly
                />
              </div>

              {/*<div className={newclasses.firstDivCourt}>*/}
              {/*  <span className={newclasses.heading}>COURT NAME</span>*/}

              {/*  <CourtDropDownData*/}
              {/*    handleSelect={val =>*/}

              {/*      setClientPricingData({ ...clientPricingData, court: val })*/}
              {/*    }*/}
              {/*    selectedValue={clientPricingData.court}*/}
              {/*  />*/}
              {/*</div>*/}

              <div className={newclasses.firstDiv}>
                <span className={newclasses.heading}>PRICE</span>
                <InputPrefix>
                <input
                  className={newclasses.inputContPrice}
                  required
                  value={clientPricingData.price}
                  type="number"
                  min="0"
                  placeholder="Type..."
                  onChange={e => {
                    setClientPricingData({
                      ...clientPricingData,
                      price: +e.target.value
                    })
                  }}
                />
                </InputPrefix>
              </div>

              {isDisabled === false ?
                <Button
                  variant="contained"
                  color="secondary"
                  className={newclasses.button}
                  disabled={
                    clientPricingData.price === "" || clientPricingData.service === ""}
                  type="submit"
                >
                  {"ADD CLIENT PRICING"}
                </Button>
                : <Button
                  variant="contained"
                  color="secondary"
                  className={newclasses.button}
                  disabled
                  type="submit"
                >
                  {"ADD CLIENT PRICING"}
                </Button>


              }
            </form>
          </div>)
        }
      </Dialog>


      <Upload_Csv_File
        openCSV={openCSV}
        setOpenCSV={setOpenCSV}
        fileName="Client Pricing"
        formateType="court,service,price"
        apiUrl={`/manage-users/client_pricing/clint_pricing_from_csv/`}
        actionName={GetAllClientPricing}

      />
    </div>
  )
}

export default ClientPricingHeader