import validator from 'validator';

let FormValiadtion=(userData,isAdding,adminSelected=false)=>{
    const {
        user_type,
        court,
        pay_plan_procedure,
        first_name,
        parole_officer,
        address,
        city,
        state,
        postal_code,
        case_number,
        user_amount,
        total_price,
        court_amount,
        email,
        services,
        username
      } = userData
    let formErrors = {}
    let formIsValid = true
    if (!user_type) {
        formIsValid = false
        formErrors["user_type"] = "*user type is required."
    }
  if(userData.user_type===2 || userData.user_type===3){
    if (!court) {
      formIsValid = false
      formErrors["court"] = "*court  is required."
    }
  }
   
    if(!adminSelected)
    if (!pay_plan_procedure) {
        formIsValid = false
        formErrors["pay_plan_procedure"] = "*payment plan is required."
    }
    if (!first_name) {
        formIsValid = false
        formErrors["first_name"] = "*first name is required."
    }
    if(!adminSelected)
    if (!parole_officer) {
        formIsValid = false
        formErrors["parole_officer"] = "*parole officer is required."
    }
    if (!address) {
        formIsValid = false
        formErrors["address"] = "*address is required."
    }
    if (!city) {
        formIsValid = false;
        formErrors["city"] = "*city is required."
    }
    if (!state) {
        formIsValid = false;
        formErrors["state"] = "*state is required."
    }
    if (!postal_code) {
        formIsValid = false;
        formErrors["postal_code"] = "*postal code is required."
    }
    if(!adminSelected)
    if (!case_number) {
        formIsValid = false;
        formErrors["case_number"] = "*case number is required."
    }
    if(user_type===2)
    // if(+pay_plan_procedure===3){
    //   if(!court_amount){
    //     formIsValid= false
    //     formErrors["court_amount"] = "*Court Pay is required."
    //   }
    //   if(!user_amount){
    //     formIsValid= false
    //     formErrors["user_amount"]= "*Client Pay is required"
    //   }
    //   if(+user_amount>+total_price){
    //     formIsValid= false
    //     formErrors["user_amount"] = "*Client Pay can't be greater than Amount"
    //   }
    // }
    // if(user_type===2)
    // if (!+total_price>0) {
    //     formIsValid = false
    //     formErrors["total_price"] = "*amount is required."
    // }
    // if (!email) {
    //     formIsValid = false
    //     formErrors["email"] = "*email is required."
    // } else
    //   if (!email.includes("@") || !email.includes(".")) {
    //     formIsValid = false
    //     formErrors["email"] = "*please enter a valid email."
    // }
    if(!adminSelected)
    if(isAdding)
    {   
        if (services.length>0) {
        let finalObj={}
        let array=services.map(ele=>{
          if(!ele.id){
            formIsValid=false
            return "*Service is Required"
          }
          return
        })
        let array1=services.map(ele=>{
          if (ele.install_date.length != 0){
          if(!validator.isDate(ele.install_date)){
            formIsValid=false
            return "*Invalid Date"
          }
          return
        }
        })
        let array2=services.map(ele=>{
          if(!ele.projected_install_date){
            formIsValid=false
            return "*Projected Install date is required "
          }
          return
        })
        // let array3=services.map(ele=>{
        //   if(!ele.price){
        //     formIsValid=false
        //     return "Price is required "
        //   }
        //   return
        // })
        let array4=services.map(ele=>{
          if(ele.one_time_fee === false){
            if (ele.end_date.length != 0){
            if(!validator.isDate(ele.end_date)){
              formIsValid=false
              return "*Invalid Date"
            }
          }
          else
          return
          }
        })
        
        finalObj={...finalObj,id:array,install_date:array1,projected_install_date:array2,end_date:array4}
        formErrors["services"] = finalObj
      }}
      if(!adminSelected)
      if(!isAdding){
        if (services.length>0) {
          let finalObj={}
          let array=services.map(ele=>{
            if(!ele.services){
              if(ele.is_delete===true)return;
              formIsValid=false
              return "*Service is Required"
            }
            return
          })
          let array1=services.map(ele=>{
            if (ele.install_date.length != 0){
            if(!validator.isDate(ele.install_date)){
              formIsValid=false
              return "*Invalid Date"
            }
            return
          }
          })
          let array2=services.map(ele=>{
            if(!ele.projected_install_date){
              if(ele.is_delete===true)return;
              formIsValid=false
              return "*Projected Install date is required "
            }
            return
          })
          let array4=services.map(ele=>{
          if(ele.one_time_fee === false){
            if (ele.end_date.length != 0){
            if(!validator.isDate(ele.end_date)){
              formIsValid=false
              return "*Invalid Date"
            }
          }
          else
          return
          }
        })
          
          finalObj={...finalObj,services:array,install_date:array1,projected_install_date:array2,end_date:array4}
          formErrors["services"] = finalObj
        }
      }
      const formValidationData={
        formIsValid:formIsValid,
        formErrorObj:{...formErrors}
      }

    return formValidationData
}

export default FormValiadtion
