import React,{useState} from 'react'
import { useStyles } from './DataFilterStyle'
import CloseIcon from './../../assets/CloseIcon.png'
import SliderIcon from './../../assets/Slider.png'
import CourtDropDownData from '../CourtDropDownData/CourtDropDownData'
import SearchableDropDown from '../SearchableDropdown/SearchableDropDown'
import axios from "../../Store/request";
import useToast from "../Toast/useToast";

const DataFilter = ({close,filterData,dataFilter,setDataFilter,inputEvent, onReset,initialFilter}) => {

  const classes = useStyles()
  const userTypeDropDownData = () => {

    const users = [
      { label: 'Admin', value: 1 },
      { label: 'Client', value: 2 },
      { label: 'Court', value: 3 },
      { label: 'Operator', value: 4 },
    ];
    return users
  }
  const [boolenOption] = useState([
    {value:true,label:"Yes"},
    {value:false,label:"No"}
  ])

  const disablePastDueDate=()=>{

    const today = new Date();
    const dd = String(today.getDate()-1).padStart(2, "0");
    const mm = String(today.getMonth()+1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  }
  const disableComingDueDates=()=>{
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  }

  return (
    <form>
    <div className={classes.mainFilter}>
      <div style={{width:"100%",textAlign:"right", marginRight:"24px"}}>
        <img src={CloseIcon} style={{ height: '18px', width: '18px', cursor:'pointer'}} onClick={close}/>
      </div>
    <div className={classes.filterHead}>
      <div className={classes.filterdiv} style={{ border: 'none', marginLeft: '0px', boxShadow: 'none' }}>
        <p style={{ fontSize: '16px' }}>Filters</p>
        <img src={SliderIcon} style={{ height: '16px', width: '16px' }} />
      </div>
    </div>
    <div className={classes.filterMiddle}>
      <div className={classes.filterMiddleLeft}>
        <div className={classes.filterMiddleTopLeft}>
          <div className={classes.smallInputDiv}>
            <p className={classes.innerPara}>City</p>
            <input
              type="text"
              name="city"
              value={dataFilter.city}
              onChange={inputEvent}
              placeholder='  Type here...'
              className={classes.smallInput}
            />
          </div>

          <div className={classes.smallInputDiv}>
            <p className={classes.innerPara}>State</p>
            <input
              type="text"
              name="state"
              value={dataFilter.state}
              onChange={inputEvent}
              placeholder='  Type here...'
              className={classes.smallInput}
            />
          </div>

          <div className={classes.smallInputDiv}>
            <p className={classes.innerPara}>Zip Code</p>
            <input
              type="text"
              name="zipcode"
              value={dataFilter.zipcode}
              onChange={inputEvent}
              placeholder='  Type here...'
              className={classes.smallInput}
            />
          </div>

        </div>

        <div className={classes.filterMiddleMiddleLeft}>
          <div className={classes.RegularInputDivCourt}>
            <p className={classes.innerPara}>Court System</p>
            <CourtDropDownData
              islabelValue
              handleSelect={val=>{
                setDataFilter({...dataFilter,court_name:val})
              }}
              selectedValue={dataFilter.court_name}
            />
          </div>

          <div className={classes.RegularInputDivCourt} >
            <p className={classes.innerPara}>User Type</p>
            <SearchableDropDown
              data={userTypeDropDownData()}
              handleClick={val =>{
                  setDataFilter({ ...dataFilter, user_type: val })}
              }
              selectedValue={dataFilter.user_type}

          />
          </div>
        </div>
        <div className={classes.filterMiddleMiddleLeft}>
          <div className={classes.RegularInputDiv}>
            <p className={classes.innerPara}>Date</p>
            <input
              type="date"
              name="date"
              value={dataFilter.date}
              onChange={inputEvent}
              className={classes.selectContInput}
            />
          </div>

          <div className={classes.RegularInputDivCourt} >
            <p className={classes.innerPara}>Deliquent</p>
            <SearchableDropDown
              data={userTypeDropDownData()}
              handleClick={val =>{
                  setDataFilter({ ...dataFilter, user_type: val })}
              }
              selectedValue={dataFilter.user_type}

          />
          </div>
        </div>
        <div>

        </div>
      </div>

      <div className={classes.filterMiddleRight} style={{marginTop:'-9px'}}>
        <div className={classes.filterMiddleTopRight}>
          <div className={classes.RegularInputDiv}>
            <p className={classes.innerPara}>Past Due</p>
            <input
              type="date"
              name="pastDue"
              // value={dataFilter.pastDue}
              onChange={inputEvent}
              className={classes.selectContInput}
              min="0001-01-01"
              max={disablePastDueDate()}
            />
          </div>

          <div className={classes.RegularInputDiv} >
            <p className={classes.innerPara}>Coming Due</p>
            <input
              type="date"
              name="comingDue"
              value={dataFilter.comingDue}
              onChange={inputEvent}
              className={classes.selectContInput}
              min={disableComingDueDates()}
              max="9999-12-31"
            />
          </div>
        </div>
        <div className={classes.filterMiddleTopRight} style={{marginTop:'-21px'}}>
          <div className={classes.RegularInputDivCourt}>
            <p className={classes.innerPara}>Pending users</p>
            <SearchableDropDown
              data={userTypeDropDownData()}
              handleClick={val =>{
                  setDataFilter({ ...dataFilter, user_type: val })}
              }
              selectedValue={dataFilter.user_type}

          />
          </div>

          <div className={classes.RegularInputDivCourt} >
            <p className={classes.innerPara}>Active Status</p>
            <SearchableDropDown
              data={boolenOption}
              handleClick={val =>{
                  setDataFilter({ ...dataFilter, active: val })}
              }
              selectedValue={dataFilter.active}

          />
          </div>
        </div>
        <div className={classes.filterMiddleTopRight} >
          <div className={classes.EliteInputDiv} style={{marginTop:'-40px'}}>
            <p className={classes.innerPara}>Activation Date</p>
            <input
              type="date"
              name="activation_date"
              value={dataFilter.activation_date}
              onChange={inputEvent}
              className={classes.InputElite}
            />
          </div>
        </div>
      </div>
    </div>

    <div className={classes.filterFooter}>
      <div className={classes.btnHolder}>
        <button type="reset" className={classes.resetBtn} onClick={onReset}>Reset Filters</button>
        <button type="submit" className={classes.applyBtn} onClick={(e)=>{e.preventDefault()
          filterData()}}>Apply Filters</button>
      </div>
    </div>
  </div>
  </form>
  )
}

export default DataFilter