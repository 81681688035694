const Actions = {
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAIL: "LOGIN_FAIL",
  INCORRECT_CREDENTIALS:"INCORRECT_CREDENTIALS",
  LOGOUT:"LOGOUT",


  SHOW_ADD_USER:"SHOW_ADD_USER",
  SHOW_CLIENT_DETAILS:"SHOW_CLIENT_DETAILS",
  SHOW_EDIT_USER:"SHOW_EDIT_USER",

  GET_USER_REQUEST:"GET_USER_REQUEST",
  GET_USER_SUCCESS:"GET_USER_SUCCESS",
  GET_USER_FAIL:"GET_USER_FAIL",
  SET_USER_TYPE:"SET_USER_TYPE",
  SET_CURRENT_PAGE:"SET_CURRENT_PAGE",
  
  GET_FILTER_DATA_REQUEST:"GET_FILTER_DATA_REQUEST",
  GET_FILTER_DATA_SUCCESS:"GET_FILTER_DATA_SUCCESS",
  GET_FILTER_DATA_FAIL:"GET_FILTER_FAIL",

  GET_USER_DETAIL_REQUEST:"GET_USER_DETAIL_REQUEST",
  GET_USER_DETAIL_SUCCESS:"GET_USER_DETAIL_SUCCESS",
  GET_USER_DETAIL_FAIL:"GET_USER_DETAIL_FAIL",

  GET_COURT_REQUEST:"GET_COURT_REQUEST",
  GET_COURT_SUCCESS:"GET_COURT_SUCCESS",
  GET_COURT_FAIL:"GET_COURT_FAIL",
  SET_COURT_CURRENT_PAGE:"SET_COURT_CURRENT_PAGE",

  GET_COURT_PRICING_REQUEST:"GET_COURT_PRICING_REQUEST",
  GET_COURT_PRICING_SUCCESS:"GET_COURT_PRICING_SUCCESS",
  GET_COURT_PRICING_FAIL:"GET_COURT_PRICING_FAIL",

  GET_CLIENT_PRICING_REQUEST:"GET_CLIENT_PRICING_REQUEST",
  GET_CLIENT_PRICING_SUCCESS:"GET_CLIENT_PRICING_SUCCESS",
  GET_CLIENT_PRICING_FAIL:"GET_CLIENT_PRICING_FAIL",

  GET_PAYMENT_PLAN_REQUEST:"GET_PAYMENT_PLAN_REQUEST",
  GET_PAYMENT_PLAN_SUCCESS:"GET_PAYMENT_PLAN_SUCCESS",
  GET_PAYMENT_PLAN_FAIL:"GET_PAYMENT_PLAN_FAIL",

  GET_OFFICER_REQUEST:"GET_OFFICER_REQUEST",
  GET_OFFICER_SUCCESS:"GET_OFFICER_SUCCESS",
  GET_OFFICER_FAIL:"GET_OFFICER_FAIL",

  GET_SERVICE_REQUEST:"GET_SERVICE_REQUEST",
  GET_SERVICE_SUCCESS:"GET_SERVICE_SUCCESS",
  GET_SERVICE_FAIL:"GET_SERVICE_FAIL",
  
  GET_DROPDOWN_DATA:"GET_DROPDOWN_DATA",

  ADD_OFFICER_DATA:"ADD_OFFICER_DATA",
  ADD_COURT_DATA:"ADD_COURT_DATA",
  ADD_PAYMENT_PLAN_DATA:"ADD_PAYMENT_PLAN_DATA",
  ADD_SERVICE_DATA:"ADD_SERVICE_DATA",

  GET_REPORT_DATA_REQUEST:"GET_REPORT_DATA_REQUEST",
  GET_REPORT_DATA_SUCCESS:"GET_REPORT_DATA_SUCCESS",
  GET_REPORT_DATA_FAIL:"GET_REPORT_DATA_FAIL",
  SET_REPORT_CURRENT_PAGE:"SET_REPORT_CURRENT_PAGE",

  GET_STATE_DATA:"GET_STATE_DATA",
  GET_TERRITORY_DATA:"GET_TERRITORY_DATA",
  GET_COURT_ARCHIVE:"GET_COURT_ARCHIVE"
  
};

export default Actions;
