import React, { useEffect, useState, useContext } from "react";
import ServicesHeader from "../../Components/ServicesHeader/ServicesHeader";
import ServicesDataTable from "../../Components/ServicesDataTable/ServicesDataTable";
import Container from '@material-ui/core/Container';
import { useStyles } from "./ServicesManagementStyle";
import { useHistory } from "react-router";
import {connect, useDispatch, useSelector} from "react-redux";
import { auth } from "../../Store/Actions/Auth";
import { ScreenContext } from './../../context/context';
import NewPagination from './../../Components/NewPagination/NewPagination'
import {GetServices} from "../../Store/Actions/Services";
import CircularSpinner from "../../Components/CircularProgress/CircularSpinner";
import Pagination_New from "../../Components/NewPagination/Pagination_New";

const ServicesManagement = (props) => {
  const classes = useStyles()

  const history = useHistory()
  const { screenSize, setScreenSize } = useContext(ScreenContext)
  const dispatch = useDispatch()
  const {data,count,loading} = useSelector(({ services }) => services)
  useEffect(() => {
    if (props.accessToken === null || props.accessToken === "" || props.accessToken === undefined) {
      history.push("/")
    }
  }, [])

  const [isDisabled,setIsDisabled]=useState(false);



  const [filterTxt, setFilterTxt] = useState('');
  var filteredData = []

  const filterData = (e) => {
    let val = e.target.value
    setFilterTxt(val)
  }

  filteredData = data?.filter(element => {
    return (element.name.toLowerCase().includes(filterTxt?.toLowerCase()))
  })

  filteredData=filteredData.sort(function(a,b){
    const nameA = a.name?.toUpperCase(); // ignore upper and lowercase
    const nameB = b.name?.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1; //nameA comes first
    }
    if (nameA > nameB) {
      return 1; // nameB comes first
    }
    return 0;  // names must be equal
  });
  
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  const sortArray=[]
  data.map((element)=>{
    sortArray.push(element)
  })

  const sortedusers = sortArray.sort(function(a, b) {
    const nameA = a.name?.toUpperCase(); // ignore upper and lowercase
    const nameB = b.name?.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1; //nameA comes first
    }
    if (nameA > nameB) {
      return 1; // nameB comes first
    }
    return 0;  // names must be equal
  });

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <div className={classes.MainContainer}>
      <ServicesHeader searchText={filterData} isDisabled={isDisabled} setIsDisabled={setIsDisabled} data={data}/>
      {loading? <CircularSpinner/>
        :
        (<Container maxWidth="lg" className={screenSize > 1400 ? classes.container : classes.containerMob}>
          {/*{!!data.length? <ServicesDataTable DataArray={currentPosts} />:<p className={classes.error}>No Data Found</p>}*/}
          {filterTxt!==""?<ServicesDataTable DataArray={filteredData} isDisabled={isDisabled} setIsDisabled={setIsDisabled}/>
          :<ServicesDataTable DataArray={currentPosts} isDisabled={isDisabled} setIsDisabled={setIsDisabled}/>}

      </Container>)}
          {count>10 &&
          <><Container maxWidth="lg" style={{visibility:loading || filterTxt.length>0?"hidden":"visible"}}>
          {/*<NewPagination*/}
          {/*  postsPerPage={postsPerPage}*/}
          {/*  totalPosts={count}*/}
          {/*  // paginate={paginate}*/}
          {/*  paginate={count===data.length && data.length>0?(pageNumber) => setCurrentPage(pageNumber):(pageNumber) => {*/}
          {/*    dispatch(GetServices({limit: postsPerPage, offset: (postsPerPage * pageNumber - postsPerPage)}))*/}
          {/*  }}*/}
          {/*/>*/}

            <Pagination_New  count={count}
                             activePage={currentPage}
                             postsPerPage={postsPerPage}
                             paginate={(pageNumber)=>{setCurrentPage(pageNumber)}}
            />
          </Container></>}


    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.accessToken
  }
}

export default connect(mapStateToProps, { auth })(ServicesManagement);