import Actions from "../Type";

const initialState= {
    loading: false,
    data: [],
    error: "",
    count: 0,
    current_page:1
}

const ReportReducer = (state = initialState, {type,payload}) => {
    switch (type) {
        case Actions.GET_REPORT_DATA_REQUEST:
        return{
            ...state,
            loading: true
        }
        case Actions.GET_REPORT_DATA_SUCCESS:
        return{
            ...state,
            loading:false,
            data:payload.results,
            count: payload.count
        }
        case Actions.GET_REPORT_DATA_FAIL:
        return{
            ...state,
            loading: false,
            count:0,
            data:[]
        }
        case Actions.SET_REPORT_CURRENT_PAGE:
        return{
            ...state,
            current_page:payload,


        }
        default:
        return state
    }
}

export default ReportReducer