import React, { useEffect, useState, useContext } from "react"
import Vector12 from "../../assets/PricingIcon.png"
import { useStyles, newStyles } from "./CourtPricingHeaderStyle"
import AddBtn from "./AddBtn"
import { connect, useDispatch, useSelector } from "react-redux"
import { auth } from "../../Store/Actions/Auth"
import axios from "../../Store/request"
import { Dialog, Button } from "@material-ui/core"
import Logo from "../../assets/AMSLogo.png"
import { barStyles } from "../SearchBar/SearchBarStyle"
import SearchIcon from "@material-ui/icons/Search"
import InputBase from "@material-ui/core/InputBase"
import { finalStyles } from "./../CourtsHeader/CourtsHeaderStyle"
import { ScreenContext } from "./../../context/context"
import useToast from "../Toast/useToast"
import CircularSpinner from "../CircularProgressUserReport/CircularSpinner";
import { GetAllCourtPricing } from "../../Store/Actions/CourtPricing"
import CourtDropDownData from "../CourtDropDownData/CourtDropDownData";
import SearchableDropDown from "../SearchableDropdown/SearchableDropDown";
import InputPrefix from "../InputPrefix/InputPrefix"
import UploadCsvBtn from "../Buttons/UploadCsvBtn";
import UploadCsvModal from "../UploadCsvModal/UploadCsvModal";
import Upload_Csv_File from "../UploadCsvModal/Upload_CSV_File";
import {GetAllCourt} from "../../Store/Actions/Court";
import ActiveCourtDropDownData from "../CourtDropDownData/ActiveCourtDropDownData";
import useAuth from "../../utils/useAuth";


const CourtPricingHeader = ({ searchText, isDisabled, setIsDisabled, courtPricingData, setCourtPricingData, addedCourtPricing, setAddedCourtPricing, serviceList, setServiceList}) => {
  const classes = useStyles()
  const newclasses = newStyles()
  const barclasses = barStyles()
  const dispatch = useDispatch()
  const [openCSV, setOpenCSV] = useState(false)
  const { data: courts, count: courtsLength, dataExist: courtsDataExist } = useSelector(({ court }) => court)
  const { data: services, count: servicesLength, dataExist: servicesDataExist } = useSelector(({ services }) => services)
  const { data: courtPricing, count: courtPricingLength } = useSelector(({ courtPricing }) => courtPricing)
  const [creditLoading, setCreditLoading] = useState(false)
  const finalclasses = finalStyles()
  const { screenSize, setScreenSize } = useContext(ScreenContext)
  useEffect(() => {
    setScreenSize(window.innerWidth)
  }, [screenSize])
  const { showToast } = useToast()

  const [open, setOpen] = useState(false)
  const { isOperator } = useAuth()

  const courtName = courts.find((element) => element.id === courtPricingData.court)
  // const [serviceList, setServiceList] = useState([])
  const searchDropDown = async () => {
    if(addedCourtPricing){
    const response = await axios.get(`/api/v1/services/services_add_court_pricing/?court=${courtPricingData.court}`)
    const serviceData = response.data?.map(item => {
      return ({ value: item.id, label: item.name })
    })
    setServiceList(serviceData)
    setAddedCourtPricing(false)
  }
  }
  const addCourtPricing = e => {
    e.preventDefault()

    let addData = { ...courtPricingData }
    addData.price = +courtPricingData.price
    addData.court = +courtPricingData.court
    addData.service = services?.find(ele => ele.id === courtPricingData.service)
    setIsDisabled(true)
    setCreditLoading(true)
    axios
      .post("/api/v1/courts/pricing/court/", { ...courtPricingData, price: +courtPricingData.price, court: +courtPricingData.court })
      .then(function (response) {
        if (response.statusText === "OK") {
          showToast("Court Pricing Added Successfully", "success")
          dispatch(GetAllCourtPricing())
          setIsDisabled(false)
          setOpen(false)
          setCourtPricingData({
            ...courtPricingData,
            price: "",
            service: "",
          })
          setAddedCourtPricing(true)
          setCreditLoading(false)
        }
      })
      .catch(function (error) {
        setIsDisabled(false)
        // setOpen(false)
        setCreditLoading(false)
        if (error.response.data.service?.non_field_errors) {
          showToast(
            `${error.response.data.service?.non_field_errors}`,
            "error"
          )
        }
          if(error.response.status===403){
          showToast(
            `Couldnot add court pricing details, please try later`,
            "error"
          )
        }
      })
  }



  return (
    <div
      className={
        screenSize > 800 ? classes.MainContainer : finalclasses.MainCont
      }
    >
      {screenSize > 800 ? (
        <div className={classes.HeaderInner}>
          <div className={classes.Wrapper}>
            <div className={classes.IconWrapper}>
              <img src={Vector12} className={classes.icon} alt="Logo"/>
            </div>
            <p className={classes.MainHeading}>COURT PRICING</p>
            <div className={classes.selection}>
              <ActiveCourtDropDownData
                handleSelect={val =>{
                  setAddedCourtPricing(true)
                  setCourtPricingData({ ...courtPricingData, court: val })
                }
                }
              />
            </div>
            <div
              onClick={() => {
                if (courtPricingData.court) {
                  setOpen(true)
                }
              }}
            >
              {!isOperator ? (
              <AddBtn signal={courtPricingData.court ? true : false} searchDropDown={searchDropDown}/>
            ): null
            }

            </div>
            <div
              onClick={() => {
                setOpenCSV(true)
              }}
            >

              {/*UPLOAD CSV BUTTON CODE*/}
              {!isOperator ? (
              <UploadCsvBtn />
            ): null
            }

            </div>
          </div>

        </div>
      ) : (
        <div className={finalclasses.topMain}>
          <div className={finalclasses.upperMain}>
            <div className={finalclasses.flex}>
              <div className={classes.IconWrapper} style={{ width: "57px" }}>
                <img src={Vector12} className={classes.icon} alt="" />
              </div>
              <p className={classes.MainHeading} style={{ fontSize: "12px" }}>
                {"Pricings Management"}
              </p>
            </div>
            <div className={barclasses.SearchContainer}>
              <div className={barclasses.search}>
                <div className={barclasses.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search…"
                  classes={{
                    root: barclasses.inputRoot,
                    input: barclasses.inputInput
                  }}
                  inputProps={{ "aria-label": "search" }}
                  onChange={searchText}
                />
              </div>
            </div>
          </div>

          <div className={finalclasses.bottomCont}>
            <div>
              <select
                className={classes.selection}
                onChange={e =>
                  setCourtPricingData({
                    ...courtPricingData,
                    court: parseInt(e.target.value)
                  })
                }
              >
                <option>Choose..</option>
                {courts.map(court => {
                  return <option value={court.id}>{court.name}</option>
                })}
              </select>
            </div>
            <div
              onClick={() => {
                if (courtPricingData.court) {
                  setOpen(true)
                }
              }}
            >
              <AddBtn signal={courtPricingData.court ? true : false}
              searchDropDown={searchDropDown} />
            </div>
            <div
              onClick={() => {
                setOpenCSV(true)
              }}
            >

              {/*UPLOAD CSV BUTTON CODE*/}
              <UploadCsvBtn />
            </div>
          </div>
        </div>
      )}

      <Dialog
        open={open}
        scroll="body"
        onClose={() => {
          setOpen(false)
          setIsDisabled(false)
          setCourtPricingData({
            ...courtPricingData,
            price: "",
            service: "",
          })
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        // overlayStyle={{ backgroundColor: "transparent" }}
        PaperProps={{
          style: {
            overflow: "unset",
            margin:40
          }
        }}
      >
        {creditLoading === true ?
          (
            <CircularSpinner onModal/>
          ) :
          (
            <div className={newclasses.topDiv}>
              <div className={newclasses.LogoWrapper}>
                <img src={Logo} className={newclasses.Logo} alt="Logo"/>
              </div>
              <form onSubmit={addCourtPricing} className={newclasses.upperDiv}>
                <div style={{ cursor: "pointer" }} onClick={() => {setOpen(false);
                setCourtPricingData({
                  ...courtPricingData,
                  price: "",
                  service: "",
                })}}>
                  <i
                    className="fal fa-times fa-lg"
                    style={{ position: "absolute", top: "3%", right: "4%" }}
                 />
                </div>

                <div className={newclasses.firstDivService}>
                  <p className={newclasses.styleTag}>SERVICE NAME</p>
                  <SearchableDropDown
                    handleClick={val => {
                      setCourtPricingData({
                        ...courtPricingData,
                        service: val
                      })
                    }}
                    data={serviceList}
                    selectedValue={courtPricingData.service}
                  />
                </div>
                <div className={newclasses.firstDiv}>
                  <p className={newclasses.styleTag}>COURT NAME</p>
                  <input
                    className={newclasses.inputCont}
                    required
                    value={courtName?.name}
                    type="text"
                    placeholder="Type..."
                    readOnly
                  />
                </div>
                <div className={newclasses.firstDiv}>
                  <p className={newclasses.styleTag}>PRICE</p>
                  <InputPrefix>
                  <input
                    className={newclasses.inputContPrice}
                    required
                    value={courtPricingData.price}
                    type="text"
                    placeholder="Type..."
                    onChange={e => {
                      setCourtPricingData({
                        ...courtPricingData,
                        price: e.target.value
                      })
                    }}
                  />
                  </InputPrefix>
                </div>
                {isDisabled === false ?
                  <Button
                    variant="contained"
                    color="secondary"
                    className={newclasses.button}
                    disabled={
                      courtPricingData.service === "" || courtPricingData.price === ""
                    }
                    type="submit"
                  >
                    {"ADD COURT PRICING"}
                  </Button>
                  : <Button
                    variant="contained"
                    color="secondary"
                    className={newclasses.button}
                    disabled
                    type="submit"
                  >
                    {"ADD COURT PRICING"}
                  </Button>
                }
              </form>
            </div>
          )
        }

      </Dialog>

      <Upload_Csv_File
        openCSV={openCSV}
        setOpenCSV={setOpenCSV}
        fileName="Court Pricing"
        formateType=" courts, service, price "
        apiUrl={`/api/v1/courts/pricing/court/court_pricing_from_csv/`}
        actionName={GetAllCourtPricing}

      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    redirectTo: state.auth.redirectTo,
    invalidUser: state.auth.invalidUser,
    accessToken: state.auth.accessToken
  }
}

export default connect(mapStateToProps, { auth })(CourtPricingHeader)
