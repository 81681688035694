import { useSelector } from "react-redux";

const useAuth = () => {
  const { user, isAuthenticated } = useSelector(state => state.auth);
  const { user_type } = user || {};
  const getRoute = () => {
    if(!isAuthenticated) return "/";
    if(user_type === 1) return "/courts";
    if(user_type === 2) return "/balance";
    if( user_type === 4) return "/courts";
    return "/User";
  }

  return {
    isAdmin: user_type === 1,
    isIndividualUser: user_type === 2,
    isCourtAdmin: user_type === 3,
    isOperator: user_type === 4,
    userType: user_type,
    redirectRoute: getRoute(),
    isAuthenticated
  }
}

export default useAuth
