import React, { useEffect, useState, useContext } from "react";
import { useStyles } from "./DashboardStyle";
import CourtsHeader from "../../Components/CourtsHeader/CourtsHeader";
import CourtsDataTable from "../../Components/CourtsDataTable/CourtsDataTable";
import Vector12 from "../../assets/Vector12.png";
import Container from '@material-ui/core/Container';
import { useHistory } from "react-router";
import {connect, useDispatch, useSelector} from "react-redux";
import { auth } from "../../Store/Actions/Auth";
import { ScreenContext } from '../../context/context'
import CircularSpinner from "../../Components/CircularProgress/CircularSpinner";
import Pagination_New from "../../Components/NewPagination/Pagination_New";
import { GetCourt } from "../../Store/Actions/Court"
const Dashboard = (props) => {

  const history = useHistory()
  const dispatch = useDispatch()
  const {data,count,loading} = useSelector(({ court }) => court)

  const { screenSize, setScreenSize } = useContext(ScreenContext)

  const [isDisabled,setIsDisabled]=useState(false);
  const [filterTxt, setFilterTxt] = useState('');
  var filteredData = []

  useEffect(() => {
    if (props.accessToken === null || props.accessToken === "" || props.accessToken === undefined) {
      history.push("/")
    }
  }, [])


  const filterData = (e) => {
    let val = e.target.value
    setFilterTxt(val)
  }

   filteredData = data?.filter(element => {
    return (element.name.toLowerCase().includes(filterTxt?.toLowerCase()))
  })

  filteredData=filteredData.sort(function(a,b){
    const nameA = a.name?.toUpperCase(); // ignore upper and lowercase
    const nameB = b.name?.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1; //nameA comes first
    }
    if (nameA > nameB) {
      return 1; // nameB comes first
    }
    return 0;  // names must be equal
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);

  const classes = useStyles()
  const { stateData:courtStateData} = useSelector(({ court }) => court)

  const stateList=courtStateData?.map((element)=>{
    return ({value:element.id,label:element.name})
  })
  const [invoiceList,setInvoiceList]=useState(false)

  return (
    <div className={classes.MainContainer}>
      <CourtsHeader MainHeading="Courts management" lable="+ Add court" img={Vector12} searchText={filterData} isDisabled={isDisabled} setIsDisabled={setIsDisabled}
                    stateList={stateList}
      />
      {loading?<CircularSpinner/>
        :
      <Container maxWidth="lg" className={screenSize > 1400 ? classes.container : classes.containerMob}>
        <CourtsDataTable DataArray={!!filterTxt ? filteredData : currentPosts} isDisabled={isDisabled} setIsDisabled={setIsDisabled}
                         stateList={stateList} invoiceList={invoiceList} setInvoiceList={setInvoiceList}
        />
      </Container>
      }
    { invoiceList===false &&  count>10 && (<>
    <Container maxWidth="lg" style={{visibility:loading || filterTxt.length>0?"hidden":"visible"}}>
      <Pagination_New  count={count}
                       activePage={currentPage}
                       postsPerPage={postsPerPage}
                       paginate={(pageNumber)=>{setCurrentPage(pageNumber)}}
      />
    </Container>
    </>)}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.accessToken
  }
}

export default connect(mapStateToProps, { auth })(Dashboard);
