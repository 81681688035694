import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  InputContainer: {
    display: "flex",
    justifyContent: "center",
  },
  Input: {
    width: 343,
    "& .MuiInputLabel-root": {
      fontSize: theme.fonts.sizes.fontL,
      fontFamily: theme.fonts.family.Light,
      color: "#1F1E1E",
      fontWeight: " normal",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "1px solid #D8D8D8",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "1px solid #D8D8D8",
      "&:hover": {
        color: "red"
      }
    }
  }

}));
