import { makeStyles } from "@material-ui/core/styles";


export const useStyles = makeStyles((theme) => ({

  MainContainer: {
    width: "100%",
    height: 44,
    background: "#FFFFFF",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.151907)",
    borderRadius: 2,
    margin: "-20px 0px 35px 0px"
  },
  ContainerInner: {
    display: "grid",
    gridTemplateColumns: "2fr 3fr 1fr 1fr",
    padding: "0 15px",
    position: "relative"

  },
  Spinner:{
    height:"60vh"
  },
  error:{
    fontFamily:"Open Sans",
    fontWeight:"800px",
    color:"#f50057",
    fontSize:"20px"
  },
  HeadingInner: {
    display: "grid",
    gridTemplateColumns: "2fr 3fr 1fr 1fr",
    padding: "0 15px",
    position: "relative",
    paddingBottom: 20,
  },
  ListItmes: {
    color: "#3971FF",
    fontFamily: "Open sans",
    fontSize: theme.fonts.sizes.fontM,
    fontWeight: 600,
    // cursor:'pointer'
  },
  ListItmesBlack: {
    color: "#333333",
    fontFamily: "Open sans",
    fontSize: theme.fonts.sizes.fontM,
    fontWeight: 600,
    // cursor:'pointer'
  },
  Heading: {
    color: "#31394D",
    fontFamily: "Open sans",
    fontSize: theme.fonts.sizes.fontM,
    fontWeight: 400,
  },
  Heading1: {
    color: "#31394D",
    fontFamily: "Open sans",
    fontSize: theme.fonts.sizes.fontM,
    fontWeight: 400,
    position: "absolute",
    left: 0,
  },
  modal_body:{
    border:"2px solid black",
  }
}));