import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom"
import Login from "../Pages/Auth/Login"
import Dashboard from "../Pages/Dashboard/Dashboard"
import LeftDrawer from "../Components/LeftDrawer/LeftDrawer"
import OfficersManagement from "../Pages/OfficersManagement/OfficersManagement"
import CourtPricing from "../Pages/CourtPricing/CourtPricing"
import ServicesManagement from "../Pages/ServicesManagement/ServicesManagement"
import Documentation from "../Pages/Documentation/Documentation"
import PaymentManagement from "../Pages/PaymentManagement/PaymentManagement"
import UserManagement from "../Pages/UserManagement/UserManagement"
import ReportManagement from "../Pages/ReportManagement/ReportManagement"
import {connect, useDispatch, useSelector} from "react-redux"
import {auth, getUserDetails} from "../Store/Actions/Auth"
import ConfigureStore from "../Store/Store"
import React, { useState, useEffect, useContext } from "react"
import ResetPassword from "../Pages/Auth/ResetPassword/ResetPassword"
import ClientPricing from "../Pages/ClientPricing/ClientPricing"
import useAuth from "../utils/useAuth"
import User from "../Pages/Users/User"
import Payments from "../Pages/Payments/Payments"
import UserProfile from "../Pages/UserProfile/UserProfile"
import {CircularProgress} from "@material-ui/core";
import {useStyles} from "../Pages/Auth/LoginStyle";
import CircularSpinner from "../Components/CircularProgress/CircularSpinner"
import Notes from "../Pages/Users/UserNotes"

const Routes = props => {
  const { store } = ConfigureStore()
  const token = localStorage.getItem("token")
  const [choice, setChoice] = useState(false)
  const { isAdmin, isIndividualUser, isOperator, redirectRoute, isAuthenticated } = useAuth()
  const dispatch = useDispatch()
  const {loadingUserDetails} = useSelector(({ auth }) => auth)

  const classes = useStyles()
  useEffect(() => {
    if(isAuthenticated) {
      dispatch(getUserDetails());
    }
  }, [isAuthenticated])
  useEffect(() => {
    let ch = false

    if (
      props.accessToken !== null &&
      props.accessToken !== "" &&
      props.accessToken !== undefined
    ) {
      setChoice(true)
      ch = true
    } else {
      setChoice(false)
      ch = false
    }
  }, [props.accessToken, choice])


  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Login />
        </Route>
        <Route exact path="/reset-password">
          <ResetPassword />
        </Route>

        {token && <LeftDrawer>
          <Route exact path="/user-profile">
            {
              loadingUserDetails===true?(
                <CircularSpinner />
              ):(
                <UserProfile/>
              )
            }

        </Route>
          {!isIndividualUser && (
            <>
              <Route exact path="/user">
                <User />
              </Route>
              <Route exact path="/balance">
                <Payments />
              </Route>
            </>
          )}
          {(isAdmin || isOperator) && (
            <>
              {/*<Redirect to="/courts" />*/}
              <Route exact path="/manage-users/:param/page=:param">
                <UserManagement />
              </Route>
              <Route exact path="/courts">
                <Dashboard />
              </Route>
              <Route exact path="/officers">
                <OfficersManagement />
              </Route>
              <Route exact path="/court-pricing">
                <CourtPricing />
              </Route>
              <Route exact path="/client-pricing">
                <ClientPricing />
              </Route>
              <Route exact path="/reports">
                <ReportManagement />
              </Route>
            </>
          )}
          {isAdmin && (
            <>
              <Route exact path="/services">
                <ServicesManagement />
              </Route>
              <Route exact path="/user">
                <User />
              </Route>
              <Route exact path="/documentation">
                <Documentation />
              </Route>
              <Route exact path="/manage-users/user_notes/:param?">
                <Notes />
              </Route>
            </>
          )}
          {isIndividualUser && (
            <>
            <Route exact path="/balance">
              <Payments />
            </Route>
            </>
          )}
        </LeftDrawer> }
        <Redirect from="*" to={redirectRoute} />
      </Switch>

    </Router>
  )
}

const mapStateToProps = state => {
  return {
    accessToken: state.auth.accessToken
  }
}

export default  connect(mapStateToProps, { auth })(Routes)
