import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback
} from "react"
import PaymentIcon from "../../assets/PaymentIcon.png"
import AddButton from "../Buttons/AddButton"
import { useStyles, dialogStyles } from "./PaymentManagementHeaderStyle"
import SearchBar from "../SearchBar/SearchBar"
import SearchIcon from "@material-ui/icons/Search"
import InputBase from "@material-ui/core/InputBase"
import { AddPaymentModal } from "../AddPaymentModal/AddPaymentModal"
import Logo from "../../assets/AMSLogo.png"
import { ScreenContext } from "./../../context/context"
import { finalStyles } from "./../CourtsHeader/CourtsHeaderStyle"
import { barStyles } from "../SearchBar/SearchBarStyle"
import useAuth from "../../utils/useAuth"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import { useDropzone } from "react-dropzone"

const PaymentManagementHeader = ({ searchText }) => {
  const classes = useStyles()
  const newClasses = dialogStyles()

  const [openSignal, setOpenSignal] = useState(false)
  const [paymentDialog, setPaymentDialog] = useState(false)
  const [confirmDialog, setConfirmDialog] = useState(false)
  const finalclasses = finalStyles()
  const barclasses = barStyles()
  const { screenSize, setScreenSize } = useContext(ScreenContext)
  const { isAdmin } = useAuth()
  const [selectedImg, setSelectedImg] = useState()
  const [uploadImg, setUploadImg] = useState()
  const [mediaList, setMediaList] = useState({
    photo: []
  })

  const [media, setMedia] = useState([])


  const [uploadMediaList, setUploadMediaList] = useState({
    photo: [],
    name: ""
  })
  const imageUploader = useRef(null)

  useEffect(() => {
    setScreenSize(window.innerWidth)
  }, [screenSize])

  const openModal = () => {
    setOpenSignal(true)
  }
  const paymentDialogHandler = e => {
    e.preventDefault()
    setPaymentDialog(false)
    setConfirmDialog(true)
  }
  const imageUploadHandler = () => {
    const found = imageUploader.current.click()
  }

  const fileUploadHandler = async event => {
    let reader = new FileReader()
    let files = event.target.files[0]

    reader.onloadend = () => {
      const updatedMedia = [...media, { name: files.name, url: reader.result }]

      setMedia(updatedMedia)

    }
    reader.readAsDataURL(files)

    imageUploader.current.value = ""
  }


  const imageDeleteHandler = (index, docs) => {
    media.splice(index, 1)
    setMedia([...media])

  }
  const [search,setSearch]=useState(false);
  const searchBtn =(e)=>{
    setSearch(!search);
  }
  return (
    <div
      className={
        screenSize > 800 ? classes.MainContainer : finalclasses.MainCont
      }
    >
      {screenSize > 800 ? (
        <div className={classes.HeaderInner}>
          <div className={classes.Wrapper}>
            <div className={classes.IconWrapper}>
              <img src={PaymentIcon} className={classes.icon} />
            </div>
            <p className={classes.MainHeading}>Payment Plans Management</p>
            {isAdmin && (
              <div onClick={openModal}>
                <AddButton lable=" + Add Plan" />
              </div>
            )}
            {isAdmin && (
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={() => setPaymentDialog(true)}
              >
                Make Payment
              </Button>
            )}
          </div>
          <div className={barclasses.SearchContainer}>
            {search===false?
              <div className={barclasses.search1}>
                <SearchIcon  onClick={searchBtn}  style={{ backgroundColor:"none"}}/>
              </div>:
              <div className={barclasses.search}>

                <div className={barclasses.searchIcon} >
                  <SearchIcon />

                </div>
                <InputBase
                  placeholder="Search…"
                  classes={{
                    root: barclasses.inputRoot,
                    input: barclasses.inputInput
                  }}
                  inputProps={{ "aria-label": "search" }}
                  onChange={searchText}

                  autoFocus
                />

              </div>
            }

          </div>
        </div>
      ) : (
        <div className={finalclasses.topMain}>
          <div className={finalclasses.upperMain}>
            <div className={finalclasses.flex}>
              <div className={classes.IconWrapper} style={{ width: "57px" }}>
                <img src={PaymentIcon} className={classes.icon} alt="" />
              </div>
              <p className={classes.MainHeading} style={{ fontSize: "12px" }}>
                Payment Plans Management
              </p>
            </div>
            <div className={barclasses.SearchContainer}>
              <div className={barclasses.search}>
                <div className={barclasses.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search…"
                  classes={{
                    root: barclasses.inputRoot,
                    input: barclasses.inputInput
                  }}
                  inputProps={{ "aria-label": "search" }}
                  onChange={searchText}
                />
              </div>
            </div>
          </div>

          <div className={finalclasses.bottomCont}>
            {isAdmin && (
              <div onClick={openModal}>
                <AddButton lable=" + Add Plan" />
              </div>
            )}
            {isAdmin && (
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={() => setPaymentDialog(true)}
              >
                Make Payment
              </Button>
            )}
          </div>
        </div>
      )}
      <AddPaymentModal open={openSignal} close={() => setOpenSignal(false)} />
      <Dialog
        open={paymentDialog}
        scroll="body"
        // keepMounted
        onClose={() => setPaymentDialog(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        overlayStyle={{ backgroundColor: "transparent" }}
        classes={{ paper: newClasses.paper }}
      >
        <div className={newClasses.topDiv}>
          <div className={newClasses.LogoWrapper}>
            <img src={Logo} className={newClasses.Logo} />
          </div>
          <form onSubmit={paymentDialogHandler} className={newClasses.upperDiv}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setPaymentDialog(false)}
            >
              <i
                class="fal fa-times fa-lg"
                style={{ position: "absolute", top: "3%", right: "4%" }}
              />
            </div>

            <div className={newClasses.firstDiv}>
              <span style={{ fontWeight: 600, marginTop: 50 }}>USER TYPE</span>
              <select
                className={newClasses.selectCont}
                onChange=""
                //required
                id="cars"
                name="paymentTypes"
              >
                <option disabled selected>
                  Select
                </option>
                {/* {DataArray.map(dataObj => {
                return (
                  <option value={dataObj.PaymentType}>
                    {dataObj.PaymentType}
                  </option>
                )
              })} */}
              </select>
            </div>
            <div className={newClasses.firstDiv}>
              <span style={{ fontWeight: 600 }}>USER NAME/ID</span>
              <input
                type="text"
                placeholder="Type..."
                className={newClasses.inputCont}
                //required
                value=""
                onChange=""
              />
            </div>

            <div className={newClasses.firstDiv}>
              <span style={{ fontWeight: 600 }}>AMOUNT</span>
              <input
                type="number"
                placeholder="$ Type..."
                className={newClasses.inputCont}
                //required
                value=""
                onChange=""
                style={{ width: "60%" }}
              />
            </div>

            <div className={newClasses.firstDiv}>
              <span style={{ fontWeight: 500 }}>Recite Attachment</span>
              <div
                className={newClasses.inputFileWrapper}
                onClick={imageUploadHandler}
              >
                <input
                  type="file"
                  ref={imageUploader}
                  style={{ display: "none" }}
                  onChange={fileUploadHandler}
                />
                <div className={newClasses.imageText}>
                  <span className="uploadImageText">
                    Drop document to upload. Or
                  </span>
                  <br />
                  <span className={newClasses.uploadInstruction}>
                    press here to browse
                  </span>
                </div>
              </div>

              <div className={newClasses.imageContainer}>
                {media?.map((doc, index) => (
                  <span className={newClasses.imageWrapper}>
                    <span onClick={() => imageDeleteHandler(index, doc)}>
                      <i
                        class="fal fa-times fa-lg"
                        style={{
                          paddingRight: "10px",
                          fontSize: "16px",
                          cursor: "pointer"
                        }}
                      />
                    </span>{" "}
                    {doc.name}
                  </span>
                ))}
              </div>
            </div>



            <Button
              variant="contained"
              color="secondary"
              className={newClasses.button}
              type="submit"

              // startIcon={<AddIcon />}
            >
              {"APPLY CREDIT"}
            </Button>
          </form>
        </div>
      </Dialog>

      <Dialog
        open={confirmDialog}
        scroll="body"
        // keepMounted
        onClose={() => setConfirmDialog(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        overlayStyle={{ backgroundColor: "transparent" }}
        classes={{ paper: newClasses.paper }}
      >
        <div className={newClasses.topDiv1}>
          <div className={newClasses.LogoWrapper}>
            <img src={Logo} className={newClasses.Logo} />
          </div>
          <form className={newClasses.upperDiv}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setConfirmDialog(false)}
            >
              <i
                className="fal fa-times fa-lg"
                style={{ position: "absolute", top: "3%", right: "4%" }}
              />
            </div>

            <div className={newClasses.dialogWrapper}>
              <div style={{ fontWeight: 600, paddingTop: 40 }}>
                PAYMENT CONFIRMATION!
              </div>
              <div className={newClasses.subHeading}>
                Review below credentials and make confirmation of the payment
              </div>
            </div>

            <div className={newClasses.dialogContainer}>
              <div className={newClasses.containerItemLeft}>
                <div>User Type</div>
                <div>User Name/ID</div>
                <div>Amount</div>
              </div>

              <div className={newClasses.containerItemRight}>
                <div>Court</div>
                <div>Court Name</div>
                <div>$120</div>
              </div>
            </div>



            <Button
              variant="contained"
              color="secondary"
              className={newClasses.button}
              type="submit"

              // startIcon={<AddIcon />}
            >
              {"APPROVE"}
            </Button>
          </form>
        </div>
      </Dialog>
    </div>
  )
}

export default PaymentManagementHeader
