import Actions from "../Type"
import axios from "../request"

export const GetClientPricing = ({ limit = 10, offset = 0 } = {}) => {
  return async dispatch => {
    try {
      dispatch({ type: Actions.GET_CLIENT_PRICING_REQUEST })
      const response = await axios.get(`/manage-users/client_pricing/?limit=${limit}&offset=${offset}`)
      dispatch({
        type: Actions.GET_CLIENT_PRICING_SUCCESS,
        // payload: response.data.results,
        payload: { results: response.data.results, count: response.data.count}
      })

    } catch (e) {
      dispatch({ type: Actions.GET_CLIENT_PRICING_FAIL })
    }
  }
}

export const GetAllClientPricing = () => {
  return async dispatch => {
    try {
      dispatch({ type: Actions.GET_CLIENT_PRICING_REQUEST })
      const response = await axios.get(`/manage-users/client_pricing/?type=full`)
      dispatch({
        type: Actions.GET_CLIENT_PRICING_SUCCESS,
        // payload: response.data.results,
        payload: { results: response.data, count: response.data.length, dataExist:response.data.length>0?true:false}
      })

    } catch (e) {
      dispatch({ type: Actions.GET_CLIENT_PRICING_FAIL })
    }
  }
}
