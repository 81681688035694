import React, { useState, useEffect, useContext } from 'react'
import { Dialog } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { useStyles } from './ReportViewsStyle'
import CircularSpinner from "../CircularProgressUserReport/CircularSpinner";
import OpenBalanceView from "./OpenBalanceView";
import ClientPayCallView from "./ClientPayCallView";
import ClientRemovalView from "./ClientRemovalView";
import SalesByServiceView from "./SalesByServiceView";
import CourtUserView from "./CourtUserView";



const ReportViews = ({ open, onClose, reportType,DataArray ,loading}) => {

    const classes = useStyles()

    let today = new Date()
    let futureDate = new Date(today)
    futureDate.setDate(futureDate.getDate() + 30)

    const useNewStyles = makeStyles(() => ({
        paper: { maxWidth: "1200px", width: '100%', maxHeight: '500px', padding: '20px 0px', overflowX: 'auto' },
    }));

    const defClasses = useNewStyles()

    const reportDataType=()=>{
        switch (reportType){
            case "openbalance":
                return(
                  <OpenBalanceView onClose={onClose} today={today} DataArray={DataArray}/>
                )
            case "clientpaycall":
                return(
                 <ClientPayCallView onClose={onClose} today={today} DataArray={DataArray}/>
                )
            case "clientremoval":
                return(
                  <ClientRemovalView onClose={onClose} today={today} futureDate={futureDate} DataArray={DataArray}/>
                )
            case "salesbyservice":
                return(
                    <SalesByServiceView onClose={onClose} today={today} futureDate={futureDate} DataArray={DataArray}/>
                )
            case "courtUser":
                return(
                  <CourtUserView onClose={onClose} today={today}/>
                )
            default:
                return(<></>)
        }
    }
    return (
        <Dialog
            open={open}
            scroll="body"
            onClose={onClose}
            // keepMounted
            classes={{ paper:!loading && defClasses.paper }}
            className={classes.customDialog}
        >
            {
                loading===true
                  ?
                      <CircularSpinner onModal/>
                  :
                    reportDataType()
            }
        </Dialog>
    )
}

export default ReportViews
