import React, {useEffect, useState} from 'react';
import { useStyles } from './ReportDataTableStyle';
import NewMoreDropDown from '../NewMoreDropDown/NewMoreDropDown';
import useToast from '../Toast/useToast';
import axios from "./../../Store/request"
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import {Container} from "@material-ui/core";
import CircularSpinner from '../CircularProgressUserReport/CircularSpinner';
import ReportViews from "../ReportViews/ReportViews";
import Pagination_New from "../NewPagination/Pagination_New";

const ReportTable = ({
 setViewReportType,
 viewReportType,
 setOpenReportViewModal,
 apiChange,
 currentPage,openReportViewModal,
 setCurrentPage,
 result,
 setResult,
 currentDataLength,
 setCurrentDataLength,
 filterParams,
  filterTxt
}) => {
    const classes = useStyles();
    const [openConfirmModal, setOpenConfirmModal] = useState(false)
    const { showToast } = useToast()
    const postsPerPage = 10
    const [deleteLoading,setDeleteLoading] = useState(false)
    const [showData,setShowData]=useState()
    const [triedFetching,setTriedFetching] = useState(false)
    const [detailLoader,setDetailLoader]=useState(false)
    const [currentData, setCurrentData] = useState({})

    const { data, count} = result;
    const getData = async () => {
        setResult({...result,loading:true})
        setTriedFetching(true)
        try {
          const { data: { results, count }} = await axios.get(`/api/v1/reports/${apiChange}/?limit=${postsPerPage}&offset=${postsPerPage * currentPage - postsPerPage}`)
            setResult({ data: results, count ,loading:false});
            setCurrentDataLength(results.length)
        }catch (e) {
          setResult({...result,loading:false})
        }
    };

    useEffect(async() => {
      setTriedFetching(true)
      setResult({...result, loading: true})

      try {
        filterParams.limit = postsPerPage;
        filterParams.offset = postsPerPage * (currentPage) - postsPerPage
        const encoded = Object.entries(filterParams).map(([k, v]) => `${k}=${encodeURIComponent(v)}`).join("&");
        const {data: {results, count}} = await axios.get(`/api/v1/reports/${apiChange}/?${encoded}`)
        setResult({data: results, count, loading: false});
        setCurrentDataLength(results.length)
      } catch (e) {
        setResult({...result, loading: false})
      }

    }, [apiChange, filterParams, currentPage])


    const deleteReport = () => {
      setDeleteLoading(true)
      axios
        .delete(`/api/v1/reports/${apiChange}/${currentData.id}/`)
        .then(function (response) {
          setOpenConfirmModal(false)
          showToast(`${apiChange} Deleted Successfully`, "success")
          setDeleteLoading(false)
          if(currentDataLength===1){
            setCurrentPage(prev=>{
              if(prev>1)
              return prev-1
              else return 1
            })
          }
          else{
            getData()
          }
        })
        .catch(function (error) {
          setOpenConfirmModal(false)
          setDeleteLoading(false)
          showToast("Unable to delete, please try again later", "error")
        })
    }

    const getCurrentData=async(id)=>{
      setDetailLoader(true)
      try {
        const response = await axios.get(`/api/v1/reports/${apiChange}/${id}/`)
        setShowData(response)
        setDetailLoader(false)
      }
      catch (e){
        showToast("Unable to fetch, please try again later", "error")
        setDetailLoader(false)
      }
    }

   // code for searching.

  const [searchResult,setSearchResult]=useState([])
  const searchData=async()=>{
    const response=await axios.get(`/api/v1/reports/${apiChange}/?limit=${count}&offset=${0}`)
    setSearchResult(response.data.results)
  }
  useEffect(()=>{
    if(filterTxt.length===1 && searchResult.length!==count)
      return searchData()
  },[filterTxt])

  let searchableData = []
  searchableData = searchResult?.filter(element => {
    return filterTxt.includes(element.id)
  })
  const checkData=filterTxt!==""?searchableData:data
    return (
        <>
         {result.loading?<CircularSpinner/>:
           <div className={classes.Container}>
           {checkData?.length ?<>
               <div className={classes.TableHeading}>
                 <div className={classes.HeadingInner}>
                   <p> </p>
                   <p className={classes.Heading1}>Report ID</p>
                   <p className={classes.Heading}>ID</p>
                 </div>
                </div>
             {checkData.map((i) => {

               return (

                 <div className={classes.MainContainer} key={i.id}>
                   <div className={classes.ContainerInner} >
                   <p className={classes.ListItmes} ><span style={{cursor:"pointer"}} onClick={()=> {
                      setOpenReportViewModal(true)
                      getCurrentData(i.id)
                    }}>{apiChange==="open_balance_report"?"Open Balance Report ":apiChange==="client_pay_call_report"?"Client Pay Call Report":apiChange==="sales_by_service"?"Sales By Service Report ":"Client Removal Report "}{i.id}</span></p>
                     <p className={classes.ListItmesBlack}>{i.id}</p>
                       <NewMoreDropDown
                         MoreDropDownItem={[
                           {actionFunction:() => {setOpenReportViewModal(true);
                               if(apiChange==="open_balance_report")
                                 setViewReportType('openbalance')
                               if(apiChange==="client_pay_call_report")
                                 setViewReportType('clientpaycall')
                               if(apiChange==="client_removal_report")
                                 setViewReportType('clientremoval')
                               if(apiChange==="sales_by_service")
                                 setViewReportType('salesbyservice')
                               getCurrentData(i.id)
                             },
                             icon:"Visibility",
                             name:"See details"},
                           {actionFunction:() => {
                               setCurrentData(i)
                               setOpenConfirmModal(true)
                             },
                             icon:"Delete",
                             name:"Delete"}
                         ]}/>
                   </div>
                 </div>
               )
             })
           }
           </>
               :
               <p className={classes.error} style={{visibility:!triedFetching?"hidden":"visible"}}>No Data Found</p>}
           <ConfirmModal
            signal={openConfirmModal}
            handleyes={deleteReport}
            handleno={() => setOpenConfirmModal(false)}
            msg={`Are you sure you want to delete this ${apiChange}?`}
            loading={deleteLoading}
          />
         </div>}
          <ReportViews
            open={openReportViewModal}
            onClose={() => setOpenReportViewModal(false)}
            reportType={viewReportType}
            DataArray={showData}
            loading={detailLoader}
          />

            {filterTxt!=="" || count>10 &&
                <Container
                    maxWidth="lg" style={{visibility:result.loading===true ?"hidden":"visible"}}>
                  <Pagination_New  count={count}
                                   activePage={currentPage}
                                   postsPerPage={postsPerPage}
                                   paginate={(pageNumber)=>{setCurrentPage(pageNumber)}}
                  />
                </Container>
            }
       </>
          )
}

export default ReportTable
