import React, { useEffect, useState } from "react"
import { useStyles } from "./CourtPricingDataTableStyle"
import NewMoreDropDown from "../NewMoreDropDown/NewMoreDropDown"
import {connect, useDispatch, useSelector} from "react-redux"
import { auth } from "../../Store/Actions/Auth"
import axios from "../../Store/request"
import ConfirmModal from "../ConfirmModal/ConfirmModal"
import ViewModal from "../ViewModal/ViewModal"
import {Dialog, Button, Container} from "@material-ui/core"
import useToast from "../../Components/Toast/useToast"
import { GetAllCourtPricing } from "../../Store/Actions/CourtPricing";
import CircularSpinner from "../CircularProgressUserReport/CircularSpinner";
import Logo from "../../assets/AMSLogo.png"
import NewPagination from "../NewPagination/NewPagination"
import InputPrefix from "../InputPrefix/InputPrefix"
import Pagination_New from "../NewPagination/Pagination_New";
import useAuth from "../../utils/useAuth";

const CourtPricingDataTable = ({DataArray, isDisabled, setIsDisabled, courtPricingData, currentDataLength, setMainCurrentPage, setServiceList}) => {
  const classes = useStyles()
  const { showToast } = useToast()


  const [currentData, setCurrentData] = useState({
    service: null,
    court: null
  })
  const [creditLoading,setCreditLoading]=useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [triedFetching,setTriedFetching] = useState(false)
  const [openViewModal, setOpenViewModal] = useState(false)
  const [arrayOfData, setArrayOfData] = useState([])
  const [openEditModal, setOpenEditModal] = useState(false)
  const dispatch = useDispatch()
  const { isOperator } = useAuth()

  
  useEffect(()=>{
    setCurrentPage(1)
  },[courtPricingData.court])

  const {data,count,dataExist} = useSelector(({ courtPricing }) => courtPricing)
  useEffect(() => {
    setTriedFetching(true)
    if(data.length>0 && data.length===count)return;
    if(dataExist){
      dispatch(GetAllCourtPricing())}
  }, [data])
  const deletePricing = () => {
    setCreditLoading(true)
    axios
      .delete(`/api/v1/courts/pricing/court/${currentData.id}/`)
      .then(async(response) => {
        setOpenConfirmModal(false)
        setCreditLoading(false)
        showToast("Court Pricing Deleted Succesfully", "success")
        if(currentDataLength===1){
          setMainCurrentPage(prev=>prev-1)
        }
        dispatch(GetAllCourtPricing())
        const responseData = await axios.get(`/api/v1/services/services_add_court_pricing/?court=${currentData.court.id}`)
        const serviceData = responseData.data?.map(item => {
          return ({ value: item.id, label: item.name })
        })
        setServiceList(serviceData)
      })
      .catch(function (error) {
        setOpenConfirmModal(false)
        setCreditLoading(false)
        showToast("Unable to delete, please try again later", "error")
      })
    }
    
    const EditPricing = e => {
      e.preventDefault()
      const lastData = {
        ...currentData,
        service: currentData.service.id,
        court: currentData.court.id
      }
      const data = JSON.stringify(lastData)
      setIsDisabled(true)
      setCreditLoading(true)
      axios
      .put(`/api/v1/courts/pricing/court/${currentData.id}/`, data)
      .then(function (response) {
        setOpenEditModal(false)
        setCurrentData({service: null,
          court: null})
          setIsDisabled(false)
          setCreditLoading(false)
          showToast("Court Pricing Edited Succesfully", "success")
          dispatch(GetAllCourtPricing())
        })
        .catch(function (error) {
          setIsDisabled(false)
          setCreditLoading(false)
          showToast("Unable to edit, please try again later", "error")
        })
      }

      const expressionConverter = txt => {
        let origString = txt.toString()
        let stringToAdd = "/"
        let indexPosition = 2

    origString = origString.split("")
    origString.splice(indexPosition, 0, stringToAdd)
    let newString = ""
    newString = origString.join("")
    return `$ ${txt}`
  }

  const TextClipper = (bio, length) => {
    let vb = ""
    
    if (bio?.length >= length) {
      for (var i = 0; i < length; i++) {
        vb += bio.charAt(i)
      }
      bio = `${vb}...`
    }
    
    return bio
  }
  
  const courtName=[]
  data.forEach((element)=>{
    if(element.court.id===courtPricingData?.court){
      courtName.push(element)
    }
  })
  const sortedService = courtName.sort(function(a, b) {
    const nameA = a.service.name.toUpperCase(); // ignore upper and lowercase
    const nameB = b.service.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1; 
    }
    if (nameA > nameB) {
      return 1; 
    }
    return 0;  
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = sortedService.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <div className={classes.Container}
         // style={{ minHeight: "650px" }}
    >
      {!!data.length?
      <>
        <div className={classes.TableHeading}>
          <div className={classes.HeadingInner}>
            <p></p>
            <p className={classes.Heading1}>Court name</p>
            <p className={classes.Heading}>Service name</p>
            <p className={classes.Heading}>Price</p>
          </div>
        </div>
        {courtPricingData.court===""?
          DataArray.map((i,index) => {
            if(i!==undefined) {
              return (
                <div className={classes.MainContainer} key={i.id}>
                  <div className={classes.ContainerInner}>
                    <p className={classes.ListItmes}>
                      {i.court.name}
                    </p>
                    <p className={classes.ListItmes}>
                      {i.service.name}
                    </p>

                    <p className={classes.ListItmes}>
                      {expressionConverter(i.price)}
                    </p>
                    { isOperator ? (
                        <NewMoreDropDown
                    MoreDropDownItem={[

                      {actionFunction:() => {
                        setArrayOfData([
                          {
                            title: "Court Name/ID",
                            val: i.court.name
                          },
                          {
                            title: "Service Name/ID",
                            val: i.service.name
                          },
                          {
                            title: "Price",
                            val: `$ ${i.price}`
                          }
                        ])
                        setCurrentData(i)
                        setOpenViewModal(true)
                      },
                      icon:"Visibility",
                      name:"See details"
                      },
                    ]}
                    />
                    ): (
                        <NewMoreDropDown
                    MoreDropDownItem={[
                      {actionFunction:() => {
                        setCurrentData(i)
                        setOpenEditModal(true)
                      },
                      icon:"Edit",
                      name:"Edit"
                      },
                      {actionFunction:() => {
                        setArrayOfData([
                          {
                            title: "Court Name/ID",
                            val: i.court.name
                          },
                          {
                            title: "Service Name/ID",
                            val: i.service.name
                          },
                          {
                            title: "Price",
                            val: `$ ${i.price}`
                          }
                        ])
                        setCurrentData(i)
                        setOpenViewModal(true)
                      },
                      icon:"Visibility",
                      name:"See details"
                      },
                      {actionFunction:() => {
                        setCurrentData(i)
                        setOpenConfirmModal(true)
                      },
                      icon:"Delete",
                      name:"Delete"
                      },
                    ]}
                    />
                      )
                    }
                  </div>
                </div>
              )
            }
        })
          :
          (courtName.length===0?
              <h1 className={classes.error}>No Data Found</h1>
              :currentPosts.map(i => {
            if(i!==undefined) {
              return (
                <div className={classes.MainContainer} key={i.id}>
                  <div className={classes.ContainerInner}>
                    <p className={classes.ListItmes}>
                      {i.court.name}
                    </p>
                    <p className={classes.ListItmes}>
                      {i.service.name}
                    </p>

                    <p className={classes.ListItmes}>
                      {expressionConverter(i.price)}
                    </p>
                    { isOperator ? (
                        <NewMoreDropDown
                    MoreDropDownItem={[

                      {actionFunction:() => {
                        setArrayOfData([
                          {
                            title: "Court Name/ID",
                            val: i.court.name
                          },
                          {
                            title: "Service Name/ID",
                            val: i.service.name
                          },
                          {
                            title: "Price",
                            val: `$ ${i.price}`
                          }
                        ])
                        setCurrentData(i)
                        setOpenViewModal(true)
                      },
                      icon:"Visibility",
                      name:"See details"
                      },
                    ]}
                    />
                    ): (
                        <NewMoreDropDown
                    MoreDropDownItem={[
                      {actionFunction:() => {
                        setCurrentData(i)
                        setOpenEditModal(true)
                      },
                      icon:"Edit",
                      name:"Edit"
                      },
                      {actionFunction:() => {
                        setArrayOfData([
                          {
                            title: "Court Name/ID",
                            val: i.court.name
                          },
                          {
                            title: "Service Name/ID",
                            val: i.service.name
                          },
                          {
                            title: "Price",
                            val: `$ ${i.price}`
                          }
                        ])
                        setCurrentData(i)
                        setOpenViewModal(true)
                      },
                      icon:"Visibility",
                      name:"See details"
                      },
                      {actionFunction:() => {
                        setCurrentData(i)
                        setOpenConfirmModal(true)
                      },
                      icon:"Delete",
                      name:"Delete"
                      },
                    ]}
                    />
                      )
                    }
                  </div>
                </div>
              )
            }
          })
          )
        }
          {courtName.length > 10 &&
          <>
          <Container maxWidth="lg">
            {
              sortedService.length>10 &&
              <Pagination_New  count={sortedService.length}
                               activePage={currentPage}
                               postsPerPage={postsPerPage}
                               paginate={(pageNumber)=>{setCurrentPage(pageNumber)}}
              />
            }

          </Container>
          </>
          }
      </>:<p className={classes.error} style={{visibility:!triedFetching?"hidden":"visible"}}>No Data Found</p>}

      <ConfirmModal
        signal={openConfirmModal}
        handleyes={deletePricing}
        handleno={() => setOpenConfirmModal(false)}
        msg={`Are you sure you want to delete this court pricing?`}
        loading={creditLoading}
      />

      <ViewModal
        signal={openViewModal}
        handleyes={() => setOpenViewModal(false)}
        arrayOfData={arrayOfData}
      />
      <Dialog
        open={openEditModal}
        scroll="body"
        // keepMounted
        onClose={() => setOpenEditModal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        // overlayStyle={{ backgroundColor: "transparent" }}
        PaperProps={{
          style: {
            overflow: "unset",
            margin:40
          }
        }}
      >
        {
          creditLoading===true?(
            <CircularSpinner onModal/>
          )
           :(
              <div className={classes.topDiv}>
              <div className={classes.LogoWrapper}>
                <img src={Logo} className={classes.Logo} alt="Logo"/>
              </div>
                <p
                  style={{
                    position: "absolute",
                    top: "3%",
                    left: "36%",
                    fontSize: "20px",
                    fontWeight: "700"
                  }}
                >
                  Edit Pricing
                </p>
                <form onSubmit={EditPricing} className={classes.upperDiv}>
                  <div
                    style={{cursor: "pointer"}}
                    onClick={() => setOpenEditModal(false)}
                  >
                    <i
                      className="fal fa-times fa-lg"
                      style={{position: "absolute", top: "3%", right: "4%"}}
                    ></i>
                  </div>
                 <div className={classes.firstDiv}>
                    <p className={classes.styleTag}>Court Name</p>
                    <input
                      type="text"
                      placeholder={currentData.price}
                      className={classes.selectCont}
                      value={currentData.court?.name}
                      readOnly
                      required
                      style={{outline:"none"}}
                    />
                  </div>
                  <div className={classes.firstDiv}>
                    <p className={classes.styleTag}>Service Name</p>
                    <input
                      type="text"
                      placeholder={currentData.price}
                      className={classes.selectCont}
                      value={currentData.service?.name}
                      readOnly
                      required
                      style={{outline:"none"}}
                    />
                  </div>

                  <div className={classes.firstDivPrice}>
                    <p className={classes.styleTag}>Pricing</p>
                    <InputPrefix>
                    <input
                      type="text"
                      placeholder="Type..."
                      className={classes.selectContPrice}
                      value={currentData.price}
                      onChange={e => {
                        setCurrentData({...currentData, price: e.target.value})
                      }}
                      required
                    />
                    </InputPrefix>
                  </div>
                  {isDisabled === false ?
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      disabled={currentData.price === ""}
                      type="submit"
                    >
                      {"SAVE PRICING"}
                    </Button>
                    : <Button
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      disabled
                      type="submit"
                    >
                      {"SAVE PRICING"}
                    </Button>
                  }


                </form>
              </div>
            )
        }

      </Dialog>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    redirectTo: state.auth.redirectTo,
    invalidUser: state.auth.invalidUser,
    accessToken: state.auth.accessToken
  }
}

export default connect(mapStateToProps, { auth })(CourtPricingDataTable)
