import { makeStyles } from "@material-ui/core/styles";


export const useStyles = makeStyles((theme) => ({
  button: {
    background: "#3971FF",
    margin: "0px 20px",
    "&.MuiButton-containedSecondary": {
      width: 185,
      height: 30,
      display: "flex",
      alingItems: "center",
      borderRadius: "5px !important",
      "&:hover": {
        background: "#3971FF",
      },
      "& .MuiButton-label": {
        fontFamily: "Open Sans",
        fontSize: 16,
        fontWeight: 700,
        textTransform: "capitalize",
        // marginTop: 3,
      },
      "& .MuiSvgIcon-root": {
        // marginTop: -2
      },
      "&.MuiButton-root":{
        lineHeight:"0 !important",
        letterSpacing: 0
      }
    }
  }
}));

export const useNewStyles = makeStyles((theme) => ({
  button: {
    background: "#3971FF",
    margin: "0px 20px",
    "&.MuiButton-containedSecondary": {
      width: 160,
      height: 30,
      display: "flex",
      alingItems: "center",
      borderRadius: "5px !important",
      "&:hover": {
        background: "#3971FF",
      },
      "& .MuiButton-label": {
        fontFamily: "Open Sans",
        fontSize: 16,
        fontWeight: 700,
        textTransform: "capitalize",
        // marginTop: 3,
      },
      "& .MuiSvgIcon-root": {
        // marginTop: -2
      },
      "&.MuiButton-root":{
        lineHeight:"0 !important",
        letterSpacing: 0
      }
    }
  }
}));

