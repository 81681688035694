import React from 'react';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Ellipse1 from "../../assets/Ellipse1.png";
import MaskGroup from "../../assets/Mask Group.svg";
import Loader from "./Loader";
import {useStyles} from "./UserProfileDataTableStyle";

const EditProfileCardPic = ({responseData,imageUploadHandler,imageUploader,fileUploadHandler,username,email,first_name,last_name}) => {
  const classes = useStyles()
  const { image } = responseData?.profile || {};
  return (
    <>
      <Card className={classes.cardpic1}>
        <CardContent>
          {/*<img src={responseData.image || Ellipse1} className={responseData.image?classes.picImg:classes.pic}   />*/}
          <img src={image || Ellipse1} className={image ?classes.picImg:classes.pic}/>

          <div className={classes.CreditfirstDiv1}>

            <div
              className={classes.inputFileWrapper1}
              onClick={imageUploadHandler}
            >
              <input
                type="file"
                ref={imageUploader}
                style={{display: "none"}}
                // value={responseData!==undefined?phone:<Loader/>}
                // onChange={event=>setResponse1({...responseData,phone:event.target.value})}
                onChange={fileUploadHandler}
                accept="image/png,image/jpeg,application/pdf"
              />

              <div className={classes.imageText}>
                <label htmlFor="img"
                       className={image ?classes.editImgUser:classes.editImg}>
                         <img src={MaskGroup}/></label>
              </div>
            </div>




          </div>



          {/*<span>image</span>*/}
          {/*<input type="file" name="uploadfile" id="img" style={{display: "none"}} />*/}
          {/*<label htmlFor="img" className={classes.editImg}>  <img src={MaskGroup}/></label>*/}
          <div className={classes.imageWrapper}>
                        <span className={classes.imageDetail}>
                          {responseData!==undefined?first_name+" "+last_name:<Loader/>}
                          <br />
                        </span>
            <span className={classes.span}>
                          {email}
                        </span>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default EditProfileCardPic;
