import React, { useState, useEffect, useContext } from "react"
import AddButton from "../Buttons/AddButton"
import UploadCsvBtn from "../Buttons/UploadCsvBtn"
import { useStyles, newStyles, finalStyles } from "./CourtsHeaderStyle"
import {connect, useDispatch} from "react-redux"
import { auth } from "../../Store/Actions/Auth"
import { barStyles } from "../SearchBar/SearchBarStyle"
import SearchIcon from "@material-ui/icons/Search"
import InputBase from "@material-ui/core/InputBase"
import { ScreenContext } from "./../../context/context"
import useAuth from "../../utils/useAuth"
import AddCourtDialog from "../AddCourtDialog/AddCourtDialog"
import {GetAllCourt, GetStateData} from "../../Store/Actions/Court";
import UploadCsvModal from "../UploadCsvModal/UploadCsvModal";
import Upload_Csv_File from "../UploadCsvModal/Upload_CSV_File";
const CourtsHeader = ({ MainHeading, lable, img, accessToken, searchText,isDisabled,setIsDisabled,stateList}) => {
  const classes = useStyles()
  const barclasses = barStyles()
  const finalclasses = finalStyles()
  const { isAdmin } = useAuth()
  const [openCourtModal, setOpenCourtModal] = useState(false)
  const [openCSV, setOpenCSV] = useState(false)
  const { screenSize, setScreenSize } = useContext(ScreenContext)
  const { isOperator } = useAuth();
  console.log("isOperator",isOperator)

  const [USstates, setUSStates] = useState([
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI"
  ])

  useEffect(() => {
    setScreenSize(window.innerWidth)
  }, [screenSize])


  const [search,setSearch]=useState(false);
  const searchBtn =(e)=>{
    setSearch(!search);
  }
  const dispatch=useDispatch()

  return (
    <>
      <div
        className={
          screenSize > 800 ? classes.MainContainer : finalclasses.MainCont
        }
      >
        {screenSize > 800 ? (
          <div className={classes.HeaderInner}>
            <div className={classes.Wrapper}>
              <div className={classes.IconWrapper}>
                <img src={img} className={classes.icon} alt="" />
              </div>
              <p className={classes.MainHeading}>{MainHeading}</p>
              {isAdmin && (
                <div onClick={() => {
                  setOpenCourtModal(true)
                  dispatch(GetStateData())
                }}>
                  <AddButton lable={lable} />
                </div>
              )}
              <div
                onClick={() => {
                  setOpenCSV(true)
                }}
              >

                {/*UPLOAD CSV BUTTON CODE*/}
                {!isOperator?<UploadCsvBtn />:""}
                
              </div>
            </div>
            <div className={barclasses.SearchContainer}>
              {search===false?
                <div className={barclasses.search1}>
                  <SearchIcon  onClick={searchBtn}  style={{ backgroundColor:"none"}}/>
                </div>:
                <div className={barclasses.search}>

                  <div className={barclasses.searchIcon} >
                    <SearchIcon />

                  </div>
                  <InputBase
                    placeholder="Search…"
                    classes={{
                      root: barclasses.inputRoot,
                      input: barclasses.inputInput
                    }}
                    inputProps={{ "aria-label": "search" }}
                    onChange={searchText}

                    autoFocus
                  />

                </div>
              }

            </div>
          </div>
        ) : (
          <div className={finalclasses.topMain}>
            <div className={finalclasses.upperMain}>
              <div className={finalclasses.flex}>
                <div className={classes.IconWrapper} style={{ width: "57px" }}>
                  <img src={img} className={classes.icon} alt="" />
                </div>
                <p className={classes.MainHeading} style={{ fontSize: "12px" }}>
                  {MainHeading}
                </p>
              </div>
              <div className={barclasses.SearchContainer}>
                <div className={barclasses.search}>
                  <div className={barclasses.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder="Search…"
                    classes={{
                      root: barclasses.inputRoot,
                      input: barclasses.inputInput
                    }}
                    inputProps={{ "aria-label": "search" }}
                    onChange={searchText}
                  />
                </div>
              </div>
            </div>

            <div className={finalclasses.bottomCont}>
              <div onClick={() => setOpenCourtModal(true)}>
                <AddButton lable={lable} />
              </div>
              {/*{MainHeading.includes("Courts") ? (*/}
              {/*  <div*/}
              {/*    onClick={() => {*/}
              {/*      setOpenCSV(true)*/}
              {/*    }}*/}
              {/*  >*/}

              {/*    /!*UPLOAD CSV BUTTON CODE*!/*/}
              {/*    /!*<UploadCsvBtn />*!/*/}
              {/*  </div>*/}
              {/*) : MainHeading.includes("Documentation") ? (*/}
              {/*  <div*/}
              {/*    onClick={() => {*/}
              {/*      setOpenCSVDocument(true)*/}
              {/*    }}*/}
              {/*  >*/}

              {/*    /!*UPLOAD CSV BUTTON CODE*!/*/}
              {/*    /!*<UploadCsvBtn />*!/*/}
              {/*  </div>*/}
              {/*) : null}*/}
              <div
                onClick={() => {
                  setOpenCSV(true)
                }}
              >

                {/*UPLOAD CSV BUTTON CODE*/}
                <UploadCsvBtn />
              </div>
            </div>
          </div>
        )}
        <AddCourtDialog
          isDisabled={isDisabled}
          setIsDisabled={setIsDisabled}
          openModal={openCourtModal}
          closeModal={() => {
            setOpenCourtModal(false)
            setIsDisabled(false)
          }}
          stateList={stateList}
        />

        {/*<UploadCsvModal*/}
        {/*  openCSV={openCSV}*/}
        {/*  setOpenCSV={setOpenCSV}*/}
        {/*  fileName="Court"*/}
        {/*  formateType="name, city, state, territory, address_1, address_2, postal_code "*/}
        {/*  apiUrl={`/api/v1/courts/court_from_csv/`}*/}
        {/*  actionName={GetAllCourt}*/}
        {/*/>*/}
        <Upload_Csv_File
            openCSV={openCSV}
            setOpenCSV={setOpenCSV}
            fileName="Court"
            formateType="name, address_1, address_2,phone_number,city, state, territory, postal_code "
            apiUrl={`/api/v1/courts/court_from_csv/`}
            actionName={GetAllCourt}

        />
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    accessToken: state.auth.accessToken
  }
}

export default connect(mapStateToProps, { auth })(CourtsHeader)
