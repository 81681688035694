import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  MainContainer: {
    width: "100%",
    height: "100vh",
    backgroundColor: "#F8FBFF"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  containerMob:{
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
   // width:'1400px'
  },
  BtnContainer: {
   
    display: 'flex',
    justifyContent:"space-between",
    
  },
  courtTitle:{
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "25px",
    width:'300px',
  },
  filterdiv: {
    background: '#FFFFFF',
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    width: '100px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    height: '40px',
    alignItems: 'center',
    cursor: 'pointer',
    marginLeft: '55%'
  },
  exportdiv: {
    background: '#FFFFFF',
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    width: '120px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    height: '40px',
    alignItems: 'center',
    cursor: 'pointer',
    padding:"2px",

    // marginLeft: '30%'
  },
  formcontrol:{
    "& .MuiInput-underline":{
      "&:before":{
        display: 'none'
      },
      "&:after":{
        display: 'none'
      }
    }
  }
}));
