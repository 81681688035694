import Actions from "../Type.js"


const getUserFromLocal = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user)
  //return {}
}
const initialState = {
  user: getUserFromLocal(),
  accessToken: localStorage.getItem("token"),
  isAuthenticated: !!getUserFromLocal(), // if user is authenticated
  invalidUser: false, // whether use typed invalid email or password during login

  redirectTo: localStorage.getItem("user")?JSON.parse(localStorage.getItem("user")).user_type===1|| JSON.parse(localStorage.getItem("user")).user_type===4?"/courts":JSON.parse(localStorage.getItem("user")).user_type===2?"/payment-plans":"User":false,
  // successfull redirect to dashboard on successfull login

  isLoading: false, //loading during login
  loadingUserDetails: true,
  //refreshToken: "",
}

const AuthReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case Actions.LOGIN_SUCCESS:
      localStorage.setItem("token", payload.key)
      localStorage.setItem("user", JSON.stringify(payload.user))

      return {
        ...state,
        isAuthenticated: true,
        user: payload.user,
        accessToken: payload.key,
        invalidUser: false,
        loginSuccess: true,
      }

    case Actions.GET_USER_DETAIL_SUCCESS:
      return {
        ...state,
        userProfile: payload,
        loadingUserDetails: false
      }

    case Actions.LOGOUT:
      localStorage.removeItem("token")
      localStorage.removeItem("user")
      return {
        ...state,
        accessToken: null,
        user: null,
        isAuthenticated: null,
        invalidUser: false,
        redirectTo: "/"
      }

    case Actions.LOGIN_FAIL:
    case Actions.INCORRECT_CREDENTIALS:
      localStorage.removeItem("token")
      localStorage.removeItem("user")
      return {
        ...state,
        accessToken: null,
        user: null,
        isAuthenticated: false,
        invalidUser: true,
        redirectTo: false
      }
    default:
      return state
  }
}

export default AuthReducer
