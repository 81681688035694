import React, { useEffect, useState, useContext, useRef } from "react"
import { ScreenContext } from "./../../context/context"
import { useStyles, finalStyles } from "./PaymentsStyles"
import UsersIcon from "../../assets/UsersIcon.png"
import { barStyles } from "../SearchBar/SearchBarStyle"
import SearchIcon from "@material-ui/icons/Search"
import InputBase from "@material-ui/core/InputBase"
import paymenticon from "../../assets/PaymentIcon.png"
import {
  Box,
  Dialog,
  Button,
  Checkbox,CircularProgress
} from "@material-ui/core"
import modal_icon from "../../assets/AMSLogo.png"
import { DataArray } from "../PaymentsDataTable/DataArray"
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from "@stripe/react-stripe-js"
import axios from "../../Store/request"
import useToast from "../../Components/Toast/useToast"
import InputPrefix from "../InputPrefix/InputPrefix"
import CircularSpinner from "../CircularProgressUserReport/CircularSpinner"
import useAuth from "../../utils/useAuth";
import { useHistory } from "react-router-dom"

const PaymentsHeader = props => {


  useEffect(()=>{
    let payment_data = props.paymentData
    let _count = payment_data.count
    if(_count){
      if (_count === 0){
        setSelectedMethod(null)
      }
      else{
        let _selected_id = payment_data?.results[_count-1]?.id
        setSelectedMethod(_selected_id)
  
      }
    }
  },[props.paymentData])

  const classes = useStyles()
  const stripe = useStripe()
  const elements = useElements()
  const [isDisable,setIsDisable] = useState(true)
  const { screenSize, setScreenSize } = useContext(ScreenContext)
  const [selectedMethod, setSelectedMethod] = useState(null)
  const finalclasses = finalStyles()
  const barclasses = barStyles()
  const [showModal, setShowModal] = useState(false)
  const handleToggleModal = () => setShowModal(!showModal)
  const [formData, setFormData] = useState({ amount: "", name: "" })
  const user = JSON.parse(localStorage.getItem("user"))
  const { showToast } = useToast()
  const { isAdmin } = useAuth()
  let userId = localStorage.getItem('userId');
  const history = useHistory()
  const userData = history.location.state?.data;

  const addPayment = async params => {
    setIsDisable(true);

    setCreditLoading(true)
    const response = await axios.post("/manage-users/user_payments/", params)
    if (response.status === 200) {
      userData.balance = parseFloat(userData.balance) + parseFloat(formData.amount)
      showToast("User Added Successfully", "success");
      setFormData({ amount: "", name: "" })

      setCreditLoading(false)
    }

    props.reloadData()
    handleToggleModal()
  }
  const [creditLoading,setCreditLoading]=useState(false)
  const submitHandler = async e => {
    e.preventDefault();
    setIsDisable(true)
    if (selectedMethod !== "new") {
      // setCreditLoading(true)
      let idUser = user.id;
        if ( isAdmin && userId ) {
          idUser = userId;
        }
      return addPayment({
        amount: +formData.amount,
        user:idUser,
        user_card: selectedMethod,
        payment_type: 1,
        new_payment_method: false
      })

    }

    if (!stripe || !elements) {

      return
    }
    const cardNumberElement = await elements?.getElement(CardNumberElement)
    if (cardNumberElement) {
      const { token } = await stripe?.createToken(cardNumberElement, {
        account_holder_type: "individual",
        name: formData.name
      })
      try {
        setIsDisable(false)
        let idUser = user.id;
        if ( isAdmin && userId ) {
          idUser = userId;
        }
        const addData = {
          payment_type: 1,
          new_payment_method: true,
          amount: +formData.amount,
          user: idUser,
          card_token: token.id,
          is_saved: true
        }
        setFormData({ amount: "", name: "" })
        return addPayment(addData)
      } catch (error) {

        showToast("Could not add Credit, please try later", "error")
      }
    }
  }


  const [search,setSearch]=useState(false);
  const searchBtn =(e)=>{
    setSearch(!search);
  }
  return (
    <>
      <Dialog
        open={showModal}
        scroll="body"
        onClose={handleToggleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        PaperProps={{
          style: {
            overflow: "unset"
          }
        }}
      >

        <Box>
          {
            creditLoading===true?
              (<CircularSpinner onModal/>)
              :(
                <div className={classes.modal_container}>
                  <div
                    style={{cursor: "pointer"}}
                    onClick={() => {
                      setShowModal(false);
                      setFormData({amount: "", name: ""})
                      setIsDisable(true)
                    }}
                  >
                    <i
                      className="fal fa-times fa-lg"
                      style={{position: "absolute", top: "3%", right: "4%"}}
                    />
                  </div>
                  <div className={classes.modal_icon}>
                    <img src={modal_icon} height="30px" width="30px"/>
                  </div>
                  <br/>

                  <div className={classes.modal_title}>Select Payment Method</div>

                  <div className={classes.modal_body}>
                    <div className={classes.paymentSelection}>
                      {props.paymentData?.results?.map((result,index) => (
                        <div className={classes.modal_payment_input1} key={result.id}>
                          <div className={classes.model_payment_checkbox}>
                            <Checkbox color="primary"
                                      checked={selectedMethod === result.id}
                                      onChange={() => {
                                        setSelectedMethod(result.id);
                                        if (formData.amount && result.id) setIsDisable(false)
                                        else setIsDisable(true)
                                      }}/>
                          </div>
                          <div className={classes.modal_payment_img}>
                            <img src={DataArray[0].img}/>
                          </div>
                          <div className={classes.model_payment_code}>
                            **** {result.card_last_four}
                          </div>
                          <div className={classes.model_payment_visa}> VISA</div>
                        </div>
                      ))}
                      <div className={classes.modal_payment_input1}>
                        <div className={classes.model_payment_checkbox}>
                          <Checkbox color="primary"
                                    checked={selectedMethod === "new"}
                                    onChange={() => {
                                      setSelectedMethod("new");
                                      if (formData.amount)
                                        setIsDisable(false)
                                      else
                                        setIsDisable(true)
                                    }}
                          />
                        </div>

                        <div className={classes.model_payment_code}>
                          New Payment Method
                        </div>
                      </div>
                    </div>
                    <hr className={classes.hrLine}/>
                    <div className={classes.card}>
                      <div className={classes.card_visa}>
                        <img src={DataArray[0].img} className={classes.visa_img}/>
                      </div>

                      <div className={classes.card_master}>
                        <img src={DataArray[1].img} className={classes.master_img}/>
                      </div>
                    </div>
                    <div>
                      <form onSubmit={submitHandler} className={classes.card_Detail}>
                        {selectedMethod === "new" && (
                          <>
                            <div>
                              <p style={{fontWeight: 600}}>CARD NUMBER</p>
                              {/* <input type="text" placeholder="Type..." className={classes.input_name}/> */}
                              <CardNumberElement className={classes.input_name}/>
                            </div>
                            <div style={{marginLeft: "20px"}}>
                              <p style={{fontWeight: 600}}>SECURITY CODE</p>
                              {/* <input type="text" placeholder="Type..." className={classes.input_code}/> */}
                              <CardCvcElement className={classes.input_code}/>
                            </div>
                            <div>
                              <p style={{fontWeight: 600}}>EXPIRE DATE</p>
                              {/* <input type="month" placeholder="MM/YYYY" className={classes.input_expiray}/> */}
                              <CardExpiryElement className={classes.input_expiray}/>
                            </div>
                            <div style={{marginLeft: "30px"}}>
                              <p style={{fontWeight: 600}}>NAME ON CARD</p>
                              <input
                                type="text"
                                placeholder="Type..."
                                className={classes.input_name_onCard}
                                value={formData.name}
                                onChange={e =>
                                  setFormData({...formData, name: e.target.value})
                                }
                              />
                            </div>
                            <div className={classes.save}>
                              <div className={classes.model_payment_checkbox}>
                                <Checkbox/> Save for future payment purpose
                              </div>
                            </div>
                          </>
                        )}
                        <hr className={classes.hrLine2}/>
                        <div className={classes.amount}>
                          <p style={{fontWeight: 600}}>AMOUNT</p>
                          <InputPrefix>
                          <input
                            type="number"
                            placeholder="Type..."
                            className={classes.input_amount}
                            value={formData.amount}
                            onChange={e => {
                              if (selectedMethod && e.target.value) {
                                setIsDisable(false)
                              } else {
                                setIsDisable(true)
                              }
                              setFormData({...formData, amount: e.target.value})
                            }
                            }
                          />
                          </InputPrefix>
                        </div>
                        <div className={classes.payBtn}>
                          <Button
                            style={{
                              background:!isDisable && "#3971FF",
                              color:!isDisable && "#FFFFFF"
                            }}
                            disabled={isDisable}
                            type="submit"
                            variant="contained"
                            className={classes.btnpay}
                          >
                            PAY
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              )
          }


        </Box>
      </Dialog>

      <div
        className={
          screenSize > 800 ? classes.MainContainer : finalclasses.MainCont
        }
      >
        {screenSize > 800 ? (
          <div className={classes.HeaderInner}>
            <div className={classes.Wrapper}>
              <div className={classes.IconWrapper}>
                <img src={paymenticon} className={classes.icon} />
              </div>

              {props.isIndividualUser || isAdmin?(
                <>
                <div className={classes.Title}>
                  {props.state === 0 ? (
                    <p className={classes.MainHeading}>Balance</p>
                  ) : (
                    ""
                  )}
                  {props.state === 1 ? (
                    <p className={classes.MainHeading}>Statements</p>
                  ) : (
                    ""
                  )}
                  {props.state === 2 ? (
                    <p className={classes.MainHeading}>Payments</p>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  <div style={{marginLeft:"-15px"}}>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.button1}
                      onClick={handleToggleModal}
                      type="submit"
                    >
                      {" MAKE PYMT "}
                    </Button>

                  </div>

                </div>
                </>
              ):
                <div className={classes.Title}>
                  {props.userManage===false?(< p className={classes.MainHeading}>Payments</p>):
                  (
                    <>
                    < p className={classes.MainHeading}>Payment Plans Management</p>
                    </>
                  )
                  }
              </div>}


            </div>

            <div className={barclasses.SearchContainer}>
              {search===false?
                <div className={barclasses.search1}>
                  <SearchIcon  onClick={searchBtn}  style={{ backgroundColor:"none"}}/>
                </div>:
                <div className={barclasses.search}>

                  <div className={barclasses.searchIcon} >
                    <SearchIcon />

                  </div>
                  <InputBase
                    placeholder="Search…"
                    classes={{
                      root: barclasses.inputRoot,
                      input: barclasses.inputInput
                    }}
                    inputProps={{ "aria-label": "search" }}

                    autoFocus
                  />

                </div>
              }

            </div>
          </div>
        ) : (
          <div className={finalclasses.topMain}>
            <div className={finalclasses.upperMain}>
              <div className={finalclasses.flex}>
                <div className={classes.IconWrapper} style={{ width: "57px" }}>
                  <img src={UsersIcon} className={classes.icon} alt="" />
                </div>
                <p className={classes.MainHeading} style={{ fontSize: "12px" }}>
                  {"Payments"}
                </p>
              </div>
              <div className={barclasses.SearchContainer}>
                <div className={barclasses.search}>
                  <div className={barclasses.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder="Search…"
                    classes={{
                      root: barclasses.inputRoot,
                      input: barclasses.inputInput
                    }}
                    inputProps={{ "aria-label": "search" }}
                    onChange={props.searchText}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default PaymentsHeader
