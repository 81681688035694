import React from "react";
import { useStyles } from "./UploadCsvBtnStyle";
import Button from '@material-ui/core/Button';
import BackupIcon from '@material-ui/icons/Backup';

const UploadCsvBtn = () => {
  const classes = useStyles();
  return (
    <div className={classes.ButtonContainer}>
      <Button
        variant="contained"
        color="secondary"
        className={classes.button}
        startIcon={<BackupIcon />}
      >
        Upload CSV
      </Button>
    </div>
  )
}

export default UploadCsvBtn