import React from "react"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import CloseIcon from "@material-ui/icons/Close"
import { useStyles } from "./NewMoreDropDownStyle"
import EditIcon from "@material-ui/icons/Edit"
import VisibilityIcon from "@material-ui/icons/Visibility"
import DeleteIcon from "@material-ui/icons/Delete"
import ArchiveIcon from '@material-ui/icons/Archive';
import CreditCardIcon from "@material-ui/icons/CreditCard"
import Description from '@material-ui/icons/Description';
import Activate from '@material-ui/icons/RotateRightSharp';
import EventNote from "@material-ui/icons/EventNote"

const NewMoreDropDown = ({ MoreDropDownItem }) => {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const finalIcon = (iconName) => {
        switch (iconName){
            case "Edit":
                return <EditIcon className={classes.EditIcon} />
            case "Delete":
                return <DeleteIcon className={classes.DeleteIcon} />
            case "Apply Credit":
                return <CreditCardIcon className={classes.CreditCardIcon} />
            case "Invoice":
                return <Description className={classes.InvoiceIcon} />
            case "Archive":
                return <ArchiveIcon className={classes.DeleteIcon} />
            case "Activate":
                return <Activate className={classes.Activate} />
            case "Note":
                return <EventNote className={classes.Activate} />
            default :
                return <VisibilityIcon className={classes.SeeIcon} />
        }
    }
    return (
      <div className={classes.MainContainer}>
          <MoreVertIcon onClick={handleClick} className={classes.VerticalIcon} />
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
              <div className={classes.Inner}>
                  <div className={classes.Wrapper}>
                      <CloseIcon onClick={handleClose} className={classes.CloseIcon} />
                  </div>
                  {MoreDropDownItem.map((element,index) => {
                      return (
                        <MenuItem
                          key={index}  
                          onClick={() => {
                              element.actionFunction()
                              handleClose()
                          }}
                          className={classes.MenuList}
                        >
                            {finalIcon(element.icon)}
                            {element.name}
                        </MenuItem>
                      )
                  })}
              </div>
          </Menu>
      </div>
    )
}
export default NewMoreDropDown









