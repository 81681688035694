import axios from 'axios';
import { clearStorage, getAccessToken } from './storage';

// production
const ax = axios.create({
    baseURL: 'https://ohio-ams-software-25416.botics.co',
    // baseURL: 'http://127.0.0.1:8000',
});

ax.interceptors.request.use((config) => {
    const accessToken = getAccessToken('token');
    if (accessToken) config.headers.Authorization = `Token ${accessToken}`;
    config.headers['Content-Type'] = 'application/json';
    return config;
});

ax.interceptors.response.use(
    (response) => response,
    async (error) => {
        const { config: originalRequest, response } = error;

        if (
            !originalRequest._retry &&
            response?.status === 401
        ) {
            originalRequest._retry = true;
            // Logout;
            clearStorage();
        }

        return Promise.reject(error);
    }
);

export default ax;
