import React from 'react';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Ellipse1 from "../../assets/Ellipse1.png";
import Loader from "./Loader";
import {useStyles} from "./UserProfileDataTableStyle";

const UserProfileCardPic = ({responseData,username,email,first_name,last_name}) => {
  const classes = useStyles()
  const { image } = responseData?.profile || {};
  return (
    <>
      <Card className={classes.cardpic}>
        <CardContent>
          <img src={image || Ellipse1} className={image ?classes.picImg:classes.pic}   />
          {/*<img src={Ellipse1} className={classes.pic} />*/}

          <div className={classes.imageWrapper}>
                        <span className={classes.imageDetail}>
                           {/*{username}*/}

                          {first_name+" "+last_name}
                          <br />
                        </span>
            <div className={classes.span}>
                           {email}
                        </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default UserProfileCardPic;
