import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent:"center",
    alignItems:"center",
    height:"100vh"
  },
  MainContainer: {
    width: "100%",
    height: "100vh",
    backgroundColor: "#F8FBFF"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  containerMob:{
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
   // width:'1400px'
  },
  BtnContainer: {
   
    display: 'flex',
    justifyContent:"space-between",
    
  },
 
}));
