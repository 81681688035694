import React, {useContext} from "react"
import { useStyles } from "../Payments/PaymentsStyle"
import { Box } from "@material-ui/core"
import { TabContext} from "@material-ui/lab"
import {Button} from "@material-ui/core"
import {useHistory} from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useSelector } from "react-redux"
import axios from "../../Store/request"
import useToast from "../../Components/Toast/useToast"

const UserNotesInformation = ({state,notesData,setNotesData}) => {
  const { showToast } = useToast()

  const {current_page} = useSelector(({ manageUser }) => manageUser)
  const classes = useStyles()
    const history = useHistory()
    const userData = history.location.state?.data;
    const openHsitoryPage = () => {
      history.push(`/manage-users/clients/page=${current_page}`)
  }

  const delete_notes = async (e,id)=>{
      try {
        const response = await axios.delete(`/manage-users/user_notes/${id}/`)
        let remaining_notes = notesData.filter(item=>item.id!=id);
        setNotesData(remaining_notes)
        showToast("Note Deleted Successfully", "success")
      } catch (error) {
        console.log(error.response)
      }
  }
  return (
    <>
      <TabContext value={state}>
        <Box sx={{ borderColor: "divider" }}>
          <div className={classes.relative}>
                <Button
                    variant="contained"
                    color="secondary"
                    style={{
                            background:"#3971FF",
                            color:"#FFFFFF"
                        }}
                    className={classes.btnpay}
                    onClick= {(e)=>{
                                e.preventDefault()
                                openHsitoryPage()}}
                    >
                    Back
                    </Button>
          </div>
        {notesData.map(item => {
            return (
            <>
             <Card style={{marginTop:"10px",marginBotton:"10px"}} key={item.id}>
               <CardContent>
                  <div style={{width:"100%",display:"flex",justifyContent:"flex-end"}}>
                    <Button variant="contained" color="secondary" onClick={(e)=>{delete_notes(e,item.id)}}>Delete</Button>
                  </div>
                  <div style={{width:"100%",display:"flex",justifyContent:"space-between"}}>
                    <h4>Created by: {item.created_by_user?.first_name} {item.created_by_user?.last_name}</h4>
                    <h4>Created at: {item.created_at}</h4>
                  </div>
                    <p>{item.note}</p>
               </CardContent>
             </Card> 
             </>
            )
        })}
        </Box>
      </TabContext>
    </>
  )
}

export default UserNotesInformation
