import React from "react"
import { CircularProgress } from "@material-ui/core"

import { useStyles } from "./CircularSpinnerStyle"

const CircularSpinner = () => 
{
    const classes=useStyles()
      
      return(<div className={classes.root} style={{width: 'calc(100% - 250px)'}}>
        <CircularProgress className={classes.progress}/>
    </div>)
}

export default CircularSpinner