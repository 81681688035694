import React, { useState, useEffect } from "react"
import { useStyles } from "./ServicesDataTableStyle"
import axios from "../../Store/request"
import {connect, useDispatch, useSelector} from "react-redux"
import { auth } from "../../Store/Actions/Auth"
import {Dialog, Button, Switch} from "@material-ui/core"
import ConfirmModal from "../ConfirmModal/ConfirmModal"
import useToast from "../../Components/Toast/useToast"
import {GetAllServices, GetServices} from "../../../src/Store/Actions/Services"
import CircularSpinner from "../CircularProgressUserReport/CircularSpinner";
import ViewModal from "../ViewModal/ViewModal";
import Logo from "../../assets/AMSLogo.png";
import NewMoreDropDown from "../NewMoreDropDown/NewMoreDropDown"

const ServicesDataTable = ({DataArray,isDisabled,setIsDisabled}) => {
  const classes = useStyles()
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const { showToast } = useToast()
  const [arrayOfData, setArrayOfData] = useState([])
  const [openViewModal, setOpenViewModal] = useState(false)
  const [creditLoading,setCreditLoading]=useState(false)
  const [triedFetching,setTriedFetching] = useState(false)

  const [currentData, setCurrentData] = useState({})
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
   const [openEditModal, setOpenEditModal] = useState(false)
  const dispatch = useDispatch()
  const {data,count,dataExist} = useSelector(({ services }) => services)
  const [errorService,setErrorService]=useState("")

  useEffect(()=>{
    setTriedFetching(true)
    if(data.length>0 && data.length===count)return;
    if(dataExist)
    dispatch(GetAllServices())
  },[])

  const deleteService = () => {
    setCreditLoading(true)
    axios
      .delete(`/api/v1/services/${currentData.id}/`)
      .then(function (response) {
        setOpenConfirmModal(false)
        showToast("Service Deleted Succesfully", "success")
        dispatch(GetAllServices())
        setCreditLoading(false)
      })
      .catch(function (error) {
        setCreditLoading(false)
        setOpenConfirmModal(false)
        showToast("Unable to delete, please try again later", "error")
      })
  }

  const EditService = e => {
    e.preventDefault()
    const existsService=data.find((element)=>element.name===currentData.name && element.id!==currentData.id)
    if(existsService)
    {
      setErrorService("*Service is already Exists")
      return
    }
    let dataJson = JSON.stringify(currentData)
    setIsDisabled(true)
    setCreditLoading(true)
    axios
      .put(`/api/v1/services/${currentData.id}/`, dataJson)
      .then(function (response) {
        setOpenEditModal(false)
        setCurrentData({})
        showToast("Service Edited Succesfully", "success")
        setCreditLoading(false)
        setIsDisabled(false)
        dispatch(GetAllServices())
      })
      .catch(function (error) {
        setCreditLoading(false)
        setIsDisabled(false)
        showToast("Unable to edit please try again later", "error")
      })
  }

  const TextClipper = (bio, length) => {
    let vb = ""

    if (bio?.length >= length) {
      for (var i = 0; i < length; i++) {
        vb += bio.charAt(i)
      }
      bio = `${vb}...`
    }

    return bio
  }

  const inputEvent=(event)=>{
    const {name,value}=event.target;
    setCurrentData((prevalue)=>{
      return {
        ...prevalue,
        [name]: value,
      }
    })
  }
  return (

    <div className={classes.Container}>
      {!!DataArray.length?<>
        <div className={classes.TableHeading}>
          <div className={classes.HeadingInner}>
            <p className={classes.Heading1}>Service name</p>
            <p className={classes.Heading}>
              Service Description
            </p>
         
            <p className={classes.Heading}>
              One Time Fee 
            </p>
          </div>
        </div>
        {DataArray.map((i, index) => {
          return (
            <div key={i.id} className={classes.MainContainer}>
              <div className={classes.ContainerInner}>
                <p className={classes.ListItmes}>{i.name}</p>
                <p className={classes.ListItmes}>
                  {i.description}
                </p>
                <p className={classes.ListItmes} style={
                      i.one_time_fee === false
                        ? { color: "#FF2A13" }
                        : { color: "#4C8210" }
                    }>
                  {i.one_time_fee?"YES":"NO"}
                </p>
                <NewMoreDropDown
                MoreDropDownItem={[
                  {actionFunction:() => {
                    setCurrentData(i)
                    setOpenEditModal(true)
                  },
                  icon:"Edit",
                  name:"Edit"
                  },
                  {actionFunction:() => {
                    setArrayOfData([
                      {
                        title: "Service Name/ID",
                        val: i.name
                      },
                      {
                        title: "Service Description",
                        val: i.description
                      },
                      {
                        title: "One Time Fee",
                        val: `${i.one_time_fee?"Yes":"No"}`
                      }
                    ])
                    setCurrentData(i)
                    setOpenViewModal(true)
                  },
                  icon:"Visibility",
                  name:"See details"
                  },
                  {actionFunction:() => {
                    setCurrentData(i)
                    setOpenConfirmModal(true)
                  },
                  icon:"Delete",
                  name:"Delete"
                  },
                ]}
                />
              </div>
            </div>
          )
        })}
      </>:<p className={classes.error} style={{visibility:!triedFetching?"hidden":"visible"}}>No Data Found</p>
      }


      {/*View dialog box */}

      <ViewModal
        signal={openViewModal}
        handleyes={() => setOpenViewModal(false)}
        arrayOfData={arrayOfData}
      />

      {/*Delete dialog*/}
      <ConfirmModal
        signal={openConfirmModal}
        handleyes={deleteService}
        handleno={() => setOpenConfirmModal(false)}
        msg={`Are you sure you want to delete service "${currentData.name}"?, This will also delete any court pricing depending on this service.`}
        loading={creditLoading}
      />

      {/*Edit Dialog */}

      <Dialog
        open={openEditModal}
        scroll="body"
        onClose={() => {setOpenEditModal(false);setErrorService("")}}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: {
            overflow: "unset",
            margin:40
          }
        }}
      >
        {creditLoading===true?(
          <CircularSpinner onModal/>
        ):(
          <div className={classes.topDiv}>
            <div className={classes.LogoWrapper}>
              <img src={Logo} className={classes.Logo} />
            </div>
            <p
              style={{
                position: "absolute",
                top: "5%",
                left: "36%",
                fontSize: "20px",
                fontWeight: "700"
              }}
            >
              Edit Service
            </p>
            <form onSubmit={EditService} className={classes.upperDiv}>
              <div
                style={{cursor: "pointer"}}
                onClick={() => {setOpenEditModal(false);setErrorService("")}}
              >
                <i
                  className="fal fa-times fa-lg"
                  style={{position: "absolute", top: "3%", right: "4%"}}
                ></i>
              </div>

              <div className={classes.firstDiv}>
                <p className={classes.styleTag}>Service Name</p>
                <input
                  type="text"
                  name="name"
                  placeholder="Type..."
                  className={classes.selectCont}
                  value={currentData.name}
                  onChange={inputEvent}
                  required
                />
                <span style={{color:"red"}}>{errorService}</span>
              </div>
              <div className={classes.firstDiv}>
                <p className={classes.styleTag}>Service Description</p>
                <input
                  type="text"
                  name="description"
                  placeholder="Type..."
                  className={classes.selectCont}
                  value={currentData.description}
                  onChange={inputEvent}
                  required
                />
              </div>
              <div className={classes.firstDiv}>
                <span className={classes.styleTag}>One Time Fee</span>
                <Switch {...label} onChange={e=>{
                  setCurrentData({...currentData,one_time_fee:e.target.checked })}} color="primary" checked={currentData.one_time_fee}/>
              </div>
              {isDisabled === false ?
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  disabled={
                    currentData.name === "" || currentData.description === ""
                  }
                  type="submit"
                >
                  {"SAVE SERVICE"}
                </Button>
                : <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  disabled
                  type="submit"
                >
                  {"SAVE SERVICE"}
                </Button>

              }

            </form>
          </div>
        )}

      </Dialog>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    accessToken: state.auth.accessToken
  }
}

export default connect(mapStateToProps, { auth })(ServicesDataTable)
