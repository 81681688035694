import React,{ useEffect, useState } from "react";
import { Dialog,Button } from "@material-ui/core";
import { useStylesClientRemoval } from "./ReportsHeaderStyle";
import Logo from '../../assets/AMSLogo.png';
import useToast from "../Toast/useToast";
import axios from "./../../Store/request";
import CircularSpinner from "../CircularProgressUserReport/CircularSpinner";
import CourtDropDownData from "../CourtDropDownData/CourtDropDownData";
import { useDispatch, useSelector } from "react-redux";
import SearchableDropDown from "../SearchableDropdown/SearchableDropDown";

const ClientRemovalReport = ({addClientRemovalModal,setAddClientRemovalModal,currentPage,result,setResult}) => {
    const classes = useStylesClientRemoval()
    const { showToast } = useToast()
    const [postsPerPage] = useState(10)
    const [teritories,setTeritories] = useState([]);
    const [reportType] = useState([{value:"1",label:"INSTALL"},{value:"2",label:"REMOVAL"}])
    
    const getTeritories = async () =>{
        setClientRemovalLoading(true)
        const response=await axios.get(`/api/v1/courts/territory/`)
        setTeritories([])
        if(response.status===200){
            response.data.map((object)=>{
                teritories.push({value:object.id,label:object.name})
            })
            setTeritories(teritories)
        }
        setClientRemovalLoading(false)
    }

    useEffect(()=>{
        getTeritories()
    },[])

    const [clientRemovalReport,setClientRemovalReport] = useState({
        court:"",
        from_date:"",
        report_type:"",
        to_date:"",
        territory:""
    })
    const [clientRemovalLoading,setClientRemovalLoading] = useState(false)
    const addClientRemovalReport = async(e) => {
        e.preventDefault();
        setClientRemovalLoading(true)
        try{
        const response=await axios.post(`/api/v1/reports/client_removal_report/`,clientRemovalReport)
        if(response.status===201){
            setClientRemovalLoading(false)
            setClientRemovalReport({
                court:"",
                report_type:"",
                from_date:"",
                to_date:"",
                territory:""
            })
            setAddClientRemovalModal(false)
            showToast("Client Removal Report Added Successfully", "Success")
            setResult({...result,loading:true})
            try {
                const { data: { results, count }} = await axios.get(`/api/v1/reports/client_removal_report/?limit=${postsPerPage}&offset=${postsPerPage * currentPage - postsPerPage}`)
                setResult({ data: results, count ,loading:false});
            }catch (e) {
              setResult({...result,loading:false})
            }
        }
        }
        catch (e){
            setClientRemovalLoading(false)
            showToast("Client Removal Report Posting Fail", "error")
        }   
    }
    const disableToDates=()=>{
        const today = new Date(clientRemovalReport.from_date);
        const dd = String(today.getDate() + 1).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    }
    const disableFromDates=()=>{

        const today = new Date(clientRemovalReport.to_date);
        const dd = String(today.getDate()-1).padStart(2, "0");
        const mm = String(today.getMonth()+1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;

    }
    return (<Dialog
        scroll="body"
        open={addClientRemovalModal}
        onClose={() =>{ setAddClientRemovalModal(false)
            setClientRemovalReport({
                court:"",
                report_type:"",
                from_date:"",
                to_date:"",
                territory:""
            })
        }}
        PaperProps={{
            style: {
              overflow: "unset",
              margin:40
            }
          }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
    >
        
        {clientRemovalLoading?<CircularSpinner onModal/>:(<div className={classes.topDiv}>
            <div className={classes.LogoWrapper}>
                <img src={Logo} className={classes.Logo} />
            </div>
            <form onSubmit={addClientRemovalReport} className={classes.upperDiv}>
                <div
                    style={{ cursor: "pointer" }}
                    onClick={() =>{ setAddClientRemovalModal(false)
                    setClientRemovalReport({
                        court:"",
                        from_date:"",
                        report_type:"",
                        to_date:"",
                        territory:""
                    })}}
                >
                    <i
                        className="fal fa-times fa-lg"
                        style={{ position: "absolute", top: "3%", right: "4%" }}
                    />
                </div>

                <div className={classes.firstDiv}>
                    <p className={classes.tagStyle}>REPORT TYPE</p>
                    <input
                        type="text"
                        placeholder="Client Removal Report"
                        className={classes.inputCont}
                        disabled
                        style={{paddingLeft:'15px'}}
                    />
                </div>

                <div className={classes.firstDivCourt}>
                    <p className={classes.tagStyle}>Report Type</p>
                    <SearchableDropDown
                        data={reportType}
                        handleClick={val =>{
                            setClientRemovalReport({ ...clientRemovalReport, report_type: val })}
                        }
                        selectedValue={clientRemovalReport.report_type}

                    />
                </div>
                <div className={classes.firstDivCourt}>
                    <p className={classes.tagStyle}>COURT NAME (Optional)</p>
                    <CourtDropDownData
                        handleSelect={val=>{
                            setClientRemovalReport({...clientRemovalReport,court:val})
                        }}  
                        selectedValue={clientRemovalReport.court} 
                    />
                </div>
                <div className={classes.firstDivCourt}>
                    <p className={classes.tagStyle}>TERRITORY (Optional)</p>
                    <SearchableDropDown
                        data={teritories}
                        handleClick={val =>{
                            setClientRemovalReport({ ...clientRemovalReport, territory: val })}
                        }
                        selectedValue={clientRemovalReport.territory}

                    />
                </div>
                <div className={classes.bottomCont} >
                    <div className={classes.bottomContLeft}>
                        <p className={classes.tagStyle}>FROM DATE</p>
                        <input
                            type="date"
                            className={classes.inputContDate}
                            required
                            style={{ paddingLeft: '15px' }}
                            value={clientRemovalReport.from_date}
                            onChange={(e) => { setClientRemovalReport({ ...clientRemovalReport, from_date: e.target.value }) }}
                        />
                    </div>
                    <div className={classes.bottomContRight}>
                        <p className={classes.tagStyle}>TO DATE</p>
                        <input
                            type="date"
                            className={classes.inputContDate}
                            required
                            style={{ paddingLeft: '15px' }}
                            value={clientRemovalReport.to_date}
                            onChange={(e) => { setClientRemovalReport({ ...clientRemovalReport, to_date: e.target.value }) }}
                        />
                    </div>

                </div>

                

                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    disabled={clientRemovalReport.report_type ===""||clientRemovalReport.to_date==="" ||clientRemovalReport.from_date===""}
                    type="submit"
                >
                    {"CREATE REPORT"}
                </Button>
            </form>
        </div>)}
    </Dialog>)
}

export default ClientRemovalReport