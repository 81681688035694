import { makeStyles } from "@material-ui/core/styles";


export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent:"center",

  },
  MainContainer: {
    width: "100%",
    height: 97,
    background: "#FFFFFF",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
    display:'flex',
    flexDirection:'column',
    justifyContent:'center'
  },
  
  selection: {
    width: '250px',
    // height:'30px',
    borderRadius: '5px',
    paddingLeft: '7px',
    color: 'rgb(70,70,250)',
    // border: '1px solid lightgray',
    marginLeft:'10px' ,
    // marginBottom:"14px",
    fontFamily:"Open Sans",
    fontWeight:600,
    fontSize:"16px",
    ".css-1qauysc-control":
      {
        border: '1px solid lightgray',
      }
  
  },
HeaderInner: {
  padding: "2px 10px",
    display: "flex",
      alignItems: "center",
        justifyContent: "space-between"
},

IconWrapper: {
  borderRadius: 10,
    display: "flex",
      justifyContent: "center",
        alignItems: "center",
          background: "#81A4FF",
            margin: "0px 20px",
              width: 47,
                height: 44,
  },
icon: {
  width: 24,
    height: 24
},
MainHeading: {
  color: "#363E51",
    fontFamily: "Open Sans",
      fontSize: theme.fonts.sizes.fontXL,
        fontWeight: 600,
          textTransform: "uppercase"
},
Wrapper: {
  display: "flex",
    alignItems: "center",
  },

  
button: {
  background: "#B5B5B5",
    margin: "0px 20px",
      "&.MuiButton-containedSecondary": {
    width: 150,
      height: 30,
        display: "flex",
          alignItems: "center",
            borderRadius: "5px !important",
              "&:hover": {
      background: "#B5B5B5",
      },
    "& .MuiButton-label": {
      fontFamily: "Open Sans",
      fontSize: 16,
      fontWeight: 700,
            textTransform: "capitalize",
              // marginTop: 3,
      },
    "& .MuiSvgIcon-root": {
      marginTop: -2
    },
    "&.MuiButton-root":{
      lineHeight:"0 !important",
      letterSpacing: 0
    }
  }
},
newbutton: {
  backgroundColor: "#3971FF !important",
    margin: "0px 20px",
      "&.MuiButton-containedSecondary": {
    width: 150,
      height: 30,
        display: "flex",
          alignItems: "center",
            borderRadius: "5px !important",
              "&:hover": {
      background: "#B5B5B5",
      },
    "& .MuiButton-label": {
      fontFamily: "Open Sans",
      fontSize: 16,
      fontWeight: 700,
            textTransform: "capitalize",
              // marginTop: 3,
      },
    "& .MuiSvgIcon-root": {
      // marginTop: -2
    },
    "&.MuiButton-root":{
      lineHeight:"0 !important",
      letterSpacing: 0
    }
  }
}
}));

export const newStyles = makeStyles((theme) => ({
  InputWithPrefix:{
    display: "flex",
    alignItems: "center",
    // maxWidth: "60%",
    background: "#f9f9f9",
    border: "1px solid lightgray",
    borderRadius: "4px",
    paddingLeft: "0.5rem",
    maxHeight: "44px",
    overflow: "hidden",
    fontFamily: "Open Sans"
  },
  Prefix:{
    fontWeight: 300,
    fontSize: "20px",
    // color: "#999"
  },

  topDiv: {
    height: '400px',
    width: '412px',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'center',
    background: 'none',
    alignItems: 'center',
    boxShadow: 'none'

  }
  ,
  styleTag:{
    fontFamily: 'Open Sans',
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 14,
    textTransform: "uppercase",
    color: "#071A32",
    marginBottom: 0
  },
  upperDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '350px',
    width: '100%',
    alignItems: 'center',
    background: 'white'

  },

  firstDiv: {
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'space-around',
    width: '80%',
    marginTop: "10px"
  },
  firstDivService: {
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'space-around',
    width: '80%',
    // height:"44px",
    // backgroundColor:"#F9F9F9",
    fontFamily: "Open Sans",
    fontSize:16,


    "& .css-1qauysc-control":{
      height:"44px",
      backgroundColor:"#F9F9F9",
      fontFamily: "Open Sans",
      fontSize:16,

    },
    "& :focus":{
      height:"30px",
      backgroundColor:"#F9F9F9"
    }
  },

  selectCont: {
    width: '100%',
    height: '44px',
    background: "#F9F9F9",
    color: '#333333',
    borderRadius: '5px',
    // paddingLeft: '5px',
    border: '1px solid lightgray',
    fontFamily: "Open Sans",
    fontSize: '16px',
    paddingLeft: '10px'
  },

  bottomCont: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '80%'
  },

  bottomContLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '45%'
  },

  bottomContRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '45%'
  },

  inputCont: {
    width: '100%',
    height: '44px',
    background: '#F9F9F9',
    color: '#333333',
    borderRadius: '5px',
    border: '1px solid lightgray',
    fontFamily: "Open Sans",
    fontSize: '16px',
    paddingLeft: '15px',
    outline: "none"
  },
  inputContPrice: {
    width: '100%',
    height: '44px',
    background: '#F9F9F9',
    color: '#333333',
    borderRadius: '5px',
    border: 'none',
    fontFamily: "Open Sans",
    fontSize: '16px',
    paddingLeft: '5px',
    marginBottom: "2px",
    outline: "none"
  },

  button: {
    background: "#3971FF",
    margin: "0px 20px",
    marginTop: "30px",
    "&.MuiButton-containedSecondary": {
      width: '80%',
      height: 44,
      display: "flex",
      alignItems: "center",
      borderRadius: "5px !important",
      "&:hover": {
        background: "#3971FF",
      },
      "& .MuiButton-label": {
        fontFamily: "Open Sans",
        fontSize: 16,
        fontWeight: 700,
        textTransform: "capitalize",
        marginTop: 3,
      },
      "& .MuiSvgIcon-root": {
        marginTop: -2
      }
    }
  },

  relativeText: {
    position: 'absolute',
    top: '72.7%',
    left: '20%',
    fontSize: '18px'
  },
  relativeText2: {
    position: 'absolute',
    top: '72.7%',
    right: '43%',
    fontSize: '18px'
  },

  LogoWrapper: {
    backgroundColor: "white",
    borderRadius: 50,
    padding: 18,
    border: "1px solid #FFFFFF",
   //  boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.203851)",
    position: "absolute",
    top: -35,
  },
  Logo: {
    width: 34,
    height: 32
  },
}))

