import React,{ useState } from "react";
import { Dialog,Button,makeStyles } from "@material-ui/core";
import { useStylesPayCall } from "./ReportsHeaderStyle";
import Logo from '../../assets/AMSLogo.png';
import useToast from "../Toast/useToast";
import axios from "./../../Store/request";
import CircularSpinner from "../CircularProgressUserReport/CircularSpinner";
import SearchableDropDown from "../SearchableDropdown/SearchableDropDown";
import { useSelector, useDispatch } from "react-redux";

const ClientPayCallReport = ({addClientPayCallModal,setAddClientPayCallModal,currentPage,result,setResult}) => {
    const classes = useStylesPayCall()
    const useNewStyles = makeStyles(() => ({
        // paper: { width: '25%' },
    }));
    const defClasses = useNewStyles()
    const { showToast } = useToast()
    const [clientPayCallReport,setClientPayCallReport] = useState({
        user_type: "",
        from_date: "",
        to_date: "",
    })
    const [userType] = useState([
        {value:true,label:"Active"},
        {value:false,label:"In Active"}
    ])
    const [postsPerPage] = useState(10)

    const [clientPayCallLoading,setClientPayCallLoading] = useState(false)
    const addClientPayCallReport = async (e) => {
        e.preventDefault();
        setClientPayCallLoading(true)
        try{
        const response=await axios.post(`/api/v1/reports/client_pay_call_report/`,clientPayCallReport)
        if(response.status===201){
            setClientPayCallLoading(false)
            setClientPayCallReport({
                user_type:"",
                from_date: "",
                to_date: "",
            })
            setAddClientPayCallModal(false)
            showToast("Client Pay Call Report Added Successfully", "Success")
            setResult({...result,loading:true})
            try {
                const { data: { results, count }} = await axios.get(`/api/v1/reports/client_pay_call_report/?limit=${postsPerPage}&offset=${postsPerPage * currentPage - postsPerPage}`)
                setResult({ data: results, count ,loading:false});
            }catch (e) {
                showToast("Unable to fetch new data", "error")
              setResult({...result,loading:false})
            }
        }
        }
        catch (e){
            setClientPayCallLoading(false)
            showToast("Client Pay Call Report Posting Fail", "error")
        }   
    }

    return (<Dialog
                open={addClientPayCallModal}
                scroll="body"
                onClose={() => {setAddClientPayCallModal(false)
                    setClientPayCallReport({
                        report_schedule:"",
                    })}}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                PaperProps={{
                    style: {
                      overflow: "unset",
                      margin:40
                    }
                  }}
                classes={{ paper: defClasses.paper }}
            >
                {clientPayCallLoading?<CircularSpinner onModal/>:(<div className={classes.topDiv}>
                    <div className={classes.LogoWrapper}>
                        <img src={Logo} className={classes.Logo} />
                    </div>
                    <form onSubmit={addClientPayCallReport} className={classes.upperDiv}>
                        <div style={{ cursor: 'pointer' }} onClick={() => {setAddClientPayCallModal(false)
                            setClientPayCallReport({
                                report_schedule:"",
                            })}}>
                            <i className="fal fa-times fa-lg" style={{ position: 'absolute', top: '3%', right: '4%' }}></i>
                        </div>

                        <div className={classes.firstDiv}>
                            <p className={classes.tagStyle}>REPORT TYPE</p>
                            <input
                                type="text"
                                placeholder="Client Pay Call Report"
                                className={classes.inputCont}
                                required
                                disabled
                                style={{paddingLeft:'15px'}}
                            />
                        </div>
                        <div className={classes.firstDiv}>
                            <p className={classes.tagStyle}>User TYPE</p>
                            <SearchableDropDown
                                data={userType}
                                handleClick={val =>{
                                    setClientPayCallReport({ ...clientPayCallReport, user_type: val })}
                                }
                                selectedValue={clientPayCallReport.user_type}

                            />
                        </div>
                        <div className={classes.bottomCont} >
                        <div className={classes.bottomContLeft}>
                            <p className={classes.tagStyle}>From Date</p>
                            <input
                            type="date"
                            name="from_date"
                            className={classes.inputContDate}
                            required
                            // style={{ paddingLeft: '15px' }}
                            value={clientPayCallReport.from_date}
                            onChange={(e)=>{
                                setClientPayCallReport({ ...clientPayCallReport, from_date: e.target.value })}
                            }
                        />
                        </div>
                        
                        <div className={classes.bottomContRight}>
                            <p className={classes.tagStyle}>TO Date</p>
                            <input
                            type="date"
                            className={classes.inputContDate}
                            name="to_date"
                            required
                            // style={{ paddingLeft: '15px' }}
                            value={clientPayCallReport.to_date}
                            onChange={(e)=>{
                                setClientPayCallReport({ ...clientPayCallReport, to_date: e.target.value })}
                            }
                        />
                        </div>
                        </div>

                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            type="submit"
                            disabled={clientPayCallReport.user_type===""}
                        >
                            {'CREATE REPORT'}
                        </Button>
                    </form>
                </div>)}
            </Dialog>)
}

export default ClientPayCallReport