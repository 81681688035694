import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  topClass: {
    height: '330px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems:'center',
    width: '370px'
  },
  innerClass: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '90%'
  },
}))