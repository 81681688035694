import { makeStyles } from "@material-ui/core/styles";


export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent:"center",
    height:50,
    width:60,

  },
  error:{
    fontFamily:"Open Sans",
    fontWeight:"800px",
    color:"#f50057",
    fontSize:"20px"
  },
  styleTag:{
    marginBottom:0,
    fontFamily: 'Open Sans',
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 14,
    textTransform: "uppercase",
    color: "#071A32",
  },
  MainContainer: {
    width: "100%",
    height: 44,
    background: "#FFFFFF",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.151907)",
    borderRadius: 2,
    margin: "-20px 0px 35px 0px"
  },
  ContainerInner: {
    display: "grid",
    gridTemplateColumns: "1.5fr 1fr 1.7fr 1fr 1fr",
    padding: "0 15px",
    position: "relative"

  },
  HeadingInner: {
    display: "grid",
    gridTemplateColumns: "1.5fr 1fr 1.7fr 1fr 1fr",
    padding: "0 15px",
    position: "relative"
  },
  ListItmes: {
    color: "#31394D",
    fontFamily: "Open Sans",
    fontSize: theme.fonts.sizes.fontM,
    fontWeight: 600,
  },
  Heading: {
    color: "#31394D",
    fontFamily: "Open Sans",
    fontSize: theme.fonts.sizes.fontM,
    fontWeight: 400,
  },
  Heading1: {
    color: "#31394D",
    fontFamily: "Open Sans",
    fontSize: theme.fonts.sizes.fontM,
    fontWeight: 400,
    position: "absolute",
    left: 0,
  },
  topDiv:{
    height: '600px',
    width: '412px',
    flexDirection:'row',
    display:'flex',
    justifyContent:'center',
    background:'none',
    alignItems:'center',
    boxShadow:'none'
}
,
upperDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '550px',
    width: '412px',
    alignItems: 'center',
    background:'white',
    marginTop:30
},

firstDiv: {
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'space-around',
    width: '80%',
    marginTop: "10px"
},
firstDivCourt:{
  display: 'flex',
  flexDirection: "column",
  justifyContent: 'space-around',
  width: '80%',
  color: '#333333',
  marginTop:"10px",
  fontFamily: "Open Sans",
  fontSize:16,
  fontWeight:600,
  "& .css-1qauysc-control":{
    height:"44px",
    backgroundColor:"#F9F9F9",
    color: '#333333',
    fontFamily: "Open Sans",
    fontSize:16,
    fontWeight:600
  },
  "& :focus":{
    height:"30px",
    backgroundColor:"#F9F9F9"
  },
  "& .css-1okebmr-indicatorSeparator": {
  background:"none"
  },
  "& .css-tlfecz-indicatorContainer": {
  color: "black"
  },
  "& .makeStyles-selectDropDown-269": {
  marginTop: 0
  }
},

selectCont: {
    width: '100%',
    height: '44px',
    background: '#F9F9F9',
    color: '#333333',
    borderRadius: '5px',
    paddingLeft: '5px',
    border: '1px solid lightgray',
    fontFamily: "Open Sans",
    fontSize: '16px',
    // paddingLeft: '10px',
    outline: "none"
},

bottomCont: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '80%'
},

bottomContLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '45%'
},

bottomContRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '45%'
},

inputCont: {
    width: '100%',
    height: '38px',
    background: 'rgb(244, 244, 244)',
    color: 'gray',
    borderRadius: '5px',
    border: '1px solid lightgray',
    fontFamily: theme.fonts.family.Light,
    fontSize: '16px',
    paddingLeft: '10px'
},

button: {
    background: "#3971FF",
    margin: "0px 20px",
    marginTop: "20px",
    "&.MuiButton-containedSecondary": {
        width: '80%',
        height: 44,
        display: "flex",
        alingItems: "center",
        borderRadius: "5px !important",
        "&:hover": {
            background: "#3971FF",
        },
        "& .MuiButton-label": {
            fontFamily: "Open Sans",
            fontSize: 16,
            fontWeight: 700,
            textTransform: "capitalize",
            marginTop: 3,
        },
        "& .MuiSvgIcon-root": {
            marginTop: -2
        }
    }
},
editTitle:{
  position: "absolute",
  top: "6%",
  left: "37%",
  fontFamily: "Open Sans",
  fontSize: 20,
  fontWeight: 700,

  // marginBottom:50
},
relativeText: {
    position: 'absolute',
    top: '72.7%',
    left: '20%',
    fontSize: '18px'
},
relativeText2: {
    position: 'absolute',
    top: '72.7%',
    right: '43%',
    fontSize: '18px'
},

LogoWrapper: {
    backgroundColor: "white",
    borderRadius: 50,
    padding: 18,
    border: "1px solid #FFFFFF",
    // boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.203851)",
    position: "absolute",
    top: -35,
},
Logo: {
    width: 34,
    height: 32,
},
}));

