import { makeStyles } from "@material-ui/core/styles";


export const useStyles = makeStyles((theme) => ({
  button: {
    background: "white",
    "&.MuiButton-containedSecondary": {
      width: 150,
      height: 28,
      display: "flex",
      alingItems: "center",
      boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25) !important",
      "&:hover": {
        background: "white",
      },
      "& .MuiButton-label": {
        fontFamily: "Open Sans",
        fontSize: 16,
        fontWeight: 600,
        color: "#3971FF",
        textTransform: "capitalize",
        marginTop: 3,
      },
      "& .MuiSvgIcon-root": {
        marginTop: -3
      }
    }
  },
  UploadIcon: {
    width: 16,
    height: 16
  }
}));

