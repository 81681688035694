import React, {useState,useRef,useEffect} from "react"
import { CircularProgress } from "@material-ui/core"
import {
  useStyles,
  dialogStyles,
} from "./UserDataTableStyle"
import Dialog from "@material-ui/core/Dialog"
import Logo from "../../assets/AMSLogo.png"
import Button from "@material-ui/core/Button"
import useToast from "../../Components/Toast/useToast"
import axios from "../../Store/request"



const CommonNotesModal = ({openNoteModal,currentData,noteDialogCloser,setOpenNoteModel}) => {
    const classes = useStyles()
    const newClasses = dialogStyles()
    const [noteLoading,setNoteLoading] = useState(false)
    const [buttonDisabled,setButtonDisabled] = useState(true)
    const [note,setNote] = useState("")
    const { showToast } = useToast()


    var PreviousNotesDiv = {border:"1px solid grey",marginTop:"5px",marginBottom:"5px",padding:"5px",height:"50px",overflowX:"scroll",overflowY:"scroll",boxShadow:"0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);"}


    let submitNote = async () => {
        setNoteLoading(true);
        var formData = {"user":currentData.id,"note":note}
        try{
        const response = await axios.post(
            "/manage-users/user_notes/",
            formData
          )
        setNoteLoading(false)
        if (response.statusText === "OK") {
            setNote("")
            setButtonDisabled(true)
            setOpenNoteModel(false)
            showToast("Note Added Successfully", "success")
        }
        }
        catch (error) {
            showToast("Something Went Wrong", "error")
        }   
    }

    return (
        <Dialog
            open={openNoteModal}
            scroll="body"
            onClose={noteDialogCloser}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            classes={{ paper: newClasses.paper1}}
            style={{alignItems:"normal"}}
            >
            <div className={classes.CreditTopDiv}>
                {noteLoading?<CircularProgress style={{color:"blue",position: "absolute", top: "10%", right: "48%"}}/>:<div/>}
                <div className={classes.CreditLogoWrapper}>
                <img src={Logo} className={classes.CreditLogo} />
                </div>
                <form className={classes.CreditupperDiv} style={{justifyContent:"normal",marginTop:"40px"}}>
                <div
                    style={{ cursor: "pointer" }}
                    onClick={noteDialogCloser}
                >
                    <i
                    className="fal fa-times fa-lg"
                    style={{ position: "absolute", top: "3%", right: "4%" }}
                    />
                </div>
                    <div className={classes.CreditfirstDiv}>
                    <span style={{fontWeight: 600, marginTop: 10}}>Client Note</span>
                    <textarea
                        type="text"
                        placeholder="Note..."
                        className={classes.CreditinputCont}
                        value={note}
                        rows={5}
                        cols={5}
                        style={{resize:"none",height:"300px",width:"100%",padding:"7px"}}
                        onChange = {(event)=>{
                            var val = event.target.value;
                            if (val.length > 0 ){
                                setButtonDisabled(false);
                            }
                            else{
                                setButtonDisabled(true);
                            }
                            setNote(event.target.value)
                        }}
                    />
                    <Button
                        variant="contained"
                        color="secondary"
                        disabled={buttonDisabled}
                        className={classes.button}
                        onClick={(e)=>{
                            e.preventDefault();
                            submitNote();
                        }}
                    >
                        {"Add Note"}
                    </Button>
                </div>
                {/* <div style={{width:"400px",overflowX:"scroll",overflowY:"scroll",height:"auto",height:"300px",paddingLeft:"40px",paddingRight:"40px",marginBottom:"20px"}}>
                <div style={PreviousNotesDiv}>Unde dolorum perspiciatis consequatur velit ducimus voluptates optio. Sunt quasi nesciunt. Dolore repudiandae suscipit. Eos in animi quod non. Cum sint suscipit tempore dolorem molestias.</div>
                <div style={PreviousNotesDiv}>Unde dolorum perspiciatis consequatur velit ducimus voluptates optio. Sunt quasi nesciunt. Dolore repudiandae suscipit. Eos in animi quod non. Cum sint suscipit tempore dolorem molestias.</div>
                <div style={PreviousNotesDiv}>Unde dolorum perspiciatis consequatur velit ducimus voluptates optio. Sunt quasi nesciunt. Dolore repudiandae suscipit. Eos in animi quod non. Cum sint suscipit tempore dolorem molestias.</div>
                <div style={PreviousNotesDiv}>Unde dolorum perspiciatis consequatur velit ducimus voluptates optio. Sunt quasi nesciunt. Dolore repudiandae suscipit. Eos in animi quod non. Cum sint suscipit tempore dolorem molestias.</div>
                <div style={PreviousNotesDiv}>Unde dolorum perspiciatis consequatur velit ducimus voluptates optio. Sunt quasi nesciunt. Dolore repudiandae suscipit. Eos in animi quod non. Cum sint suscipit tempore dolorem molestias.</div>
                <div style={PreviousNotesDiv}>Unde dolorum perspiciatis consequatur velit ducimus voluptates optio. Sunt quasi nesciunt. Dolore repudiandae suscipit. Eos in animi quod non. Cum sint suscipit tempore dolorem molestias.</div>
                <div style={PreviousNotesDiv}>Unde dolorum perspiciatis consequatur velit ducimus voluptates optio. Sunt quasi nesciunt. Dolore repudiandae suscipit. Eos in animi quod non. Cum sint suscipit tempore dolorem molestias.</div>
                <div style={PreviousNotesDiv}>Unde dolorum perspiciatis consequatur velit ducimus voluptates optio. Sunt quasi nesciunt. Dolore repudiandae suscipit. Eos in animi quod non. Cum sint suscipit tempore dolorem molestias.</div>
                </div> */}
                </form>
            </div>
        </Dialog>
    )
}

export default CommonNotesModal