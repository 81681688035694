import React, { useEffect, useState } from "react"
import { useStyles } from "./InputPrefixStyle"

const InputPrefix = ({children,hasWidth}) => {
    const classes = useStyles()
    return ( <div className={classes.InputWithPrefix} style={{width:hasWidth && "60%"}}>
            <span className={classes.Prefix}>$</span>
            {children}        
            </div>)
}

export default InputPrefix