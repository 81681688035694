import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography'
import {
    dialogStyles,
  } from "./../UserDataTable/UserDataTableStyle"
import CircularSpinner from "../CircularProgressUserReport/CircularSpinner";

const ConfirmModal = ({ signal, msg, handleno, handleyes ,loading=false}) => {

    const newClasses = dialogStyles()


    return (
        <div>
            <Dialog open={signal} onClose={handleno} aria-labelledby="form-dialog-title"
            scroll="body"
            classes={{root:newClasses.dialogRoot}}>
                {
                    loading?
                      <CircularSpinner onModal/>
                      :
                    <div className="" style={{width: '300px', height: 'auto'}}>
                    <DialogTitle id="form-dialog-title" style={{textAlign: 'center'}}>Confirm</DialogTitle>
                    <DialogContent>
                        <Typography variant="inherit">
                            {msg}
                        </Typography>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={handleno} color="primary">
                            No
                        </Button>
                        <Button onClick={handleyes} color="primary">
                            Yes
                        </Button>
                    </DialogActions>
                </div>}
            </Dialog>
        </div>
    )
}

export default ConfirmModal;