import React,{useEffect,useState,useContext} from "react";
import Container from '@material-ui/core/Container';
import CourtPricingHeader from "../../Components/CourtPricingHeader/CourtPricingHeader";
import CourtPricingDataTable from "../../Components/CourtPricingDataTable/CourtPricingDataTable";
import { useStyles } from "./CourtPricingStyle"
import { useHistory } from "react-router";
import {connect, useDispatch, useSelector} from "react-redux";
import { auth } from "../../Store/Actions/Auth";
import {ScreenContext} from './../../context/context';
import NewPagination from "../../Components/NewPagination/NewPagination";
import {GetCourtPricing} from "../../Store/Actions/CourtPricing";
import CircularSpinner from "../../Components/CircularProgress/CircularSpinner";
import Pagination_New from "../../Components/NewPagination/Pagination_New";

const CourtPricing = (props) => {
  const classes = useStyles()

  const history = useHistory()
  const [isDisabled,setIsDisabled]=useState(false);
  const [pricingArray, setPricingArray] = useState([])
  const [addedCourtPricing,setAddedCourtPricing] = useState(true)
  const [deletedCourtPricing,setDeletedCourtPricing] = useState(true)
  const [serviceList, setServiceList] = useState([])
  const {screenSize, setScreenSize} = useContext(ScreenContext)
  const dispatch = useDispatch()
  const [courtPricingData, setCourtPricingData] = useState({
    price: "",
    court: "",
    service: "",
  })
  const {data,count,loading} = useSelector(({ courtPricing }) => courtPricing)
  useEffect(()=>{
    if (props.accessToken === null || props.accessToken === "" || props.accessToken === undefined){
      history.push("/")
    }
  },[])

  const [filterTxt, setFilterTxt] = useState('');
  var filteredData = []

  const filterData = (e) => {
    let val = e.target.value
    setFilterTxt(val)
  }

  filteredData = data?.filter(element => {
    return (element.price.toString().toLowerCase().includes(filterTxt?.toLowerCase()))
  })


  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);



  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <div className={classes.MainContainer}>
      <CourtPricingHeader searchText={filterData} isDisabled={isDisabled} setIsDisabled={setIsDisabled} courtPricingData={courtPricingData} setCourtPricingData={setCourtPricingData}
                          addedCourtPricing={addedCourtPricing}
                          setAddedCourtPricing={setAddedCourtPricing}
                          serviceList={serviceList}
                          setServiceList={setServiceList}
      />
      {loading?
       <CircularSpinner/>
        :
        <Container maxWidth="lg" className={classes.containerMob}>
          {filterTxt!==""? <CourtPricingDataTable DataArray={filteredData} isDisabled={isDisabled} setIsDisabled={setIsDisabled}
          deletedCourtPricing={deletedCourtPricing}
          setMainCurrentPage={setCurrentPage}
          setDeletedCourtPricing={setDeletedCourtPricing}
          serviceList={serviceList}
          setServiceList={setServiceList}
          />
          : <CourtPricingDataTable DataArray={currentPosts} currentDataLength={currentPosts.length} isDisabled={isDisabled} setIsDisabled={setIsDisabled} courtPricingData={courtPricingData}
          setMainCurrentPage={setCurrentPage}
          deletedCourtPricing={deletedCourtPricing}
          setDeletedCourtPricing={setDeletedCourtPricing}
          serviceList={serviceList}
          setServiceList={setServiceList}/>

          }
        </Container>}

          {count > 10 && 
          <>
          <Container maxWidth="lg" style={{visibility:loading===true?"hidden":"visible"}}>
            {
              courtPricingData.court===""
              &&
              // <NewPagination
              //     postsPerPage={postsPerPage}
              //     totalPosts={count}
              //     activePage={currentPage}
              //     // paginate={paginate}
              //     paginate={data.length===count && data.length>0?(pageNumber) => {setCurrentPage(pageNumber)}:(pageNumber) => {
              //       dispatch(GetCourtPricing({limit: postsPerPage, offset: (postsPerPage * pageNumber - postsPerPage)}))
              //     }}
              //   />
              <Pagination_New  count={count}
                               activePage={currentPage}
                               postsPerPage={postsPerPage}
                               paginate={(pageNumber)=>{setCurrentPage(pageNumber)}}
              />
            }

          </Container>
          </>
          }
        


    </div>
  )
}

const mapStateToProps = (state) => {
  return{
    accessToken:state.auth.accessToken
  }
}

export default connect(mapStateToProps,{auth})(CourtPricing);