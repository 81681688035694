import { divide } from 'lodash';
import React from 'react'
import { useStyles, finalStyles } from '../StorageDataTable/StorageDataTableStyle';
import MultiplyBtn from '../../../src/assets/Multiply.png';
import dummyImg from '../../../src/assets/Rectangle.png';
import pdfImg from '../../../src/assets/PDF.png'
import Line from '../../../src/assets/Line 17.png';
import { Switch } from '@material-ui/core';
import MoreDropDown from "../MoreDropDown/MoreDropDown";
import testImg from '../../../src/assets/test.png';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const StorageDataTable = (props) => {

    const classes = useStyles()
    const newclasses = finalStyles()

    const DataArray = [
        {
            name: 'User Name',
            isImage: true,
            isDocument: true,

        },
        {
            name: 'User Name',
            isImage: false,
            isDocument: false,

        },
        {
            name: 'User Name',
            isImage: false,
            isDocument: false,

        },
        {
            name: 'User Name',
            isImage: false,
            isDocument: false,

        },
        {
            name: 'User Name',
            isImage: false,
            isDocument: false,

        },

    ]

    const pdfArray = [
        {
            name: 'Document name.PDF',
            pinnedUser: 'User Name',
            visiblity: true
        },
        {
            name: 'Document name.CSV',
            pinnedUser: '-',
            visiblity: true
        },
        {
            name: 'Document name.PDF',
            pinnedUser: 'User Name',
            visiblity: true
        },
        {
            name: 'Document name.PDF',
            pinnedUser: '-',
            visiblity: false
        },
        {
            name: 'Document name.CSV',
            pinnedUser: 'User Name',
            visiblity: true
        },
    ]

    const imgArray = [
        {
            name: 'Image name.PNG',
            pinnedUser: 'User Name',
            visiblity: true
        },
        {
            name: 'Image name.JPG',
            pinnedUser: '-',
            visiblity: false
        },
        {
            name: 'Image name.JPG',
            pinnedUser: '-',
            visiblity: true
        },
        {
            name: 'Image name.PNG',
            pinnedUser: 'User Name',
            visiblity: true
        },
        {
            name: 'Image name.PNG',
            pinnedUser: '-',
            visiblity: false
        },
    ]

    const TextClipper = (bio, length) => {

        let vb = "";

        if (bio?.length >= length) {

            for (var i = 0; i < length; i++) {
                vb += bio.charAt(i);
            }
            bio = `${vb}...`;
        }

        return bio;
    }

    const usersSection = () => {
        return (
            <div className={classes.BarContainer}>
                {DataArray.map((obj, i) => {
                    return (
                        <div className={(obj.isImage === false || obj.isDocument === false) ? classes.simpleCont : classes.Cont
                        }>
                            <p>{obj.name}</p>
                            {
                                obj.isImage && (
                                    <div className={classes.imageBtn}>

                                        <img src={MultiplyBtn} />
                                        <div className={classes.holder}>
                                            <img src={dummyImg} />
                                            <p style={{ fontSize: '11px' }}>Img name.JPG</p>
                                            <img src={Line} />
                                        </div>
                                        <div className={classes.lastHandler}>
                                            <p style={{ color: '#2F353A', fontSize: '11px', lineHeight: "15px" }}>Make visible for the user</p>
                                            <div style={{ marginTop: '8px' }}>
                                                <Switch
                                                    // checked={state.checkedB}
                                                    // onChange={handleChange}
                                                    color="primary"
                                                    name="checkedB"
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}

                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) || null
                            }

                            {
                                obj.isDocument && (
                                    <div className={classes.docBtn}>
                                        <img src={MultiplyBtn} />
                                        <div className={classes.holder}>
                                            <img src={pdfImg} />
                                            <p style={{ fontSize: '11px' }}>Doc name.JPG</p>
                                            <img src={Line} />
                                        </div>
                                        <div className={classes.lastHandler}>
                                            <p style={{ color: '#2F353A', fontSize: '11px', lineHeight: "15px" }}>Make visible for the user</p>
                                            <div style={{ marginTop: '8px' }}>
                                                <Switch
                                                    // checked={state.checkedB}
                                                    // onChange={handleChange}
                                                    color="primary"
                                                    name="checkedB"
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}

                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) || null
                            }

                            <div className={classes.addBtn} style={{ marginLeft: (obj.isImage === false || obj.isDocument === false) ? '60px' : '0px' }}>
                                + add file / image
                            </div>
                        </div >
                    );
                })}
            </div >
        )
    }

    const pdfSection = () => {
        return (
            <div className={newclasses.Container}>
                <div className={newclasses.TableHeading}>
                    <div className={newclasses.HeadingInner}>
                        <p></p>
                        <p className={newclasses.Heading1}>Document name</p>
                        <p className={newclasses.Heading}>Pinned user</p>
                        <p className={newclasses.Heading}>Visiblity</p>
                    </div>
                </div>
                {
                    props.DataArray.map((i) => {
                        return (
                            <div className={newclasses.MainContainer}>
                                <div className={newclasses.ContainerInner}>
                                    <p className={newclasses.ListItmes}>{i?.files?.name ? TextClipper(i?.files?.name, 15) : '-'}</p>
                                    <p className={newclasses.ListItmes}>{TextClipper(i.user, 15)}</p>
                                    <div className={classes.pdfcont}>
                                        <p className={newclasses.ListItmes}>{i.visiblity ? 'yes' : 'no'}</p>
                                        <Switch
                                            // checked={state.checkedB}
                                            // onChange={handleChange}
                                            color="primary"
                                            name="checkedB"
                                            checked={i.visiblity}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                            onChange={(e) => { }}

                                        />
                                    </div>
                                    {<MoreDropDown />/*pricingView={() => {
                                        setArrayOfData([
                                            {
                                                title: 'Service Name/ID',
                                                val: i.service.name
                                            },
                                            {
                                                title: 'Court Name/ID',
                                                val: i.court.name
                                            },
                                            {
                                                title: 'Price',
                                                val: `$ ${i.price}`
                                            },

                                        ]); setCurrentData(i); setOpenViewModal(true)
                                    }} pricingDelete={() => { setCurrentData(i); setOpenConfirmModal(true) }}
                                        pricingEdit={() => { setCurrentData(i); setOpenEditModal(true) }} */}

                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    const imagesSection = () => {
        return (
            <div className={newclasses.Container}>
                <div className={newclasses.TableHeading}>
                    <div className={newclasses.HeadingInner}>
                        <p></p>
                        <p className={newclasses.Heading1}>Image name</p>
                        <p className={newclasses.Heading}>Pinned user</p>
                        <p className={newclasses.Heading}>Visiblity</p>
                    </div>
                </div>
                {
                    imgArray.map((i) => {
                        return (
                            <div className={newclasses.MainContainer}>
                                <div className={newclasses.ContainerInner}>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', width: '180px', marginTop: '-5px', marginLeft: '-22px' }}>
                                        <img src={testImg} />
                                        <p className={newclasses.ListItmes}>{TextClipper(i.name, 15)}</p>
                                    </div>
                                    <p className={newclasses.ListItmes}>{TextClipper(i.pinnedUser, 15)}</p>
                                    <div className={classes.pdfcont}>
                                        <p className={newclasses.ListItmes}>{i.visiblity ? 'yes' : 'no'}</p>
                                        <Switch
                                            // checked={state.checkedB}
                                            // onChange={handleChange}
                                            color="primary"
                                            name="checkedB"
                                            checked={i.visiblity}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                            onChange={(e) => { }}

                                        />
                                    </div>
                                    {<MoreDropDown />/*pricingView={() => {
                                        setArrayOfData([
                                            {
                                                title: 'Service Name/ID',
                                                val: i.service.name
                                            },
                                            {
                                                title: 'Court Name/ID',
                                                val: i.court.name
                                            },
                                            {
                                                title: 'Price',
                                                val: `$ ${i.price}`
                                            },

                                        ]); setCurrentData(i); setOpenViewModal(true)
                                    }} pricingDelete={() => { setCurrentData(i); setOpenConfirmModal(true) }}
                                        pricingEdit={() => { setCurrentData(i); setOpenEditModal(true) }} */}

                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    return (
        <>
            {
                props.passedIndex[0].selected ? (
                    pdfSection()
                ) :
                    props.passedIndex[1].selected ? (
                        imagesSection()
                    ) : null
            }
        </>
    )
}

export default StorageDataTable;
