import React from "react"
import { CircularProgress } from "@material-ui/core"

import { useStyles } from "./CircularSpinnerStyle"

const CircularSpinner = ({onModal=false,colored}) =>
{
  const classes=useStyles()
  return(<div className={classes.root} style={{width:!onModal && "100%",height:!onModal && "60vh"}}>
    <CircularProgress className={classes.progress} style={{color:colored && "white"}}/>
  </div>)
}

export default CircularSpinner