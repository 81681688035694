import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme)=>({
    topDiv:{
        height: '750px',
        width: '450px',
        flexDirection:'row',
        display:'flex',
        justifyContent:'center',
        background:'none',
        alignItems:'center',
        boxShadow:'none'
    },
    dynamicCont : {
        padding: '15px',
        display:'flex',
        flexDirection: 'column',
        marginTop:"25px",
        justifyContent:'space-around',
    },

    dynamicInner : {
        marginLeft: '20px',
    },

    dynamicTitle: {
        fontFamily: 'Open Sans',
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 16,
        textTransform: "uppercase",
        color: "#071A32",
        // marginBottom: '1px',
        marginTop:"25px",
    },

    dynamicVal: {
        // font:'500',
        fontSize: '14px',
        marginTop: '-15px'
    },
    LogoWrapper: {
        backgroundColor: "white",
        borderRadius: 50,
        padding: 18,
        border: "1px solid #FFFFFF",
        // boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.203851)",
        position: "absolute",
        top: -37,
        left:"40%",
    },
    Logo: {
        width: 34,
        height: 32,
    },
}))