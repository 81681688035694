import { makeStyles } from "@material-ui/core/styles";


export const useStyles = makeStyles((theme) => ({
  InputWithPrefix:{
    display: "flex",
    alignItems: "center",
    // maxWidth: "60%",
    background: "#f9f9f9",
    border: "1px solid lightgray",
    borderRadius: "4px",
    paddingLeft: "0.5rem",
    maxHeight: "44px",
    overflow: "hidden",
    fontFamily: "Open Sans"
  },
  Prefix:{
    fontWeight: 300,
    fontSize: "20px",
    // color: "#999"
  },
  root: {
    display: 'flex',
    justifyContent:"center",
    height:50,
    width:60,

  },
  error:{
    fontFamily:"Open Sans",
    fontWeight:"800px",
    color:"#f50057",
    fontSize:"20px"
  },
  MainContainer: {
    width: "100%",
    height: 44,
    background: "#FFFFFF",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.151907)",
    borderRadius: 2,
    margin: "-20px 0px 35px 0px"
  },
  ContainerInner: {
    display: "grid",
    gridTemplateColumns: "2fr 3fr 1fr 1fr",
    padding: "0 15px",
    position: "relative"

  },
  HeadingInner: {
    display: "grid",
    gridTemplateColumns: "2fr 3fr 1fr 1fr",
    padding: "0 15px",
    position: "relative",
    paddingBottom: 20,
  },
  ListItmes: {
    color: "#31394D",
    fontFamily: "Open Sans",
    fontSize: theme.fonts.sizes.fontM,
    fontWeight: 600,
  },
  Heading: {
    color: "#31394D",
    fontFamily: "Open Sans",
    fontSize: theme.fonts.sizes.fontM,
    fontWeight: 400,
  },
  Heading1: {
    color: "#31394D",
    fontFamily: "Open Sans",
    fontSize: theme.fonts.sizes.fontM,
    fontWeight: 400,
    position: "absolute",
    left: 0,
  },
  topDiv:{
    height: '400px',
    width: '412px',
    flexDirection:'row',
    display:'flex',
    justifyContent:'center',
    background:'none',
    alignItems:'center',
    boxShadow:'none'
}
,
upperDiv: {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  height: '250px',
  width: '412px',
  alignItems: 'center',
  background:'white',
  marginTop:20
},

firstDiv: {
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'space-around',
    width: '80%',
},
  firstDivCourt: {
    display: "flex",
    flexDirection: "column",
    marginTop:"15px",
    marginBottom:"10px",
    justifyContent: "space-around",
    // width: "100%",
    fontFamily:"Open Sans",
    fontWeight:600,
    fontSize:16,
    "& .css-1qauysc-control":{
      height:"44px",
      backgroundColor:"#F9F9F9",
      fontFamily:"Open Sans",
      fontWeight:600,
      fontSize:16
    },
    "& :focus":{
      height:"30px",
      backgroundColor:"#F9F9F9"
    },
    "& .css-1okebmr-indicatorSeparator": {
      background:"none"
    },
    "& .css-tlfecz-indicatorContainer": {
      color: "black"
    },
    "& .makeStyles-selectDropDown-269": {
      marginTop: 0
    }
  },
  heading:{
    fontFamily: 'Open Sans',
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 14,
    textTransform: "uppercase",
    color: "#071A32",
    marginBottom: 0
  },
selectCont: {
    width: '100%',
  marginBottom:"10px",
    height: '44px',
    background: '#F9F9F9',
    color: '#333333',
    borderRadius: '5px',
    paddingLeft: '5px',
    border: '1px solid lightgray',
    fontFamily: "Open Sans",
    fontSize: '16px',
    outline: 'none'
    // paddingLeft: '10px'
},
selectContPrice: {
    width: '100%',
    height: '44px',
    background: '#F9F9F9',
    color: '#333333',
    borderRadius: '5px',
    paddingLeft: '5px',
    border: 'none',
    fontFamily: "Open Sans",
    fontSize: '16px',
    outline: 'none'
    // paddingLeft: '10px'
},

bottomCont: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '80%'
},

bottomContLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '45%'
},

bottomContRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '45%'
},

inputCont: {
    width: '100%',
    height: '38px',
    background: 'rgb(244, 244, 244)',
    color: 'gray',
    borderRadius: '5px',
    border: '1px solid lightgray',
    fontFamily: theme.fonts.family.Light,
    fontSize: '16px',
    paddingLeft: '10px'
},
styleTag:{
  fontFamily: 'Open Sans',
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: 14,
  textTransform: "uppercase",
  color: "#071A32",
  marginBottom: 0
},
button: {
    background: "#3971FF",
    margin: "0px 20px",
    marginTop:"10px", 
    "&.MuiButton-containedSecondary": {
        width: '80%',
        height: 44,
        display: "flex",
        alingItems: "center",
        borderRadius: "5px !important",
        "&:hover": {
            background: "#3971FF",
        },
        "& .MuiButton-label": {
            fontFamily: "Open Sans",
            fontSize: 16,
            fontWeight: 700,
            textTransform: "capitalize",
            marginTop: 3,
        },
        "& .MuiSvgIcon-root": {
            marginTop: -2
        }
    }
},

relativeText: {
    position: 'absolute',
    top: '72.7%',
    left: '20%',
    fontSize: '18px'
},
relativeText2: {
    position: 'absolute',
    top: '72.7%',
    right: '43%',
    fontSize: '18px'
},

LogoWrapper: {
    backgroundColor: "white",
    borderRadius: 50,
    padding: 18,
    border: "1px solid #FFFFFF",
    position: "absolute",
    top: -35,
},
Logo: {
    width: 34,
    height: 32,
},
}));

