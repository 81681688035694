import React, { useState, useEffect, useContext } from "react";
import Container from '@material-ui/core/Container';
import PaymentManagementHeader from "../../Components/PaymentManagementHeader/PaymentManagementHeader";
import PaymentDataTable from "../../Components/PaymentDataTable/PaymentDataTable";
import Pagination from "../../Components/Pagination/Pagination"
import { AddPaymentModal } from "../../Components/AddPaymentModal/AddPaymentModal"
import { useStyles } from "./PaymentManagementStyle"
import { DataArray } from "../../Components/PaymentDataTable/PaymentDataArray";
import { useHistory } from "react-router";
import { connect, useDispatch } from "react-redux";
import { auth } from "../../Store/Actions/Auth";
import { filter } from "lodash";
import {ScreenContext} from './../../context/context'


const PaymentManagement = (props) => {
  const classes = useStyles()
  const {screenSize, setScreenSize} = useContext(ScreenContext)

  
  const history = useHistory()


  const [Data, setData] = useState([])
  const [filterTxt, setFilterTxt] = useState('');
  var filteredData = []

  useEffect(() => {
    setData(DataArray)
    if (props.accessToken === null || props.accessToken === "" || props.accessToken === undefined){
      history.push("/")
    }
  }, [])


  const filterData = (e) => {
    let val = e.target.value
    setFilterTxt(val)
  }

  filteredData = DataArray.filter(data => {
    return (data.PlanName.toLowerCase().includes(filterTxt.toLowerCase()))
  })


  return (
    <div className={classes.MainContainer}>
      <PaymentManagementHeader searchText={filterData} />
      <Container maxWidth="lg" className={screenSize > 1400 ? classes.container:classes.containerMob}>
        <PaymentDataTable DataArray={filteredData} />

      </Container>
    </div>
  )
}

const mapStateToProps = (state) => {
  return{
    accessToken:state.auth.accessToken
  }
}

export default connect(mapStateToProps,{auth})(PaymentManagement);