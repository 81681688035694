import React, {useState} from 'react';
import {Button, CircularProgress, Dialog, DialogTitle} from "@material-ui/core";
import {useDispatch} from "react-redux"
import useToast from "../Toast/useToast";
import axios from "../../Store/request";
import {useStyles} from "./UploadCsvModalStyle";
import {newStyles} from "../CourtsHeader/CourtsHeaderStyle";

import CircularSpinner from "../CircularProgressUserReport/CircularSpinner.js";
const Upload_Csv_File = ({openCSV,setOpenCSV,fileName,formateType,apiUrl,actionName}) => {
	const classes = useStyles()
	const newclasses = newStyles()
	const { showToast } = useToast()
	const dispatch=useDispatch()
	const [isFile, setIsFile] = useState(false)

	const [file,setFile]=useState()
	const [loading,setLoading]=useState(false)
	const handleChange=(event)=>{
	    setFile(event.target.files[0])
			setIsFile(true)
	}
	const handleSubmit=(event)=>{
		event.preventDefault()
		const formData = new FormData();
		formData.append('file', file);
		formData.append('fileName', file.name);
		const config = {
			headers: {
				'content-type': 'multipart/form-data',
			},
		};
		setLoading(true)
		axios
			.post(apiUrl, formData, config)
			.then(function (response) {
				if (response.statusText === "Created" || response.statusText === "OK") {
					showToast(
						`${fileName} ${response.data.message}`,
						"success"
					)
					setOpenCSV(false)
					setLoading(false)
					dispatch(actionName())

				}
			})
			.catch(function (error) {
				showToast(
					`${file.name} Couldnot add ${fileName} details, please check the CSV data format, invalid attribute`,
					"error"
				)
				setLoading(false)
			})
	}
	return (
		<>
			{/*CSV moDAL court */}
			<Dialog
				open={openCSV}
				scroll="body"
				onClose={() => {
					setOpenCSV(false)
					setIsFile(false)
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
			{
				loading===true?
						<CircularSpinner onModal/>
					:
					<>
					<div style={{ cursor: "pointer" }}
					     onClick={() => {setOpenCSV(false)
					     }}
					>
						<i
							className="fal fa-times fa-lg"
							style={{ position: "absolute", top: "3%", right: "4%" }}
						/>
					</div>
				<div className={classes.topClass}>
				<DialogTitle id="alert-dialog-title">
				{`Upload CSV Of ${fileName}`}
				</DialogTitle>
				<p style={{ fontStyle: "italic", width: "90%" }}>
				<b>Required Format</b>:
				<p> {formateType}</p>

				</p>
				<div className={classes.innerClass}>
				<label> File should have .csv extension</label>
				<input type="file"
				onChange={handleChange}
				accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
				       style={{color:"red"}}
				/>
				</div>
				<Button
				variant="contained"
				color="secondary"
				className={newclasses.button}
				disabled={!isFile}
				style={{ color: "white",marginTop: "10px" }}
				onClick={handleSubmit}
				>
				Upload
				</Button>

				</div>
					</>
			}


			</Dialog>
		</>
	);
};

export default Upload_Csv_File;