import React, {useEffect, useState} from 'react'
import UserProfileDataTable from '../../Components/UserProfileDataTable/UserProfileDataTable'
import UserProfileHeader from '../../Components/UserProfileHeader/UserProfileHeader'
import EditProfileDataTable from "../../Components/UserProfileDataTable/EditProfileDataTable";
import {useStyles} from './UserProfileStyle'
import Container from "@material-ui/core/Container"
import useAuth from "../../utils/useAuth";
import axios from "../../Store/request";
import {useDispatch, useSelector} from "react-redux";
import {GetAllCourt, GetCourt} from "../../Store/Actions/Court";
import { GetAllOfficers } from '../../Store/Actions/Officers';
import { GetAllPaymentPlan, GetPaymentPlan } from '../../Store/Actions/PaymentPlan';

const UserProfile = () => {
  const {isIndividualUser } = useAuth()
  const classes=useStyles()
  const dispatch=useDispatch()
  const [editProfile,setEditProfile]=useState(false)
  let localData = JSON.parse(localStorage.getItem("user"));
  const [responseData,setResponseData]=useState();
  const { userProfile } = useSelector(({ auth }) => auth)
  const { data:courtDetail,count:courtDetailCount,dataExist:courtDataExist } = useSelector(({ court }) => court)
  const { data:officerDetail,count:officerDetailCount,dataExist:officerDataExist } = useSelector(({ officers }) => officers)
  const { data:paymentPlanDetail,count:paymentPlanCount,dataExist:paymentPlanDataExist } = useSelector(({ paymentPlan }) => paymentPlan)

  const [courtData,setCourtData]=useState({});
  const [officerData,setofficerData]=useState({});
  const [payPlanData,setpayPlanData]=useState({});

  useEffect(()=>{
    if (courtDetail.length>0 && courtDetail.length===courtDetailCount)return
    if(courtDataExist)
    dispatch(GetAllCourt())
  },[courtDetail])
  useEffect(()=>{
    if (officerDetail.length>0 && officerDetail.length===officerDetailCount)return
    if(officerDataExist)
    dispatch(GetAllOfficers())
  },[officerDetail])
  useEffect(()=>{
    if(paymentPlanDetail.length>0 && paymentPlanDetail.length===paymentPlanCount)return
    if(paymentPlanDataExist)
    dispatch(GetPaymentPlan())
  },[paymentPlanDetail])
  
  useEffect(()=>{
    const foundData = courtDetail?.find(item => item.id === userProfile?.court)
    setCourtData({...courtData,court: foundData?.id,courtName:foundData?.name})
  },[courtDetail])

  useEffect(()=>{
    const foundData = officerDetail?.find(item => item.id === userProfile?.parole_officer)
    setofficerData({...officerData,officer: foundData?.id,officerName:foundData?.first_name})
  },[officerDetail])

  useEffect(()=>{
    const foundData = paymentPlanDetail?.find(item => item.id === userProfile?.pay_plan_procedure)
    setpayPlanData({...payPlanData,payPlan: foundData?.id,payPlanName:foundData?.name})
  },[paymentPlanDetail])


  useEffect(() => {
    setResponseData(userProfile)
  }, [userProfile])


  const ShowUserType = userType => {
    switch (userType) {
      case 1:
        return "admin"
      case 2:
        return "user"
      case 3:
        return "court"
      case 4:
        return "operator"
      default:
        return ""
    }
  }
  // date conversition method (March 03,2022)
  const day=new Date(responseData?.date_of_birth).getDate();
  const month=new Date(responseData?.date_of_birth).toLocaleString('default', { month: 'long' });
  const year=new Date(responseData?.date_of_birth).getFullYear();
  const handleEditProfile=()=>{
    setEditProfile(!editProfile)
  }

  return (
    <div className={classes.MainContainer}>
      <UserProfileHeader/>
      <Container maxWidth="lg" className={classes.containerMob}>

        {editProfile===true
          ?<EditProfileDataTable
            handleEditProfile={handleEditProfile}
            isIndividualUser={isIndividualUser}
            responseData={responseData}
            setResponseData={setResponseData}
            username={responseData?.username}
            email={responseData?.email}
            date={(month+" "+day+","+year)}
            phone={responseData?.phone}
            address={responseData?.address}
            ShowUserType={ShowUserType(responseData?.user_type)}
            emergency_contact={responseData?.emergency_contact}
            home_phone={responseData?.home_phone}
            alt_phone={responseData?.alt_phone}
            courtData={courtData?.courtName}
            pay_plan_procedure={payPlanData?.payPlan}
            payPlanData={payPlanData?.payPlanName}
            parole_officer={officerData?.officer}
            officerData={officerData?.officerName}
            image={responseData?.image}
            first_name={responseData?.first_name}
            middle_name={responseData?.middle_name}
            last_name={responseData?.last_name}
          />
          :

          <UserProfileDataTable
            isIndividualUser={isIndividualUser}
            handleEditProfile={handleEditProfile}
            responseData={responseData}
            username={responseData?.username}
            email={responseData?.email}
            phone={responseData?.phone}
            address={responseData?.address}
            ShowUserType={ShowUserType(responseData?.user_type)}
            user_type={responseData?.is_active}
            first_name={responseData?.first_name}
            middle_name={responseData?.middle_name}
            last_name={responseData?.last_name}
            courtName={courtData?.courtName}
            date={(month+" "+day+","+year)}
            emergency_contact={responseData?.emergency_contact}
            home_phone={responseData?.home_phone}
            alt_phone={responseData?.alt_phone}
            payPlanName={payPlanData?.payPlanName}
            officerName={officerData?.officerName}
            payPlanData={payPlanData}
            officerData={officerData}
          />

        }

      </Container>
    </div>
  )
}

export default UserProfile