import React, {useState} from "react"
import { DataArray } from "../../Components/PaymentsDataTable/DataArray"
import { useStyles } from "../../Components/PaymentDataTable/PaymentDataTableStyle"
import MoreDropDown from "../../Components/MoreDropDown/MoreDropDown";
import ReportViews from "../../Components/ReportViews/ReportViews";

const Invoice = ({selectuser}) => {
  const classes = useStyles()
  const [openReportViewModal, setOpenReportViewModal] = useState(false)
  const [viewReportType, setViewReportType] = useState('courtUser')
  const [dataArray, setDataArray] = useState([])
  return (
    <div className={classes.Container}>
      <div className={classes.TableHeading}>
        <div className={classes.HeadingInner}>
          <p></p>
        </div>
      </div>

        {selectuser==="Select User" && DataArray?.map((i,index) => {

          return (
          //<>
            <div className={classes.MainContainer} key={index}>
              <div className={classes.ContainerInner}>
                <p className={classes.ListItmes}>{i.Name}</p>

              </div>

            </div>

          //</>
            )
        })}

      {selectuser==="Select Court" && DataArray?.map((i,index) => {

        return (

          <div className={classes.MainContainer} key={index}>
            <div className={classes.ContainerInner}>
              <p className={classes.ListItmes}>{i.InvoiceNo}</p>
              {/*<p className={classes.ListItmes}>{i.Payment}</p>*/}
              {/*<p className={classes.ListItmes}>{i.Duration}</p>*/}
              <MoreDropDown courtDescriptionView={() => { setOpenReportViewModal(true); setViewReportType('courtUser') }}/>
              <ReportViews
                open={openReportViewModal}
                onClose={() => setOpenReportViewModal(false)}
                reportType={viewReportType}
                DataArray={dataArray}
              />
            </div>
          </div>
        )
      })}




    </div>
  )
}

export default Invoice
