import { makeStyles } from "@material-ui/core/styles";
import autoMergeLevel1 from "redux-persist/es/stateReconciler/autoMergeLevel1";


export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent:"center",
    height:50,
    width:60,
  },
  error:{
    fontFamily:"Open Sans",
    fontWeight:"800px",
    color:"#f50057",
    fontSize:"20px"
  },
  MainContainer: {
    width: "100%",
    height: 44,
    background: "#FFFFFF",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.151907)",
    borderRadius: 2,
    margin: "-20px 0px 35px 0px"
  },
  ContainerInner: {
    display: "grid",
    gridTemplateColumns: "2fr 2fr 1fr 1fr",
    padding: "0 15px",
    position: "relative"

  },
  HeadingInner: {
    display: "grid",
    gridTemplateColumns: "2fr 2fr 1fr 1fr",
    padding: "0 15px",
    paddingBottom: 20,
  },
  ListItmes: {
    color: "#31394D",
    fontFamily: "Open Sans",
    fontSize: theme.fonts.sizes.fontM,
    fontWeight: 600,
  },
  Heading: {
    color: "#31394D",
    fontFamily: "Open Sans",
    fontSize: theme.fonts.sizes.fontM,
    fontWeight: 400,
  },
  Heading1: {
    color: "#31394D",
    fontFamily: "Open Sans",
    fontSize: theme.fonts.sizes.fontM,
    fontWeight: 400,
    marginLeft: -14,
  },
  
  descModal:{
    height:'auto',
    width:'350px',
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-around',
    alignItems: 'center'
  },
  innerModal:{
    width:'85%',
    marginTop:'40px',
    marginBottom:'40px'
  },
  holder:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-around',
    margin: '20px'
  },
  modalHead:{
    fontSize:'20px',
    fontWeight:700 ,
    marginTop:'15px',
    marginBottom: '15px' 
  },
  modalText:{
    fontSize:'18px',
    marginTop:'15px',
    marginBottom: '15px' 
  },
  styleTag: {
    fontFamily: 'Open Sans',
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 14,
    textTransform: "uppercase",
    color: "#071A32",
    marginBottom: 0
  },
  topDiv:{
    height: '400px',
    width: '412px',
    flexDirection:'row',
    display:'flex',
    justifyContent:'center',
    background:'none',
    alignItems:'center',
    boxShadow:'none',

  }
,
upperDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '350px',
    width: '412px',
    alignItems: 'center',
    background:'white',
    marginTop:"30px"
},

firstDiv: {
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'space-around',
    width: '80%',
    marginTop: 20,
},

selectCont: {
    width: '100%',
    height: '44px',
    background: '#F9F9F9',
    color: '#333333',
    borderRadius: '5px',
    paddingLeft: '5px',
    border: '1px solid lightgray',
    fontFamily: "Open Sans",
    fontSize: '16px',
    paddingLeft: '10px',
    outline: "none"

},

bottomCont: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '80%'
},

bottomContLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '45%'
},

bottomContRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '45%'
},

inputCont: {
    width: '100%',
    height: '38px',
    background: 'rgb(244, 244, 244)',
    color: 'gray',
    borderRadius: '5px',
    border: '1px solid lightgray',
    fontFamily: theme.fonts.family.Light,
    fontSize: '16px',
    paddingLeft: '10px'
},

button: {
    background: "#3971FF",
    margin: "0px 20px",
    marginTop: 20,
    "&.MuiButton-containedSecondary": {
        width: '80%',
        height: 38,
        display: "flex",
        alingItems: "center",
        borderRadius: "5px !important",
        "&:hover": {
            background: "#3971FF",
        },
        "& .MuiButton-label": {
            fontFamily: "Open Sans",
            fontSize: 16,
            fontWeight: 700,
            textTransform: "capitalize",
            marginTop: 3,
        },
        "& .MuiSvgIcon-root": {
            marginTop: -2
        }
    }
},

relativeText: {
    position: 'absolute',
    top: '72.7%',
    left: '20%',
    fontSize: '18px'
},
relativeText2: {
    position: 'absolute',
    top: '72.7%',
    right: '43%',
    fontSize: '18px'
},

LogoWrapper: {
    backgroundColor: "white",
    borderRadius: 50,
    padding: 18,
    border: "1px solid #FFFFFF",
    // boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.203851)",
    position: "absolute",
    top: -35,
},
Logo: {
    width: 34,
    height: 32,
},
  
}));

const newStyles = makeStyles((theme)=>({
  MainContainer: {
    position: "absolute",
    right: 0,
    top: 11,
    right: 10,
  },
  Inner: {
    padding: "0px 10px",
    "&:focus": {
      outline: "none"
    }
  },
  Wrapper: {
    display: "flex",
    justifyContent: "flex-end"
  },
  CloseIcon: {
    cursor: "pointer",
    fontSize: 20,
    '&:focus': {
      outline: "none"
    }
  },
  EditIcon: {
    marginRight: 10,
  },
  SeeIcon: {
    marginRight: 10,

  },
  DeleteIcon: {
    marginRight: 10,
    color: "#C2143E"
  },
  VerticalIcon: {
    color: "#004267"
  },
  MenuList: {
    color: "#31394D",
    fontFamily: theme.fonts.family.Medium,
    fontSize: theme.fonts.sizes.fontM,
  },
  MenuListDel: {
    color: "#C2143E",
    fontFamily: theme.fonts.family.Medium,
    fontSize: theme.fonts.sizes.fontM,
  },

}));

