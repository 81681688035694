import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  filterdiv: {
    background: "#FFFFFF",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    width: "100px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    height: "40px",
    alignItems: "center",
    cursor: "pointer",
    fontFamily: "Open Sans",
    fontWeight: 600,
    marginTop:'-75px'
  },

  innerPara:{
    margin:0,
    padding:0,
    fontWeight:400,
    fontSize:14,
    fontFamily:"Open Sans"
  },

  mainFilter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    height: "auto",
    paddingTop: "15px",
    paddingBottom: "15px",
    marginTop: "20px",
    // width: "90%",
    minWidth: "300px",
    background: "#ffffff",
    boxShadow: "0px 1px 5px rgb(0,0,0, 0.25)",
    borderRadius: "5px"
  },

  filterHead: {
    height: "70px", 
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "90%",
    marginRight:19
  },

  filterMiddle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    width: "95%",
    height: "300px",
    marginTop:-45
  },

  filterMiddleLeft: {
    width: "45%",
    minWidth: "300px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around"
  },

  filterMiddleRight: {
    width: "45%",
    minWidth: "300px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around"
  },

  filterMiddleTopLeft: {
    height: "20%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    // marginLeft: "-10px"
  },

  filterMiddleMiddleLeft: {
    height: "20%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },

  smallInputDiv: {
    // height: "65px",
    width: "31%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  RegularInputDiv: {
    // height: "65px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "48%"
  },
  RegularInputDivCourt: {
    height: "65px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "48%",
    fontFamily:"Open Sans",
    fontSize:"16px",
    "& .css-1qauysc-control":{
      height:40,
    },
    "& :focus":{
    height:"30px",
    backgroundColor:"#F9F9F9"
    },
    "& .css-1okebmr-indicatorSeparator": {
      background:"none"
    },
    "& .css-tlfecz-indicatorContainer": {
      color: "black"
    },
    "& .makeStyles-selectDropDown-242": {
      marginTop: 0
    }
  },

  smallInput: {
    width: "100%",
    height: "44px",
    background: "#F9F9F9",
    color: "black",
    borderRadius: "5px",
    border: "1px solid lightgray",
    fontFamily: "Open Sans",
    fontSize: "16px",
    paddingLeft: "10px",
    outline:"none"
  },

  filterMiddleTopRight: {
    height: "20%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },

  selectContInput: {
    width: "100%",
    height: "44px",
    background: "#F9F9F9",
    color: "black",
    borderRadius: "5px",
    border: "1px solid lightgray",
    fontFamily: "Open Sans",
    fontSize: "16px",
    paddingLeft: "10px",
    outline:"none"
  },

  EliteInputDiv: {
    width: "100%",
    // height: "65px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around"
  },

  InputElite: {
    width: "100%",
    height: "44px",
    background: "#F9F9F9",
    color: "#333333",
    borderRadius: "5px",
    border: "1px solid lightgray",
    fontFamily: "Open Sans",
    fontSize: "16px",
    paddingLeft: "10px",
    outline: "none"
  },

  filterFooter: {
    width: "90%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },

  btnHolder: {
    width: "40%",
    minWidth: "250px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center"
  },

  resetBtn: {
    background: "#FFFFFF",
    boxshadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    color: "#3971FF",
    width: "40%",
    height: "40px",
    cursor: "pointer",
    border: "1px solid #3971FF",
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: "16px"
  },

  applyBtn: {
    background: "#3971FF",
    boxshadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    color: "white",
    width: "40%",
    height: "40px",
    cursor: "pointer",
    border: "none",
    fontSize: "16px",
    fontFamily: "Open Sans",
    fontWeight: 700,
  },
  error:{
    color:"red",
    fontSize:"13px",
    fontWeight:"10px"
    // marginTop: "85px",
    // width: "124px",
    // marginLeft: "-197px"
  },
  errorSelect:{
    color:"red",
    fontSize:"13px"
  }
}))
