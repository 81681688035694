import React,{useState,useEffect,useContext} from 'react';
import { useStyles } from './DocumentationDataTableStyle';
import { DataArray } from './DataTableArray';
import MoreDropDown from "../MoreDropDown/MoreDropDown";
import clsx from 'clsx';

const DocumentationDataTable = (props) => {
  const classes = useStyles();

  const TextClipper = (bio, length) => {

    let vb = "";

    if (bio?.length >= length) {

      for (var i = 0; i < length; i++) {
        vb += bio.charAt(i);
      }
      bio = `${vb}...`;

    }

    return bio;
  }

  const InvoiceTable = () => {
    return (
      <div className={classes.Container}>
        <div className={classes.TableHeading}>
          <div className={classes.HeadingInner}>
            <p className={classes.Heading1}>Invoice Number</p>
            <p></p>
            <p className={clsx(classes.Heading, classes.ServiceHeading)}>Service</p>
            <p className={classes.Heading}>Days</p>
            <p className={classes.Heading}>Service amount</p>
            <p className={classes.Heading}>One time fee</p>
            <p className={classes.Heading}> Created date</p>
            <p className={classes.Heading}> Created by </p>
            <p className={classes.Heading}> Updated </p>
            <p className={classes.Heading}> Updated by </p>
          </div>
        </div>
        {
          DataArray.map((i) => {
            return (
              <div className={classes.MainContainer}>
                <div className={classes.ContainerInner}>
                  <p className={classes.ListItmes}>{TextClipper(i.InvoiceNumber, 15)}</p>
                  <p className={clsx(classes.ListItmes, classes.Service)}>{TextClipper(i.Service, 15)}</p>
                  <p className={classes.ListItmes}>{TextClipper(i.Days, 15)}</p>
                  <p className={classes.ListItmes}>{TextClipper(i.ServiceAmount, 15)}</p>
                  <p className={classes.ListItmes}>{TextClipper(i.Fees, 15)}</p>
                  <p className={classes.ListItmes}>{TextClipper(i.CreatedDate, 15)}</p>
                  <p className={classes.ListItmes}>{TextClipper(i.CreatedBy, 15)}</p>
                  <p className={classes.ListItmes}>{TextClipper(i.Updated, 15)}</p>
                  <p className={classes.ListItmes}>{TextClipper(i.UpdatedBy, 15)}</p>
                  <MoreDropDown />
                </div>
              </div>
            )
          })
        }
      </div>

    )
  }

  const PrepaidTable = () => {
    return (
      <div className={classes.Container}>
        <div className={classes.TableHeading}>
          <div className={classes.HeadingInner}>
            <p className={classes.Heading1}>Invoice Number</p>
            <p></p>
            <p className={clsx(classes.Heading, classes.ServiceHeading)}>Service</p>
            <p className={classes.Heading}>Days</p>
            <p className={classes.Heading}>Service amount</p>
            <p className={classes.Heading}>One time fee</p>
            <p className={classes.Heading}> Created date</p>
            <p className={classes.Heading}> Created by </p>
            <p className={classes.Heading}> Updated </p>
            <p className={classes.Heading}> Updated by </p>
          </div>
        </div>
        {
          DataArray.map((i) => {
            return (
              <div className={classes.MainContainer}>
                <div className={classes.ContainerInner}>
                  <p className={classes.ListItmes}>{TextClipper(i.InvoiceNumber, 15)}</p>
                  <p className={clsx(classes.ListItmes, classes.Service)}>{TextClipper(i.Service, 15)}</p>
                  <p className={classes.ListItmes}>{TextClipper(i.Days, 15)}</p>
                  <p className={classes.ListItmes}>{TextClipper(i.ServiceAmount, 15)}</p>
                  <p className={classes.ListItmes}>{TextClipper(i.Fees, 15)}</p>
                  <p className={classes.ListItmes}>{TextClipper(i.CreatedDate, 15)}</p>
                  <p className={classes.ListItmes}>{TextClipper(i.CreatedBy, 15)}</p>
                  <p className={classes.ListItmes}>{TextClipper(i.Updated, 15)}</p>
                  <p className={classes.ListItmes}>{TextClipper(i.UpdatedBy, 15)}</p>
                  <MoreDropDown />
                </div>
              </div>
            )
          })
        }
      </div>
    )
  }



  return (
    <>
      {
        props.passedIndex[0].selected ? (
          InvoiceTable()
        ) :
          props.passedIndex[1].selected ? (
            PrepaidTable()
          ) : null
      }
    </>

  );
}

export default DocumentationDataTable
