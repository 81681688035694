import React, { useEffect, useState } from "react"
import { useStyles } from "./ClientPricingDataTableStyle"
import MoreDropDown from "../MoreDropDown/MoreDropDown"
import NewMoreDropDown from "../NewMoreDropDown/NewMoreDropDown"
import {useDispatch, useSelector} from "react-redux"
import axios from "../../Store/request"
import ConfirmModal from "../ConfirmModal/ConfirmModal"
import ViewModal from "../ViewModal/ViewModal"
import {Dialog, Button} from "@material-ui/core"
import useToast from "../../Components/Toast/useToast"
import {GetAllClientPricing} from "../../Store/Actions/ClientPricing";
import CircularSpinner from "../CircularProgressUserReport/CircularSpinner";
import Logo from "../../assets/AMSLogo.png"
import InputPrefix from "../InputPrefix/InputPrefix"
import CourtDropDownData from "../CourtDropDownData/CourtDropDownData";
import Pagination_New from "../NewPagination/Pagination_New";
import Container from "@material-ui/core/Container";
import useAuth from "../../utils/useAuth";

const ClientPricingDataTable = ({DataArray,setIsDisabled, setServiceList,clientData}) => {
  const classes = useStyles()
  const { showToast } = useToast()
  const [currentData, setCurrentData] = useState({
    service: null,
    price:null,
    override_price:null
  })
  
  const {data:clientPricingData,count:clientPricingDataLength,dataExist:clientPricingDataExist}= useSelector(({ clientPricing }) => clientPricing)
  const dispatch = useDispatch()
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [openViewModal, setOpenViewModal] = useState(false)
  const [triedFetching,setTriedFetching] = useState(false)
  const [arrayOfData, setArrayOfData] = useState([])
  const [openEditModal, setOpenEditModal] = useState(false)
  const [creditLoading,setCreditLoading]=useState(false)
  const { isOperator } = useAuth()
  useEffect(()=>{
    setTriedFetching(true)
    if(clientPricingData.length>0 && clientPricingData.length===clientPricingDataLength)return;
    if(clientPricingDataExist){
      dispatch(GetAllClientPricing())}
  },[clientPricingData])

  const deletePricing = () => {
    setCreditLoading(true)
    axios
      .delete(`/manage-users/client_pricing/${currentData.id}/`)
      .then(async(response) => {
        setOpenConfirmModal(false)
        showToast("Client Pricing Deleted Succesfully", "success")
        dispatch(GetAllClientPricing())
        const responseServices = await axios.get("/api/v1/services/services_add_client_pricing/")
        const serviceData = responseServices.data?.map(item => {
          return ({ value: item.id, label: item.name })
        })
        setServiceList(serviceData)
        setCreditLoading(false)
      })
      .catch(function (error) {
        setOpenConfirmModal(false)
        setCreditLoading(false)
        showToast("Unable to delete, please try again later", "error")
      })
  }

  const EditPricing = e => {
    e.preventDefault()
    const lastData = {
      service: currentData.service.id,
      price: +currentData.price,
      override_price:+currentData.override_price
    }
    const data = JSON.stringify(lastData)
    setIsDisabled(true)
    setCreditLoading(true)
    axios
      .put(`/manage-users/client_pricing/${currentData.id}/`, data)
      .then(function (response) {
        setOpenEditModal(false)
        setCurrentData({service: null,
          price:null,
          override_price:null})
        setIsDisabled(false)
        dispatch(GetAllClientPricing())
        setCreditLoading(false)
        showToast("Client Pricing Edited Succesfully", "success")
      })
      .catch(function (error) {
        setIsDisabled(false)
        setCreditLoading(false)
        if (error.response.data.service?.non_field_errors) {
          showToast(
            `${error.response.data.service?.non_field_errors}`,
            "error"
          )
        }
        if(error.response.status===403){
          showToast(
            `Couldnot add client pricing details, please try later`,
            "error"
          )
        }
      })

  }

  const expressionConverter = txt => {
    let origString = txt.toString()
    let stringToAdd = "/"
    let indexPosition = 2
    origString = origString.split("")
    origString.splice(indexPosition, 0, stringToAdd)
    return ` $ ${txt}`
  }

  const TextClipper = (bio, length) => {
    let vb = ""

    if (bio?.length >= length) {
      for (var i = 0; i < length; i++) {
        vb += bio.charAt(i)
      }
      bio = `${vb}...`
    }

    return bio
  }
  const courtName=[]
  clientPricingData.forEach((element)=>{
    if(element.court?.id===clientData?.court){
      courtName.push(element)
    }
  })
  const sortedService = courtName.sort(function(a, b) {
    const nameA = a.service.name.toUpperCase(); // ignore upper and lowercase
    const nameB = b.service.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = sortedService.slice(indexOfFirstPost, indexOfLastPost);
  

  return (
    <div className={classes.Container}
         // style={{ minHeight: "650px" }}
    >
      {!!clientPricingData.length?
        <>
          <div className={classes.TableHeading}>
            <div className={classes.HeadingInner}>
              <p> </p>
              <p className={classes.Heading1}>Court name</p>
              <p className={classes.Heading}>Service name</p>
              <p className={classes.Heading}>Price</p>
            </div>
          </div>
          {clientData.court===""?
            DataArray.map((i,index) => {
              if(i!==undefined)
                return (
                  <div className={classes.MainContainer} key={i.id}>
                    <div className={classes.ContainerInner}>
                      <p className={classes.ListItmes}>
                        {i.court===null?"-": TextClipper(i.court?.name,30)}
                      </p>
                      <p className={classes.ListItmes}>
                        {TextClipper(i.service?.name,20)}
                      </p>

                      <p className={classes.ListItmes}>
                        {expressionConverter (i.price)}
                      </p>
                      { isOperator ? (
                          <NewMoreDropDown
                              MoreDropDownItem={[
                                {
                                  actionFunction: () => {
                                    setArrayOfData([
                                      {
                                        title: "Court Name/ID",
                                        val: i.court.name
                                      },
                                      {
                                        title: "Service Name/ID",
                                        val: i.service.name
                                      },
                                      {
                                        title: "Price",
                                        val: `$ ${i.price}`
                                      }
                                    ])
                                    setCurrentData(i)
                                    setOpenViewModal(true)
                                  },
                                  icon: "Visibility",
                                  name: "See details"
                                }
                              ]}
                          />
                      ) : (
                          <NewMoreDropDown
                          MoreDropDownItem={[
                            {
                              actionFunction: () => {
                                setCurrentData(i)
                                setOpenEditModal(true)
                              },
                              icon: "Edit",
                              name: "Edit"
                            },
                            {
                              actionFunction: () => {
                                setArrayOfData([
                                  {
                                    title: "Court Name/ID",
                                    val: i.court.name
                                  },
                                  {
                                    title: "Service Name/ID",
                                    val: i.service.name
                                  },
                                  {
                                    title: "Price",
                                    val: `$ ${i.price}`
                                  }
                                ])
                                setCurrentData(i)
                                setOpenViewModal(true)
                              },
                              icon: "Visibility",
                              name: "See details"
                            },
                            {
                              actionFunction: () => {
                                setCurrentData(i)
                                setOpenConfirmModal(true)
                              },
                              icon: "Delete",
                              name: "Delete"
                            },
                          ]}

                      />)
                      }
                    </div>
                  </div>
                )
            })
            :
            (courtName.length===0?
                <h1 className={classes.error}>No Data Found</h1>
                :currentPosts.map(i => {
                  if(i!==undefined) {
                    return (
                      <div className={classes.MainContainer} key={i.id}>
                        <div className={classes.ContainerInner}>
                          <p className={classes.ListItmes}>
                            {i.court?.name}
                          </p>
                          <p className={classes.ListItmes}>
                            {i.service?.name}
                          </p>

                          <p className={classes.ListItmes}>
                            {expressionConverter(i?.price)}
                          </p>
                          <NewMoreDropDown
                            MoreDropDownItem={[
                              {actionFunction:() => {
                                  setCurrentData(i)
                                  setOpenEditModal(true)
                                },
                                icon:"Edit",
                                name:"Edit"
                              },
                              {actionFunction:() => {
                                  setArrayOfData([
                                    {
                                      title: "Court Name/ID",
                                      val: i.court.name
                                    },
                                    {
                                      title: "Service Name/ID",
                                      val: i.service.name
                                    },
                                    {
                                      title: "Price",
                                      val: `$ ${i.price}`
                                    }
                                  ])
                                  setCurrentData(i)
                                  setOpenViewModal(true)
                                },
                                icon:"Visibility",
                                name:"See details"
                              },
                              {actionFunction:() => {
                                  setCurrentData(i)
                                  setOpenConfirmModal(true)
                                },
                                icon:"Delete",
                                name:"Delete"
                              },
                            ]}
                          />
                        </div>
                      </div>
                    )
                  }
                })
            )
          }
          {courtName.length > 10 &&
          <>
            <Container maxWidth="lg">
              {
                sortedService.length>10 &&
                <Pagination_New  count={sortedService.length}
                activePage={currentPage}
                postsPerPage={postsPerPage}
                paginate={(pageNumber)=>{setCurrentPage(pageNumber)}}
                />
              }

            </Container>
          </>
          }
        </>:<p className={classes.error} style={{visibility:!triedFetching?"hidden":"visible"}}>No Data Found</p>}
      <ConfirmModal
        signal={openConfirmModal}
        handleyes={deletePricing}
        handleno={() => setOpenConfirmModal(false)}
        loading={creditLoading}
        msg={`Are you sure you want to delete this client pricing?`}
      />

      <ViewModal
        signal={openViewModal}
        handleyes={() => setOpenViewModal(false)}
        arrayOfData={arrayOfData}
      />

      <Dialog
        open={openEditModal}
        scroll="body"
        onClose={() => setOpenEditModal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        // overlayStyle={{ backgroundColor: "transparent" }}
        PaperProps={{
          style: {
            overflow: "unset",
            margin:40
          }
        }}
      >

        {
          creditLoading === true ? (
             <CircularSpinner onModal/>
            )
            : (
              <div className={classes.topDiv}>
                <div className={classes.LogoWrapper}>
                  <img src={Logo} className={classes.Logo} alt="Logo"/>
                </div>
                <p
                  style={{
                    position: "absolute",
                    top: "4%",
                    left: "36 %",
                    fontSize: "20px",
                    fontWeight: "700"
                  }}
                >
                  Edit Pricing
                </p>
                <form onSubmit={EditPricing} className={classes.upperDiv}>
                  <div
                    style={{cursor: "pointer"}}
                    onClick={() => setOpenEditModal(false)}
                  >
                    <i
                      className="fal fa-times fa-lg"
                      style={{position: "absolute", top: "3%", right: "4%"}}
                    ></i>
                  </div>

                  <div className={classes.firstDiv}>
                    <span className={classes.styleTag}>COURT NAME</span>
                    <input
                      type="text"
                      className={classes.selectCont}
                      value={currentData.court?.name}
                      readOnly
                      required
                    />

                    {/*<CourtDropDownData*/}
                    {/*  handleSelect={val =>*/}

                    {/*    setCurrentData({ ...currentData , court: val })*/}
                    {/*  }*/}
                    {/*  selectedValue={currentData.court?.id}*/}
                    {/*/>*/}
                  </div>

                  <div className={classes.firstDiv}>
                    <p className={classes.styleTag}>Service Name</p>
                    <input
                      type="text"
                      className={classes.selectCont}
                      value={currentData.service?.name}
                      readOnly
                      required
                    />




                    <p className={classes.styleTag}>Pricing</p>
                    <InputPrefix>
                    <input
                      type="text"
                      placeholder="Type..."
                      className={classes.selectContPrice}
                      value={currentData.price}
                      onChange={e => {
                        setCurrentData({...currentData, price: e.target.value})
                      }}
                      required
                    />
                    </InputPrefix>
                  </div>

                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    disabled={currentData.price === ""}
                    type="submit"
                  >
                    {"SAVE PRICING"}
                  </Button>
                </form>
              </div>
            )
        }

      </Dialog>
    </div>
  )
}


export default ClientPricingDataTable
