import React, {useState,useRef,useEffect} from "react"
import {
  useStyles,
  newStyles,
  dialogStyles,
} from "./UserDataTableStyle"
import Dialog from "@material-ui/core/Dialog"
import Logo from "../../assets/AMSLogo.png"
import Button from "@material-ui/core/Button"
import InputPrefix from "../InputPrefix/InputPrefix"
import CircularSpinner from "../CircularProgressUserReport/CircularSpinner";



const CommonCreditModal = ({openCreditModal,setMedia,setAmount,creditLoading,currentData,paymentDialogHandler,media,amount,erramount,errattachment,checkDisable,applyCreditFunc,creditDialogCloser}) => {
    const classes = useStyles()
    const newClasses = dialogStyles()
    const imageUploader = useRef(null)

    const imageUploadHandler = () => {
        const found = imageUploader.current.click()
    }
    const fileUploadHandler = async event => {
        let reader = new FileReader()
        let files = event.target.files[0]

        reader.onloadend = () => {
        const updatedMedia = [ { name: files.name, url: reader.result }]
        setMedia(updatedMedia)

        }
        reader.readAsDataURL(files)

        imageUploader.current.value = ""

    }
    const imageDeleteHandler = ( ) => {
        setMedia([])
    }
    return (
        <Dialog
            open={openCreditModal}
            scroll="body"
            onClose={creditDialogCloser}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            classes={{ paper: newClasses.paper1}}
            >
            {creditLoading===true?
            (<CircularSpinner onModal/>):
            (<div className={classes.CreditTopDiv}>
                <div className={classes.CreditLogoWrapper}>
                <img src={Logo} className={classes.CreditLogo} />
                </div>
                <form onSubmit={paymentDialogHandler} className={classes.CreditupperDiv}>
                <div
                    style={{ cursor: "pointer" }}
                    onClick={creditDialogCloser}
                >
                    <i
                    className="fal fa-times fa-lg"
                    style={{ position: "absolute", top: "3%", right: "4%" }}
                    />
                </div>


                    <div className={classes.CreditfirstDiv}>
                    <span style={{fontWeight: 600, marginTop: 50}}>USER TYPE</span>
                    <input
                        type="text"
                        placeholder="Type..."
                        className={classes.CreditinputCont}
                        value={currentData.user_type===1?"Admin":currentData.user_type===2?"Client":currentData.user_type===3?"Court":"Operator"}
                        readOnly

                    />
                    </div>
                    <div className={classes.CreditfirstDiv}>
                    <span style={{fontWeight: 600}}>USER NAME/ID</span>
                    <input
                        type="text"
                        placeholder="Type..."
                        className={classes.CreditinputCont}
                        value={currentData.username}
                        readOnly
                    />
                </div>

                <div className={classes.CreditfirstDiv}>
                    <span style={{ fontWeight: 600 }}>AMOUNT</span>
                    <InputPrefix hasWidth>
                    <input
                    type="number"
                    placeholder="Type..."
                    className={classes.CreditinputContAmount}
                    value={amount}
                    onChange={(event)=>{setAmount(event.target.value)}}
                    />
                    </InputPrefix>
                    <span style={{color: "red"}}>{erramount}</span>
                </div>

                <div className={classes.CreditfirstDiv}>
                    <span style={{ fontWeight: 600 }}>Recite Attachment</span>
                    <div
                    className={classes.inputFileWrapper}
                    onClick={imageUploadHandler}
                    >
                    <input
                        type="file"
                        ref={imageUploader}
                        style={{ display: "none" }}
                        onChange={fileUploadHandler}
                    />
                    <div className={classes.imageText}>
                        <span className="uploadImageText">
                        Drop document to upload. Or
                        </span>
                        <br />
                        <span className={classes.uploadInstruction}>
                        press here to browse
                        </span>
                    </div>
                    </div>

                    <div className={classes.imageContainer}>
                    {media[0] &&
                        <span className={classes.imageWrapper}>
                        <span onClick={() => imageDeleteHandler( )}>
                        <i
                            className="fal fa-times fa-lg"
                            style={{
                            paddingRight: "10px",
                            fontSize: "16px",
                            cursor: "pointer"
                            }}
                        />
                        </span>{" "}
                            {media[0].name}
                    </span>
                    }
                    </div>
                    {/*<span style={{color: "red"}}>{errattachment}</span>*/}
                </div>

                <Button
                    variant="contained"
                    color="secondary"
                    disabled={checkDisable}
                    className={classes.button}
                    type="submit"
                    onClick={applyCreditFunc}
                >
                    {"APPLY CREDIT"}
                </Button>

                </form>
            </div>)}
        </Dialog>
    )
}

export default CommonCreditModal