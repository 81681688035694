import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  MainContainer: {
    width: "100%",
    // height: "100vh",
    backgroundColor: "#F8FBFF"
  },
  relative:{
    position:'relative'
  },
  creditBtn:{
    position:'absolute',
    right:0,
    fontFamily: "Open Sans",
    fontSize:"17px",
    borderRadius:"85px",
    backgroundColor:"#F9F9F9"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  selectTab:{
    background: '#3971FF',
    boxShadow: '0 1px 5px rgba(0, 0, 0, 0.25)',
    borderRadius: 35,
    opacity:1,
    textTransform: 'uppercase',
    color: '#FFFFFF',
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '22px',
    marginRight: 13,
    textDecoration:"none",

  },
  containerMob: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
    // width:'1400px'
  },
  paymentInformation: {
    border: "2px solid black"
  },

  credit:{

    marginLeft:"653px",


  },

  menu_btn:{
    color:"white",
    background:"navy"
  },
  modal:{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgColor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  },

  BtnContainer: {
    paddingBottom: 30,
    // backgroundColor:"#F8FBFF",
    "& .MuiButton-contained": {
      "&:hover": {
        background: "#3971FF",
      }
    },
    "& .MuiButton-containedSecondary": {
      "&:hover": {
        background: "#F9F9F9 !important",
      }
    }
  },
  InvoiceButton: {
    background: "#3971FF",
    marginRight: "20px",
    color: "white",
    width: 171,
    height: 36,
    borderRadius: 85,
    fontFamily: "Open Sans",
    fontSize:"17px",
  },
  PrepaidButton: {
    background: "#F9F9F9",
    marginRight: "20px",
    color: "#3971FF",
    width: 171,
    height: 36,
    borderRadius: 85,
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
    fontFamily: "Open Sans",
    fontSize:"17px",

  },
  option:{
    position: "absolute",
    right: "1px",
    bottom: "28px",
    width:"186px",
    height:"32px",
    background: "#FFFFFF",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
  },

  optionText:{
    color: "#3971FF",
    width:"186px",
    // border:"2px solid black",
    height:"40px",
    fontFamily: "Open Sans",
    fontSize:"17px",
    padding:"10px",
    paddingBottom:"17px",

  },
  formcontrol:{
    // border:"2px solid black",
      color:"#FFFFFF",
    "& .MuiInput-underline":{
      "&:before":{
        display: 'none'
      },
      "&:after":{
        display: 'none'
      }
    }
  }

}))
