import React,{useState} from "react"
import {
  newStyles,
} from "./UserDataTableStyle"
import { useDispatch, useSelector } from "react-redux"
import useToast from "../../Components/Toast/useToast"
import {
  GetFilterData,
  GetUser, SetCurrentPage,
} from "../../Store/Actions/ManageUserAction"
import Container from "@material-ui/core/Container";
import NewPagination from "../NewPagination/NewPagination";
import NewMoreDropDown from "../NewMoreDropDown/NewMoreDropDown"
import Pagination_New from "../NewPagination/Pagination_New";
import useAuth from "../../utils/useAuth";
import {useHistory} from "react-router-dom";
import CommonNotesModal from "./CommonNotesModel"
import axios from "../../Store/request"

const ClientTable = ({currentData,setCurrentData,ShowCourt,ShowPaymentPlan,ShowParoleOfficer,dialogHandler,setOpenCreditModal,setOpenConfirmModal,editDialogHandler,filteredData,editUser,postsPerPage,count,setCurrentPage,current_page,
                       getTerritory,filterTxt,searchCount,filterPage,initialFilter}) => {
    const history = useHistory()
    const { isOperator } = useAuth()
    const newclasses = newStyles()
    const dispatch = useDispatch()
    const { showToast } = useToast()


    const { filterdata,filterCount } = useSelector(({ manageUser }) => manageUser )
    const {
        addUser: isAddUser,
        clientDetail,
        data: manageUserData,
        loading: userLoading,
      } = useSelector(({ manageUser }) => manageUser)
    const TextClipper = (bio = "", length) => {
      let vb = ""
      
      if (bio?.length >= length) {
        for (var i = 0; i < length; i++) {
          vb += bio.charAt(i)
        }
        bio = `${vb}...`
      }

      return bio
    }
    const userDataPayment = history.location.state?.userDataPayment;
    if ( userDataPayment ) {
        editDialogHandler(userDataPayment);
        getTerritory(userDataPayment)
        history.push({
      state: {
        userDataPayment: null,
      },
    })
    }

    const paymentDialogHandler = e => {
        e.preventDefault()
        setOpenCreditModal(false)
    }

  const [openNoteModel,setOpenNoteModel] = useState(false);

  const noteDialogCloser = () => {
    setOpenNoteModel(false)
  }

  const arrayList = [];

  for (let i = 0; i < filteredData?.length; i++) {
    if (filteredData[i].user_type===2) {
      arrayList.push(filteredData[i])
    }
  }
  const paymentDropDownData = () => {
    return paymentPlanData?.map(item => ({ value: item.id, label: item.name }))
  }
  const { data: paymentPlanData, responseData: paymentPlan } = useSelector(
    ({ paymentPlan }) => paymentPlan
  )
  const { data: courtData, responseData: courtsData, count:courtDataLength, dataExist:courtsDataExist } = useSelector(({ court }) => court)
  const filterData =() => {
    const {date_added, active, date_removed, balance_owed} = initialFilter
    const params = {
      date_added: date_added ? date_added : null,
      active: active ? active : null,
      date_removed: date_removed ? date_removed : null,
      balance_owed: balance_owed ? balance_owed : null,
    }
    return params
  }
   const openHsitoryPage = (data) => {
      localStorage.setItem('userId', data.id)
      history.push('/balance')
      history.push({
      pathname: '/balance',
      state: {
        data: data,
        courtData: courtData,
        paymentPlanData: paymentPlanData,
      },
    })
  }
  const openNotesPage = (data) => {
      localStorage.setItem('userId', data.id)
      history.push(`/manage-users/user_notes/?user-id=${data.id}/`)
       history.push({
        pathname: `/manage-users/user_notes/?user-id=${data.id}/`,
    })
  }
  return (
      <>
        {
          filterTxt==="" || searchCount>0 ?
            <div className={newclasses.Container}>
              {
                filterPage && filterdata.length ===0?
                  <p className={newclasses.error}>No Data Found</p>
                  :
                  <>
                    {!!manageUserData.length?<div className={newclasses.TableHeading}>
                      <div className={newclasses.ClientHeadingInner}>
                        <p className={newclasses.Heading1}>User Name</p>
                        <p></p>
                        <p className={newclasses.Heading}>Last Name</p>
                        <p className={newclasses.Heading}>First Name</p>
                        <p className={newclasses.Heading}>Court System</p>
                        <p className={newclasses.Heading}>Payment Plan</p>
                        <p className={newclasses.Heading}>Home Phone</p>
                        <p className={newclasses.Heading}>Active/Inactive</p>
                        <p className={newclasses.Heading}>Balance</p>
      
                        <p></p>
                      </div>
                    </div>:<p className={newclasses.error}>No Data Found</p>}
                    {!!manageUserData.length &&
                    arrayList?.map((i,index) => {
                      return i.user_type===2?(
                        <div className={newclasses.MainContainer} key={i.id}>
                          <div className={newclasses.ClientContainerInner}>
                            <p className={newclasses.ListItmes}>
                              <span className={newclasses.ListUsername} onClick={()=>{editDialogHandler(i)
                      getTerritory(i)
                    }}>{i.username === null ? "-" : TextClipper(i.username, 15)} </span>
                            </p>
                            <p className={newclasses.ListItmes}>{i.last_name===null?"-": TextClipper(i.last_name, 15)}</p>
                            <p className={newclasses.ListItmes}>{i.first_name===null?"-": TextClipper(i.first_name, 15)}</p>
                            <p className={newclasses.ListItmes}>
                              {TextClipper(ShowCourt(i?.court) ?? "N/A",15)}
                            </p>
                            <p className={newclasses.ListItmes}>
                              {TextClipper(ShowPaymentPlan(i?.pay_plan_procedure) ?? "N/A",15)}
                            </p>
                            <p className={newclasses.ListItmes}>
                              {i?.home_phone!=="" ?i?.home_phone :"N/A"}
                            </p>
                            <p className={newclasses.ListItmes}>
                              {i?.is_active===true?"Active":"Inactive"}
                            </p>
                            <p className={newclasses.ListItmes}>
                              {parseFloat(i?.balance).toFixed(2)}
                            </p>
                              { isOperator ? (
                                  <NewMoreDropDown
                              MoreDropDownItem={[
                                {actionFunction:() => {
                                  setCurrentData(i)
                                  setOpenNoteModel(true)
                                },
                                icon:"Note",
                                name:"Add Notes"
                                },
                                {actionFunction:() => {
                                  setCurrentData(i)
                                  openNotesPage(i)
                                },
                                icon:"Note",
                                name:"View Notes"
                                },
                                {actionFunction:() => {
                                  setCurrentData(i)
                                  openHsitoryPage(i)
                                },
                                icon:"Apply Credit",
                                name:"Payment History"
                              },
                              {actionFunction:()=>{
                                  setCurrentData(i)
                                  if(i.id)
                                    setOpenCreditModal(true)
                                },
                                icon:"Apply Credit",
                                name:"Apply Credit"
                              },
                              {actionFunction:() => {
                                  getTerritory(i)
                                  editDialogHandler(i)
                                },
                                icon:"Edit",
                                name:"Edit"
                              },
                              {actionFunction:() => {
                                  setCurrentData(i)
                                  setOpenConfirmModal(true)
                                },
                                icon:"Delete",
                                name:"Delete"
                              }
                            ]}/>
                              ): (
                                  <NewMoreDropDown
                              MoreDropDownItem={[
                                  {actionFunction:() => {
                                    setCurrentData(i)
                                    setOpenNoteModel(true)
                                  },
                                  icon:"Note",
                                  name:"Add Notes"
                                  },
                                  {actionFunction:() => {
                                    setCurrentData(i)
                                    openNotesPage(i)
                                  },
                                  icon:"Note",
                                  name:"View Notes"
                                  },
                                  {actionFunction:() => {
                                    setCurrentData(i)
                                    openHsitoryPage(i)
                                  },
                                  icon:"Apply Credit",
                                  name:"Payment History"
                                },
                                {actionFunction:()=>{
                                    setCurrentData(i)
                                    if(i.id)
                                      setOpenCreditModal(true)
                                  },
                                  icon:"Apply Credit",
                                  name:"Apply Credit"
                                },
                                {actionFunction:() => {
                                    getTerritory(i)
                                    editDialogHandler(i)
                                  },
                                  icon:"Edit",
                                  name:"Edit"
                                },
                                {actionFunction: async () => {
                                      await axios.get(`/manage-users/user_active_status_change/`,{
                                      params:{
                                        id:i.id
                                      }
                                    });
                                    window.location.reload(true)
                                  },
                                  icon:"Activate",
                                  name:"Change User Status"
                                },
                                {actionFunction:() => {
                                    setCurrentData(i)
                                    setOpenConfirmModal(true)
                                  },
                                  icon:"Delete",
                                  name:"Delete"
                                }
                              ]}/>
                                  ) }
                          </div>
                        </div>
                      ):null
                    })}
                    <CommonNotesModal
                      openNoteModal = {openNoteModel}
                      currentData={currentData}
                      noteDialogCloser={noteDialogCloser}
                      setOpenNoteModel={setOpenNoteModel}
                    />
                  </>
                  
              }
              
              {filterTxt==="" &&
              count>10 &&  !filterPage &&
              <Container
                maxWidth="lg" style={{visibility:userLoading===true || (isAddUser===true || editUser===true || clientDetail===true)?"hidden":"visible"}}>
                    <Pagination_New  count={count}
                                     postsPerPage={postsPerPage}
                                     activePage={current_page}
                                     paginate={(pageNumber)=>{
                                      history.replace(`/manage-users/clients/page=${pageNumber}`)
                                       dispatch(SetCurrentPage(pageNumber))
                                       dispatch(GetUser({ limit: postsPerPage, offset: (postsPerPage * pageNumber - postsPerPage),user_type:2}))}}
                    />
              </Container>}
              { filterTxt==="" &&
              filterCount>10 &&  filterPage &&
              <Container
                maxWidth="lg" style={{visibility:userLoading===true || (isAddUser===true || editUser===true || clientDetail===true)?"hidden":"visible"}}>
                <Pagination_New  count={filterCount}
                                 postsPerPage={postsPerPage}
                                 activePage={current_page}
                                 paginate={(pageNumber)=>{
                                  history.replace(`/manage-users/clients/page=${pageNumber}`)
                                   dispatch(SetCurrentPage(pageNumber))
                                   dispatch(GetFilterData({initialFilter:filterData(), limit: postsPerPage, offset: (postsPerPage * pageNumber - postsPerPage)}))}}
                />
              </Container>
              }
            </div>
            : <p className={newclasses.error}>No Data Found</p>
        }


        
      </>
    )

}

export default ClientTable;