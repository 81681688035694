import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom"
import Login from "../Pages/Auth/Login"
import Dashboard from "../Pages/Dashboard/Dashboard"
import LeftDrawer from "../Components/LeftDrawer/LeftDrawer"
import OfficersManagement from "../Pages/OfficersManagement/OfficersManagement"
import CourtPricing from "../Pages/CourtPricing/CourtPricing"
import ServicesManagement from "../Pages/ServicesManagement/ServicesManagement"
import Documentation from "../Pages/Documentation/Documentation"
import PaymentManagement from "../Pages/PaymentManagement/PaymentManagement"
import UserManagement from "../Pages/UserManagement/UserManagement"
import ReportManagement from "../Pages/ReportManagement/ReportManagement"
import { useHistory } from "react-router"
import { connect, useDispatch, useSelector } from "react-redux"
import { auth } from "../Store/Actions/Auth"
import ConfigureStore from "../Store/Store"
import React, { useState, useEffect } from "react"
import ResponsiveHeader from "../Components/ResponsiveHeader/ResponsiveHeader"
import FileStorageManagement from "../Pages/FileStorageManagement/FileStorageManagement"
import ResetPassword from "../Pages/Auth/ResetPassword/ResetPassword"
// import ForgetPassword from "../Pages/Auth/ForgetPassword/ForgetPassword"
// import TokenInput from "../Pages/Auth/TokenInput/TokenInput"
import useAuth from "../utils/useAuth"
import ClientPricing from "../Pages/ClientPricing/ClientPricing"
import UserProfile from "../Pages/UserProfile/UserProfile"

const ResponsiveRoutes = props => {
  const { store } = ConfigureStore()
  const token = localStorage.getItem("token")

  const [choice, setChoice] = useState(false)
  const { isAdmin, isIndividualUser, isOperator } = useAuth()
  useEffect(() => {
    if (
      props.accessToken !== null &&
      props.accessToken !== "" &&
      props.accessToken !== undefined
    ) {
      setChoice(true)
    } else {
      setChoice(false)
    }
  }, [props.accessToken, choice])

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Login />
        </Route>

        <Route exact path="/reset-password">
          <ResetPassword />
        </Route>
        <>
          <ResponsiveHeader />
          <Route exact path="/user-profile">
          <UserProfile/>
        </Route>
          {!isIndividualUser && (
            <>
              <Route exact path="/reports">
                <ReportManagement />
              </Route>
              <Route exact path="/documentation">
                <Documentation />
              </Route>

            </>
          )}
          {(isAdmin || isOperator) && (
            <>
              <Route exact path="/manage-users/:param?">
                <UserManagement />
              </Route>
              <Route exact path="/courts">
                <Dashboard />
              </Route>
            </>
          )}
          {(isAdmin || isOperator) && (
            <>

              <Route exact path="/court-pricing">
                <CourtPricing />
              </Route>
              <Route exact path="/client-pricing">
                <ClientPricing />
              </Route>
            </>
          )}
          {isAdmin  && (
            <>
              <Route exact path="/officers">
                <OfficersManagement />
              </Route>
              <Route exact path="/services">
                <ServicesManagement />
              </Route>
            </>
          )}
        </>
      </Switch>
    </Router>
  )
}
const mapStateToProps = state => {
  return {
    accessToken: state.auth.accessToken
  }
}

export default connect(mapStateToProps, { auth })(ResponsiveRoutes)
