import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  MainContainer: {
    width: "100%",
    height: 97,
    background: "#FFFFFF",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  HeaderInner: {
    padding: "2px 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },

  IconWrapper: {
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#81A4FF",
    margin: "0px 20px",
    width: 47,
    height: 44
  },
  icon: {
    width: 24,
    height: 24
  },
  MainHeading: {
    color: "#363E51",
    fontFamily: "Open Sans",
    fontSize: theme.fonts.sizes.fontXL,
    fontWeight: 600,
    textTransform: "uppercase"
  },
  Wrapper: {
    display: "flex",
    alignItems: "center"
  },

  topDiv: {
    height: "600px",
    width: "400px",
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    background: "none",
    alignItems: "center",
    boxShadow: "none",
    marginTop: "25px"
  },
  upperDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    height: "550px",
    width: "400px",
    alignItems: "center",
    background: "white"
  },

  firstDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "80%"
  },

  selectCont: {
    width: "100%",
    height: "38px",
    background: "rgb(244, 244, 244)",
    color: "gray",
    borderRadius: "5px",
    paddingLeft: "5px",
    border: "1px solid lightgray",
    fontFamily: theme.fonts.family.Light,
    fontSize: "16px",
    // paddingLeft: "10px"
  },

  bottomCont: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "80%"
  },

  bottomContLeft: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "45%"
  },

  bottomContRight: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "45%"
  },

  inputCont: {
    width: "100%",
    height: "38px",
    background: "rgb(244, 244, 244)",
    color: "gray",
    borderRadius: "5px",
    border: "1px solid lightgray",
    fontFamily: theme.fonts.family.Light,
    fontSize: "16px",
    paddingLeft: "10px"
  },

  button: {
    background: "#3971FF",
    margin: "0px 20px",
    "&.MuiButton-containedSecondary": {
      width: "80%",
      height: 38,
      display: "flex",
      alingItems: "center",
      borderRadius: "5px !important",
      marginTop: "15px",
      "&:hover": {
        background: "#3971FF"
      },
      "& .MuiButton-label": {
        fontFamily: theme.fonts.family.SemiBold,
        fontSize: 15,
        fontWeight: 400,
        textTransform: "capitalize",
        marginTop: 3
      },
      "& .MuiSvgIcon-root": {
        marginTop: -2
      }
    }
  },

  relativeText: {
    position: "absolute",
    top: "72.7%",
    left: "20%",
    fontSize: "18px"
  },
  relativeText2: {
    position: "absolute",
    top: "72.7%",
    right: "43%",
    fontSize: "18px"
  },

  LogoWrapper: {
    backgroundColor: "white",
    borderRadius: 50,
    padding: 10,
    border: "1px solid #FFFFFF",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.203851)",
    position: "absolute",
    top: -25
  },
  Logo: {
    width: 34,
    height: 32
  },
  paper: {
    overflowY: "initial"
  }
}))
