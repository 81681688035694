import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import {useStyles} from './ViewModalStyle';
import Logo from "../../assets/AMSLogo.png"


const ViewModal = ({ signal, arrayOfData, handleyes }) => {

    const classes = useStyles()

    return (
        <div>
            <Dialog open={signal} scroll="body" onClose={handleyes} aria-labelledby="form-dialog-title" PaperProps={{
          style: {
            overflow: "unset",
            margin:40
          }
        }}>
                <div className="" style={{ width: '350px', height: 'auto' }}>
                <div className={classes.LogoWrapper} >
              <img src={Logo} className={classes.Logo}/>
            </div>
                    <div className={classes.dynamicCont}>
                        {
                            arrayOfData.map((data)=>{
                                return(
                                    <div className={classes.dynamicInner} key={data.title}>
                                        <p className={classes.dynamicTitle}>{data.title}</p>
                                        <p className={classes.dynamicVal}>{data.val}</p>
                                    </div>    
                                )
                            })
                        }
                    </div>
                    <DialogActions>

                        <Button onClick={handleyes} color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    )
}

export default ViewModal;