import React, { useState, useEffect } from "react"
import { useStyles } from "./LoginStyle"
import Logo from "../../assets/AMSLogo.png"
import Input from "../../Components/Input/Input"
import Button from "@material-ui/core/Button"
import { useHistory } from "react-router"
import { connect, useDispatch } from "react-redux"
import { auth } from "../../Store/Actions/Auth"
import ConfigureStore from "../../Store/Store"
import { CircularProgress } from "@material-ui/core"
import { Link } from "react-router-dom"
import useAuth from "../../utils/useAuth";
import CircularSpinner from "../../Components/CircularProgressUserReport/CircularSpinner"

const Login = props => {

  const { store } = ConfigureStore()
  const history = useHistory()
  const { redirectRoute } = useAuth()

  const [credentials, setCredentials] = useState({
    username: "",
    password: ""
  })
  const [isLoading, setIsLoading] = useState(false)
  const [invalid, setInvalid] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [preventUrl, setPreventUrl] = useState("")

  useEffect(() => {
    setInvalid(props.invalidUser)
    if (invalid) {
      setIsLoading(false)
    }
  }, [props.invalidUser, invalid])

  //check if user is trying to go to / route while logged in
  useEffect(() => {
    if (props.accessToken) {
      history.push(redirectRoute)
    }
  }, [props.accessToken])


  const classes = useStyles()
  const dispatch = useDispatch()
  
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }


  const LoginCheck = e => {
    e.preventDefault()
    // Login()      have to build
    let { username, password } = credentials

    setIsLoading(true)
    dispatch(
      isValidEmail(username) ? auth({
        email: username,
        password: password
      }):auth({
        username: username,
        password: password
      })
    )

  }

  return (
    <div className={classes.MainContainer}>
      <div className={classes.Overlay}>
        <div className={classes.LoginContainer}>
          <div className={classes.LoginFormContainer}>
            <form onSubmit={LoginCheck} className={classes.Inner}>
              <div className={classes.FormLogo}>
                <div className={classes.LogoWrapper}>
                  <img src={Logo} className={classes.Logo} />
                </div>
              </div>
              <h3 className={classes.MainHeading}>
                Welcome to OHIO AMS dashboard!
              </h3>
              <div className={classes.InputWrapper}>
                <Input
                  label="Email or Username"
                  value={credentials.username}
                  onChange={e =>
                    setCredentials({ ...credentials, username: e.target.value })
                  }
                  isRequired={true}
                />
                <Input
                  label="Password"
                  type="password"
                  value={credentials.password}
                  onChange={e =>
                    setCredentials({ ...credentials, password: e.target.value })
                  }
                  isRequired={true}
                />
                 <Link to="/reset-password" className={classes.ForgetPasswordLink}>
                    Forgot Password
                  </Link>
                <div>
                 
                  <p style={{ color: "red", textAlign: "center" }}>
                    {invalid ? "Invalid Email, Username or Password" : ""}
                  </p>
                </div>
              </div>
              <div className={classes.BtnWrapper}>
                <Button
                  variant="contained"
                  type="submit"
                  style={{ height: "50px" }}
                  className={classes.ContinueBtn}
                  disabled={isLoading}
                >
                  {!isLoading ? (
                    "Continue"
                  ) : (
                    <CircularSpinner colored/>
                  )}
                </Button>
              </div>

              <div className={classes.Footer}>
                <div className={classes.FooterWrapper}>
                  <span className={classes.HorizontalLine}></span>
                  <p className={classes.FooterText}>
                   Terms & Conditions
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    redirectTo: state.auth.redirectTo,
    invalidUser: state.auth.invalidUser,
    accessToken: state.auth.accessToken
  }
}

export default connect(mapStateToProps, { auth })(Login)
