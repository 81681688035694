import { makeStyles } from "@material-ui/core/styles"
import LoginBg from "../../../assets/LoginBg.png"

export const useStyles = makeStyles(theme => ({
  //STYLING BACKGROUNG IMAGE AND OVERLAY

  MainContainer: {
    backgroundImage: `url(${LoginBg})`,
    minHeight: "100vh",
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "100% 100%"
  },
  Overlay: {
    position: "absolute",
    backgroundColor: "rgba(0, 0, 0, 0.50)",
    top: 0,
    left: 0,
    width: "100%",
    minHeight: "100vh"
  },

  //STYLING LOGIN FORM

  LoginContainer: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  LoginFormContainer: {
    width: 423,
    height: 440,
    backgroundColor: theme.colors.primary.white,
    boxshadow: "0px 1px 5px #E5E9F2",
    borderRadius: 10
  },
  Inner: {
    padding: "0px 10px"
  },
  FormLogo: {
    display: "flex",
    justifyContent: "center"
  },
  LogoWrapper: {
    backgroundColor: "white",
    borderRadius: 50,
    padding: 10,
    border: "1px solid #FFFFFF",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.203851)",
    position: "relative",
    top: -25
  },
  Logo: {
    width: 34,
    height: 32
  },
  MainHeading: {
    fontFamily: "Open Sans",
    colo: "#1F1E1E",
    fontSize: theme.fonts.sizes.fontL,
    fontWeight: 500,
    textAlign: "center",
    textTransform: "uppercase",
    marginTop: 15
  },
  SubHeading: {
    color: "#979797",
    fontSize: "12px",
    fontFamily: "Roboto",
    textAlign: "center",
    display: "flex",
    padding: "0 14%"
  },

  //INPUT STYLING

  InputWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // margin: "10px 0px",
    height: "100px"
  },

  //STYLING CONTINUE BUTTON

  BtnWrapper: {
    display: "flex",
    justifyContent: "center"
  },
  ContinueBtn: {
    width: 344,
    height: 44,
    backgroundColor: "#3971FF !important",
    borderRadius: "22px !important",
    color: "white !important",
    fontFamily: "Open Sans",
    marginBottom: "15px"
  },

  //LOADING SPINNER

  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2)
    }
  },

  //STYLING FOOTER TEXT
  Footer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 215,
    
  },
  FooterText: {
    fontFamily: theme.fonts.family.Medium,
    color: "#3971FF",
    fontSize: "14px",
    lineHeight: "22px",
    width: 352,
    textAlign: "center",
    textDecoration:"underline",
    paddingTop: "8px",
    cursor: "pointer"
  },
  HorizontalLine: {
    position: "absolute",
    width: 349,
    height: 1,
    backgroundColor: "#F0F0F0"
  }
}))

export const TokenInputStyles=makeStyles((theme)=>({

  MainContainer: {
    backgroundImage: `url(${LoginBg})`,
    minHeight: "100vh",
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "100% 100%"
  },
  Overlay: {
    position: "absolute",
    backgroundColor: "rgba(0, 0, 0, 0.50)",
    top: 0,
    left: 0,
    width: "100%",
    minHeight: "100vh"
  },

  //STYLING LOGIN FORM

  LoginContainer: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  LoginFormContainer: {
    width: 423,
    height: 440,
    backgroundColor: theme.colors.primary.white,
    boxshadow: "0px 1px 5px #E5E9F2",
    borderRadius: 10
  },
  Inner: {
    padding: "0px 10px"
  },
  FormLogo: {
    display: "flex",
    justifyContent: "center"
  },
  LogoWrapper: {
    backgroundColor: "white",
    borderRadius: 50,
    padding: 10,
    border: "1px solid #FFFFFF",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.203851)",
    position: "relative",
    top: -25
  },
  Logo: {
    width: 34,
    height: 32
  },
  MainHeading: {
    fontFamily: "Open Sans",
    colo: "#1F1E1E",
    fontSize: theme.fonts.sizes.fontL,
    fontWeight: 500,
    textAlign: "center",
    textTransform: "uppercase",
    marginTop: 15
  },
  SubHeading: {
    color: "#979797",
    fontSize: "12px",
    fontFamily: "Roboto",
    textAlign: "center",
    display: "flex",
    padding: "0 20%"
  },

  //INPUT STYLING

  InputWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "35px 70px 10px 70px"
  },
  InputBox: {
    height: "50px",
    width: "45px",
    outline: "none",
    border: "1px solid #afb7bb21",
    backgroundColor: "#afb7bb17",
    borderRadius: "5px",
    fontSize: "24px",
    textAlign: "center",
    fontFamily: theme.fonts.family.Medium,
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    }
  },
  //STYLING CONTINUE BUTTON

  BtnWrapper: {
    display: "flex",
    justifyContent: "center"
  },
  ContinueBtn: {
    width: 344,
    height: 44,
    backgroundColor: "#3971FF !important",
    borderRadius: "22px !important",
    color: "white !important",
    fontFamily: "Open Sans",
    // marginBottom: "15px"
  },

  //LOADING SPINNER

  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2)
    }
  },

  //STYLING FOOTER TEXT
  Footer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 170
  },
  FooterText: {
    fontFamily: theme.fonts.family.Medium,
    color: "#3971FF",
    fontSize: "14px",
    lineHeight: "22px",
    width: 352,
    textAlign: "center",
    textDecoration: "underline",
    paddingTop: "8px",
    cursor: "pointer"
  },
  HorizontalLine: {
    position: "absolute",
    width: 349,
    height: 1,
    backgroundColor: "#F0F0F0"
  }
}))


export const resetPswdStyles = makeStyles(theme => ({
  //STYLING BACKGROUNG IMAGE AND OVERLAY

  MainContainer: {
    backgroundImage: `url(${LoginBg})`,
    minHeight: "100vh",
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "100% 100%"
  },
  Overlay: {
    position: "absolute",
    backgroundColor: "rgba(0, 0, 0, 0.50)",
    top: 0,
    left: 0,
    width: "100%",
    minHeight: "100vh"
  },

  //STYLING LOGIN FORM

  LoginContainer: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  LoginFormContainer: {
    // width: 423,
    // height: 543,
    backgroundColor: theme.colors.primary.white,
    boxshadow: "0px 1px 5px #E5E9F2",
    borderRadius: 10
  },
  Inner: {
    padding: "0px 10px"
  },
  FormLogo: {
    display: "flex",
    justifyContent: "center"
  },
  LogoWrapper: {
    backgroundColor: "white",
    borderRadius: 50,
    padding: 10,
    border: "1px solid #FFFFFF",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.203851)",
    position: "relative",
    top: -25
  },
  Logo: {
    width: 34,
    height: 32
  },
  MainHeading: {
    fontFamily: theme.fonts.family.Medium,
    colo: "#1F1E1E",
    fontSize: theme.fonts.sizes.fontL,
    fontWeight: 500,
    textAlign: "center",
    textTransform: "uppercase",
    //marginTop: 10
  },
  SubHeading: {
   
    color: "#979797",
    fontSize: "12px",
    fontFamily: "Roboto",
    textAlign: "center",
    display: "flex",
    padding: "0 14%",
  },

  //INPUT STYLING

  InputWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "20px 0px",
   // height: "150px"
  },

  //STYLING CONTINUE BUTTON

  BtnWrapper: {
    display: "flex",
    justifyContent: "center"
  },
  SubmitBtn: {
    width: 344,
   
    backgroundColor: "#3971FF !important",
    borderRadius: "22px !important",
    color: "white !important",
    fontFamily: theme.fonts.family.Medium,
    margin: "20px 0"
  },
  CancelBtn: {
    width: 344,
    
    border: "2px solid #3971FF !important",
    borderRadius: "22px !important",
    color: "#3971FF !important",
    fontFamily: theme.fonts.family.Medium,
    marginBottom:"30px"
  },

  //LOADING SPINNER

  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2)
    }
  },

  //STYLING FOOTER TEXT
  Footer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    height: 170,
  },
  FooterText: {
    fontFamily: theme.fonts.family.Medium,
    color: "#3971FF",
    fontSize: "14px",
    lineHeight: "22px",
    width: 352,
    textAlign: "center",
    textDecoration:"underline",
    paddingTop: "8px",
    paddingBottom: "20px",
    cursor: "pointer"
  },
  HorizontalLine: {
    position: "absolute",
    width: 349,
    height: 1,
    backgroundColor: "#F0F0F0"
  }
}))
