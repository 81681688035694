import image1 from "../../assets/visa.svg";
import image2 from "../../assets/maestro.svg";
import image3 from "../../assets/ach.svg";

export const DataArray = [
  {
      img:image1,
      Name:'User Name Invoice Id',
      InvoiceNo:"Invoice number ID",
      code:"**** 2289",
      ID:1,
      Duration:"Monthly",
      Date:"24/12/2021",
      payment:"$150",
  },
  {
      img:image2,
      Name:'User Name Invoice Id',
      InvoiceNo:"Invoice number ID",
       code:"**** 2289",
      ID:2,
      Duration:"Monthly",
      Date:"24/12/2021",
      payment:"$150",
  },
  {
      img:image3,
      Name:'User Name Invoice Id',
    InvoiceNo:"Invoice number ID",
      code:"ACH Check",
      ID:3,
      Duration:"Monthly",
      Date:"24/12/2021",
      payment:"$150",
  },
  {
      img:image1,
      Name:'User Name Invoice Id',
    InvoiceNo:"Invoice number ID",
      code:"**** 2289",
      ID:4,
      Duration:"Monthly",
      Date:"24/12/2021",
      payment:"$150",
  },
  {
      img:image1,
      Name:'User Name Invoice Id',
    InvoiceNo:"Invoice number ID",
      code:"**** 2289",
      ID:5,
      Duration:"Monthly",
      Date:"24/12/2021",
      payment:"$150",
  },
]