import Actions from "../Type"
import axios from "../request"
export const ManageUserAction = data => {
  return async dispatch => {
    try {
      dispatch({
        type: Actions.SHOW_ADD_USER,
        payload: data
      })
    } catch (e) {
    }
  }
}

export const ClientDetailAction = data => {
  return async dispatch => {
    try {
      dispatch({
        type: Actions.SHOW_CLIENT_DETAILS,
        payload: data
      })
    } catch (e) {
    }
  }
}

export const EditUserAction = data => {
  return async dispatch => {
    try {
      dispatch({
        type: Actions.SHOW_EDIT_USER,
        payload: data
      })
    } catch (e) {
    }
  }
}



export const GetUser = ({ limit = 10, offset = 0 ,user_type = 1 } = {}) => {
  return async dispatch => {
    try {
      dispatch({ type: Actions.GET_USER_REQUEST })
      const response = await axios.get(`/manage-users/`,{
        params:{
          limit,
          offset,
          user_type
        }
      })
      dispatch({
        type: Actions.GET_USER_SUCCESS,
        payload: { results: response.data.results, count: response.data.count, current_user_type:user_type}
      })
    } catch (e) {
      dispatch({ type: Actions.GET_USER_FAIL })
    }
  }
}

export const SetUserType = () => {
  return async dispatch => {
    try {
      dispatch({
        type: Actions.SET_USER_TYPE,
        payload: 0
      })
    } catch (e) {
    }
  }
}

export const SetCurrentPage = ( data ) => {
  return async dispatch => {
    try {
      dispatch({
        type: Actions.SET_CURRENT_PAGE,
        payload: data
      })
    } catch (e) {
    }
  }
}
export const GetFilterData = ({initialFilter, limit = 10, offset = 0 } = {}) => {
  return async dispatch => {
    try {
      dispatch({ type: Actions.GET_FILTER_DATA_REQUEST })
      const response = await axios.get(`manage-users/user_filter/`,{
        params: {...initialFilter,limit,offset}
      })
      dispatch({
        type: Actions.GET_FILTER_DATA_SUCCESS,
        payload: { results: response.data.results, count: response.data.count}
      })
    } catch (e) {
      dispatch({ type: Actions.GET_FILTER_DATA_FAIL })
    }
  }
}