import Actions from "../Type"
import axios from "../request"

export const GetCourtPricing = ({ limit = 10, offset = 0 } = {}) => {
  return async dispatch => {
    try {
      dispatch({ type: Actions.GET_COURT_PRICING_REQUEST })
      const response = await axios.get(`/api/v1/courts/pricing/court/?limit=${limit}&offset=${offset}`)
      dispatch({
        type: Actions.GET_COURT_PRICING_SUCCESS,
        // payload: response.data.results,
        payload: { results: response.data.results, count: response.data.count}
      })

    } catch (e) {
      dispatch({ type: Actions.GET_COURT_PRICING_FAIL })
    }
  }
}
export const GetAllCourtPricing = () => {
  return async dispatch => {
    try {
      dispatch({ type: Actions.GET_COURT_PRICING_REQUEST })
      const response = await axios.get(`/api/v1/courts/pricing/court/?type=full`)
      dispatch({
        type: Actions.GET_COURT_PRICING_SUCCESS,
        // payload: response.data.results,
        payload: { results: response.data, count: response.data.length, dataExist: response.data.length>0?true:false}
      })

    } catch (e) {
      dispatch({ type: Actions.GET_COURT_PRICING_FAIL })
    }
  }
}
