import React, {useState, useEffect, useContext, useRef} from "react"
import {
  dialogStyles,
} from "./UserDataTableStyle"
import axios from "../../Store/request"
import { connect, useDispatch, useSelector } from "react-redux"
import { auth } from "../../Store/Actions/Auth"
import { ScreenContext } from "../../context/context"
import useToast from "../../Components/Toast/useToast"
import {
  ClientDetailAction,
  EditUserAction,
  GetUser,
  SetCurrentPage,
} from "../../Store/Actions/ManageUserAction"
import ConfirmModal from "../ConfirmModal/ConfirmModal"
import { GetAllOfficers } from "../../Store/Actions/Officers"
import { GetPaymentPlan } from "../../Store/Actions/PaymentPlan"
import ClientDetail from "./ClientDetail";
import AdminTable from "./AdminTable";
import ClientTable from "./ClientTable";
import CourtAdminTable from "./CourtAdminTable";
import OperatorsTable from "./OperatorsTable";
import AddClient from "./AddClient";
import EditClient from "./EditClient"
import CommonCreditModal from "./CommonCreditModal"
import {GetAllCourt, GetStateData, GetTerritoryData} from "../../Store/Actions/Court"
import CircularSpinner from "../CircularProgressUserReport/CircularSpinner";

import { useHistory, useParams, useLocation } from "react-router"

const UserDataTable = ({passedIndex,filteredData,filterTxt,searchCount,filterPage,initialFilter,setFilterTxt}) => {
  const [addClientProcessing,setAddClientProcessing] = useState(false)
  const [editClientProcessing,setEditClientProcessing] = useState(false)
  const Loaction=useLocation()
  const { param } = useParams();
  const newClasses = dialogStyles()
  const { showToast } = useToast()
  const [currentData, setCurrentData] = useState({})
  const [copyCurrentData, setCopyCurrentData] = useState({})
  const [openCreditModal, setOpenCreditModal] = useState(false)
  const [media, setMedia] = useState([])
  const [checkDisable,setCheckDisable]=useState(false)
  const [openConfirmModal,setOpenConfirmModal]=useState(false)
  const dispatch = useDispatch()
  const { screenSize, setScreenSize } = useContext(ScreenContext)
  const [creditFormValidation,setCreditFormValidation]=useState({})
  const [amount,setAmount]=useState("")


  useEffect(()=>{
    setCurrentData(copyCurrentData)
  },[copyCurrentData])

  const {
    addUser: isAddUser,
    clientDetail,
    editUser,
    data,
    loading: userLoading,
    count,
    current_user_type,
    current_page
  } = useSelector(({ manageUser }) => manageUser)

  const { data: officersData, count: officersDataCount, dataExist: officersDataExist } = useSelector(({ officers }) => officers)
  const { data: courtsData, count: courtsDataCount, dataExist: courtsDataExist } = useSelector(({ court }) => court)
  const { data: paymentPlanData, count: paymentPlanDataCount, dataExist: paymentPlanDataExist } = useSelector(
    ({ paymentPlan }) => paymentPlan
  )

  const imageUploader = useRef(null)

  const imageUploadHandler = () => {
    const found = imageUploader.current.click()
  }
  const fileUploadHandler = async event => {
    let reader = new FileReader()
    let files = event.target.files[0]

    reader.onloadend = () => {
      const updatedMedia = [...media, { name: files.name, url: reader.result }]
      setMedia(updatedMedia)

    }
    reader.readAsDataURL(files)

    imageUploader.current.value = ""

  }
  const imageDeleteHandler = (index, docs) => {
    media.splice(index, 1)
    setMedia([...media])

  }

  const creditDialogCloser = () => {
    setOpenCreditModal(false)
    setMedia([])
    setAmount("")
    setCreditFormValidation({
        amount:"",
        attachment:""
    })
  }


  useEffect(() => {
    if(paymentPlanData?.length>0 && paymentPlanData?.length===paymentPlanDataCount)return;
    if(paymentPlanDataExist)
    dispatch(GetPaymentPlan())
  }, [paymentPlanData])
  useEffect(() => {
    if(officersData?.length>0 && officersData?.length===officersDataCount)return;
    if(officersDataExist)
    dispatch(GetAllOfficers())
  }, [officersData])
  useEffect(() => {
    if(courtsData?.length>0 && courtsData?.length===courtsDataCount)return;
    if(courtsDataExist)
    dispatch(GetAllCourt())
  }, [courtsData])

  
  useEffect(() => {
    const findData = passedIndex?.find(d => d.selected);
    dispatch(SetCurrentPage(parseInt(param)??1))
    setCurrentPage(parseInt(param)??1)
    dispatch(GetUser({user_type: findData?.index === 1 ? 1:findData?.index === 2 ? 4:findData?.index === 3? 3:findData?.index===4? 2: 1,offset:(10 * current_page - 10)}))
  }, [passedIndex[0]?.selected,passedIndex[1]?.selected,passedIndex[2]?.selected,passedIndex[3]?.selected])

  useEffect(() => {
    setScreenSize(window.innerWidth)
  }, [screenSize])

const [creditLoading,setCreditLoading]=useState(false)
const [dialogError,setDialogError]=useState({
  amount:"",
  attachment:"",
})

const deleteClient = () => {
    setCreditLoading(true)
  axios
    .delete(`/manage-users/${currentData.id}/`)
    .then(function (response) {
      setOpenConfirmModal(false)
      setCreditLoading(false)
      showToast("Client Deleted Succesfully", "success")
      dispatch(GetUser({ limit: postsPerPage, offset: postsPerPage * currentPage - postsPerPage,user_type:currentData.user_type }))
      setFilterTxt('')
    })
    .catch(function (error) {
      setOpenConfirmModal(false)
      setCreditLoading(false)
      showToast("Unable to delete, please try again later", "error")
    })
}

const creditValidation = () => {
  const checkAmount=amount;
  const checkMedia=media;
  let formErrors = {}
  let formIsValid = true;
  if(!checkAmount){
    formIsValid=false;
    formErrors["amount"]="Amount can't be empty"
  }
  setCreditFormValidation(formErrors)
  return formIsValid
}

const adminApplyCredit=async ()=>{
  try {
  
    if(media[0]?.url===undefined || media[0]?.url===undefined){
      setCreditLoading(true)
      setCheckDisable(true);
      const creditData = {
        payment_type: 2,
        user: currentData.id,
        amount: +amount,
      }
      const response = await axios.post("/manage-users/user_payments/", creditData)
    }
    else {
      setCreditLoading(true)
      setCheckDisable(true);
      const creditData = {
        payment_type: 2,
        user: currentData.id,
        amount: +amount,
        cheque_image: media[0].url,
      }
      await axios.post("/manage-users/user_payments/", creditData)
    } 

      setMedia([])
      setAmount("")
      showToast("Apply Credit Successfully", "success")

      setCreditLoading(false)
      setOpenCreditModal(false)
      setCreditFormValidation({
        amount:"",
        attachment:""
      })
      setCheckDisable(false)
      window.location.reload()
  }
  catch(error)
  {

    setCreditLoading(false)
    setCheckDisable(false)
    setCreditFormValidation({
      amount:"",
      attachment:""
    })
    showToast("Could not apply credit", "error")
  }
}

  const applyCreditFunc=(e)=>{
    e.preventDefault();
    if(creditValidation()){
      adminApplyCredit()
    }
  }

  const ShowCourt = courtId => {
    const foundData = courtsData?.find(item => item.id === courtId)
    return foundData?.name
  }
  const ShowPaymentPlan = paymentId => {
    const foundData = paymentPlanData?.find(item => item.id === paymentId)
    return foundData?.name
  }
  const ShowParoleOfficer = officerId => {
    const foundData = officersData?.find(item => item.id === officerId)
    return foundData?.first_name
  }
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  let currentPosts = [];
  let arrayList = [];
  for (let i = 0; i < filteredData?.length; i++) {
      if (filteredData[i].user_type===1) {
        arrayList.push(filteredData[i])
      }
  }
  currentPosts = arrayList.slice(indexOfFirstPost, indexOfLastPost);

  const dialogHandler = i => {
    console.log("dialogHandler",i)
    dispatch(ClientDetailAction(true))
    setCurrentData(i)
  }
  const paymentDialogHandler = e => {
    e.preventDefault()
    setOpenCreditModal(false)
}
  const editDialogHandler = i => {
    dispatch(EditUserAction(true))
    const { services = [], attachments} = i || {};
    i.services = services.map(service => {
      if(typeof(service.services)==="object")
       return ({...service,services:service.services?.id, is_new: false, is_delete: false,install_date:service.install_date===null?"":service.install_date.split(" ")[0] , projected_install_date:service.projected_install_date.split(" ")[0],end_date:service.end_date===null?"":service.end_date.split(" ")[0],one_time_fee:service?.services?.one_time_fee  })
       else{
         return {...service}
       }
      });

    console.log("i services is is ",i)

    i.attachments = attachments.map(attachment => ({...attachment, is_new: false, is_delete: false }));
    setCurrentData({...i,state:i.state,territory:i.territory})
  }

  const [territoryData,setTerritoryData]=useState()
  const { territoryData:TerritoryData} = useSelector(({ court }) => court)
  const getTerritory=async(i)=>{
    dispatch(GetTerritoryData(i.state?.id))
    //
  }

    let tableProps={
      currentData,setCurrentData,editDialogHandler,setOpenCreditModal,setOpenConfirmModal,dialogHandler,filteredData,editUser,postsPerPage,count,setCurrentPage,current_page
      ,getTerritory,filterTxt,searchCount,
  }
  const manageUserTable=()=> {
    const convertedIndex = [...passedIndex]
    const getIndex = convertedIndex?.find((element) => element?.selected)?.index
    switch (getIndex) {
      case 1:
        return (
          <AdminTable
            {...tableProps}
          />
        )
      case 2:
        return (
          <ClientTable
          {...tableProps}
          ShowCourt={ShowCourt}
          ShowPaymentPlan={ShowPaymentPlan}
          ShowParoleOfficer={ShowParoleOfficer}
          filterPage={filterPage}
          initialFilter={initialFilter}
          current_page={current_page}
        />
        )
      case 3:
        return (
          <CourtAdminTable
            {...tableProps}
          />
        )
      case 4:
        return (
          <ClientTable
            {...tableProps}
            ShowCourt={ShowCourt}
            current_page={current_page}
            ShowPaymentPlan={ShowPaymentPlan}
            ShowParoleOfficer={ShowParoleOfficer}
            filterPage={filterPage}
            initialFilter={initialFilter}
          />
        )
      default:
        return (<></>)
    }
  }
  const { stateData:courtStateData} = useSelector(({ court }) => court)
  // court list
  useEffect(async()=>{
    if(courtStateData?.length>0) return;
    dispatch(GetStateData())
  },[courtStateData])
  const stateList=courtStateData?.map((element)=>{
    return ({value:element.id,label:element.name})
  })
  return (
    <>
      {userLoading ? (
        <CircularSpinner />

      ) : isAddUser ? (
          <AddClient setAddClientProcessing={setAddClientProcessing}
          addClientProcessing={addClientProcessing}
          currentPage={current_page}
          postsPerPage={postsPerPage}
          passedIndex={passedIndex}
          stateList={stateList}
          />

      ) : clientDetail ? (
          <ClientDetail 
          {...tableProps}
          currentData={currentData}
          passedIndex={passedIndex}
          />

      ) :editUser ? (
        <EditClient
          setOpenCreditModal={setOpenCreditModal}
        currentData={currentData}
        setCurrentData={setCurrentData}
        editClientProcessing={editClientProcessing}
        setEditClientProcessing={setEditClientProcessing}
        currentPage={current_page}
        postsPerPage={postsPerPage}
        passedIndex={passedIndex}
        stateList={stateList}
        territoryData={TerritoryData}
        setTerritoryData={setTerritoryData}
          setFilterTxt={setFilterTxt}
         />

    ) : manageUserTable() }
      <CommonCreditModal
        openCreditModal={openCreditModal}
        setOpenCreditModal={setOpenConfirmModal}
        setMedia={setMedia}
        media={media}
        setAmount={setAmount}
        amount={amount}
        setCreditFormValidation={setCreditFormValidation}
        creditLoading={creditLoading}
        currentData={currentData}
        paymentDialogHandler={paymentDialogHandler}
        erramount={creditFormValidation.amount}
        errattachment={creditFormValidation.attachment}
        checkDisable={checkDisable}
        imageUploadHandler={imageUploadHandler}
        imageUploader={imageUploader}
        fileUploadHandler={fileUploadHandler}
        imageDeleteHandler={imageDeleteHandler}
        applyCreditFunc={applyCreditFunc}
        creditDialogCloser={creditDialogCloser}
        />
        <ConfirmModal
          signal={openConfirmModal}
          handleyes={deleteClient}
          handleno={() => setOpenConfirmModal(false)}
          msg={`Are you sure you want to delete the ${currentData.username}?`}
          classes={{root:newClasses.dialogRoot}}
          loading={creditLoading}
        />

    </>
  )
}

const mapStateToProps = state => {
  return {
    accessToken: state.auth.accessToken
  }
}

export default connect(mapStateToProps, { auth })(UserDataTable)
