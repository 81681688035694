import React, { useState, useRef } from "react"
import {
  useStyles,
  TokenInputStyles,
  resetPswdStyles
} from "./ResetPasswordStyle.js"
import Logo from "../../../assets/AMSLogo.png"
import Input from "../../../Components/Input/Input"
import Button from "@material-ui/core/Button"
import { CircularProgress } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import axios from "../../../Store/request"
import useToast from "../../../Components/Toast/useToast"
import { useEffect } from "react"
import { getQueryString } from "../../../utils/helpers.js"

const ForgetPassword = () => {
  const code = getQueryString("code")
  const classes = useStyles()
  const tokenClasses = TokenInputStyles()
  const resetClasses = resetPswdStyles()
  const { showToast } = useToast()

  const [userEmail, setUserEmail] = useState("")

  const [validation, setValidation] = useState({})
  const [formError, setFormError] = useState("")
  const [screen, setScreen] = useState(code ? 3 : 1)
  const history = useHistory()
  // const [resetPassword, setResetPassword] = useState({
  //   password: "",
  //   confirmPassword: ""
  // })
  const [formData, setFormData] = useState({
    digit1: "",
    digit2: "",
    digit3: "",
    digit4: "",
    password: "",
    confirmPassword: ""
  })
  const firstInput = useRef()

  const forgetPasswordValidation = () => {
    let formErrors = {}
    let formIsValid = true
    if (!userEmail) {
      formIsValid = false
      formErrors["email"] = "Email field may not be blank."
    } else if (!userEmail.includes("@") || !userEmail.includes(".")) {
      formIsValid = false
      formErrors["email"] = "Please enter a valid email address"
    }
    setValidation(formErrors)
    return formIsValid
  }
  const forgetPasswordHandler = e => {
    e.preventDefault()
    if (forgetPasswordValidation()) {
      const finalData = { email: userEmail }
      getToken(finalData)
      setFormError("")
    }
  }

  const getToken = async data => {
    try {
      const response = await axios.post("/manage-users/forget_password/", data)
      if (response.status === 200) {
        setScreen(2)
        showToast("Otp sent to your email!", "success")
      }
    } catch (e) {
      setFormError(e.response.data.message)
      showToast("Data not submitted, please try again later", "error")
    }
  }

  const tokenValidation = () => {
    const { digit1, digit2, digit3, digit4 } = formData
    let formErrors = {}
    let formIsValid = true
    if (!digit1 || !digit2 || !digit3 || !digit4) {
      formIsValid = false
      formErrors["token"] = "Please Enter OTP."
    }
    setValidation(formErrors)
    return formIsValid
  }
  const tokenInputHandler = e => {
    e.preventDefault()
    const { digit1, digit2, digit3, digit4 } = formData
    if (tokenValidation()) {
      const code = digit1.concat(digit2, digit3, digit4)
      const finalData = {
        code: code
      }

      tokenVerification(finalData)
    }
  }

  const tokenVerification = async data => {
    try {
      const response = await axios.post(
        "/manage-users/code_verification/",
        data
      )
      if (response.status === 200) {
        setScreen(3)
        showToast("otp verification successfully", "success")
      }
    } catch (e) {
      showToast("Please enter valid otp", "error")
    }
  }

  const submitValidation = () => {
    const { password, confirmPassword } = formData
    let formErrors = {}
    let formIsValid = true
    if (!password) {
      formIsValid = false
      formErrors["password"] = "Password field may not be blank."
    }
    if (!confirmPassword) {
      formIsValid = false
      formErrors["confirmPassword"] = "Confirm Password field may not be blank."
    } else if (password !== confirmPassword) {
      formIsValid = false
      formErrors["passwordMatch"] = "The password confirmation does not match."
    }
    setValidation(formErrors)
    return formIsValid
  }

  const submitHandler = e => {
    e.preventDefault()
    const { password, digit1, digit2, digit3, digit4 } = formData
    if (submitValidation()) {
      const codeValue = digit1.concat(digit2, digit3, digit4)
      const finalData = {
        code: code || codeValue,
        password: password
      }
      updatePassword(finalData)
    }
  }

  const updatePassword = async data => {
    try {
      const response = await axios.post("/manage-users/update_password/", data)
      if (response.status === 200) {
        showToast("Password reset successfully!", "success")
        history.push("/login")
      }
    } catch (e) {
      showToast("Password could not reset, please try again later", "error")
    }
  }

  const maxLengthHandler = e => {
    if (e.target.value.length > 1) {
      e.target.value = e.target.value.slice(0, 1)
    }
  }
  const inputfocus = elmnt => {
    if (
      elmnt.key === "Delete" ||
      elmnt.key === "Backspace" ||
      elmnt.key === "ArrowLeft"
    ) {
      const next = elmnt.target.tabIndex - 2
      if (next > -1) {
        elmnt.target.form.elements[next].focus()
      }
    } else {
      const next = elmnt.target.tabIndex
      if (next < 4 && elmnt.target.value.length !== 0) {
        elmnt.target.form.elements[next].focus()
      }
    }
  }
  return (
    <div>
      {screen === 1 && (
        <div className={classes.MainContainer}>
          <div className={classes.Overlay}>
            <div className={classes.LoginContainer}>
              <div className={classes.LoginFormContainer}>
                <form
                  onSubmit={forgetPasswordHandler}
                  className={classes.Inner}
                >
                  <div className={classes.FormLogo}>
                    <div className={classes.LogoWrapper}>
                      <img src={Logo} className={classes.Logo} />
                    </div>
                  </div>
                  <h3 className={classes.MainHeading}>Forget Password</h3>
                  <span className={classes.SubHeading}>
                    Enter your email for varification process. we will send the
                    token code in your email.{" "}
                  </span>
                  <div className={classes.InputWrapper}>
                    <Input
                      label="Email"
                      type="text"
                      value={userEmail}
                      onChange={e => setUserEmail(e.target.value)}
                      // isRequired={true}
                    />
                    <span style={{ color: "red", padding: "10px 30px" }}>
                      {validation?.email} {formError}
                    </span>
                    {/* <div>
                  <p style={{ color: 'red', textAlign: 'center' }}>{
                    invalid ? 'Invalid Email or Password' : ''
                  }</p>
                </div> */}
                  </div>
                  <div className={classes.BtnWrapper}>
                    <Button
                      variant="contained"
                      type="submit"
                      style={{ height: "50px" }}
                      className={classes.ContinueBtn}
                      //disabled="false"
                    >
                      Continue
                    </Button>
                  </div>

                  <div className={classes.Footer}>
                    <div className={classes.FooterWrapper}>
                      <span className={classes.HorizontalLine}></span>
                      <p className={classes.FooterText}>Terms & Conditions</p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      {screen === 2 && (
        <div className={tokenClasses.MainContainer}>
          <div className={tokenClasses.Overlay}>
            <div className={tokenClasses.LoginContainer}>
              <div className={tokenClasses.LoginFormContainer}>
                <form
                  onSubmit={tokenInputHandler}
                  className={tokenClasses.Inner}
                >
                  <div className={tokenClasses.FormLogo}>
                    <div className={tokenClasses.LogoWrapper}>
                      <img src={Logo} className={tokenClasses.Logo} />
                    </div>
                  </div>
                  <h3 className={tokenClasses.MainHeading}>
                    Enter 4 Digit Code
                  </h3>
                  <span className={tokenClasses.SubHeading}>
                    Enter 4 digit code that you received on your email{" "}
                  </span>
                  <div className={tokenClasses.InputWrapper}>
                    {/* <Input
                  label=""
                  type="Email"
                  value=''
                  // onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
                  isRequired={true}
                /> */}

                    <input
                      type="number"
                      name="digit1"
                      className={tokenClasses.InputBox}
                      value={formData.digit1}
                      onChange={e =>
                        setFormData({ ...formData, digit1: e.target.value })
                      }
                      tabIndex={1}
                      ref={firstInput}
                      //required
                      onKeyUp={inputfocus}
                      onInput={maxLengthHandler}
                    />
                    <input
                      type="number"
                      name="digit2"
                      className={tokenClasses.InputBox}
                      value={formData.digit2}
                      onChange={e =>
                        setFormData({ ...formData, digit2: e.target.value })
                      }
                      tabIndex={2}
                      onKeyUp={inputfocus}
                      onInput={maxLengthHandler}
                      //required
                    />
                    <input
                      type="number"
                      name="digit3"
                      className={tokenClasses.InputBox}
                      value={formData.digit3}
                      onChange={e =>
                        setFormData({ ...formData, digit3: e.target.value })
                      }
                      tabIndex={3}
                      onKeyUp={inputfocus}
                      onInput={maxLengthHandler}
                      //required
                    />
                    <input
                      type="number"
                      name="digit4"
                      className={tokenClasses.InputBox}
                      value={formData.digit4}
                      onChange={e =>
                        setFormData({ ...formData, digit4: e.target.value })
                      }
                      tabIndex={4}
                      onKeyUp={inputfocus}
                      onInput={maxLengthHandler}
                      //required
                    />

                    {/* <div>
                  <p style={{ color: 'red', textAlign: 'center' }}>{
                    invalid ? 'Invalid Email or Password' : ''
                  }</p>
                </div> */}
                  </div>
                  <p
                    style={{
                      color: "red",
                      padding: "5px 30px",
                      textAlign: "center"
                    }}
                  >
                    {validation?.token}
                  </p>
                  <div className={tokenClasses.BtnWrapper}>
                    <Button
                      variant="contained"
                      type="submit"
                      style={{ height: "50px" }}
                      className={tokenClasses.ContinueBtn}
                      //disabled="false"
                    >
                      Continue
                      {/* {
                    'loading' ?
                      'Continue' :
                      (<div className={tokenClasses.root}>
                        <CircularProgress style={{ color: 'white' }} />
                      </div>)
                  } */}
                    </Button>
                  </div>

                  <div className={tokenClasses.Footer}>
                    <div className={tokenClasses.FooterWrapper}>
                      <span className={tokenClasses.HorizontalLine}></span>
                      <p className={tokenClasses.FooterText}>
                        Terms & Conditions
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      {screen === 3 && (
        <div className={resetClasses.MainContainer}>
          <div className={resetClasses.Overlay}>
            <div className={resetClasses.LoginContainer}>
              <div className={resetClasses.LoginFormContainer}>
                <form onSubmit={submitHandler}>
                  <div className={resetClasses.FormLogo}>
                    <div className={resetClasses.LogoWrapper}>
                      <img src={Logo} className={resetClasses.Logo} />
                    </div>
                  </div>
                  <h3 className={resetClasses.MainHeading}>Reset Password</h3>
                  <span className={resetClasses.SubHeading}>
                    Set the new password for your account. So you can login and
                    enjoy all the features{" "}
                  </span>
                  <div className={resetClasses.InputWrapper}>
                    <Input
                      label="New password"
                      type="password"
                      value={formData.password}
                      onChange={e =>
                        setFormData({
                          ...formData,
                          password: e.target.value
                        })
                      }
                      // isRequired={true}
                    />
                    <span style={{ color: "red", padding: "10px 45px" }}>
                      {validation?.password}
                    </span>
                    <Input
                      label="Confirm new password"
                      type="password"
                      value={formData.confirmPassword}
                      onChange={e =>
                        setFormData({
                          ...formData,
                          confirmPassword: e.target.value
                        })
                      }
                      // isRequired={true}
                    />
                    <span style={{ color: "red", padding: "10px 45px" }}>
                      {validation?.confirmPassword} {validation?.passwordMatch}
                    </span>
                    {/* <div>
                      <p style={{ color: "red", textAlign: "center" }}>
                        {invalid
                          ? "The password confirmation does not match"
                          : ""}
                      </p>
                    </div> */}
                  </div>
                  <div className={resetClasses.BtnWrapper}>
                    <Button
                      variant="contained"
                      type="submit"
                      style={{ height: "50px", marginBottom: "20px" }}
                      className={resetClasses.SubmitBtn}
                    >
                      Submit
                      {/* {
                    'loading' ?
                      'Submit' :
                      (<div className={resetClasses.root}>
                        <CircularProgress style={{ color: 'white' }} />
                      </div>)
                  } */}
                    </Button>
                  </div>
                  <div className={resetClasses.BtnWrapper}>
                    <Button
                      style={{ height: "50px" }}
                      className={resetClasses.CancelBtn}
                    >
                      {"loading" ? (
                        "Cancel"
                      ) : (
                        <div className={resetClasses.root}>
                          <CircularProgress style={{ color: "white" }} />
                        </div>
                      )}
                    </Button>
                  </div>

                  <div className={resetClasses.Footer}>
                    <div className={resetClasses.FooterWrapper}>
                      <span className={resetClasses.HorizontalLine}></span>
                      <p className={resetClasses.FooterText}>
                        Terms & Conditions
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ForgetPassword
