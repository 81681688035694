import { useState, createContext, useContext } from "react"

const ToastContext = createContext()

export function ToastProvider({ children }) {
  const toast = useToastProvider()
  return <ToastContext.Provider value={toast}>{children}</ToastContext.Provider>
}

const useToastProvider = () => {
  const [toastsList, setToastsList] = useState([])

  const showToast = (message, type) => {
    const randomId = "id" + Math.random().toString(16).slice(2)
    setToastsList(prev => [...prev, { message, type, id: randomId }])
  }

  const closeToast = id => {
    const index = toastsList.findIndex(e => e.id === id)
    toastsList.splice(index, 1)
    setToastsList([...toastsList])
  }

  return {
    toastsList,
    showToast,
    closeToast
  }
}
const useToast = () => useContext(ToastContext)

export default useToast
