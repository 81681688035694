import React, { useState, useEffect } from "react"
import CssBaseline from "@material-ui/core/CssBaseline"
import Drawer from "@material-ui/core/Drawer"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import clsx from "clsx"
import { useStyles } from "./LeftDrawerStyle"
import AMSLogo from "../../assets/AMSLogo.png"
import { Divider } from "@material-ui/core"
import Ellipse1 from "../../assets/Ellipse1.png"
import Dropdownicon from "../../assets/Dropdownicon.png"
import {
  adminDataArray,
  courtDataArray,
  userDataArray,
  operatorDataArray
} from "./LeftDrawerArray"
import { useHistory, useLocation } from "react-router"
import { connect, useDispatch, useSelector } from "react-redux"
import { auth } from "../../Store/Actions/Auth"
import Actions from "../../Store/Type"
import useAuth from "../../utils/useAuth"
import { Link, NavLink } from "react-router-dom"
import { GetUser, SetCurrentPage, SetUserType } from "../../Store/Actions/ManageUserAction"
import {GetAllCourtData, GetCourt} from "../../Store/Actions/Court";

const LeftDrawer = ({ children, redirectTo }) => {
  const classes = useStyles()
  const [Active, setActive] = useState(1)
  const [path, setPath] = useState("")
  const [showLogout, setShowLogout] = useState(false)
  const { user, loginSuccess, userProfile } = useSelector(({ auth }) => auth)
  const { current_user_type , current_page, addUser, editUser, clientDetail } = useSelector(({ manageUser }) => manageUser)
  const { isAdmin, isCourtAdmin, isOperator,isIndividualUser, redirectRoute, isAuthenticated } = useAuth()
  const [userTypeLabel, setUserTypeLabel] = useState()
  const [dataArray, setDataArray] = useState([])

  const History = useHistory()
  const Location = useLocation()
  const dispatch = useDispatch()
  useEffect(() => {
    if (isAdmin) return setDataArray(adminDataArray), setUserTypeLabel("Admin")
    else if (isCourtAdmin)
      return setDataArray(courtDataArray), setUserTypeLabel("Court Admin")
    else if (isOperator)
      return setDataArray(operatorDataArray), setUserTypeLabel("Operator")
    else return setDataArray(userDataArray), setUserTypeLabel("Client")
  }, [])

  useEffect(() => {
    if(History && loginSuccess) {
      History.push(redirectRoute);
    }
  }, [loginSuccess])

  useEffect(() => {
    setPath(Location.pathname)
  }, [Location])

  const logout = () => {
    dispatch({
      type: Actions.LOGOUT
    })
    window.location.reload();
  }
  const logoutHandler = () => {
    setShowLogout(prev => !prev)
  }
  const { image } = userProfile?.profile || {};
  
const profilePath="/user-profile"
  const replacePath=()=>{
      const updatedData=[...dataArray]
      const findData=updatedData.findIndex((elements)=>elements.id===6)
      if(findData!==-1){
          if(current_user_type===1){
              updatedData[findData].path="/manage-users/clients/page=1"
          }
          if(current_user_type===2){
              updatedData[findData].path="/manage-users/clients/page=1"
          }
          if(current_user_type===0){
              updatedData[findData].path="/manage-users/clients/page=1"
          }
      }
      return updatedData
  }
  
  return (
      <div className={classes.root}>
        <CssBaseline />
        <div className={classes.sticky}>
          <Drawer
              variant="permanent"
              classes={{
                paper: classes.drawerPaper
              }}
          > 
          <div className={classes.parentDiv}>

            <div className={classes.DrawerHeader}>
              <div className={classes.LogoWrapper}>
                <img src={AMSLogo} className={classes.Logo} />
                <h3 className={classes.mainHeading}>Ohio AMS</h3>
              </div>
            </div>
            <Divider />

            <div className={Location.pathname===profilePath?classes.ProfileContainerActive:classes.ProfileContainer}>
              <span
                className={classes.ActiveBorderProfile}
                style={
                  Location.pathname === profilePath
                    ? { display: "block" }
                    : { display: "none" }
                }
              > </span>
              <div className={classes.ProfileWrapper}>
                <NavLink to="/user-profile" style={{height:"34px",width:"34px",marginRight:"15px"}}>
                  <img src={image===null? Ellipse1: image} className={classes.ProfileAvatar}   />
                </NavLink>
                <div className={classes.testWrapper} >
                  <div className={classes.NameWrapper}>
                    <div className={classes.userdetail}>
                      <div className={classes.username}>
                        {userProfile?.first_name!==undefined &&
                          <span className={[classes.Name,classes.StyledFont].join(" ")}>{userProfile?.first_name+" "+userProfile?.last_name}</span>
                        }

                      </div>
                      <div className={classes.usernameLabel}>
                        <p className={classes.Admin}><span className={classes.styledFont1}>{userTypeLabel}</span></p>
                      </div>
                    </div>
                    <div className={classes.arrowDropdown}>
                 <span onClick={logoutHandler}>
                   <img src={Dropdownicon} className={classes.arrowDown}  />
                 </span>
                    </div>
                  </div>
                  <div>
                  </div>
                </div>
              </div>
            </div>
            {showLogout && (
              <p className={classes.logoutBtn} onClick={logout}>
                LogOut
              </p>
            )}
            {replacePath()?.map((i,index) => {
              return (
                  <div key={i.id}>
                    <ListItem
                        className={clsx(
                            classes.ListWrapper,
                            Location.pathname.includes(i.path)
                                ? classes.Active
                                : classes.InActive
                        )}
                        onClick={() => {
                          History.push(`${i.path}`)
                          setActive(i.path)
                          if(i.path==="/courts")
                          {
                                dispatch(GetAllCourtData())
                          }
                          if(i.path==="/manage-users/admins/page=1" || i.path==="/manage-users/clients/page=1")
                          {
                            if((addUser===true || editUser===true || clientDetail===true) && current_user_type!==0)
                            dispatch(GetUser({limit: 10,offset: 10 * current_page - 10,user_type:current_user_type}))
                          }
                          else{
                            if(current_user_type!==0)
                            dispatch(SetUserType())
                            if(current_page!==1)
                            dispatch(SetCurrentPage(1))
                          }
                        }}
                    >
                <span
                    className={classes.ActiveBorder}
                    style={
                      Location.pathname === i.path
                          ? { display: "block" }
                          : { display: "none" }
                    }
                ></span>
                      <ListItemIcon className={classes.IconWrapper}>
                        <img src={i.icon} className={classes.icon} />
                      </ListItemIcon>
                      <ListItemText
                          className={clsx(
                              classes.ListName,
                              // Location.pathname === i.path
                            Location.pathname.includes(i.path)
                                  ? classes.ActiveList
                                  : classes.InActiveList
                          )}
                      >
                        <span className={classes.StyledFont}>{i.Name}</span>
                      </ListItemText>
                    </ListItem>
                  </div>
              )
            })}
        </div>
          </Drawer>
        </div>
        <main className={classes.content}>{children}</main>

      </div>
  )
}

const mapStateToProps = state => {
  return {
    redirectTo: state.auth.redirectTo
  }
}

export default connect(mapStateToProps, { auth })(LeftDrawer)
