import React, { useState, useEffect } from "react"
import { useStyles } from "./CourtsDataTableStyle"
import { useStyle } from "../AddCourtDialog/AddCourtDialogStyle"
import { connect, useDispatch, useSelector } from "react-redux"
import { auth } from "../../Store/Actions/Auth"
import axios from "../../Store/request"
import ConfirmModal from "../ConfirmModal/ConfirmModal"
import { Button, Dialog } from "@material-ui/core"
import useToast from "../../Components/Toast/useToast"
import {GetAllCourtData, GetTerritoryData} from "../../Store/Actions/Court";
import CircularSpinner from "../CircularProgressUserReport/CircularSpinner";
import NewMoreDropDown from "../NewMoreDropDown/NewMoreDropDown"
import Logo from "../../assets/AMSLogo.png"
import SearchableDropDown from "../SearchableDropdown/SearchableDropDown"
import {GetStateData} from "../../Store/Actions/Court";
import CourtInvoice from "./CourtInvoice";

const BasicTable = ({ DataArray, isDisabled, setIsDisabled,stateList,invoiceList,setInvoiceList }) => {
  const classes = useStyles()
  const newClasses = useStyle()
  const state = useSelector(state=>state);
  const { showToast } = useToast()
  const [triedFetching,setTriedFetching] = useState(false)
  const [currentData, setCurrentData] = useState({})
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [openViewModal, setOpenViewModal] = useState(false)
  const [arrayOfData, setArrayOfData] = useState([])
  const [openEditModal, setOpenEditModal] = useState(false)
  const [creditLoading, setCreditLoading] = useState(false);
  const [validation, setValidation] = useState({})

  


  const normalizeInput = (value, previousValue) => {
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {

      if (cvLength < 5) return currentValue;

      return `${currentValue.slice(0, 5)}`;
    }
    if (!value) return value;
  };


  const dispatch = useDispatch()
  const { data, count, dataExist } = useSelector(({ court }) => court)
  useEffect(() => {
    setTriedFetching(true)
    if (data.length > 0 && data.length === count) return;
    if (dataExist){
      dispatch(GetAllCourtData())}
  }, [])

  useEffect(() => {
    setInvoiceList(false)
    }
  ,[data])

  const deleteCourt = () => {
    setCreditLoading(true)
    axios
      .delete(`/api/v1/courts/${currentData.id}/`)
      .then(function (response) {
        setOpenConfirmModal(false)
        setCreditLoading(false)
        showToast(" Archive Court Succesfully", "success")
        dispatch(GetAllCourtData())
      })
      .catch(function (error) {
        setOpenConfirmModal(false)
        setCreditLoading(false)
        showToast("Unable to Archive court", "error")
      })
  }
  const archiveCourt = () => {
    setCreditLoading(true)
    axios
      .post(`api/v1/courts/update_archive_courts/?id=${currentData.id}`)
      .then(function (response) {
        setOpenConfirmModal(false)
        setCreditLoading(false)
       
        showToast("Activate Court Succesfully", "success")
       
        dispatch(GetAllCourtData())
      })
      .catch(function (error) {
        setOpenConfirmModal(false)
        setCreditLoading(false)
        showToast("Unable to archive court, please try again later", "error")
      })
  }
  const EditCourt = e => {
    e.preventDefault()

    let trueData = currentData
    const existedData = data.find(ele => ele.name === currentData.name && ele.id !== currentData.id)
    if (existedData !== undefined) {
      const formValidation = {}
      formValidation["name"] = "*Court name already exist"
      if (("" + currentData.postal_code).length !== 5) {
        formValidation["postal_code"] = "*Insert Valid ZIP Code"
      }
      setValidation(formValidation)

      return;
    }
    if (("" + currentData.postal_code).length !== 5) {
      const formValidation = {}
      formValidation["postal_code"] = "*Insert Valid ZIP Code"
      setValidation(formValidation)
      return;
    }
    if (currentData.address_2 === "" || currentData.address_2 === null) {

      trueData = { ...currentData, address_2: "-" }
    }
    let dataPassed = JSON.stringify(trueData)
    setIsDisabled(true)
    setCreditLoading(true)
    axios
      .put(`/api/v1/courts/${currentData.id}/`, dataPassed)
      .then(function (response) {
        setOpenEditModal(false)
        setCurrentData({})
        setCreditLoading(false)
        showToast("Court Edited Succesfully", "success")
        dispatch(GetAllCourtData())
        setIsDisabled(false)
      })
      .catch(function (error) {
        setCreditLoading(false)
        setIsDisabled(false)
        if (error.response) {

          showToast(
            `${error.response.data.service?.non_field_errors}`,
            "error"
          )
        }
        else {
          showToast("Unable to edit, please try again later", "error")
        }
      })
  }


  const TextClipper = (bio, length) => {
    let vb = ""
    if (bio?.length >= length) {
      for (var i = 0; i < length; i++) {
        vb += bio.charAt(i)
      }
      bio = `${vb}...`

    }

    return bio
  } 

  const normalizePhoneInput = (value, previousValue) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {

      if (cvLength < 4) return currentValue;

      if (cvLength < 7) return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;

      return `${currentValue.slice(0, 3)}-${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    }
  };

  const handlePhNoChange = (event) => {
    const { name, value } = event.target;
    setCurrentData(prevState => ({ ...prevState, ['phone_number']: normalizePhoneInput(value, prevState['phone_number']) }));
  };

  const inputEvent = (event) => {
    const { name, value } = event.target;
    setCurrentData((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      }
    })
  }

  const { territoryData:TerritoryData} = useSelector(({ court }) => court)
  const getTerritory=async(val)=>{
    dispatch(GetTerritoryData(val))
  }

  const territoryList=TerritoryData?.map((element)=>{
    return ({value:element.id,label:element.name})
  })
  const [invoiceData,setInvoiceData]=useState()
  const invoice=async(i)=>{
    const response=await axios.get(`/api/v1/courts/court_invoice/?court=${i.id}`)
    setInvoiceData(response.data)
    setTriedFetching(true)
    setInvoiceList(true)
  }

  const onBack = () => {
    setInvoiceList(false);
    setInvoiceData([])
  }
  const getMoreOptions = (i) => {
    if(state.auth.user.user_type === 4){
      return [{actionFunction:() => {
        setArrayOfData([
          {
            title: "Court Name",
            val: i.name
          },
          {
            title: "City",
            val: i.city
          },
          {
            title: "Address 1",
            val: i.address_1
          },
          {
            title: "Address 2",
            val: i.address_2
          },
          {
            title: "Phone Number",
            val: i.phone_number
          },
          {
            title: "Territory",
            val: i.territory?.name
          },
          {
            title: "State",
            val: i.state?.name
          },
          {
            title: "ZIP Code",
            val: i.postal_code
          }
        ])
        setCurrentData(i)
        setOpenViewModal(true)
      },
      icon:"Visibility",
      name:"See details"
    },]
    }else{
      const buttons = [
        {actionFunction:async () => {
            dispatch(GetStateData())
            getTerritory(i.state?.id)
            setCurrentData({...i,state:i.state?.id,territory:i.territory?.id})
            // setCurrentData(i)
            setOpenEditModal(true)
          },
          icon:"Edit",
          name:"Edit"
        },
        {actionFunction:() => {
            setArrayOfData([
              {
                title: "Court Name",
                val: i.name
              },
              {
                title: "City",
                val: i.city
              },
              {
                title: "Address 1",
                val: i.address_1
              },
              {
                title: "Address 2",
                val: i.address_2
              },
              {
                title: "Phone Number",
                val: i.phone_number
              },
              {
                title: "Territory",
                val: i.territory?.name
              },
              {
                title: "State",
                val: i.state?.name
              },
              {
                title: "ZIP Code",
                val: i.postal_code
              }
            ])
            setCurrentData(i)
            setOpenViewModal(true)
          },
          icon:"Visibility",
          name:"See details"
        },
        {actionFunction:async() => {
            setCurrentData(i)
            invoice(i)
          },
          icon:"Invoice",
          name:"Invoices"
        },
    ];
    if(i?.is_active) {
      return[
        ...buttons,
        {actionFunction:() => {
            setCurrentData(i)
            setOpenConfirmModal(true)
          },
          icon:"Archive",
          name:"Archive"
        },
      ]
    }
    else
    {
      return [
        ...buttons,
        {actionFunction:() => {
            setCurrentData(i)
            setOpenConfirmModal(true)
          },
          icon:"Activate",
          name:"Activate"
        },
      ]
    
    } 
    }
  };
  return (
    <div className={classes.Container}
         // style={{ minHeight: "650px" }}
    >
      {
        invoiceList===true?
          <CourtInvoice DataArray={invoiceData}
                        triedFetching={triedFetching}
                        onBack={onBack}
                        currentData={currentData}
          />
          :
        !!DataArray.length ? <>
        <div className={classes.TableHeading}>
        <div className={classes.HeadingInner}>
        <p className={classes.Heading1}>Court name</p>
        <p> </p>
        <p className={classes.Heading}>Address 1</p>
        <p className={classes.Heading}>Address 2</p>
        <p className={classes.Heading}>City</p>
        <p className={classes.Heading}> State</p>
        <p className={classes.Heading}> ZIP Code </p>
        <p className={classes.Heading}>Territory</p>
         <p className={classes.Heading}>Status</p>
        <p> </p>
        </div>
        </div>
        {DataArray.map(i => {
          return (
            <div className={classes.MainContainer} key={i.id}>
              <div className={classes.ContainerInner}>
                <p className={classes.ListItmes}>{i.name}</p>
                <p className={classes.ListItmes}>
                  {TextClipper(i.address_1, 15)}
                </p>
                <p className={classes.ListItmes}>
                  {TextClipper(i.address_2, 15)}
                </p>
                <p className={classes.ListItmes}>{TextClipper(i.city, 15)}</p>
                <p className={classes.ListItmes}>{i.state===null?".":  TextClipper(i.state?.name, 15)}</p>
                <p className={classes.ListItmes}>
                  {TextClipper(i.postal_code, 15)}
                </p>
                <p className={classes.ListItmes}>{i.territory===null?"." : TextClipper(i.territory?.name, 15)}</p>
                <p className={classes.ListItmes}>{i.is_active===true?"Active":"Inactive"}</p>
                <NewMoreDropDown
                  MoreDropDownItem={getMoreOptions(i)}
                />

              </div>
            </div>
          )
        })}
        </> : <p className={classes.error} style={{visibility:!triedFetching?"hidden":"visible"}}>No Data Found</p>
      }
      {
        currentData.is_active ?
          <ConfirmModal
            signal={openConfirmModal}
            handleyes={deleteCourt}
            handleno={() => setOpenConfirmModal(false)}
            msg={`Are you sure you want to archive court "${currentData.name}"?, this will also archive any officer or pricing associated with it`}
            loading={creditLoading}
          />
          :
          <ConfirmModal
            signal={openConfirmModal}
            handleyes={archiveCourt}
            handleno={() => setOpenConfirmModal(false)}
            msg={`Are you sure you want to activate court "${currentData.name}"?, this will also activate any officer or pricing associated with it`}
            loading={creditLoading}
          />
      }
    

      <Dialog
        open={openViewModal}
        scroll="body"
        onClose={() => {
          setOpenViewModal(false)
        }}
        PaperProps={{
          style: {
            overflow: "unset",
            margin:40
          }
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={newClasses.topDiv}>
          <div className={newClasses.LogoWrapper}>
            <img src={Logo} className={newClasses.Logo} alt="Logo"/>
          </div>
          <form className={newClasses.upperDiv}>
            <div style={{ cursor: "pointer" }} onClick={() => { setOpenViewModal(false) }}>
              <i
                className="fal fa-times fa-lg"
                style={{ position: "absolute", top: "3%", right: "4%" }}
              />
            </div>

            <div className={newClasses.firstDiv}>
              <p className={newClasses.tagStyle}>COURT NAME</p>
              <input
                type="text"
                placeholder="Type..."
                name="name"
                className={newClasses.inputCont}
                required
                value={currentData.name}
                readOnly
              />
            </div>

            <div className={newClasses.firstDiv}>
              <p className={newClasses.tagStyle}>ADDRESS 1</p>
              <input
                type="text"
                name="address_1"
                placeholder="Type..."
                className={newClasses.selectCont}
                value={currentData.address_1}
                readOnly
              />
            </div>
            <div className={newClasses.firstDiv}>
              <p className={newClasses.tagStyle}>ADDRESS 2</p>
              <input
                type="text"
                name="address_2"
                placeholder="Type...(optional)"
                className={newClasses.selectCont}
                value={currentData.address_2}
                readOnly
              />
            </div>
            <div className={newClasses.firstDiv}>
              <p className={newClasses.tagStyle}>Phone Number</p>
              <input
                type="text"
                name="phone_number"
                placeholder="XXX-XXX-XXXX"
                className={newClasses.selectCont}
                value={currentData.phone_number}
                readOnly
              />
            </div>
            <div className={newClasses.bottomCont}>
              <div className={newClasses.bottomContLeft}>
                <p className={newClasses.tagStyle}>CITY</p>
                <input
                  type="text"
                  name="city"
                  placeholder="Type..."
                  className={newClasses.inputCont}
                  value={currentData.city}
                  readOnly
                />
              </div>
              <div className={newClasses.bottomContRight}>
                <p className={newClasses.tagStyle}>STATE</p>
                <input
                  type="text"
                  name="state"
                  placeholder="State"
                  className={newClasses.inputCont}
                  value={currentData.state?.name}
                  readOnly
                />
              </div>
            </div>

            <div className={newClasses.bottomCont}>
              <div className={newClasses.bottomContLeft}>
                <p className={newClasses.tagStyle}>TERRITORY</p>
                <input
                  type="text"
                  placeholder="territory"
                  className={newClasses.inputCont}
                  value={currentData.territory?.name}
                  readOnly
                />
              </div>
              <div className={newClasses.bottomContRight}>
                <p className={newClasses.tagStyle}>ZIP CODE</p>
                <input
                  type="text"
                  name="postal_code"
                  placeholder="Type..."
                  className={newClasses.inputCont}
                  value={currentData.postal_code}
                  readOnly
                />
              </div>
            </div>
            <Button
              variant="contained"
              color="secondary"
              className={newClasses.button}
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                setOpenViewModal(false)
              }}
            >
              {"OK"}
            </Button>
          </form>
        </div>
      </Dialog>

      <Dialog
        open={openEditModal}
        scroll="body"
        onClose={() => {
          setOpenEditModal(false)
          setIsDisabled(false)
          setValidation({})
        }}
        PaperProps={{
          style: {
            overflow: "unset",
            margin:40
          }
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {creditLoading === true ?
          <CircularSpinner onModal/>
          : (
            <div className={newClasses.topDiv}>
              <div className={newClasses.LogoWrapper}>
                <img src={Logo} className={newClasses.Logo} alt="Logo"/>
              </div>
              <p
                style={{
                  position: "absolute",
                  top: "3.1%",
                  left: "40%",
                  fontSize: "20px",
                  fontWeight: "700"
                }}
              >
                Edit Court
              </p>
              <form onSubmit={EditCourt} className={newClasses.upperDiv} style={{marginTop:"20px"}}>
                <div style={{ cursor: "pointer" }} onClick={() => { setOpenEditModal(false); setValidation({}) }}>
                  <i
                    className="fal fa-times fa-lg"
                    style={{ position: "absolute", top: "3%", right: "4%" }}
                  />
                </div>

                <div className={newClasses.firstDiv}>
                  <p className={newClasses.tagStyle}>COURT NAME</p>
                  <input
                    type="text"
                    placeholder="name"
                    name="name"
                    className={newClasses.inputCont}
                    value={currentData.name}
                    onChange={inputEvent}
                  />
                  <span style={{ color: "red" }}>
                    {validation.name}
                  </span>
                </div>

                <div className={newClasses.firstDiv}>
                  <p className={newClasses.tagStyle}>ADDRESS 1</p>
                  <input
                    type="text"
                    name="address_1"
                    placeholder="Type..."
                    className={newClasses.selectCont}
                    value={currentData.address_1}
                    onChange={(e) => {
                      inputEvent(e);
                    }}
                    required
                  />
                </div>
                <div className={newClasses.firstDiv}>
                  <p className={newClasses.tagStyle}>ADDRESS 2</p>
                  <input
                    type="text"
                    name="address_2"
                    placeholder="Type...(optional)"
                    className={newClasses.selectCont}
                    value={currentData.address_2}
                    onChange={inputEvent}
                  />
                </div>
                <div className={newClasses.firstDiv}>
                  <p className={newClasses.tagStyle}>Phone Number</p>
                  <input
                    type="text"
                    name="phone_number"
                    placeholder="XXX-XXX-XXXX"
                    className={newClasses.selectCont}
                    value={currentData.phone_number}
                    onChange={handlePhNoChange}
                  />
                </div>
                <div className={newClasses.bottomCont}>
                  <div className={newClasses.bottomContLeft}>
                    <p className={newClasses.tagStyle}>CITY</p>
                    <input
                      type="text"
                      name="city"
                      placeholder="city"
                      className={newClasses.inputCont}
                      required
                      value={currentData.city}
                      onChange={inputEvent}
                    />
                  </div>
                  <div className={newClasses.bottomContRightState}>
                    <p className={newClasses.tagStyle}>STATE</p>
                    <SearchableDropDown
                        data={stateList}
                        handleClick={val => {
                          setCurrentData({...currentData, state: val, territory: null})
                          getTerritory(val)
                        }
                        }
                        selectedValue={currentData?.state}
                    />

                  </div>
                </div>

                <div className={newClasses.bottomCont}>
                  <div className={newClasses.bottomContRightState}>
                    <p className={newClasses.tagStyle}>TERRITORY</p>

                    <SearchableDropDown
                      data={territoryList}
                      handleClick={val =>{
                        setCurrentData({ ...currentData, territory: val })}

                      }
                      selectedValue={currentData?.territory?currentData?.territory:""}
                    />
                  </div>
                  <div className={newClasses.bottomContRight}>
                    <p className={newClasses.tagStyle}>ZIP CODE</p>
                    <input
                      type="number"
                      name="postal_code"
                      placeholder="ZIP code"
                      className={newClasses.inputCont}
                      value={currentData.postal_code}
                      onChange={(e) => {
                        setCurrentData(prevalue => ({ ...prevalue, postal_code: normalizeInput(e.target.value, prevalue["postal_code"]) }))
                      }}
                      required
                    />
                    <span style={{ color: "red" }}>
                      {validation.postal_code}
                    </span>
                  </div>
                </div>
                {isDisabled === false ?
                  <Button
                    variant="contained"
                    color="secondary"
                    className={newClasses.button}
                    disabled={
                      currentData.postal_code === "" ||
                      currentData.address_1 === "" ||
                      currentData.city === "" ||
                      currentData.state === "" ||
                      currentData.name === "" ||
                      currentData.territory === "" ||
                      currentData.territory === null
                    }
                    type="submit"
                  >
                    {"EDIT COURT"}
                  </Button>
                  : <Button
                    variant="contained"
                    color="secondary"
                    className={newClasses.button}
                    disabled
                    type="submit"
                  >
                    {"EDIT COURT"}
                  </Button>

                }
              </form>
            </div>
          )
        }

      </Dialog>


    </div>
  )
}

const mapStateToProps = state => {
  return {
    accessToken: state.auth.accessToken
  }
}

export default connect(mapStateToProps, { auth })(BasicTable)
