import { makeStyles } from "@material-ui/core/styles";


export const useStyles = makeStyles((theme) => ({
  MainContainer: {
    width: "100%",
    height: 97,
    background: "#FFFFFF",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
    display:'flex',
    flexDirection:'column',
    justifyContent:'center'
  },
  
  selection: {
    width: '250px',
    // height:'30px',
    borderRadius: '5px',
    paddingLeft: '7px',
    color: 'rgb(70,70,250)',
    // border: '1px solid lightgray',
    marginLeft:'10px' ,
    // marginBottom:"14px",
    fontFamily:"Open Sans",
    fontWeight:600,
    fontSize:"16px",
    ".css-1qauysc-control":
      {
        border: '1px solid lightgray',
      }
  
  },
HeaderInner: {
  padding: "2px 10px",
    display: "flex",
      alignItems: "center",
        justifyContent: "space-between"
},

IconWrapper: {
  borderRadius: 10,
    display: "flex",
      justifyContent: "center",
        alignItems: "center",
          background: "#81A4FF",
            margin: "0px 20px",
              width: 47,
                height: 44,
  },
icon: {
  width: 24,
    height: 24
},
MainHeading: {
  color: "#363E51",
    fontFamily: "Open Sans",
      fontSize: theme.fonts.sizes.fontXL,
        fontWeight: 600,
          textTransform: "uppercase"
},
Wrapper: {
  display: "flex",
    alignItems: "center",
  },

  
button: {
  background: "#B5B5B5",
  width: "150px",
    margin: "0px 20px",
      "&.MuiButton-containedSecondary": {
    // width: 180,
      height: 30,
        display: "flex",
          alingItems: "center",
            borderRadius: "5px !important",
              "&:hover": {
      background: "#B5B5B5",
      },
    "& .MuiButton-label": {
      fontFamily: "Open Sans",
      fontSize: 16,
      fontWeight: 700,
            textTransform: "capitalize",
              // marginTop: 3,
      },
    "& .MuiSvgIcon-root": {
      // marginTop: -2
    },
    "&.MuiButton-root":{
      lineHeight:"0 !important",
      letterSpacing: 0
    }
  }
},
newbutton: {
    width:"150px",
  backgroundColor: "#3971FF !important",
    margin: "0px 20px",
      "&.MuiButton-containedSecondary": {
    // width: 180,
      height: 30,
        display: "flex",
          alingItems: "center",
            borderRadius: "5px !important",
              "&:hover": {
      background: "#B5B5B5",
      },
    "& .MuiButton-label": {
      fontFamily: "Open Sans",
      fontSize: 16,
      fontWeight: 700,
            textTransform: "capitalize",
              // marginTop: 3,
      },
    "& .MuiSvgIcon-root": {
      // marginTop: -2
    },
    "&.MuiButton-root":{
      lineHeight:"0 !important",
      letterSpacing: 0
    }
  }
}
}));

export const newStyles = makeStyles((theme) => ({
  InputWithPrefix:{
    display: "flex",
    alignItems: "center",
    // maxWidth: "60%",
    background: "#f9f9f9",
    border: "1px solid lightgray",
    borderRadius: "4px",
    paddingLeft: "0.5rem",
    maxHeight: "44px",
    overflow: "hidden",
    fontFamily: "Open Sans"
  },
  Prefix:{
    fontWeight: 300,
    fontSize: "20px",
    // color: "#999"
  },
  topDiv: {
    height: '400px',
    width: '412px',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'center',
    background: 'none',
    alignItems: 'center',
    boxShadow: 'none'
  }
  ,
  styleTag:{
    fontFamily: 'Open Sans',
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 14,
    textTransform: "uppercase",
    color: "#071A32",
    marginBottom: 0
  },
  upperDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '250px',
    width: '412px',
    alignItems: 'center',
    background: 'white',
    // paddingTop: 15


  },

  firstDiv: {
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'space-around',
    width: '80%',
    paddingBottom: 20,
    marginTop:"20px"
  },
  firstDivService: {
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'space-around',
    width: '80%',
    marginTop:"10px",
    // height:"44px",
    // backgroundColor:"#F9F9F9",
    fontFamily: "Open Sans",
    fontSize:16,


    "& .css-1qauysc-control":{
      height:"44px",
      backgroundColor:"#F9F9F9",
      fontFamily: "Open Sans",
      fontSize:16,

    },
    "& :focus":{
      height:"30px",
      backgroundColor:"#F9F9F9"
    }
  },
  firstDivCourt: {
    display: "flex",
    flexDirection: "column",
    marginTop:"15px",
    justifyContent: "space-around",
    width: "80%",
    fontFamily:"Open Sans",
    fontWeight:600,
    fontSize:16,
    "& .css-1qauysc-control":{
      height:"44px",
      backgroundColor:"#F9F9F9",
      fontFamily:"Open Sans",
      fontWeight:600,
      fontSize:16
    },
    "& :focus":{
      height:"30px",
      backgroundColor:"#F9F9F9"
    },
    "& .css-1okebmr-indicatorSeparator": {
      background:"none"
    },
    "& .css-tlfecz-indicatorContainer": {
      color: "black"
    },
    "& .makeStyles-selectDropDown-269": {
      marginTop: 0
    }
  },
  heading:{
    fontFamily: 'Open Sans',
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 14,
    textTransform: "uppercase",
    color: "#071A32",
    marginBottom: 0
  },
  selectCont: {
    width: '100%',
    height: '44px',
    background: '#F9F9F9',
    color: 'gray',
    borderRadius: '5px',
    // paddingLeft: '5px',
    border: '1px solid lightgray',
    fontFamily: theme.fonts.family.Light,
    fontSize: '16px',
    paddingLeft: '10px'
  },

  bottomCont: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '80%'
  },

  bottomContLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '45%'
  },

  bottomContRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '45%'
  },

  inputCont: {
    width: '100%',
    height: '44px',
    background: '#F9F9F9',
    color: '#333333',
    borderRadius: '5px',
    border: '1px solid lightgray',
    fontFamily: "Open Sans",
    fontSize: '16px',
    paddingLeft: '15px',
    outline:"none"
  },
  inputContPrice: {
    width: '100%',
    height: '44px',
    background: '#F9F9F9',
    color: '#333333',
    borderRadius: '5px',
    border: 'none',
    fontFamily: "Open Sans",
    fontSize: '16px',
    paddingLeft: '5px',
    marginBottom: '2px',
    outline:"none"
  },

  button: {
    background: "#3971FF",
    margin: "0px 20px",
    marginTop: 10,
    "&.MuiButton-containedSecondary": {
      width: '80%',
      height: 44,
      display: "flex",
      alingItems: "center",
      borderRadius: "5px !important",
      "&:hover": {
        background: "#3971FF",
      },
      "& .MuiButton-label": {
        fontFamily: "Open Sans",
        fontSize: 16,
        fontWeight: 700,
        textTransform: "capitalize",
        // marginTop: 3,
      },
      "& .MuiSvgIcon-root": {
        // marginTop: -2
      }
    }
  },

  relativeText: {
    position: 'absolute',
    top: '72.7%',
    left: '20%',
    fontSize: '18px'
  },
  relativeText2: {
    position: 'absolute',
    top: '72.7%',
    right: '43%',
    fontSize: '18px'
  },

  LogoWrapper: {
    backgroundColor: "white",
    borderRadius: 50,
    padding: 18,
    border: "1px solid #FFFFFF",
    // boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.203851)",
    position: "absolute",
    top: -35,
  },
  Logo: {
    width: 34,
    height: 32,
  },
  paper: {
   // maxWidth: "1200px",
   // width: "100%",
    //maxHeight: "775px",
    //height: "90%",
    position: "relative",
    overflowY: "initial",
    margin:40
  },
 
  dropdownWrapper:{
   
    position: "absolute",
    height: 130,
    width: 320,
    top: 226,
    //padding: 5,
    zIndex: 100,
    overflowY:"auto"
  },
  searchDropDown:{
    background: 'rgb(244, 244, 244)',
    cursor: "pointer",
    padding: "5px 10px",
    "&:hover":{
      background: "lightgray"
    }
  },
}))

