import React, { useContext, useEffect, useState } from 'react';
import { useStyles } from './ReportDataTableStyle';
import ReportTable from "./ReportTable";
import {SetReportCurrentPage} from "../../Store/Actions/ReportsAction";
import {useDispatch, useSelector} from "react-redux";

const ReportDataTable = ({
 passedIndex,
 count,
 loading,
 currentPage,
 setCurrentPage,
 result,
 setResult,
 currentDataLength,
 setCurrentDataLength,
 filterGetData,
 isApplyFilter,
 filterParams,
 filterTxt
}) => {
  const [openReportViewModal, setOpenReportViewModal] = useState(false)
  const [payPlanModal, setPayPlanModal] = useState(false)
  const [typeOfPayPlanModal, setTypeOfPayPlanModal] = useState("")
  const [viewReportType, setViewReportType] = useState('openbalance')
  const classes = useStyles();

  useEffect(()=>{
    if(passedIndex[0].selected){
      setViewReportType("openbalance")
    }
    if(passedIndex[1].selected){
      setViewReportType("clientpaycall")
    }
    if(passedIndex[2].selected){
      setViewReportType("clientremoval")
    }
    if(passedIndex[3].selected){
      setViewReportType("salesbyservice")
    }
  },[passedIndex])

  const tableProps = {
    filterGetData,
    isApplyFilter,
    setViewReportType,
    viewReportType,
    setOpenReportViewModal,
    currentPage,
    classes,
    loading,
    count,
    setCurrentPage,
    result,
    setResult,
    currentDataLength,
    setCurrentDataLength,
    filterParams,
    filterTxt
  }

  const renderDataTable=()=>{
    const convertedIndex=[...passedIndex]
    const getIndex=convertedIndex.find((element)=>element.selected).index
    switch (getIndex) {
      case 1:
        return(
          <ReportTable
            {...tableProps}
            setPayPlanModal={setPayPlanModal}
            apiChange="open_balance_report"
            openReportViewModal={openReportViewModal}
          />
        )
      case 2:
        return(
          <ReportTable
            {...tableProps}
            setPayPlanModal={setPayPlanModal}
            setTypeOfPayPlanModal={setTypeOfPayPlanModal}
            apiChange="client_pay_call_report"
            openReportViewModal={openReportViewModal}
            payPlanModal={payPlanModal}
            typeOfPayPlanModal={typeOfPayPlanModal}
          />
        )
      case 3:
        return(
          <ReportTable
            apiChange="client_removal_report"
            openReportViewModal={openReportViewModal}
            {...tableProps}
          />
        )
      case 4:
        return(
          <ReportTable
            apiChange="sales_by_service"
            {...tableProps}
            openReportViewModal={openReportViewModal}
          />
        )
      default:
        return(<></>)
    }
  }

  return (

    <>
      {renderDataTable()}
    </>

  );
}

export default ReportDataTable
