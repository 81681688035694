import React from "react";
import { useStyles, useNewStyles } from "./AddButtonStyle";
import Button from '@material-ui/core/Button';

const AddButton = ({ lable, disabled, backgrondColor }) => {
  const classes = useStyles();
  const specialClasses = useNewStyles();



  return (
    <div className={(lable === " + Add Plan") || (lable === "+ Add user")? specialClasses.buttonContainer: classes.buttonContainer}>
      <Button
        variant="contained"
        color="secondary"
        className={(lable === "+ Create Report") || (lable === "+ Add service") || (lable === "+ Add officer") || (lable === "+ Add court") || (lable === "+ Add Admin") || (lable === "+ Add Operator") || (lable === "+ Add Client")? specialClasses.button: classes.button}
        disabled={disabled}

        
      // startIcon={<AddIcon />}
      >
        {`${lable}`}
      </Button>
    </div>
  )
}

export default AddButton