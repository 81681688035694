import React, {useEffect, useRef, useState} from "react"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import { useStyles } from "./UserProfileDataTableStyle"
import Ellipse1 from "../../assets/Ellipse1.png"
import DataArray from "../UserProfileDataTable/DataArray.js"
import Button from "@material-ui/core/Button";
import {CourtDataArray} from "../UserProfileDataTable/DataArray";
import MaskGroup from "../../assets/Mask Group.svg"
import Loader from "./Loader";
import axios from "../../Store/request";
import useToast from "../Toast/useToast";
import SearchableDropDown from "../SearchableDropdown/SearchableDropDown";
import CourtDropDownData from "../CourtDropDownData/CourtDropDownData";
import EditProfileCardPic from "./EditProfileCardPic";
import {useDispatch, useSelector} from "react-redux";
import {GetPaymentPlan} from "../../Store/Actions/PaymentPlan"
import {GetAllOfficers, GetOfficers} from "../../Store/Actions/Officers"
import {getUserDetails} from "../../Store/Actions/Auth";
import CircularSpinner from "./../CircularProgressUserReport/CircularSpinner"
import UserProfileCardPic from "./UserProfileCardPic";
const EditProfileDataTable = ({ handleEditProfile,isIndividualUser,responseData,setResponseData,username,email,date,phone,address,ShowUserType, image,courtData
                                ,officerData,payPlanData,first_name,middle_name,last_name}) => {
  const classes = useStyles()
  const [new_profile_image, setnew_profile_image] = useState(false);

  const { showToast } = useToast()
  const [currentData, setCurrentData] = useState({})
  const [openCourtModal, setOpenCourtModal] = useState(false)
  const [dropDownInput, setDropDownInput] = useState("")
  const [media, setMedia] = useState([])

  const imageUploader = useRef(null)
  const imageUploadHandler = () => {
    const found = imageUploader.current.click()
    setnew_profile_image(true);
  }

  const fileUploadHandler = async event => {
    let reader = new FileReader()
    let files = event.target.files[0]
    reader.onloadend = () => {
      const updatedMedia = [...media, { name: responseData.name, url: reader.result }]
      setMedia(updatedMedia)
      setResponseData({...responseData,image: reader.result, profile: { image: reader.result} })
    }

    reader.readAsDataURL(files)
    imageUploader.current.value = ""

  }

  const dispatch=useDispatch();

  const { data: paymentPlanData, responseData: paymentPlan } = useSelector(
    ({ paymentPlan }) => paymentPlan
  )

  const [openPaymentModal, setOpenPaymentModal] = useState(false)
  const paymentDropDownData = () => {
    return paymentPlanData?.map(item => ({ value: item.id, label: item.name }))

  }
  useEffect(()=>{
    if(paymentPlanData.length>0)return;
    dispatch(GetPaymentPlan())
  },[])


  // edit parole officer

  const [openOfficerModal, setOpenOfficerModal] = useState(false)
  const { data: officersData, count: officersDataCount, dataExist: officersDataExist } = useSelector(({ officers }) => officers)

  const officersDropDownData = () => {

    return officersData?.map(item => ({
      value: item.id,
      label: item.first_name
    }))
  }
  useEffect(() => {
    if(officersData.length>0 && officersData.length===officersDataCount)return;
    if(officersDataExist)
    dispatch(GetAllOfficers())
  }, [officersData])

  const [creditLoading,setCreditLoading]=useState(false)
  const handleClickUser=async(event)=>{

    setCreditLoading(true)
    try {
      await axios.post(`/manage-users/profile/`, {...responseData, new_profile_image})
      showToast(" Edit Profile Success", "success")

      dispatch(getUserDetails())
      handleEditProfile(false);
      setCreditLoading(false)
    }
    catch (e) {
      setCreditLoading(false)
      showToast("Could not Edit Profile", "error")
    }
  }
  const handleClickCancle=()=>{
    handleEditProfile(false);
  }
  const inputEvent=(event)=>{
    const {name,value}=event.target;
    setResponseData((prevalue)=>{
      return{
        ...prevalue,
        [name]:value
      }
    })
  }
  const normalizeInput = (value, previousValue) => {
    if (!value) return value;

    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {

      if (cvLength < 4) return currentValue;

      if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    }
  };

  const handlePhNoChange = (event) => {
    const {name,value}=event.target;
    setResponseData(prevState=>({...prevState,[name]:normalizeInput(value,prevState[name])}));
  };
     return(

            <div>
              {creditLoading?<CircularSpinner/>: isIndividualUser && DataArray.map((element)=>{
                return(
                <>
                  <div className={classes.cardWrapper}>
                      <EditProfileCardPic responseData={responseData} image={image} imageUploader={imageUploader} imageUploadHandler={imageUploadHandler}
                                          fileUploadHandler={fileUploadHandler} username={username} email={email} first_name={first_name} last_name={last_name}
                      />
                  <Card className={classes.maincardinfo}>
                    <div className={classes.detailWrapper}>
                      <div className={classes.cardDetail}>
                        <span className={classes.span}>User Type</span><br/>
                        <input type="text"
                               value={ShowUserType}
                               className={classes.editprofileData}
                               min="0"
                               style={{outline:"none"}}
                               readOnly
                        />
                        {/*<span> {ShowUserType}</span>*/}
                      </div>
                      <div className={classes.cardDetail} >
                        <span className={classes.span}>Court System</span><br/>

                        <input type="text"
                               value={courtData}
                               className={classes.editReadonlyData}
                               min="0"
                               style={{outline:"none"}}
                               readOnly
                        />
                      </div>
                      <div className={classes.cardDetail}>
                        <span className={classes.span}>Payment Plan</span><br/>
                        <input type="text"
                               value={payPlanData}
                               className={classes.editReadonlyData}
                               min="0"
                               style={{outline:"none"}}
                               readOnly
                        />
                        {/*<SearchableDropDown*/}
                        {/*  data={paymentDropDownData()}*/}
                        {/*  handleClick={val =>*/}
                        {/*    setResponseData({ ...responseData, pay_plan_procedure: val })*/}
                        {/*  }*/}
                        {/*  openModalHandler={() => setOpenPaymentModal(true)}*/}
                        {/*  inputValue={(input) => setDropDownInput(input)}*/}
                        {/*  selectedValue={responseData.pay_plan_procedure}*/}
                        {/*/>*/}
                        {/*<span>{payPlanData!==undefined?pay_plan_procedure:<Loader/>}</span>*/}
                      </div>

                    </div>
                    <hr className={classes.hr} />
                    <div className={classes.detailWrapper}>
                      <div className={classes.cardDetail}>
                        <span className={classes.span}>First Name</span><br/>
                         <input type="text"
                                name="first_name"
                               value={responseData.first_name}
                                placeholder={responseData.first_name===null&&"Enter First Name"}
                                onChange={inputEvent}
                               // onChange={event=>setResponseData({...responseData,first_name:event.target.value})}
                               className={classes.editprofileData}
                         />
                        {/*<div className={classes.borderEffect}></div>*/}
                      </div>
                      <div className={classes.cardDetail}>
                        <span className={classes.span}>Middle Name</span><br/>
                        <input type="text"
                               name="middle_name"
                               value={responseData.middle_name}
                               placeholder={responseData.middle_name===null&&"Enter Middle Name"}
                               onChange={inputEvent}
                               className={classes.editprofileData}
                        />
                      </div>
                      <div className={classes.cardDetail}>
                        <span className={classes.span}>Last Name</span><br/>
                        <input type="text"
                               name="last_name"
                               value={responseData.last_name}
                               placeholder={responseData.last_name===null&&"Enter Last Name"}
                               onChange={inputEvent}
                               className={classes.editprofileData}
                        />
                      </div>
                    </div>
                    <hr className={classes.hr} />
                    <div className={classes.detailWrapper}>
                      <div className={classes.cardDetail}>
                        <span className={classes.span}>Date of Birth</span><br/>
                        <input type="date"
                               name="date_of_birth"
                               className={classes.editprofileData}
                               value={responseData.date_of_birth}
                               placeholder={responseData.date_of_birth===null&&"Enter DOB"}
                               onChange={inputEvent}
                        />
                      </div>
                      <div className={classes.cardDetail}>
                        <span className={classes.span}>Parole Officer</span><br/>
                        <input type="text"
                               value={officerData}
                               className={classes.editprofileData}
                               style={{outline:"none"}}
                               readOnly
                        />

                        {/*<SearchableDropDown*/}
                        {/*  data={officersDropDownData()}*/}
                        {/*  handleClick={val =>*/}
                        {/*    setResponseData({ ...responseData, parole_officer: val })*/}
                        {/*  }*/}
                        {/*  openModalHandler={() => setOpenOfficerModal(true)}*/}
                        {/*  inputValue={(input) => setDropDownInput(input)}*/}

                        {/*  selectedValue={officerData}*/}
                        {/*/>*/}


                        {/*<span>{officerData!==undefined?parole_officer:<Loader/>}</span>*/}
                      </div>
                      <div className={classes.cardDetail}>
                        <span className={classes.span}>Full Address</span><br/>
                        <input type="text"
                               name="address"
                               value={responseData.address}
                               placeholder={responseData.address===""&&"Enter Address"}
                               onChange={inputEvent}
                               className={classes.editprofileData}
                       />
                      </div>
                    </div>

                  </Card>
                  </div>
                  <div className={classes.mainContainer}>

                    <div>
                      <Card className={classes.infofficer}>
                        <div className={classes.userInfo}>
                          <div className={classes.subinfo1}>
                            <span className={classes.span}>Emergency Contact</span>
                            <br />
                            <input type="text"
                                   name="emergency_contact"
                                   value={responseData.emergency_contact}
                                   placeholder={responseData.emergency_contact===null && "Enter Contact"}
                                   onChange={inputEvent}
                                   className={classes.editprofileData}
                                   min="0"
                             />
                          </div>
                          <div className={classes.subinfo1}>
                            <span className={classes.span}>Email Address</span>
                            <br />
                            <input type="text"
                                   value={email}
                                   className={classes.editReadonlyData}
                                   min="0"
                                   style={{outline:"none"}}
                                   readOnly
                            />
                            {/*{email}*/}
                          </div>
                          <div className={classes.subinfo1}>
                            <span className={classes.span}>Resident Address</span>
                            <br /> <input type="text"
                                          name="address"
                                          placeholder={responseData.address===""&&"Enter Address"}
                                          value={responseData.address}
                                          onChange={inputEvent}
                                          className={classes.editprofileData}
                            />
                          </div>
                          <div className={classes.subinfo1}>
                            <span className={classes.span}>Home Phone</span>
                            <br />

                            <input type="text"
                                   name="home_phone"
                                   className={classes.editprofileData}
                                   placeholder={responseData.home_phone===null&&"Enter Phone"}
                                   value={responseData.home_phone}
                                   onChange={handlePhNoChange}
                                   min="0"
                            />
                          </div>

                        </div>
                        <hr className={classes.hr}/>
                        <div className={classes.userInfo}>
                          <div className={classes.subinfo1}>
                            <span className={classes.span}>Cell Phone</span>
                            <br />
                            <input type="text"
                                   name="phone"
                                   value={responseData.phone}
                                   placeholder={responseData.phone===null&&"Enter Cell Phone"}
                                   onChange={handlePhNoChange}
                                   className={classes.editprofileData}
                         />
                          </div>
                          <div className={classes.subinfo1}>
                            <span className={classes.span}>Alternative Phone</span>
                            <br />

                            <input type="text"
                                   name="alt_phone"
                                   value={responseData.alt_phone}
                                   placeholder={responseData.phone===null&&"Enter Phone"}
                                   onChange={handlePhNoChange}
                                   className={classes.editprofileData}
                                   min="0"
                           />
                          </div>
                          <div className={classes.subinfo1}>
                            <span className={classes.span}>

                            </span>
                            <br />

                          </div>
                          <div className={classes.subinfo1}>
                            <span className={classes.span}></span>
                            <br />

                          </div>
                        </div>
                      </Card>

                      <div className={classes.buttonWrapper}>
                        <Button
                          variant="contained"
                          color="secondary"
                          className={classes.btnCancle}
                          style={{ backgroundColor: "white" ,color:"#3971FF",border:"none"}}
                          onClick={handleClickCancle}
                        >
                          CANCEL
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          className={classes.button}
                          style={{ marginLeft: "10px" }}
                          onClick={handleClickUser}
                        >
                          SAVE CHANGES
                        </Button>

                      </div>
                    </div>
                  </div>
                </>
                )
              })}
              {creditLoading?<CircularSpinner/>: !isIndividualUser && CourtDataArray.map((element)=>{
                return(
                  <>
                    <div className={classes.cardWrapper}>

                      <EditProfileCardPic responseData={responseData} image={image} imageUploader={imageUploader} imageUploadHandler={imageUploadHandler}
                                          fileUploadHandler={fileUploadHandler} username={username} email={email} first_name={first_name} last_name={last_name}
                      />

                      <Card className={classes.maincardinfo}>
                        <div className={classes.detailWrapper}>
                          <div className={classes.cardDetail}>
                            <span className={classes.span}>First Name</span>
                            <br />
                            
                            <input type="text"
                                   name="first_name"
                                   value={responseData.first_name}
                                   onChange={inputEvent}
                                   className={classes.editprofileSelect}
                            />
                          </div>
                          
                          <div className={classes.cardDetail}>
                            <span className={classes.span}>Middle Name</span>
                            <br />
                            <input type="text"
                                   name="middle_name"
                                   value={responseData.middle_name}
                                   onChange={inputEvent}
                                   className={classes.editprofileSelect}
                            />
                          </div>
                          <div className={classes.cardDetail}>
                            <span className={classes.span}>Last Name</span>
                            <br />
                            
                            <input type="text"
                                   name="last_name"
                                   value={responseData.last_name}
                                   onChange={inputEvent}
                                   className={classes.editprofileSelect}
                            />
    
                          </div>
                        </div>
                        
                        <div className={classes.detailWrapper}>
                          <div className={classes.cardDetail}>
                            <span className={classes.span}>Gender</span>
                            <br />
                            <select className={classes.editprofileSelect}>
                              <option>{element.Gender}</option>
                              <option>Female</option>
                            </select>
                          </div>
                          <div className={classes.cardDetail}>
                            <span className={classes.span}>Birthday</span>
                            <br />
                            <input type="date"
                                   name="dob"
                                   value={responseData!==undefined?responseData.date_of_birth:<Loader/>}
                                   className={classes.editprofileSelect}
                                  onChange={event=>setResponseData({...responseData,date_of_birth:event.target.value})}
                            />
                          </div>
                          <div className={classes.cardDetail}>
                            <span className={classes.span}>Phone Number</span>
                            <br />
                            <input type="text" name="phone" value={responseData!==undefined?phone:<Loader/>}
                                    onChange={handlePhNoChange}
                                   // onChange={event=>setResponseData({...responseData,phone:event.target.value})}
                                   className={classes.editprofileSelect}
                                    min={0}
                            />

                          </div>
                        </div>
                        <hr className={classes.hr} />
                        <div className={classes.detailWrapper}>
                          <div className={classes.cardDetail}>
                            <span className={classes.span}>Street Address</span>
                            <br />
                            <input type="text" name="address" value={responseData!==undefined?address:<Loader/>}
                                   onChange={event=>setResponseData({...responseData,address:event.target.value})}
                                   className={classes.editprofileSelect}/>
                          </div>
                          <div className={classes.cardDetail}>
                            <span className={classes.span}>City</span>
                            <br />
                            <select className={classes.editprofileSelect} >
                              <option>{responseData!==undefined?address:<Loader/>}</option>

                            </select>
                          </div>
                          <div className={classes.cardDetail}>
                            <span className={classes.span}>Zip Code</span>
                            <br />
                            <input type="text"
                                   value={element.zipCode}
                                   // value={responseData!==undefined?address:<Loader/>}
                                   // onChange={event=>setResponseData({...responseData,address:event.target.value})}
                                   className={classes.editprofileSelect}/>
                          </div>
                        </div>

                      </Card>
                    </div>

                    <div className={classes.buttonWrapper}>
                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.btnCancle}
                        onClick={handleClickCancle}
                      >
                        CANCEL
                      </Button>

                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        style={{ marginLeft: "10px" }}
                        onClick={handleClickUser}
                      >
                        SAVE CHANGES
                      </Button>

                    </div>
                  </>
                )
              })}

            </div>

          )



}

export default EditProfileDataTable
