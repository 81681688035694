  import React, { useState, useEffect } from "react"
import { ThemeProvider } from "@material-ui/core/styles"
import theme from "../src/Theme/theme"
import Routes from "../src/Routes/Routes"
import ResponsiveRoutes from "../src/Routes/ResponsiveRoutes"
import { Provider } from "react-redux"
import ConfigureStore from "../src/Store/Store"
import { ScreenContext } from "./context/context"
import { ReportProvider } from "./context/ReportContext"

import Toast from "./Components/Toast"
import { ToastProvider } from "./Components/Toast/useToast"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"

const { store } = ConfigureStore()
const stripePromise = loadStripe(
  "pk_test_51JDv7lFbqbf1nSqd7SVuxVqxK8EGu91uxEjP5akKw9BTcq6tn6K887DcHk9P4NNm4SAZTbYrnggePBXnvTOnatDO00QGWHi6Tx"
)

const App = () => {
  const [screenSize, setScreenSize] = useState(window.innerWidth)

  useEffect(() => {
    setScreenSize(window.innerWidth)
  }, [screenSize])

  const changed = () => {
    setScreenSize(window.innerWidth)
  }

  window.addEventListener("resize", changed)

  return (
    <Provider store={store}>
      <Elements stripe={stripePromise}>
        <ReportProvider>
          <ScreenContext.Provider value={{ screenSize, setScreenSize }}>
            <ThemeProvider theme={theme}>
              <ToastProvider>
                <Toast />
                {screenSize < 800 ? <ResponsiveRoutes /> : <Routes />}
              </ToastProvider>
            </ThemeProvider>
          </ScreenContext.Provider>
        </ReportProvider>
      </Elements>
    </Provider>
  )
}

export default App
