import { makeStyles } from "@material-ui/core/styles";


export const useStyles = makeStyles((theme) => ({
  MainContainer: {
    width: "100%",
    height: 97,
    background: "#FFFFFF",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-around'
  },
  
  selection: {
    width: '150px',
    height:'30px',
    borderRadius: '5px',
    paddingLeft: '7px',
    color: 'rgb(70,70,250)',
    border: '1px solid lightgray',
    marginLeft:'10px' 
  
  },
HeaderInner: {
  padding: "2px 10px",
    display: "flex",
      alignItems: "center",
        justifyContent: "space-between"
},

IconWrapper: {
  borderRadius: 10,
    display: "flex",
      justifyContent: "center",
        alignItems: "center",
          background: "#81A4FF",
            margin: "0px 20px",
              width: 47,
                height: 44,
  },
icon: {
  width: 24,
    height: 24
},
MainHeading: {
  color: "#363E51",
    fontFamily: theme.fonts.family.Medium,
      fontSize: theme.fonts.sizes.fontXL,
        fontWeight: 500,
          textTransform: "uppercase"
},
Wrapper: {
  display: "flex",
    alignItems: "center",
  },

  
button: {
  background: "#B5B5B5",
    margin: "0px 20px",
      "&.MuiButton-containedSecondary": {
    width: 150,
      height: 30,
        display: "flex",
          alingItems: "center",
            borderRadius: "5px !important",
              "&:hover": {
      background: "#B5B5B5",
      },
    "& .MuiButton-label": {
      fontFamily: theme.fonts.family.SemiBold,
        fontSize: 12,
          fontWeight: "bold",
            textTransform: "capitalize",
              marginTop: 3,
      },
    "& .MuiSvgIcon-root": {
      marginTop: -2
    },
    "&.MuiButton-root":{
      lineHeight:"0 !important",
      letterSpacing: 0
    }
  }
},
newbutton: {
  backgroundColor: "#3971FF !important",
    margin: "0px 20px",
      "&.MuiButton-containedSecondary": {
    width: 150,
      height: 30,
        display: "flex",
          alingItems: "center",
            borderRadius: "5px !important",
              "&:hover": {
      background: "#B5B5B5",
      },
    "& .MuiButton-label": {
      fontFamily: theme.fonts.family.SemiBold,
        fontSize: 12,
          fontWeight: "bold",
            textTransform: "capitalize",
              marginTop: 3,
      },
    "& .MuiSvgIcon-root": {
      marginTop: -2
    },
    "&.MuiButton-root":{
      lineHeight:"0 !important",
      letterSpacing: 0
    }
  }
}
}));


export const finalStyles = makeStyles(theme => ({
  MainCont: {
    width: "100%",
    height: 127,
    background: "#FFFFFF",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)"
  },

  topMain: {
    height: 117,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%"
  },

  upperMain: {
    height: 65,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
    alignItems: "center"
  },

  bottomCont: {
    height: 45,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    alignItems: "center"
  },

  flex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center"
  }
}))
