import React, {useEffect, useState} from "react"
import {useStyles} from "./ClientPricingStyle"
import Container from '@material-ui/core/Container';
import ClientPricingHeader from "../../Components/ClientPricingHeader/ClientPricingHeader"
import ClientPricingDataTable from "../../Components/ClientPricingDataTable/ClientPricingDataTable"
import NewPagination from "../../Components/NewPagination/NewPagination";
import {useDispatch, useSelector} from "react-redux";
import {GetCourtPricing} from "../../Store/Actions/CourtPricing";
import axios from "../../Store/request";
import {GetClientPricing} from "../../Store/Actions/ClientPricing";
import {CircularProgress} from "@material-ui/core";
import CircularSpinner from "../../Components/CircularProgress/CircularSpinner";
import Pagination_New from "../../Components/NewPagination/Pagination_New";


const ClientPricing = () => {
  const classes = useStyles()

  const [isDisabled,setIsDisabled]=useState(false);
  const dispatch = useDispatch()
  const [clientPricingData, setClientPricingData] = useState({
    court:"",
    service: "",
    price: "",
  })
  const {data,count,loading} = useSelector(({ clientPricing }) => clientPricing)
  // useEffect(() => {
  //   dispatch(GetClientPricing())
  // }, [])

  const [serviceList, setServiceList] = useState([])
  const [addedClientPricing,setAddedClientPricing] = useState(true)
  const [pricingArray, setPricingArray] = useState([])
  const [filterTxt, setFilterTxt] = useState('');
  var filteredData = []

  const filterData = (e) => {
    let val = e.target.value
    setFilterTxt(val)
  }
  filteredData = data?.filter(element => {
    return (element.service.name?.toLowerCase().includes(filterTxt?.toLowerCase()))
  })
  // Get current posts
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);
  
  return (
    <div className={classes.MainContainer}>
      <ClientPricingHeader searchText={filterData} isDisabled={isDisabled} setIsDisabled={setIsDisabled} serviceList={serviceList} setServiceList={setServiceList}
                           addedClientPricing={addedClientPricing}
                           setAddedClientPricing={setAddedClientPricing}
                           clientPricingData={clientPricingData}
                           setClientPricingData={setClientPricingData}

      />
      {loading?
       <CircularSpinner/>
        :
      <Container maxWidth="lg" className={classes.containerMob}>
         {filterTxt!==""?<ClientPricingDataTable DataArray={filteredData} isDisabled={isDisabled} setIsDisabled={setIsDisabled}/>
         :<ClientPricingDataTable DataArray={currentPosts} isDisabled={isDisabled} setIsDisabled={setIsDisabled} filterTxt={filterTxt}
                                  setServiceList={setServiceList}
                                  clientData={clientPricingData}
           />}
    </Container>}
      {count > 10 &&
      <>
        <Container maxWidth="lg" style={{visibility: loading === true ? "hidden" : "visible"}}>
          {
            clientPricingData.court === ""
            &&
            // <NewPagination
            //     postsPerPage={postsPerPage}
            //     totalPosts={count}
            //     activePage={currentPage}
            //     // paginate={paginate}
            //     paginate={data.length===count && data.length>0?(pageNumber) => {setCurrentPage(pageNumber)}:(pageNumber) => {
            //       dispatch(GetCourtPricing({limit: postsPerPage, offset: (postsPerPage * pageNumber - postsPerPage)}))
            //     }}
            //   />
            <Pagination_New count={count}
                            activePage={currentPage}
                            postsPerPage={postsPerPage}
                            paginate={(pageNumber) => {
                              setCurrentPage(pageNumber)
                            }}
            />
          }
    
        </Container>
      </>
      }
      
    </div>
  )
}

export default ClientPricing
