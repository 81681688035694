import React, {useEffect, useState} from "react"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import { useStyles } from "./UserProfileDataTableStyle"
import Button from "@material-ui/core/Button"
import Ellipse1 from "../../assets/Ellipse1.png"
import DataArray from "../UserProfileDataTable/DataArray.js"
import { CourtDataArray } from "./DataArray"
import EditProfileDataTable from "./EditProfileDataTable";
import {GetUser} from "../../Store/Actions/ManageUserAction"
import {useDispatch, useSelector} from "react-redux";
import Actions from "../../Store/Type";
import axios from "../../Store/request";
import Loader from "./Loader";
import {GetCourt} from "../../Store/Actions/Court";
import UserProfileCardPic from "./UserProfileCardPic";
const UserProfileDataTable = ({ isIndividualUser ,handleEditProfile,responseData,username,email,phone,address,ShowUserType,user_type,first_name,middle_name,last_name,courtName,
                                date,emergency_contact,home_phone,alt_phone,payPlanName,officerName}) => {
  const classes = useStyles()
  const [editProfile,setEditProfile]=useState(false)
  const handleClickEdit=()=>{
    // showData()
   setEditProfile(true);
   handleEditProfile(true);
  }




  return (
    <>
      <div className={classes.mainContainer}>
        {isIndividualUser &&
          DataArray?.map(element => {
            return (
              <>
                <div className={classes.cardWrapper}>
                  <UserProfileCardPic responseData={responseData} username={username} email={email} first_name={first_name} last_name={last_name}/>
                  <Card className={classes.maincardinfo}>
                    <div className={classes.detailWrapper}>
                      <div className={classes.cardDetail}>
                        <span className={classes.span}>User Type</span>
                        <br />
                        <span> {ShowUserType}</span>
                      </div>
                      <div className={classes.cardDetail}>
                        <span className={classes.span}>Court System</span>
                        <br />
                        <span>{courtName}</span>
                      </div>
                      <div className={classes.cardDetail}>
                        <span className={classes.span}>Payment Plan</span>
                        <br />
                        <span>{payPlanName}</span>
                      </div>
                    </div>
                    <hr className={classes.hr} />
                    <div className={classes.detailWrapper}>
                      <div className={classes.cardDetail}>
                        <span className={classes.span}>First Name</span>
                        <br />
                        <span>{first_name}</span>
                      </div>
                      <div className={classes.cardDetail}>
                        <span className={classes.span}>Middle Name</span>
                        <br />
                        <span>{middle_name}</span>
                      </div>
                      <div className={classes.cardDetail}>
                        <span className={classes.span}>Last Name</span>
                        <br />
                        <span>{last_name}</span>
                      </div>
                    </div>
                    <hr className={classes.hr} />
                    <div className={classes.detailWrapper}>
                      <div className={classes.cardDetail}>
                        <span className={classes.span}>Date of Birth</span>
                        <br />
                        <span>{date}</span>
                      </div>
                      <div className={classes.cardDetail}>
                        <span className={classes.span}>Parole Officer</span>
                        <br />

                        <span>{officerName}</span>
                      </div>
                      <div className={classes.cardDetail}>
                        <span className={classes.span}>Full Address</span>
                        <br />
                        <span>{address}</span>
                      </div>
                    </div>
                  </Card>
                  {/*</div>*/}
                </div>


                <div>
                  <Card className={classes.infofficer}>
                    <div className={classes.userInfo}>
                      <div className={classes.subinfo1}>
                        <span className={classes.span}>Emergency Contact</span>
                        <br />
                        {emergency_contact}
                      </div>
                      <div className={classes.subinfo1_email}>
                        <span className={classes.span}>Email Address</span>
                        <br />
                        {email}
                      </div>
                      <div className={classes.subinfo1}>
                        <span className={classes.span}>Resident Address</span>
                        <br />
                        {address}
                      </div>
                      <div className={classes.subinfo1}>
                        <span className={classes.span}>Home Phone</span>
                        <br />
                        {home_phone}
                      </div>
                    </div>
                    <hr className={classes.hr} />
                    <div className={classes.userInfo}>
                      <div className={classes.subinfo1}>
                        <span className={classes.span}>Cell Phone</span>
                        <br />
                        {phone}
                      </div>
                      <div className={classes.subinfo1}>
                        <span className={classes.span}>Alternative Phone</span>
                        <br />
                        {alt_phone}
                      </div>
                      <div className={classes.subinfo1}>
                        <span className={classes.span}></span>
                        <br />

                      </div>
                      <div className={classes.subinfo1}>
                        <span className={classes.span}></span>
                        <br />

                      </div>
                    </div>
                  </Card>
                </div>
                <div className={classes.buttonWrapper}>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    // style={{ color: "white" }}
                    // style={{ marginTop: "20px" }}
                    onClick={handleClickEdit}
                  >
                    Edit Profile
                  </Button>

                </div>
              </>

            )
          })}
        {!isIndividualUser &&
          CourtDataArray?.map(element => {
            return (
              <>
                <div className={classes.cardWrapper}>
                  <UserProfileCardPic responseData={responseData} username={username} email={email} first_name={first_name} last_name={last_name}/>

                  <Card className={classes.maincardinfo}>
                    <div className={classes.detailWrapper}>
                      <div className={classes.cardDetail}>
                        <span className={classes.span}>Gender</span>
                        <br />
                        <span>{element.Gender}</span>
                      </div>
                      <div className={classes.cardDetail}>
                        <span className={classes.span}>Birthday</span>
                        <br />
                        <span>
                            {date}


                        </span>
                      </div>
                      <div className={classes.cardDetail}>
                        <span className={classes.span}>Phone Number</span>
                        <br />
                        <span>
                          {phone}
                        </span>
                      </div>
                    </div>
                    <hr className={classes.hr} />
                    <div className={classes.detailWrapper}>
                      <div className={classes.cardDetail}>
                        <span className={classes.span}>Street Address</span>
                        <br />
                        <span>
                          {address}
                        </span>
                      </div>
                      <div className={classes.cardDetail}>
                        <span className={classes.span}>City</span>
                        <br />
                        <span>
                          {address}
                        </span>
                      </div>
                      <div className={classes.cardDetail}>
                        <span className={classes.span}>Zip Code</span>
                        <br />
                        <span>{element.zipCode}</span>
                      </div>
                    </div>
                    <hr className={classes.hr} />
                    <div className={classes.detailWrapper}>
                      <div className={classes.cardDetail}>
                        <span className={classes.span}>Member Status</span>
                        <br />
                        <span>{element.MemberStatus}</span>
                      </div>
                      <div className={classes.cardDetail}>
                        <span className={classes.span}>Registered Date</span>
                        <br />
                        <span>
                           {date}
                        </span>
                      </div>
                      <div className={classes.cardDetail}>
                        <span className={classes.span}></span>
                        <br />
                        <span></span>
                      </div>
                    </div>
                  </Card>
                  {/*</div>*/}
                </div>
                <div>
                  <Card className={classes.infofficer}>
                    <div className={classes.userInfo}>
                      <div className={classes.subinfo1}>
                        <span className={classes.span}>User Type</span>
                        <br />
                        {
                          user_type===true?<span>Active</span>:<span>Deactive</span>
                         }
                      </div>
                      <div className={classes.subinfo1}>
                        <span className={classes.span}>Officer Firstname</span>
                        <br />
                        {first_name}
                      </div>
                      <div className={classes.subinfo1}>
                        <span className={classes.span}>Officer Lastname</span>
                        <br />
                        {last_name}
                      </div>
                      <div className={classes.subinfo1}>
                        <span className={classes.span}>Court Name</span>
                        <br />
                        {courtName}
                      </div>
                    </div>
                    <hr className={classes.hr} />
                    <div className={classes.userInfo}>
                      <div className={classes.subinfo1}>
                        <span className={classes.span}>Statement</span>
                        <br />
                        {element.Statement}
                      </div>
                      <div className={classes.subinfo1_email}>
                        <span className={classes.span}>Email Address</span>
                        <br />
                        {email}
                      </div>
                      <div className={classes.subinfo1}>
                        <span className={classes.span}></span>
                        <br />

                      </div>
                      <div className={classes.subinfo1}>
                        <span className={classes.span}></span>
                        <br />

                      </div>
                    </div>
                  </Card>
                </div>
                <div className={classes.buttonWrapper}>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    // style={{ color: "white" }}
                    // style={{ marginTop: "20px" }}
                    onClick={handleClickEdit}
                  >
                    Edit Profile
                  </Button>
                </div>
              </>
            )
          })}

        {/*<div className={classes.buttonWrapper}>*/}
        {/*  <Button*/}
        {/*    variant="contained"*/}
        {/*    color="secondary"*/}
        {/*    className={classes.button}*/}
        {/*    // style={{ color: "white" }}*/}
        {/*    // style={{ marginTop: "20px" }}*/}

        {/*  >*/}
        {/*    Edit Profile*/}
        {/*  </Button>*/}
        {/*</div>*/}
      </div>
    </>
  )
}

export default UserProfileDataTable
